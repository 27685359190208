import { io, Socket } from "socket.io-client";

let socket: Socket | null = null;

export const initializeWebSocket = async (token: string): Promise<Socket> => {
  if (!socket) {
    socket = io(
      "https://websocket.freetech.co",
      {
        withCredentials: true,
        auth: {
          token: token || "",
        },
        transports: ["websocket"],
        reconnectionAttempts: 5,
        reconnectionDelay: 1000,
        timeout: 60000,
      }
    );
  }

  return socket;
};
