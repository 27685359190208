import { collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";
import { loadClientsNonAdmin } from "core/clients";
import { UserInfoType } from "@freetech/models/user";
import { ClientInvoiceData } from "@freetech/models/bank";
import { invoiceConverter } from "@freetech/models/converters/invoiceConverter";

export const loadMyStakeholderInvoices = async (privateUserInfo: UserInfoType) => {
  const clients = await loadClientsNonAdmin(privateUserInfo);

  let invoices: ClientInvoiceData[] = [];

  await Promise.all(clients.map(
    async (client) => {
      const invoicesRef = collection(db, 'clients', client.id, 'invoices').withConverter(invoiceConverter);
      const invoiceDocs = await getDocs(invoicesRef);
      const data = invoiceDocs.docs.map((doc) => {
        const docData = doc.data();
        return docData;
      });
      invoices = [...invoices, ...data];
    }
  ));

  console.log(invoices);

  return invoices;
};