import React, { useState } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Alert,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import CompleteProfile from "./CompleteProfile";
import CompanySetup from "./CompanySetup";
import SetupPayment from "./SetupPayment";
import ProjectSetup from "./ProjectSetup";
import ReviewStep from "./ReviewStep";
import WelcomeStep from "./WelcomeStep";
import { useStakeholderOnboarding } from "hooks/stakeholder/useStakeholderOnboarding";
import { useAuth } from "hooks/auth/useAuth";
import { StepData } from "./types";

const onboardingSteps = [
  {
    label: "Personal Information",
    description: "Tell us about yourself",
  },
  {
    label: "Company Setup",
    description: "Set up your company information",
  },
  {
    label: "Project Setup",
    description: "Set up your first project",
  },
  {
    label: "Payment Setup",
    description: "Set up your payment information",
  },
  {
    label: "Review & Verify",
    description: "Review and verify your information",
  },
];

export const StakeholderOnboarding: React.FC = () => {
  const [activeStep, setActiveStep] = useState(-1); // Start at -1 for welcome step
  const [stepData, setStepData] = useState<StepData>({});
  const { updateProfile, isUpdating, error } = useStakeholderOnboarding();

  const handleNext = async (data: any) => {
    if (activeStep === -1) {
      setActiveStep(0);
      return;
    }

    try {
      await updateProfile({ step: activeStep, data });
      setStepData((prev) => ({
        ...prev,
        [activeStep]: data,
      }));
      setActiveStep((prevStep) => prevStep + 1);
    } catch (err) {
      console.error("Error updating profile:", err);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => Math.max(-1, prevStep - 1));
  };

  const renderStepContent = (step: number) => {
    if (step === -1) {
      return <WelcomeStep onNext={() => handleNext({})} />;
    }

    switch (step) {
      case 0:
        return (
          <CompleteProfile
            onNext={(data) => handleNext(data)}
            initialData={stepData.personalInfo}
          />
        );
      case 1:
        return (
          <CompanySetup
            onNext={(data) => handleNext(data)}
            onBack={handleBack}
            initialData={stepData.companyInfo}
          />
        );
      case 2:
        return (
          <ProjectSetup
            onNext={(data) => handleNext(data)}
            onBack={handleBack}
            initialData={stepData.projectInfo}
          />
        );
      case 3:
        const paymentInfo = stepData.paymentInfo;
        return (
          <SetupPayment
            onNext={async (data) => {
              if (data === null) {
                await handleNext({});
              } else {
                await handleNext(data);
              }
            }}
            onBack={handleBack}
            initialData={paymentInfo}
          />
        );
      case 4:
        return (
          <ReviewStep
            stepData={Object.values(stepData)}
            onNext={() => handleNext({})}
            onBack={handleBack}
            isUpdating={isUpdating}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 800, mx: "auto", p: 3 }}>
      {activeStep >= 0 && (
        <>
          <Typography variant="h4" gutterBottom align="center">
            Welcome to FreeTech!
          </Typography>
          <Typography
            variant="body1"
            gutterBottom
            align="center"
            sx={{ mb: 4, paddingInline: 16 }}
          >
            We're excited to create value for your organization and connect you
            with the right talent. Let's get your stakeholder profile set up.
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 3 }}>
              Error updating profile: {error.message}
            </Alert>
          )}

          <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
            {onboardingSteps.map((step) => (
              <Step key={step.label}>
                <StepLabel>
                  <Typography variant="body2">{step.label}</Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </>
      )}

      {renderStepContent(activeStep)}
    </Box>
  );
};

export default StakeholderOnboarding;
