import React, { useEffect, useState, useRef, useCallback } from "react";
import {
  Box,
  Paper,
  IconButton,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import CodeBlockLowlight from "@tiptap/extension-code-block-lowlight";
import { common, createLowlight } from "lowlight";
import TaskList from "@tiptap/extension-task-list";
import TaskItem from "@tiptap/extension-task-item";
import Link from "@tiptap/extension-link";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
// import Suggestion from "@tiptap/suggestion";
import { Editor, Range } from "@tiptap/core";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import "./KnowledgeBaseDocument.css";
import { generateId } from "core/knowledgeBase/utils/generateId";
import { DateTime } from "luxon";
import { useKnowledgeBase } from "hooks/knowledgeBase/useKnowledgeBase";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import { useAuth } from "hooks/auth/useAuth";
import { useCollaboration } from "hooks/knowledgeBase/useCollaboration";
import DocumentHeader from "../DocumentHeader/DocumentHeader";
import { DocumentMenuBar } from "../DocumentMenuBar/DocumentMenuBar";
import {
  SlashCommands,
  getSuggestions,
  renderItems,
} from "../DocumentSlashCommands/DocumentSlashCommands";
import { LanguageSelector } from "../LanguageSelector/LanguageSelector";
import ConnectionBanner from "../ConnectionBanner/ConnectionBanner";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { CodeBlock } from "../CodeBlock/CodeBlock";
import Image from "@tiptap/extension-image";
import { KnowledgeBaseFile } from "types/knowledgeBase";
const lowlight = createLowlight(common);

interface CommandProps {
  editor: Editor;
  range: Range;
}

interface SuggestionItem {
  title: string;
  command: (props: CommandProps) => void;
}

interface SuggestionGroup {
  title: string;
  items: SuggestionItem[];
}

interface CommandsListProps {
  items: SuggestionGroup[];
  command: (item: SuggestionItem) => void;
}

interface SuggestionProps {
  editor: Editor;
  range: Range;
  props?: any;
}

interface KnowledgeBaseDocumentProps {
  document: KnowledgeBaseFile | null;
  isEditing: boolean;
  editingTitle: string;
  editingContent: string;
  onEditingTitleChange: (title: string) => void;
  onCancel: () => void;
  onEdit: () => void;
  onClose: () => void;
  onContentChange?: (content: string) => void;
  onDelete?: () => void;
  collaborationDocId?: string;
  setSelectedDocument?: (document: KnowledgeBaseFile) => void;
}

const AUTO_SAVE_DELAY = 3000; // 3 seconds

interface ConnectionState {
  attemptCount: number;
  lastAttempt: Date | null;
  maxAttempts: number;
  lastStatus: "connected" | "disconnected" | "reconnecting" | "connecting";
  stableStatus: "connected" | "disconnected" | "reconnecting" | "connecting";
  disconnectedSince: Date | null;
}

const KnowledgeBaseDocument: React.FC<KnowledgeBaseDocumentProps> = ({
  document,
  isEditing,
  editingTitle,
  editingContent,
  onEditingTitleChange,
  onCancel,
  onEdit,
  onClose,
  onContentChange,
  onDelete,
  collaborationDocId = "default-doc",
  setSelectedDocument,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  // Add new state to track content changes
  const [localContent, setLocalContent] = useState(editingContent);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  // Add this state for saving status
  const [isSaving, setIsSaving] = useState(false);

  // Add these state variables near the other state declarations
  const [lastAutoSave, setLastAutoSave] = useState<Date | null>(null);
  const [lastContentChange, setLastContentChange] = useState<Date | null>(null);
  const [isTyping, setIsTyping] = useState(false);

  const { updateFile } = useKnowledgeBase();

  const [isTitleEditing, setIsTitleEditing] = useState(false);
  const titleInputRef = useRef<HTMLInputElement>(null);

  const [localTitle, setLocalTitle] = useState(editingTitle);
  const { userInfo } = useAuth();

  // Memoize the collaboration props to prevent unnecessary hook re-renders
  const collaborationProps = React.useMemo(
    () => ({
      documentId: document?.id,
      userName: userInfo?.firstName,
    }),
    [document?.id, userInfo?.firstName]
  );

  // Use memoized props
  const {
    provider,
    status,
    ydoc,
    updateCursorPosition,
    cursors,
    isReconnecting,
  } = useCollaboration(collaborationProps);

  const editorElementRef = useRef<HTMLDivElement>(null);

  const editor = useEditor(
    {
      extensions: [
        StarterKit.configure({
          history: false,
        }),
        // Only add collaboration extensions when provider is available
        ...(provider
          ? [
              Collaboration.configure({
                document: ydoc,
              }),
              CollaborationCursor.configure({
                provider: provider,
                user: {
                  name: userInfo?.firstName || "Anonymous",
                  color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
                  email: userInfo?.email || "No email",
                  uid: userInfo?.id,
                },
              }),
            ]
          : []),
        // Other extensions...
        Placeholder.configure({
          placeholder: ({ node }) => {
            if (node.type.name === "heading") {
              return "What's the title?";
            }
            return "Can you add some further context?";
          },
        }),
        Table.configure({
          resizable: true,
          HTMLAttributes: {
            class: "custom-table",
          },
        }),
        Image.configure({
          inline: true,
        }),
        TableRow,
        TableHeader,
        TableCell,
        TaskList,
        TaskItem,
        CodeBlockLowlight.extend({
          addNodeView() {
            return ReactNodeViewRenderer(CodeBlock);
          },
        }).configure({
          lowlight,
          defaultLanguage: "javascript",
          HTMLAttributes: {
            class: "code-block",
          },
        }),
        Link.configure({
          openOnClick: true,
          HTMLAttributes: {
            class: "custom-link",
          },
        }),
        SlashCommands.configure({
          suggestion: {
            items: getSuggestions,
            render: renderItems,
          },
        }),
      ],
      editable: isEditing,
      content: document?.content || "",
      onUpdate: ({ editor }) => {
        const content = editor.getHTML();
        handleContentChange(content);
      },
    },
    // Add provider to dependency array to reinitialize editor when provider becomes available
    [document?.content, isEditing, provider, ydoc, userInfo?.firstName]
  );

  // Add an effect to handle provider changes
  useEffect(() => {
    if (editor && provider) {
      // Reinitialize collaboration when provider changes
      editor.setEditable(isEditing);
      if (document?.content) {
        editor.commands.setContent(document.content);
      }
    }
  }, [editor, provider, isEditing, document?.content]);

  // Update editable state when isEditing changes
  useEffect(() => {
    if (editor) {
      editor.setEditable(isEditing);
    }
  }, [isEditing]);

  // Update content when document changes
  useEffect(() => {
    if (editor && document) {
      editor.commands.setContent(document.content || "");
    }
  }, [document]);


  const handleCopyLink = (id: string) => {
    // Get the base URL without hash
    const baseUrl = window.location.href.split("#")[0];
    // Create the full URL with the hash
    const url = `${baseUrl}#${id}`;
    navigator.clipboard.writeText(url);
    setSnackbarMessage("Link copied to clipboard!");
    setSnackbarOpen(true);
  };

  const handleTitleClick = () => {
    if (!isEditing) return;
    setIsTitleEditing(true);
    setLocalTitle(editingTitle);
    setTimeout(() => {
      titleInputRef.current?.focus();
      titleInputRef.current?.select();
    }, 0);
  };

  const handleTitleBlur = () => {
    setIsTitleEditing(false);
    if (localTitle !== editingTitle) {
      handleTitleUpdate(localTitle);
    }
  };

  const handleTitleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      setIsTitleEditing(false);
      if (localTitle !== editingTitle) {
        handleTitleUpdate(localTitle);
      }
    }
    if (e.key === "Escape") {
      setIsTitleEditing(false);
      setLocalTitle(document?.name || "");
      onEditingTitleChange(document?.name || "");
    }
  };

  const updateHeadingIds = (editor: Editor) => {
    const transaction = editor.state.tr;
    let hasChanges = false;

    editor.state.doc.descendants((node, pos) => {
      if (node.type.name === "heading") {
        const id = generateId(node.textContent);
        if (!node.attrs.id || node.attrs.id !== id) {
          transaction.setNodeMarkup(pos, undefined, {
            ...node.attrs,
            id,
          });
          hasChanges = true;
        }
      }
    });

    if (hasChanges) {
      editor.view.dispatch(transaction);
    }
  };

  useEffect(() => {
    if (!editor) return;

    const timeoutId = setTimeout(() => {
      if (editor) {
        updateHeadingIds(editor);
      }
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [editor?.getHTML()]);

  // Add this effect to handle hash changes without page refresh
  useEffect(() => {
    // Prevent default hash change behavior
    const handleHashChange = (e: HashChangeEvent) => {
      e.preventDefault();
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  useEffect(() => {
    if (editor && document) {
      editor.commands.setContent(document.content);
      onEditingTitleChange(document.name);
    }
  }, [editor, document, onEditingTitleChange]);

  useEffect(() => {
    if (editor) {
      editor.setEditable(isEditing);
    }
  }, [editor, isEditing]);

  useEffect(() => {
    // Scroll to hash on load
    const hash = window.location.hash.slice(1);
    if (hash && editor) {
      const element = window.document.getElementById(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [editor]);

  // Handle save
  const handleSave = async () => {
    if (editor && document) {
      try {
        setIsSaving(true);
        const content = editor.getHTML();
        const now = DateTime.now().toISO();

        await updateFile.mutateAsync({
          fileId: document.id,
          name: editingTitle,
          content: content,
        });

        // Create updated document with new timestamp
        const updatedDocument = {
          ...document,
          content,
          updatedAt: now,
        };

        // Update all relevant states
        setSelectedDocument?.(updatedDocument);
        setLastUpdatedTime(now);
        onContentChange?.(content);
        setSnackbarMessage("Document saved successfully");
        setSnackbarOpen(true);
        setHasUnsavedChanges(false);
        hasUnsavedChangesRef.current = false;
      } catch (error) {
        console.error("Failed to save:", error);
        setSnackbarMessage("Failed to save document");
        setSnackbarOpen(true);
      } finally {
        setIsSaving(false);
      }
    }
  };

  // Add loading state for save mutation
  const isSavingMutation = updateFile.isPending;

  // Handle cancel
  const handleCancel = () => {
    if (editor && document) {
      editor.commands.setContent(document.content);
      setLocalContent(document.content);
      onContentChange?.(document.content);
      setHasUnsavedChanges(false);
      hasUnsavedChangesRef.current = false;
      onCancel();
    }
  };

  // Add warning before closing with unsaved changes
  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [hasUnsavedChanges]);

  // Add a useEffect to track when user stops typing
  useEffect(() => {
    if (!isTyping) return;

    const typingTimeout = setTimeout(() => {
      setIsTyping(false);
    }, 1000); // Consider user stopped typing after 1 second of no changes

    return () => clearTimeout(typingTimeout);
  }, [lastContentChange]);

  const contentRef = useRef(editingContent);
  const hasUnsavedChangesRef = useRef(false);

  const [lastUpdatedTime, setLastUpdatedTime] = useState<string | null>(
    document?.updatedAt || null
  );

  const handleAutoSave = useCallback(async () => {
    if (!document || !hasUnsavedChangesRef.current || !editor) return;

    try {
      const selection = editor.state.selection;
      const now = DateTime.now().toISO();
      const content = editor.getHTML();

      await updateFile.mutateAsync({
        fileId: document.id,
        name: editingTitle,
        content: content,
      });

      // Create updated document with new timestamp
      const updatedDocument = {
        ...document,
        content,
        updatedAt: now,
      };

      // Update all relevant states
      setSelectedDocument?.(updatedDocument);
      setLastUpdatedTime(now);
      hasUnsavedChangesRef.current = false;
      setHasUnsavedChanges(false);
      setSnackbarMessage("Auto-saved successfully");
      setSnackbarOpen(true);

      if (selection && editor) {
        const transaction = editor.state.tr.setSelection(selection);
        editor.view.dispatch(transaction);
      }
    } catch (error) {
      console.error("Auto-save failed:", error);
    }
  }, [document, editingTitle, updateFile, editor, setSelectedDocument]);

  useEffect(() => {
    contentRef.current = editingContent;
  }, [editingContent]);

  const handleContentChange = useCallback(
    (newContent: string) => {
      onContentChange?.(newContent);
      setHasUnsavedChanges(true);
      hasUnsavedChangesRef.current = true;
      setLastContentChange(new Date());
      setIsTyping(true);
    },
    [onContentChange]
  );

  useEffect(() => {
    if (!isEditing || !document) return;

    const timer = setInterval(() => {
      if (hasUnsavedChangesRef.current) {
        handleAutoSave();
      }
    }, AUTO_SAVE_DELAY);

    return () => {
      clearInterval(timer);
    };
  }, [isEditing, document, handleAutoSave]);

  // Add this function to handle title updates
  const handleTitleUpdate = async (newTitle: string) => {
    if (!document || !newTitle.trim()) return;

    try {
      setIsSaving(true);
      await updateFile.mutateAsync({
        fileId: document.id,
        name: newTitle.trim(),
        content: document.content, // Keep existing content
      });

      onEditingTitleChange(newTitle);
      setHasUnsavedChanges(false);
      setSnackbarMessage("Title updated successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Failed to update title:", error);
      setSnackbarMessage("Failed to update title");
      setSnackbarOpen(true);
    } finally {
      setIsSaving(false);
    }
  };

  // Disable autosave when disconnected or reconnecting
  const isAutosaveEnabled = status === "connected" && !isReconnecting;

  const [manuallyDisconnected, setManuallyDisconnected] = useState(false);

  // Add these new states in the KnowledgeBaseDocument component
  const [connectionState, setConnectionState] = useState<ConnectionState>({
    attemptCount: 0,
    lastAttempt: null,
    maxAttempts: 10,
    lastStatus: "connected",
    stableStatus: "connected",
    disconnectedSince: null,
  });

  // Add this function to handle manual disconnection
  const handleManualDisconnect = useCallback(() => {
    provider?.disconnect();
    setManuallyDisconnected(true);
    setSnackbarMessage("Manually disconnected from server");
    setSnackbarOpen(true);
  }, [provider]);

  // Add this function to handle manual reconnection
  const handleManualReconnect = useCallback(() => {
    provider?.connect();
    setManuallyDisconnected(false);
    setConnectionState((prev: ConnectionState) => ({
      ...prev,
      attemptCount: 0,
      lastAttempt: new Date(),
      lastStatus: "connecting",
      stableStatus: "connecting",
      disconnectedSince: prev.disconnectedSince,
    }));
    setSnackbarMessage("Attempting to reconnect...");
    setSnackbarOpen(true);
  }, [provider]);

  // Add this before the return statement
  const showConnectionBanner =
    connectionState.stableStatus !== "connected" && !manuallyDisconnected;
  const showReconnectBanner = manuallyDisconnected;

  // Add this effect to track connection attempts
  useEffect(() => {
    if (status === "disconnected") {
      setConnectionState((prev: ConnectionState) => ({
        ...prev,
        lastStatus: "disconnected",
        stableStatus: "disconnected",
        disconnectedSince: prev.disconnectedSince || new Date(),
      }));
    } else if (status === "connecting") {
      setConnectionState((prev: ConnectionState) => ({
        ...prev,
        lastStatus: "connecting",
        stableStatus:
          prev.stableStatus === "connected" ? "connecting" : prev.stableStatus,
        lastAttempt: new Date(),
      }));
    } else if (isReconnecting) {
      setConnectionState((prev: ConnectionState) => ({
        ...prev,
        attemptCount: Math.min(prev.attemptCount + 1, prev.maxAttempts),
        lastAttempt: new Date(),
        lastStatus: "reconnecting",
        stableStatus:
          prev.stableStatus === "connected"
            ? "reconnecting"
            : prev.stableStatus,
      }));
    } else if (status === "connected") {
      setConnectionState((prev: ConnectionState) => ({
        ...prev,
        attemptCount: 0,
        lastStatus: "connected",
        stableStatus: "connected",
        disconnectedSince: null,
      }));
    }
  }, [isReconnecting, status]);

  // Add a connection status component to show in the document header
  const ConnectionStatusIndicator = () => {
    const getStatusColor = () => {
      switch (connectionState.lastStatus) {
        case "connected":
          return "success.main";
        case "connecting":
        case "reconnecting":
          return "warning.main";
        case "disconnected":
          return "error.main";
        default:
          return "text.secondary";
      }
    };

    const getStatusText = () => {
      switch (connectionState.lastStatus) {
        case "connected":
          return "Connected";
        case "connecting":
          return "Connecting...";
        case "reconnecting":
          return `Reconnecting (${connectionState.attemptCount}/${connectionState.maxAttempts})`;
        case "disconnected":
          return "Disconnected";
        default:
          return "Unknown";
      }
    };

    return (
      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: "50%",
            bgcolor: getStatusColor(),
            transition: "background-color 0.3s ease",
          }}
        />
        <Typography
          variant="caption"
          sx={{
            color: getStatusColor(),
            transition: "color 0.3s ease",
          }}
        >
          {getStatusText()}
        </Typography>
      </Box>
    );
  };

  if (!document) return null;

  return (
    <Paper
      elevation={2}
      sx={{
        position: "relative",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ConnectionBanner
        connectionState={connectionState}
        showConnectionBanner={showConnectionBanner}
        showReconnectBanner={showReconnectBanner}
        onManualDisconnect={handleManualDisconnect}
        onManualReconnect={handleManualReconnect}
      />

      <DocumentHeader
        document={document}
        isTitleEditing={isTitleEditing}
        localTitle={localTitle}
        editingTitle={editingTitle}
        isEditing={isEditing}
        isSaving={isSaving}
        provider={provider}
        onTitleChange={(e) => setLocalTitle(e.target.value)}
        onTitleBlur={handleTitleBlur}
        onTitleKeyDown={handleTitleKeyDown}
        onTitleClick={handleTitleClick}
        ConnectionStatusIndicator={ConnectionStatusIndicator}
      />

      <Box
        onClick={!isEditing ? onEdit : undefined}
        sx={{
          flex: 1,
          p: 3,
          cursor: isEditing ? "text" : "pointer",
          position: "relative",
          "& .ProseMirror": {
            height: "100%",
            minHeight: "400px",
            outline: "none",
            padding: "1rem",
          },
        }}
      >
        {isEditing && editor && (
          <DocumentMenuBar
            editor={editor}
            onSave={handleSave}
            onCancel={handleCancel}
            hasUnsavedChanges={hasUnsavedChanges}
            isSaving={isSaving || isSavingMutation}
          />
        )}
        <div ref={editorElementRef} className="element tiptap-editor">
          <EditorContent editor={editor} />
        </div>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Paper>
  );
};

export default React.memo(KnowledgeBaseDocument, (prevProps, nextProps) => {
  // Custom comparison function to determine if re-render is needed
  return (
    prevProps.document?.id === nextProps.document?.id &&
    prevProps.isEditing === nextProps.isEditing &&
    prevProps.editingTitle === nextProps.editingTitle &&
    prevProps.editingContent === nextProps.editingContent
  );
});
