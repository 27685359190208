import React, { createContext, useState, useEffect, ReactNode } from "react";
import {
  User,
  onAuthStateChanged,
  signOut,
  verifyPasswordResetCode,
  confirmPasswordReset,
  sendPasswordResetEmail,
  applyActionCode,
  onIdTokenChanged,
  getMultiFactorResolver,
  PhoneMultiFactorGenerator,
  TotpMultiFactorGenerator,
  signInWithEmailLink,
  checkActionCode,
  ActionCodeInfo,
} from "@firebase/auth";
import { auth, db } from "config/firebase";
import { handleLoginWithGoogle } from "core/auth/handleLoginWithGoogle";
import { handleLoginWithEmail } from "core/auth/handleLoginWithEmail";
import HyperDX from "@hyperdx/browser";
import { getAuthUserProfile } from "core/auth/getAuthUserProfile";
import {
  PrivateUserInfoType,
  LoginCredentials,
  Freelancer,
  Stakeholder,
  UserInfoType,
} from "types/user";
import { LoginMFACode } from "views/Auth/LoginMFACode";
import { MultiFactorError } from "@firebase/auth";
import { GoogleLoginResponse } from "core/auth/handleLoginWithGoogle";
import { MainLoading } from "router/layouts/MainLoading";
import { portalFunctions } from "core/functions/portalFunctions";
import { FirebaseError } from "@firebase/util";

export const EXPIRATION_TIME_MINUTES = 60 * 24;
export const EXPIRATION_TIME_SECONDS = EXPIRATION_TIME_MINUTES * 60;
export const EXPIRATION_TIME_MS = EXPIRATION_TIME_SECONDS * 1000;

type AuthContextType = {
  // User state
  currentUser?: User | null;
  userInfo?: UserInfoType;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  refreshUser: (currentUser: User) => Promise<void>;
  // Auth methods
  loginWithEmail: (credentials: LoginCredentials) => Promise<any>;
  loginWithGoogle: () => Promise<GoogleLoginResponse | MultiFactorError>;
  logout: () => void;

  // Email verification
  handleResendVerificationEmail: (email: string) => Promise<boolean>;
  handleVerifyActionCode: (code: string) => Promise<boolean>;
  handleSignInWithEmailLink: (code: string) => Promise<boolean>;

  // Password reset
  handleSendPasswordResetEmail: (email: string) => Promise<boolean>;
  handleVerifyPasswordResetCode: (code: string) => Promise<string>;
  handleConfirmPasswordReset: (
    code: string,
    newPassword: string
  ) => Promise<boolean>;
  remainingSessionTime: number | null;
};

type AuthProviderProps = {
  children: ReactNode;
};

export const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [userInfo, setUserInfo] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [remainingSessionTime, setRemainingSessionTime] = useState<
    number | null
  >(null);

  const loginWithEmail = async (credentials: LoginCredentials) => {
    try {
      const response = await handleLoginWithEmail(
        credentials.email,
        credentials.password,
        setUserInfo
      );

      if (
        response instanceof Error &&
        response.code === "auth/multi-factor-auth-required"
      ) {
        setIsLoading(false);
        return response;
      }

      setIsLoading(false);
      return response;
    } catch (error: any) {
      setIsLoading(false);
      return {
        success: false,
        error: error?.code || "auth/internal-error",
      };
    }
  };

  const loginWithGoogle = async () => {
    setIsLoading(true);
    try {
      const response = await handleLoginWithGoogle(setUserInfo);
      setIsLoading(false);
      return response;
    } catch (error: any) {
      switch (error.code) {
        case "auth/multi-factor-auth-required":
          setIsLoading(false);
          // return (window.location.href = "/login-mfa-code");
          // window.location.href = "/login-mfa-code";
          return error;
        default:
          console.error("Error logging in with Google:", error);
      }
      setIsLoading(false);
      return false;
    }
  };

  const logout = async () => {
    return await signOut(auth);
  };

  const handleResendVerificationEmail = async (email: string) => {
    try {
      await portalFunctions.public.resendVerificationEmail(email);
      return true;
    } catch (error) {
      console.error("Error resending verification email:", error);
      return false;
    }
  };

  const handleVerifyActionCode = async (code: string) => {
    console.log("[AuthContext] Attempting to verify action code:", code);
    try {
      await applyActionCode(auth, code);
      console.log("[AuthContext] Action code verified successfully");
      return true;
    } catch (error) {
      console.error("[AuthContext] Error verifying action code:", error);
      if (error instanceof FirebaseError) {
        console.log("[AuthContext] Firebase error code:", error.code);
        console.log("[AuthContext] Firebase error message:", error.message);
      }
      return false;
    }
  };

  const handleVerifyPasswordResetCode = async (code: string) => {
    console.log("[AuthContext] Attempting to verify password reset code");
    try {
      const userEmail = await verifyPasswordResetCode(auth, code);
      console.log(
        "[AuthContext] Password reset code verified for email:",
        userEmail
      );
      return userEmail;
    } catch (error) {
      console.error(
        "[AuthContext] Error verifying password reset code:",
        error
      );
      if (error instanceof FirebaseError) {
        console.log("[AuthContext] Firebase error code:", error.code);
        console.log("[AuthContext] Firebase error message:", error.message);
      }
      throw error;
    }
  };

  const handleSendPasswordResetEmail = async (email: string) => {
    const actionCodeSettings = {
      url: "http://localhost:3000",
      handleCodeInApp: false,
    };

    try {
      const response = await sendPasswordResetEmail(
        auth,
        email,
        actionCodeSettings
      );
      return true;
    } catch (error) {
      console.error("Error sending password reset email:", error);
      return false;
    }
  };

  const handleConfirmPasswordReset = async (
    code: string,
    newPassword: string
  ) => {
    try {
      const response = await confirmPasswordReset(auth, code, newPassword);
      return true;
    } catch (error) {
      console.error("Error confirming password reset:", error);
      return false;
    }
  };

  const handleSignInWithEmailLink = async (code: string) => {
    console.log("[AuthContext] Attempting to sign in with email link");
    try {
      // Get email from URL parameters
      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get("email");

      if (!email) {
        console.error("[AuthContext] No email found in URL parameters");
        return false;
      }
      console.log("[AuthContext] Found email in URL:", email);

      // await applyActionCode(auth, code);
      // console.log("[AuthContext] Email link verified successfully");

      // After verifying the link, sign in the user
      let signInLink = window.location.href.split("&email")[0];

      signInLink = signInLink.replace(
        "http://localhost:3000",
        "https://stg-portal.freetech.co"
      );

      const result = await signInWithEmailLink(auth, email, signInLink);
      console.log("[AuthContext] User signed in successfully:", result.user);
      // Update user info
      await refreshUser(result.user);

      return true;
    } catch (error) {
      console.error("[AuthContext] Error signing in with email link:", error);
      if (error instanceof FirebaseError) {
        console.log("[AuthContext] Firebase error code:", error.code);
        console.log("[AuthContext] Firebase error message:", error.message);
      }
      return false;
    }
  };

  const checkExpired = () => {
    const user = auth.currentUser;
    if (!user) return;

    user.getIdTokenResult().then((tokenResult) => {
      const issuedAt = tokenResult.claims.auth_time;
      if (!issuedAt) {
        console.error("No issuedAt found");
        return;
      }

      const issuedAtInt = parseInt(issuedAt) * 1000; // Convert seconds to milliseconds
      const twoHoursFromIssuedTime =
        issuedAtInt + EXPIRATION_TIME_MINUTES * 60 * 1000; // Add 2 hours to issued time
      const currentTime = Date.now();

      console.log({
        issuedAtInt,
        twoHoursFromIssuedTime,
        currentTime,
        hasExpired: currentTime > twoHoursFromIssuedTime,
      });

      if (currentTime > twoHoursFromIssuedTime) {
        logout();
        window.location.href = "/session-expired";
      } else {
        setRemainingSessionTime(twoHoursFromIssuedTime - currentTime);
      }
    });
  };

  const refreshUser = async (currentUser: User) => {
    checkExpired();

    await currentUser.reload();

    const docSnapData = await getAuthUserProfile(currentUser);

    console.log("docSnapData", docSnapData);
    const verified = currentUser.emailVerified;
    const data = { ...docSnapData, email_verified: verified };

    if (docSnapData) {
      setUserInfo((prev: PrivateUserInfoType | Freelancer | Stakeholder) => ({
        ...prev,
        ...data,
      }));
    }
  };

  useEffect(() => {
    setIsLoading(true);

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoading(true);
      try {
        if (user) {
          if (user.email) {
            HyperDX.setGlobalAttributes({
              userId: user.uid,
              userEmail: user.email,
            });
          }
          await refreshUser(user);
        } else {
          setUserInfo(null);
        }
      } catch (error) {
        console.error("Auth state change error:", error);
        setUserInfo(null);
      } finally {
        setIsLoading(false);
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser: auth.currentUser,
        userInfo,
        refreshUser,
        isLoading,
        setIsLoading,
        loginWithEmail,
        loginWithGoogle,
        logout,
        handleResendVerificationEmail,
        handleSendPasswordResetEmail,
        handleVerifyPasswordResetCode,
        handleConfirmPasswordReset,
        handleVerifyActionCode,
        handleSignInWithEmailLink,
        remainingSessionTime,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
