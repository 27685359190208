import { collection, addDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { BoardData } from "types/freelo/crud";

export const createBoard = async (data: BoardData): Promise<string> => {
  const boardRef = collection(db, "boards");
  const newBoardDoc = await addDoc(boardRef, {
    ...data,
    createdAt: DateTime.now().toISO(),
    updatedAt: DateTime.now().toISO(),
  });
  return newBoardDoc.id;
}; 