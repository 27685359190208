import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { portalFunctions } from "core/functions/portalFunctions";

interface AuthErrorDialogProps {
  open: boolean;
  onClose: () => void;
  title: string;
  errorMessage: string;
  showResetPassword?: boolean;
  showResendVerification?: boolean;
  resetToken?: string;
  email?: string;
  customButton?: {
    text: string;
    onClick: () => void;
  };
  hideDefaultButton?: boolean;
}

const AuthErrorDialog: React.FC<AuthErrorDialogProps> = ({
  open,
  onClose,
  title,
  errorMessage,
  showResetPassword = false,
  showResendVerification = false,
  resetToken = "",
  email = "",
  customButton,
  hideDefaultButton = false,
}) => {
  const navigate = useNavigate();

  if (!open) return null;

  const handleResendVerificationEmail = async () => {
    if (email) {
      await portalFunctions.public.resendVerificationEmail(email);
      onClose();
    }
  };

  const navigateToResetPassword = () => {
    onClose();
    navigate("/reset-password?token=" + resetToken);
  };

  return ReactDOM.createPortal(
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Typography>{errorMessage}</Typography>
      </DialogContent>
      <DialogActions>
        {showResendVerification && (
          <Button onClick={handleResendVerificationEmail} color="primary">
            Resend Verification Email
          </Button>
        )}
        {showResetPassword && (
          <Button onClick={navigateToResetPassword} color="primary">
            Reset Password
          </Button>
        )}
        {customButton && (
          <Button onClick={customButton.onClick} color="primary">
            {customButton.text}
          </Button>
        )}
        {!hideDefaultButton && (
          <Button onClick={onClose} color="primary">
            Ok
          </Button>
        )}
      </DialogActions>
    </Dialog>,
    document.body
  );
};

export default AuthErrorDialog;
