import { collection, addDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { timesheetConverter } from "@freetech/models/converters/timesheetConverter";
import { Timesheet } from "types/timesheet";
export const createTimesheet = async (
  timesheetData: Omit<Timesheet, "id">,
  userId: string
) => {
  // Ensure userId is included in the timesheet data
  const timesheetWithUser: Omit<Timesheet, "id"> = {
    ...timesheetData,
    userId,
  };
const timesheetsRef = collection(db, "timesheets").withConverter(
    timesheetConverter
  );
  const docRef = await addDoc(timesheetsRef, timesheetWithUser);
  return docRef.id;
};
