import React, { useEffect, useState } from "react";
import {
  Button,
  TextField,
  Link,
  Grid,
  Box,
  useTheme,
  Typography,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { authTextFieldStyle } from "core/theme";
import { errorMessages } from "core/errorMessages";
import { FcGoogle } from "react-icons/fc";
import { AuthErrorDialog } from "components";
import { useMFA } from "hooks/auth/useMFA";
import { MultiFactorError } from "@firebase/auth";
import { GoogleLoginResponse } from "core/auth/handleLoginWithGoogle";
import { getAuth } from "firebase/auth";

const Login = () => {
  const navigate = useNavigate();
  const { loginWithEmail, loginWithGoogle, handleVerifyActionCode } = useAuth();
  const { handleMFAError } = useMFA();
  const auth = getAuth();
  const [openDialog, setOpenDialog] = useState(false);
  const [resetToken, setResetToken] = useState("");
  const [dialogContent, setDialogContent] = useState({
    title: "",
    errorMessage: "",
    showResetPassword: false,
    showResendVerification: false,
    email: "",
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const googleError = searchParams.get("googleError");

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const credentials = {
      email: formData.get("email") as string,
      password: formData.get("password") as string,
    };

    try {
      const response = await loginWithEmail(credentials);

      if (!response.success) {
        // Check if this is an MFA challenge
        const mfaResolver = handleMFAError(response.error);
        if (mfaResolver) {
          navigate("/login-mfa-code");
          return;
        }

        setDialogContent({
          title: "Login Error",
          errorMessage: errorMessages[response.error || "auth/internal-error"],
          showResendVerification: response.error === "auth/unverified-email",
          showResetPassword: response.error === "auth/wrong-password",
          email:
            response.error === "auth/unverified-email" ? credentials.email : "",
        });
        setOpenDialog(true);
      }
    } catch (error: any) {
      const mfaResolver = handleMFAError(error);
      if (mfaResolver) {
        navigate("/login-mfa-code");
        return;
      }

      setDialogContent({
        title: "Login Error",
        errorMessage: errorMessages[error?.code || "auth/internal-error"],
        showResendVerification: false,
        showResetPassword: false,
        email: "",
      });
      setOpenDialog(true);
    }
  };

  const openLoginWithGooglePopup = async () => {
    // Clear any previous errors
    setSearchParams((params) => {
      params.delete("googleError");
      return params;
    });

    const response = await loginWithGoogle();

    if (
      response instanceof Error &&
      "code" in response &&
      response.code === "auth/multi-factor-auth-required"
    ) {
      const mfaResolver = handleMFAError(response as MultiFactorError);
      if (mfaResolver) {
        navigate("/login-mfa-code");
        return;
      }
    } else if ("success" in response) {
      if (!response.success) {
        setSearchParams((params) => {
          params.set(
            "googleError",
            errorMessages[response.error || "auth/internal-error"]
          );
          return params;
        });
      } else if (response.user) {
        navigate("/" + response.user.role);
      }
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    navigate("/login");
  };

  const theme = useTheme();

  return (
    <>
      <AuthErrorDialog
        open={openDialog}
        onClose={handleCloseDialog}
        title={dialogContent.title}
        errorMessage={dialogContent.errorMessage}
        showResetPassword={dialogContent.showResetPassword}
        showResendVerification={dialogContent.showResendVerification}
        resetToken={resetToken}
        email={dialogContent.email}
      />
      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ width: "100%", mt: 4 }}
      >
        <TextField
          required
          fullWidth
          margin="normal"
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          sx={{
            ...authTextFieldStyle,
            mb: 3,
            "& .MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.6)",
              "&.Mui-focused": {
                color: "black",
              },
            },
          }}
          autoFocus
        />
        <TextField
          required
          fullWidth
          margin="normal"
          name="password"
          label="Password"
          type="password"
          id="password"
          autoComplete="current-password"
          sx={{
            ...authTextFieldStyle,
            mb: 4,
            "& .MuiInputLabel-root": {
              color: "rgba(0, 0, 0, 0.6)",
              "&.Mui-focused": {
                color: "black",
              },
            },
          }}
        />

        <Button
          fullWidth
          type="submit"
          variant="contained"
          sx={{
            mt: 2,
            mb: 3,
            bgcolor: "black",
            color: "white",
            "&:hover": {
              bgcolor: "rgba(0, 0, 0, 0.8)",
            },
          }}
        >
          Sign In
        </Button>

        <Button
          fullWidth
          variant="contained"
          sx={{
            backgroundColor: "#4285F4",
            color: "white",
            "&:hover": {
              backgroundColor: "#357ae8",
            },
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            mt: 1,
            mb: 1,
          }}
          startIcon={<FcGoogle />}
          onClick={() => openLoginWithGooglePopup()}
        >
          Login with Google
        </Button>

        {googleError && (
          <Typography
            color="error"
            variant="body2"
            sx={{
              mt: 2,
              mb: 1,
              textAlign: "center",
            }}
          >
            {googleError}
          </Typography>
        )}

        <Grid container sx={{ mt: 3 }}>
          <Grid item xs>
            <Link
              href="/forgot-password"
              variant="body2"
              sx={{ color: "black" }}
            >
              {"Forgot password?"}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Login;
