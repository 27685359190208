import { collection, query, getDocs, getFirestore } from "firebase/firestore";
import { UserDocument } from "types/user";
const db = getFirestore();

export const fetchFreelancerDocuments = async (userId: string) => {
  const documentsQuery = query(collection(db, "users", userId, "documents"));
  const documentsSnapshot = await getDocs(documentsQuery);
  return documentsSnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  } as UserDocument));
};
