import React, { useState, ChangeEvent, FormEvent } from "react";
import { Box, Paper, Typography, Button, Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ArticleIcon from "@mui/icons-material/Article";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PendingIcon from "@mui/icons-material/Pending";
import ErrorIcon from "@mui/icons-material/Error";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useFreelancerPayroll } from "hooks/freelancer/useFreelancerPayroll";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";
import { useAuth } from "hooks/auth/useAuth";
import ViewFileDialog from "components/ViewFileDialog";
import PaymentHistoryTable from "./PaymentHistoryTable";
import { DateTime } from "luxon";
import FreelancerPayrollForm from "./FreelancerPayrollForm";
import { IncreaseExternalAccountDetails } from "@freetech/models/bank/bank";
import { PayrollFormData } from "./FreelancerPayrollForm";

const FreelancerPayrollHome: React.FC = () => {
  const { currentUser } = useAuth();
  const { freelancerProfile: freelancer } = useFreelancerProfile(
    currentUser?.uid || ""
  );
  const {
    paymentProfile,
    updateMyFreelancerPayrollProfile,
    getTaxFormStorageUrl,
  } = useFreelancerPayroll();

  const [viewTaxFormDialogOpen, setViewTaxFormDialogOpen] = useState(false);
  const [taxFormFileUrl, setTaxFormFileUrl] = useState<string | null>(null);
  const [isLoadingTaxForm, setIsLoadingTaxForm] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [formData, setFormData] = useState<
    Partial<IncreaseExternalAccountDetails>
  >({});

  const handleViewTaxForm = async () => {
    setIsLoadingTaxForm(true);
    try {
      const url = await getTaxFormStorageUrl();
      setTaxFormFileUrl(url);
      setViewTaxFormDialogOpen(true);
    } catch (error) {
      console.error("Error loading tax form:", error);
      setError("Failed to load tax form. Please try again.");
    } finally {
      setIsLoadingTaxForm(false);
    }
  };

  const handleCloseTaxFormDialog = () => {
    setViewTaxFormDialogOpen(false);
    setTaxFormFileUrl(null);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleUpdate = async (data: PayrollFormData) => {
    try {
      setError(null);
      const response = await updateMyFreelancerPayrollProfile({
        ...data,
        nickname: `${data.accountHolderFullName}'s ${data.funding} account`,
      });

      if (!response.success) {
        setError(response.error || "Failed to update payment profile");
        return;
      }

      setSuccess("Payment profile updated successfully");
      setIsUpdating(false);
    } catch (err) {
      setError("Failed to update payment profile. Please try again.");
      console.error(err);
    }
  };

  const renderPayrollStatusCard = () => {
    if (!freelancer) return null;

    let statusInfo = {
      icon: <VerifiedIcon sx={{ mr: 1.5, color: "warning.main", mt: 0.5 }} />,
      title: "Your payment and tax information is awaiting admin approval",
      subtitle: freelancer?.paymentAndTaxProfileVerifiedByUserAt
        ? `Submitted on ${DateTime.fromISO(freelancer.paymentAndTaxProfileVerifiedByUserAt).toFormat("MMM d, yyyy")}`
        : "",
      bgColor: "rgb(255, 244, 229)",
      borderColor: "warning.light",
      textColor: "warning",
    };

    if (freelancer.readyForPayroll) {
      statusInfo = {
        icon: (
          <CheckCircleIcon sx={{ mr: 1.5, color: "success.main", mt: 0.5 }} />
        ),
        title:
          "Your payment and tax information has been approved and verified",
        subtitle: freelancer.readyForPayrollAt
          ? `Approved on ${DateTime.fromISO(freelancer.readyForPayrollAt).toFormat("MMM d, yyyy")}`
          : "",
        bgColor: "rgb(237, 247, 237)",
        borderColor: "success.light",
        textColor: "success",
      };
    } else if (
      freelancer.taxFormVerifiedByAdmin &&
      freelancer.increaseExternalAccountProfile?.testPaymentStatus
    ) {
      switch (freelancer.increaseExternalAccountProfile?.testPaymentStatus) {
        case "pending":
          statusInfo = {
            icon: (
              <PendingIcon sx={{ mr: 1.5, color: "warning.main", mt: 0.5 }} />
            ),
            title: "Bank Account Verification in Progress",
            subtitle:
              "We've initiated a small test deposit to verify your bank account",
            bgColor: "rgb(255, 244, 229)",
            borderColor: "warning.light",
            textColor: "warning",
          };
          break;
        case "completed":
          statusInfo = {
            icon: (
              <CheckCircleIcon
                sx={{ mr: 1.5, color: "success.main", mt: 0.5 }}
              />
            ),
            title: "Bank Account Verified",
            subtitle: "Your bank account has been successfully verified",
            bgColor: "rgb(237, 247, 237)",
            borderColor: "success.light",
            textColor: "success",
          };
          break;
        case "failed":
          statusInfo = {
            icon: <ErrorIcon sx={{ mr: 1.5, color: "error.main", mt: 0.5 }} />,
            title: "Bank Account Verification Failed",
            subtitle:
              "There was an issue verifying your bank account. Please contact support.",
            bgColor: "rgb(253, 237, 237)",
            borderColor: "error.light",
            textColor: "error",
          };
          break;
      }
    }

    return (
      <Paper
        elevation={0}
        sx={{
          p: 3,
          bgcolor: statusInfo.bgColor,
          border: 1,
          borderColor: statusInfo.borderColor,
          borderRadius: 2,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          {statusInfo.icon}
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                color: `${statusInfo.textColor}.dark`,
                fontWeight: 500,
                mb: 0.5,
              }}
            >
              {statusInfo.title}
            </Typography>
            {statusInfo.subtitle && (
              <Typography
                variant="body2"
                sx={{ color: `${statusInfo.textColor}.main` }}
              >
                {statusInfo.subtitle}
              </Typography>
            )}
          </Box>
        </Box>
      </Paper>
    );
  };

  if (isUpdating) {
    return (
      <FreelancerPayrollForm
        formData={formData}
        onSubmit={handleUpdate}
        error={error}
        success={success}
        isUpdate={true}
        onBack={() => {
          setIsUpdating(false);
          setError(null);
          setSuccess(null);
        }}
      />
    );
  }

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Payment & Tax Information
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: "100%" }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <ArticleIcon sx={{ mr: 1 }} color="primary" />
              <Typography variant="h6">
                {freelancer?.taxFormType === "w9"
                  ? "W9 Tax Form"
                  : "W8-BEN Tax Form"}
              </Typography>
            </Box>
            <Box sx={{ mb: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <CheckCircleIcon
                  sx={{ mr: 1, color: "success.main", fontSize: 20 }}
                />
                <Typography variant="body2">
                  Submitted and verified by you on{" "}
                  {DateTime.fromISO(
                    freelancer?.taxFormCompletedAt || ""
                  ).toFormat("MMM d, yyyy")}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                {freelancer?.taxFormVerifiedByAdmin ? (
                  <>
                    <CheckCircleIcon
                      sx={{ mr: 1, color: "success.main", fontSize: 20 }}
                    />
                    <Typography variant="body2">
                      Verified by admin on{" "}
                      {DateTime.fromISO(
                        freelancer.taxFormVerifiedByAdminAt || ""
                      ).toFormat("MMM d, yyyy")}
                    </Typography>
                  </>
                ) : (
                  <>
                    <PendingIcon
                      sx={{ mr: 1, color: "warning.main", fontSize: 20 }}
                    />
                    <Typography variant="body2" color="warning.main">
                      Pending admin verification
                    </Typography>
                  </>
                )}
              </Box>
            </Box>
            <Button
              startIcon={<VisibilityIcon />}
              onClick={handleViewTaxForm}
              variant="outlined"
              size="small"
            >
              View Tax Form
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 3, height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AccountBalanceIcon sx={{ mr: 1 }} color="primary" />
                <Typography variant="h6">Direct Deposit Information</Typography>
              </Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  if (paymentProfile) {
                    setFormData({
                      accountHolderFullName:
                        paymentProfile.accountHolderFullName,
                      accountHolderType: paymentProfile.accountHolderType,
                      funding: paymentProfile.funding,
                      routingNumber: "",
                      accountNumber: "",
                    });
                    setIsUpdating(true);
                  }
                }}
              >
                Update Payment Account
              </Button>
            </Box>
            {paymentProfile && (
              <>
                <Box sx={{ mb: 3 }}>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Account Holder:</strong>{" "}
                    {paymentProfile.accountHolderFullName}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Account Type:</strong> {paymentProfile.funding}
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Financial Institution:</strong>{" "}
                    {
                      freelancer?.increaseExternalAccountProfile
                        ?.financialInstitution
                    }
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    <strong>Routing Number:</strong>{" "}
                    {paymentProfile.routingNumber}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Account Number:</strong> ****
                    {paymentProfile.accountNumber
                      ? paymentProfile.accountNumber.slice(-4)
                      : "****"}
                  </Typography>
                </Box>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                >
                  Last updated:{" "}
                  {DateTime.fromISO(
                    freelancer?.payrollProfileUpdatedAt || ""
                  ).toFormat("MMM d, yyyy 'at' h:mm a")}
                </Typography>
              </>
            )}
          </Paper>
        </Grid>

        <Grid item xs={12}>
          {renderPayrollStatusCard()}
        </Grid>
      </Grid>

      <PaymentHistoryTable
        totalCount={0}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleRowsPerPageChange}
      />

      <ViewFileDialog
        open={viewTaxFormDialogOpen}
        onClose={handleCloseTaxFormDialog}
        fileUrl={taxFormFileUrl || ""}
        fileName={`${freelancer?.taxFormType === "w9" ? "W9" : "W8-BEN"} Form.pdf`}
        isLoading={isLoadingTaxForm}
      />
    </Box>
  );
};

export default FreelancerPayrollHome;
