import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { ProjectAssignment } from "types/projects";
import { useAuth } from "hooks/auth/useAuth";
import { IndependentEngineerAgreement } from "types/user";
import {
  getAgreementsToSign,
  AgreementToSign,
} from "core/freelancer/getAgreementsToSign";
import { signProjectAssignment } from "core/freelancer/signProjectAssignment";
import { signIndependentEngineerAgreement } from "core/independentEngineerAgreements/signIndependentEngineerAgreement";
import { portalFunctions } from "core/functions/portalFunctions";

export const useFreelancerSignAgreements = () => {
  const { userInfo } = useAuth();
  const freelancerId = userInfo?.id;
  const queryClient = useQueryClient();

  // Fetch all agreements that need signing
  const {
    data: agreementsToSign = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["freelancerAgreementsToSign", freelancerId],
    queryFn: () => (freelancerId ? getAgreementsToSign(freelancerId) : []),
    enabled: !!freelancerId,
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchInterval: 1000 * 60 * 5, // 5 minutes
  });

  const handleAgreementSigned = async (agreementToSign: AgreementToSign) => {
    try {
      if (!agreementToSign.agreement.eversignDocumentHash || !freelancerId) {
        throw new Error("No agreement or freelancer ID found");
      }

      if (agreementToSign.type === "projectAssignment") {
        const assignment = agreementToSign.agreement as ProjectAssignment;
        const result =
          await portalFunctions.eversign.processSignedProjectAssignment(
            assignment.clientId,
            assignment.id
          );
        queryClient.invalidateQueries({
          queryKey: ["freelancerAgreementsToSign", freelancerId],
        });
        return result;
      } else if (agreementToSign.type === "independentEngineerAgreement") {
        const agreement =
          agreementToSign.agreement as IndependentEngineerAgreement;
        // await signIndependentEngineerAgreement(freelancerId, agreement, file);
        queryClient.invalidateQueries({
          queryKey: ["freelancerAgreementsToSign", freelancerId],
        });
      } else {
        throw new Error("Invalid agreement type");
      }
    } catch (error) {
      console.error("Error signing agreement:", error);
      throw error;
    }
  };

  return {
    agreementsToSign,
    isLoading,
    error: error?.message || null,
    handleAgreementSigned,
  };
};
