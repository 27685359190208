import React, { useMemo } from "react";
import { Box, Paper, Typography, Grid } from "@mui/material";
import { DateTime } from "luxon";
import { Timesheet } from "types/timesheet";

interface TimesheetStatsProps {
  timesheets: Timesheet[];
}

const TimesheetStats: React.FC<TimesheetStatsProps> = ({ timesheets }) => {
  const stats = useMemo(() => {
    const totalHours = timesheets.reduce(
      (sum, timesheet) => sum + timesheet.duration / 3600,
      0
    );
    // const totalEarnings = timesheets.reduce((sum, timesheet) => sum + ((timesheet.duration / 3600) * timesheet), 0);
    // const averageRate = totalHours > 0 ? totalEarnings / totalHours : 0;
    const uniqueProjects = new Set(timesheets.map((t) => t.projectId)).size;

    return {
      totalHours,
      //   totalEarnings,
      //   averageRate,
      uniqueProjects,
    };
  }, [timesheets]);

  return (
    <Grid container spacing={2}>
      {/* <Grid item xs={12} sm={6} md={3}>
        <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant="h6">${stats.totalEarnings.toFixed(2)}</Typography>
          <Typography variant="body2">Total Earnings</Typography>
        </Paper>
      </Grid> */}
      <Grid item xs={12} sm={6} md={3}>
        <Paper elevation={2} sx={{ p: 2, textAlign: "center" }}>
          <Typography variant="h6">{stats.totalHours.toFixed(2)}</Typography>
          <Typography variant="body2">Total Hours</Typography>
        </Paper>
      </Grid>
      {/* <Grid item xs={12} sm={6} md={3}>
        <Paper elevation={2} sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant="h6">${stats.averageRate.toFixed(2)}/hr</Typography>
          <Typography variant="body2">Average Rate</Typography>
        </Paper>
      </Grid> */}
      <Grid item xs={12} sm={6} md={3}>
        <Paper elevation={2} sx={{ p: 2, textAlign: "center" }}>
          <Typography variant="h6">{stats.uniqueProjects}</Typography>
          <Typography variant="body2">Active Projects</Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default TimesheetStats;
