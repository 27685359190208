import React from "react";
import { Box, Typography, IconButton, Alert, CircularProgress } from "@mui/material";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { DateTime } from "luxon";

interface ConnectionState {
  attemptCount: number;
  lastAttempt: Date | null;
  maxAttempts: number;
  lastStatus: "connected" | "disconnected" | "reconnecting" | "connecting";
  stableStatus: "connected" | "disconnected" | "reconnecting" | "connecting";
  disconnectedSince: Date | null;
}

interface ConnectionBannerProps {
  connectionState: ConnectionState;
  showConnectionBanner: boolean;
  showReconnectBanner: boolean;
  onManualDisconnect: () => void;
  onManualReconnect: () => void;
}

const ConnectionBanner: React.FC<ConnectionBannerProps> = ({
  connectionState,
  showConnectionBanner,
  showReconnectBanner,
  onManualDisconnect,
  onManualReconnect,
}) => {
  const MainBanner = () => {
    if (!showConnectionBanner) return null;

    const isConnecting = connectionState.stableStatus === "connecting";
    const isReconnecting = connectionState.stableStatus === "reconnecting";
    const disconnectedDuration = connectionState.disconnectedSince
      ? DateTime.fromJSDate(connectionState.disconnectedSince).toRelative()
      : null;

    return (
      <Alert
        severity={isConnecting || isReconnecting ? "warning" : "error"}
        sx={{
          borderRadius: 0,
          alignItems: "center",
        }}
        action={
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {(isConnecting || isReconnecting) && (
              <Typography variant="caption" color="text.secondary">
                Attempt {connectionState.attemptCount}/
                {connectionState.maxAttempts}
              </Typography>
            )}
            <IconButton
              color={isConnecting || isReconnecting ? "warning" : "error"}
              size="small"
              onClick={onManualDisconnect}
              sx={{ ml: 2 }}
            >
              <WifiOffIcon fontSize="small" />
            </IconButton>
          </Box>
        }
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {isConnecting || isReconnecting ? (
            <>
              <CircularProgress size={16} color="inherit" />
              <Box>
                <Typography variant="body2">
                  {isConnecting
                    ? "Attempting to connect to server"
                    : "Attempting to reconnect to server"}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Disconnected {disconnectedDuration || "moments ago"}
                  {connectionState.lastAttempt &&
                    ` • Last attempt ${DateTime.fromJSDate(
                      connectionState.lastAttempt
                    ).toRelative()}`}
                </Typography>
              </Box>
            </>
          ) : (
            <Box>
              <Typography variant="body2">
                Connection lost to collaboration server
              </Typography>
              {disconnectedDuration && (
                <Typography variant="caption" color="text.secondary">
                  Disconnected {disconnectedDuration}
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Alert>
    );
  };

  const ReconnectBanner = () => {
    if (!showReconnectBanner) return null;

    return (
      <Alert
        severity="info"
        sx={{
          borderRadius: 0,
          alignItems: "center",
        }}
        action={
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="caption" color="text.secondary">
              Click to retry connection
            </Typography>
            <IconButton
              color="primary"
              size="small"
              onClick={onManualReconnect}
              sx={{ ml: 2 }}
            >
              <RestartAltIcon fontSize="small" />
            </IconButton>
          </Box>
        }
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>Auto-save is temporarily disabled</Typography>
          {connectionState.lastAttempt && (
            <Typography variant="caption" color="text.secondary">
              • Last attempt:{" "}
              {DateTime.fromJSDate(connectionState.lastAttempt).toRelative()}
            </Typography>
          )}
        </Box>
      </Alert>
    );
  };

  return (
    <>
      <MainBanner />
      <ReconnectBanner />
    </>
  );
};

export default ConnectionBanner; 