import React from "react";
import { getAuth } from "firebase/auth";
import { Typography, Box, CircularProgress, Paper, Grid } from "@mui/material";

import ProfileInfo from "./components/ProfileInfo";
import SkillsSection from "./components/SkillsSection";
import DocumentsSection from "./components/DocumentsSection";
import AgreementSection from "./components/AgreementSection";
import FreelancerReadOnlyInfo from "./components/FreelancerReadOnlyInfo";
import { useAuth } from "hooks/auth/useAuth";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";

const FreelancerProfile: React.FC = () => {
  const { userInfo } = useAuth();

  if (!userInfo?.id) {
    return <Typography>No user data found</Typography>;
  }

  const { 
    freelancerProfile, 
    isLoadingFreelancerProfile, 
    updateFreelancerProfile, 
    freelancerDocuments,
    uploadFreelancerDocument
  } = useFreelancerProfile(userInfo?.id);

  if (isLoadingFreelancerProfile) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!freelancerProfile) {
    return <Typography>No freelancer data found</Typography>;
  }

  return (
    <Box py={4}>
      <Paper elevation={3} sx={{ mb: 4 }}>
        <Box p={4}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <ProfileInfo
                freelancer={freelancerProfile}
                onUpdate={updateFreelancerProfile}
                userInfo={userInfo}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SkillsSection freelancer={freelancerProfile} onUpdate={updateFreelancerProfile} />
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <FreelancerReadOnlyInfo freelancer={freelancerProfile} />

      <Paper elevation={3} sx={{ mb: 4 }}>
        <Box p={4}>
          <AgreementSection freelancerId={freelancerProfile.id} />
        </Box>
      </Paper>

      <DocumentsSection 
        documents={freelancerDocuments || []} 
        onUploadDocument={uploadFreelancerDocument}
      />
    </Box>
  );
};

export default FreelancerProfile;
