import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { db, storage } from "config/firebase";
import { UserDocument } from "types/user";
import { UploadIndependentEngineerAgreementParams } from "types/admin";

export const uploadIndependentEngineerAgreement = async ({
  freelancerId,
  file,
}: UploadIndependentEngineerAgreementParams): Promise<UserDocument> => {
  // Create the storage reference with the proper path
  const storagePath = `users/${freelancerId}/contracts/${file.name}`;
  const storageRef = ref(storage, storagePath);

  // Upload the file
  await uploadBytes(storageRef, file);

  // Get the download URL
  const fileUrl = await getDownloadURL(storageRef);

  // Create the document object
  const agreementDoc: UserDocument = {
    id: Date.now().toString(),
    name: file.name,
    documentType: "Independent Engineer Agreement",
    documentDate: new Date().toISOString(),
    uploadDate: new Date().toISOString(),
    fileUrl,
  };

  // Update the contract document using setDoc instead of updateDoc
  const contractRef = doc(
    db,
    "users",
    freelancerId,
    "contracts",
    "independentEngineerAgreement"
  );
  await setDoc(contractRef, agreementDoc);

  return agreementDoc;
};
