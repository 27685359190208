import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Chip,
  Grid,
  IconButton,
  Tooltip,
  useTheme,
  alpha,
} from '@mui/material';
import {
  BugReport as BugIcon,
  NewReleases,
  Build,
  Schedule,
  Person,
  Folder,
} from '@mui/icons-material';
import { DateTime } from 'luxon';
import { useBugReport } from "hooks/bugReport/useBugReport";
import { useProjectsNonAdmin } from "hooks/project/useProjectsNonAdmin";

const getPriorityColor = (priority: string) => {
  switch (priority.toLowerCase()) {
    case 'critical':
      return 'error';
    case 'high':
      return 'warning';
    case 'medium':
      return 'info';
    case 'low':
      return 'success';
    default:
      return 'default';
  }
};

const getTypeIcon = (type: string) => {
  switch (type.toLowerCase()) {
    case 'bug':
      return <BugIcon />;
    case 'feature':
      return <NewReleases />;
    case 'improvement':
      return <Build />;
    default:
      return <BugIcon />;
  }
};

const BugReportCards: React.FC = () => {
  const theme = useTheme();
  const { myBugReports, isLoadingMyReports } = useBugReport();
  const { data: projects } = useProjectsNonAdmin();

  if (isLoadingMyReports) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <Typography>Loading bug reports...</Typography>
      </Box>
    );
  }

  if (!myBugReports?.length) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
        <Typography>No bug reports found</Typography>
      </Box>
    );
  }

  const getProjectName = (projectId: string) => {
    return projects?.find(project => project.id === projectId)?.name || 'Unknown Project';
  };

  return (
    <Grid container spacing={3} sx={{ p: 2 }}>
      {myBugReports.map((report) => (
        <Grid item xs={12} md={6} lg={4} key={report.id}>
          <Card
            elevation={3}
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              '&:hover': {
                boxShadow: theme.shadows[6],
              },
              bgcolor: report.status === 'resolved' 
                ? alpha(theme.palette.success.light, 0.1)
                : undefined,
            }}
          >
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Tooltip title={report.type}>
                  <IconButton size="small" sx={{ mr: 1 }}>
                    {getTypeIcon(report.type)}
                  </IconButton>
                </Tooltip>
                <Box sx={{ display: 'flex', gap: 1 }}>
                  <Chip
                    label={report.priority}
                    size="small"
                    color={getPriorityColor(report.priority)}
                  />
                  <Chip
                    label={report.status}
                    size="small"
                    variant="outlined"
                  />
                </Box>
              </Box>

              <Typography variant="h6" component="h2" gutterBottom>
                {report.title}
              </Typography>

              <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                  mb: 2,
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {report.description}
              </Typography>

              <Box sx={{ mt: 'auto' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <Folder fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    {getProjectName(report.projectId)}
                  </Typography>
                </Box>

                {report.assignedToId && (
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Person fontSize="small" sx={{ mr: 1 }} />
                    <Typography variant="body2">
                      Assigned to: {report.assignedToId}
                    </Typography>
                  </Box>
                )}

                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Schedule fontSize="small" sx={{ mr: 1 }} />
                  <Typography variant="body2">
                    Created: {DateTime.fromJSDate(report.createdAt).toRelative()}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default BugReportCards; 