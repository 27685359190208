import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Alert,
  Stack,
  Paper,
} from "@mui/material";
import { Client, Project, ConsultingServicesAgreement } from "types/projects";
import { DateTime } from "luxon";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";

interface ActiveProjectsCardProps {
  clients?: Client[];
}

export const ActiveProjectsCard: React.FC<ActiveProjectsCardProps> = ({
  clients,
}) => {
  const activeProjects =
    clients?.flatMap((client) =>
      (client.projects || [])
        .filter((project) => project.status === "active")
        .map((project) => ({
          ...project,
          clientName: client.companyName,
          consultingServicesAgreements: client.consultingServicesAgreements?.filter(
            csa => csa.projectId === project.id
          ) || []
        }))
    ) || [];

  if (!activeProjects.length) {
    return (
      <Alert severity="info">
        No active projects found. Active projects and their budgets will appear
        here.
      </Alert>
    );
  }

  const sortCSAs = (csas: ConsultingServicesAgreement[]) => {
    return [...csas].sort((a, b) => 
      new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime()
    );
  };

  const formatDate = (date: string) => {
    return DateTime.fromISO(date).toFormat("MMM d, yyyy");
  };

  const getBudgetAmount = (csa: ConsultingServicesAgreement) => {
    return csa.monthlyBudget || csa.fixedPriceBudget || 0;
  };

  return (
    <Paper elevation={0} sx={{ border: 1, borderColor: 'divider', borderRadius: 2 }}>
      <Box sx={{ p: 2, borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', gap: 1 }}>
        <AccountBalanceIcon color="primary" />
        <Typography variant="h6">Active Projects</Typography>
      </Box>

      <List disablePadding>
        {activeProjects.map((project, index) => {
          const currentCSA = sortCSAs(project.consultingServicesAgreements || [])[0];
          return (
            <React.Fragment key={project.id}>
              {index > 0 && <Divider />}
              <ListItem sx={{ py: 2.5, px: 3 }}>
                <ListItemText
                  primary={
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        gap: 3,
                      }}
                    >
                      <Box sx={{ flex: 1 }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: 600, mb: 0.5 }}>
                          {project.name}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                          {project.clientName}
                        </Typography>
                        {project.description && (
                          <Typography 
                            variant="body2" 
                            color="text.secondary" 
                            sx={{ 
                              bgcolor: 'grey.50', 
                              p: 1, 
                              borderRadius: 1,
                              border: 1,
                              borderColor: 'divider'
                            }}
                          >
                            {project.description}
                          </Typography>
                        )}
                      </Box>
                      {currentCSA && (
                        <Paper 
                          elevation={0}
                          sx={{ 
                            p: 1.5,
                            minWidth: 200,
                            bgcolor: 'primary.main',
                            borderRadius: 2,
                          }}
                        >
                          <Typography 
                            variant="caption" 
                            sx={{ 
                              color: 'primary.contrastText',
                              display: 'block',
                              mb: 0.5,
                              fontWeight: 500
                            }}
                          >
                            Current Budget
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'baseline', gap: 1 }}>
                            <Typography 
                              variant="h6"
                              sx={{ color: 'primary.contrastText', fontWeight: 600 }}
                            >
                              ${getBudgetAmount(currentCSA).toLocaleString()}
                            </Typography>
                            <Typography 
                              variant="caption"
                              sx={{ color: 'primary.contrastText', opacity: 0.9 }}
                            >
                              {currentCSA.monthlyBudget ? 'per month' : 'fixed price'}
                            </Typography>
                          </Box>
                        </Paper>
                      )}
                    </Box>
                  }
                />
              </ListItem>
            </React.Fragment>
          );
        })}
      </List>
    </Paper>
  );
};
