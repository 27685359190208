import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { IBugReport } from "../bugReport";

export const bugReportConverter: FirestoreDataConverter<IBugReport> = {
  toFirestore(client: WithFieldValue<IBugReport>): DocumentData {
    return {
      id: client.id,
      title: client.title,
      description: client.description,
      priority: client.priority,
      status: client.status,
      type: client.type,
      projectId: client.projectId,
      reportedById: client.reportedById,
      createdAt: client.createdAt,
      updatedAt: client.updatedAt,
      resolvedAt: client.resolvedAt,
    };
  },
  fromFirestore(
    snapshot: DocumentSnapshot,
    options: SnapshotOptions
  ): IBugReport {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      title: data?.title,
      description: data?.description,
      priority: data?.priority,
      status: data?.status,
      type: data?.type,
      projectId: data?.projectId,
      reportedById: data?.reportedById,
      createdAt: data?.createdAt,
      updatedAt: data?.updatedAt,
      resolvedAt: data?.resolvedAt,
    };
  },
};
