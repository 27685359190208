import React, { useState, useCallback } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  CircularProgress,
  Box,
  Typography,
  Snackbar,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDropzone } from "react-dropzone";
import { uploadCSA } from "core/csa/createAndUploadCSA";
import { ConsultingServicesAgreement } from "types/projects";
import { BillableRate, CSAType, CSAStatus } from "types/projects";
interface UploadCSADialogProps {
  open: boolean;
  onClose: () => void;
  clientId: string;
  projectId: string;
}

const UploadCSADialog: React.FC<UploadCSADialogProps> = ({
  open,
  onClose,
  clientId,
  projectId,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [name, setName] = useState("");
  const [effectiveDate, setEffectiveDate] = useState("");
  const [monthlyBudget, setMonthlyBudget] = useState("");
  const [billableRate, setBillableRate] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [billableRates, setBillableRates] = useState<BillableRate[]>([]);
  const [csaType, setCsaType] = useState<CSAType>("monthly");
  const [fixedPriceBudget, setFixedPriceBudget] = useState("");
  const [status, setStatus] = useState<CSAStatus>("active");

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "application/msword": [".doc"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
    },
    multiple: false,
  });

  const handleAddBillableRate = () => {
    setBillableRates([...billableRates, { role: "", rate: 0 }]);
  };

  const handleRemoveBillableRate = (index: number) => {
    const newRates = billableRates.filter((_, i) => i !== index);
    setBillableRates(newRates);
  };

  const handleBillableRateChange = (
    index: number,
    field: "role" | "rate",
    value: string
  ) => {
    const newRates = [...billableRates];
    if (field === "rate") {
      newRates[index][field] = parseFloat(value);
    } else {
      newRates[index][field] = value;
    }
    setBillableRates(newRates);
  };

  const handleUpload = async () => {
    if (
      !file ||
      !name ||
      !effectiveDate ||
      billableRates.length === 0 ||
      (csaType === "monthly" && !monthlyBudget) ||
      (csaType === "fixed_price" && !fixedPriceBudget)
    ) {
      setSnackbarMessage(
        "All fields are required. Please fill in all the fields and add at least one billable rate."
      );
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    try {
      const csaData: Omit<ConsultingServicesAgreement, "id" | "fileUrl"> = {
        clientId,
        projectId,
        name,
        effectiveDate,
        csaType,
        billableRates,
        status,
      };

      if (csaType === "monthly") {
        csaData.monthlyBudget = parseFloat(monthlyBudget);
      } else {
        csaData.fixedPriceBudget = parseFloat(fixedPriceBudget);
      }

      await uploadCSA(clientId, projectId, csaData, file);
      onClose();
      setSnackbarMessage("CSA uploaded successfully!");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error uploading CSA:", error);
      setSnackbarMessage("Error uploading CSA. Please try again.");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogTitle>Upload Consulting Services Agreement</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="CSA Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <TextField
            fullWidth
            margin="normal"
            label="Effective Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={effectiveDate}
            onChange={(e) => setEffectiveDate(e.target.value)}
            required
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>CSA Type</InputLabel>
            <Select
              value={csaType}
              label="CSA Type"
              onChange={(e) => setCsaType(e.target.value as CSAType)}
            >
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="fixed_price">Fixed Price</MenuItem>
            </Select>
          </FormControl>
          {csaType === "monthly" ? (
            <TextField
              fullWidth
              margin="normal"
              label="Monthly Budget"
              type="number"
              value={monthlyBudget}
              onChange={(e) => setMonthlyBudget(e.target.value)}
              required
            />
          ) : (
            <TextField
              fullWidth
              margin="normal"
              label="Fixed Price Budget"
              type="number"
              value={fixedPriceBudget}
              onChange={(e) => setFixedPriceBudget(e.target.value)}
              required
            />
          )}
          <FormControl fullWidth margin="normal">
            <InputLabel>CSA Status</InputLabel>
            <Select
              value={status}
              label="CSA Status"
              onChange={(e) => setStatus(e.target.value as CSAStatus)}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="cancelled">Cancelled</MenuItem>
            </Select>
          </FormControl>
          {billableRates.map((rate, index) => (
            <Box key={index} display="flex" alignItems="center" mt={2}>
              <TextField
                label="Role"
                value={rate.role}
                onChange={(e) =>
                  handleBillableRateChange(index, "role", e.target.value)
                }
                required
                sx={{ mr: 2, flexGrow: 1 }}
              />
              <TextField
                label="Rate"
                type="number"
                value={rate.rate}
                onChange={(e) =>
                  handleBillableRateChange(index, "rate", e.target.value)
                }
                required
                sx={{ mr: 2, flexGrow: 1 }}
              />
              {index > 0 && (
                <IconButton onClick={() => handleRemoveBillableRate(index)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </Box>
          ))}
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddBillableRate}
            sx={{ mt: 2 }}
          >
            Add Role
          </Button>
          <Box
            {...getRootProps()}
            sx={{
              border: "2px dashed #cccccc",
              borderRadius: "4px",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
              mt: 2,
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the file here ...</Typography>
            ) : (
              <Typography>
                Drag and drop a file here, or click to select a file
              </Typography>
            )}
          </Box>
          {file && (
            <Typography sx={{ mt: 2 }}>Selected file: {file.name}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button onClick={handleUpload} disabled={!file || loading}>
            {loading ? <CircularProgress size={24} /> : "Upload"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbarMessage}
      />
    </>
  );
};

export default UploadCSADialog;
