import { ref, deleteObject } from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import { db, storage } from "config/firebase";



export const deleteIndependentEngineerAgreement = async (
  freelancerId: string
): Promise<void> => {
  // Delete from storage
  const storagePath = `users/${freelancerId}/contracts/independentEngineerAgreement`;
  const storageRef = ref(storage, storagePath);
  await deleteObject(storageRef);

  // Remove from Firestore using setDoc with null values
  const contractRef = doc(
    db,
    "users",
    freelancerId,
    "contracts",
    "independentEngineerAgreement"
  );
  await setDoc(contractRef, {
    id: null,
    name: null,
    documentType: null,
    documentDate: null,
    uploadDate: null,
    fileUrl: null,
  });
};
