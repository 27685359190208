import { collection, addDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { CreateFileParams } from "../types";
import { KnowledgeBaseFile } from "types/knowledgeBase";

export const createFile = async (params: CreateFileParams): Promise<string> => {
  const { folderPath, name, content, createdBy } = params;

  const fileData: Omit<KnowledgeBaseFile, "id"> = {
    name,
    content,
    path: `${folderPath}/${name}`,
    storageUrl: "",
    createdAt: DateTime.now().toISO(),
    updatedAt: DateTime.now().toISO(),
    createdBy,
    lastModifiedBy: createdBy,
    metadata: {
      description: "",
      tags: [],
    },
  };

  const fileRef = collection(db, "knowledgeBaseFiles");
  const newFileDoc = await addDoc(fileRef, fileData);
  return newFileDoc.id;
}; 