import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { ConsultingServicesAgreement } from "../projects";
export const consultingServicesAgreementConverter: FirestoreDataConverter<ConsultingServicesAgreement> =
  {
    toFirestore(
      csa: WithFieldValue<ConsultingServicesAgreement>
    ): DocumentData {
      const { id, ...data } = csa;
      return data;
    },
    fromFirestore(
      snapshot: DocumentSnapshot,
      options: SnapshotOptions
    ): ConsultingServicesAgreement {
      const data = snapshot.data(options);
      return {
        id: snapshot.id,
        clientId: data?.clientId,
        status: data?.status,
        projectId: data?.projectId,
        name: data?.name,
        effectiveDate: data?.effectiveDate,
        csaType: data?.csaType,
        billableRates: data?.billableRates,
        monthlyBudget: data?.monthlyBudget,
        fixedPriceBudget: data?.fixedPriceBudget,
      };
    },
  };
