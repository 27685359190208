import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';


export default function Footer() {
    const theme = useTheme();
    const [termsDialogOpen, setTermsDialogOpen] = React.useState(false);
    const [pdfUrl, setPdfUrl] = React.useState<string | null>(null);

    const handleOpenTerms = async () => {
        if (!pdfUrl) {
            const storage = getStorage();
            const pdfRef = ref(storage, 'terms.pdf');
            const url = await getDownloadURL(pdfRef);
            setPdfUrl(url);
        }
        setTermsDialogOpen(true);
    };

    return (
        <>
            <Box component="footer" sx={{ 
                py: 3, 
                justifyContent: 'center', 
                position: 'relative', 
                bottom: 0, 
                width: '100%', 
                left: 0,
                right: 0,
                margin: 'auto',
                boxSizing: 'border-box'
            }}>
                <Container>
                    <Typography 
                        variant="body2" 
                        color={theme.palette.primary.main} 
                        align="center"
                        sx={{ 
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 0.5
                        }}
                    >
                        <span>Copyright ©</span>
                        <Link color="inherit" href="https://freetech.co">
                            FreeTech
                        </Link>
                        <span>{new Date().getFullYear()}.</span>
                        <span>•</span>
                        <Link
                            component="button"
                            variant="body2"
                            onClick={handleOpenTerms}
                            color="inherit"
                            sx={{ textDecoration: 'none' }}
                        >
                            Terms & Conditions
                        </Link>
                    </Typography>
                </Container>
            </Box>

            <Dialog
                open={termsDialogOpen}
                onClose={() => setTermsDialogOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Terms and Conditions</DialogTitle>
                <DialogContent>
                    {pdfUrl && (
                        <Box sx={{ width: '100%', height: '70vh' }}>
                            <iframe
                                src={`${pdfUrl}#view=FitH`}
                                width="100%"
                                height="100%"
                                title="Terms and Conditions"
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setTermsDialogOpen(false)}>Close</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
