import React, { useState } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";
import FreelancerPayrollOnboarding from "./components/FreelancerPayrollOnboarding";
import FreelancerPayrollHome from "./components/FreelancerPayrollHome";
import { Box, CircularProgress } from "@mui/material";

const FreelancerPayroll: React.FC = () => {
  const { currentUser } = useAuth();
  const { freelancerProfile: freelancer, isLoadingFreelancerProfile: isLoading } = useFreelancerProfile(
    currentUser?.uid || ""
  );
  const [hasStartedSetup, setHasStartedSetup] = useState(false);

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Show onboarding if either tax form or payment profile is not completed, or if not verified
  if (
    !freelancer?.taxFormCompleted || 
    !freelancer?.payrollProfileCompleted || 
    !freelancer?.paymentAndTaxProfileVerifiedByUser
  ) {
    return <FreelancerPayrollOnboarding onBeginSetup={() => setHasStartedSetup(true)} />;
  }

  // Show main payroll view if onboarding is complete and verified
  return <FreelancerPayrollHome />;
};

export default FreelancerPayroll;
