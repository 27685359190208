import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { ProjectAssignment } from "../projects";

export const projectAssignmentConverter: FirestoreDataConverter<ProjectAssignment> =
  {
    toFirestore(assignment: WithFieldValue<ProjectAssignment>): DocumentData {
      const { id, clientName, projectName, ...data } = assignment;
      return data;
    },
    fromFirestore(
      snapshot: DocumentSnapshot,
      options: SnapshotOptions
    ): ProjectAssignment {
      const data = snapshot.data(options);
      return {
        id: snapshot.id,
        clientId: data?.clientId || "",
        projectId: data?.projectId || "",
        clientName: data?.clientName,
        projectName: data?.projectName,
        status: data?.status || "",
        startDate: data?.startDate || "",
        endDate: data?.endDate || "",
        billableRate: data?.billableRate || 0,
        engineerId: data?.engineerId || "",
        csaId: data?.csaId || null,
        budgetedHours: data?.budgetedHours,
        everSignDocumentStatus: data?.everSignDocumentStatus,
        isUploadedToStorage: data?.isUploadedToStorage,
      };
    },
  };
