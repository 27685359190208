import React from "react";
import {
  Box,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
} from "@mui/material";

interface WelcomeStepProps {
  onNext: () => void;
}

export const WelcomeStep: React.FC<WelcomeStepProps> = ({ onNext }) => {
  const renderNumberedStep = (number: number, text: string) => (
    <Grid item xs={12}>
      <Typography
        variant="body2"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <Box
          component="span"
          sx={{
            width: 24,
            height: 24,
            borderRadius: "50%",
            bgcolor: "primary.main",
            color: "white",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center",
            mr: 2,
          }}
        >
          {number}
        </Box>
        {text}
      </Typography>
    </Grid>
  );

  return (
    <Box sx={{ maxWidth: 800, mx: "auto", textAlign: "center" }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        Welcome to FreeTech
      </Typography>

      <Grid container spacing={4} sx={{ mb: 6 }}>
        <Grid item xs={12} md={4}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Who We Are
              </Typography>
              <Typography variant="body2" color="text.secondary">
                FreeTech is a platform connecting organizations with top tech
                talent. We help businesses scale their tech capabilities while
                providing opportunities for skilled professionals.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                What to Expect
              </Typography>
              <Typography variant="body2" color="text.secondary">
                During onboarding, you'll set up your company profile, define
                your first project, and configure payment details. This
                typically takes about 10-15 minutes.
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card sx={{ height: "100%" }}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Getting Started
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Have your company details and payment information ready. We'll
                guide you through each step to ensure a smooth setup process.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ mb: 4 }}>
        <Typography variant="h6" gutterBottom>
          Onboarding Steps
        </Typography>
        <Grid
          container
          spacing={2}
          sx={{ textAlign: "left", maxWidth: 600, mx: "auto" }}
        >
          {renderNumberedStep(1, "Personal Information - Basic details about you")}
          {renderNumberedStep(2, "Company Setup - Your organization's details")}
          {renderNumberedStep(3, "Project Setup - Define your first project")}
          {renderNumberedStep(4, "Payment Setup - Configure billing details")}
        </Grid>
      </Box>

      <Button variant="contained" size="large" onClick={onNext}>
        Start Onboarding
      </Button>
    </Box>
  );
};

export default WelcomeStep; 