import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { ClientInvoiceData } from "../bank";

export const invoiceConverter: FirestoreDataConverter<ClientInvoiceData> = {
  toFirestore(invoice: WithFieldValue<ClientInvoiceData>): DocumentData {
    const { id, ...data } = invoice;
    return data as DocumentData;
  },
  fromFirestore(
    snapshot: DocumentSnapshot,
    options: SnapshotOptions
  ): ClientInvoiceData {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      clientId: data?.clientId || "",
      projectId: data?.projectId || "",
      status: data?.status || "unpaid",
      dueDate: data?.dueDate || "",
      csaId: data?.csaId || "",
      invoiceNumber: data?.invoiceNumber || "",
      invoiceDate: data?.invoiceDate || "",
      billTo: data?.billTo || { name: "", company: "" },
      shipTo: data?.shipTo || { name: "", company: "" },
      items: data?.items || [],
      terms: data?.terms || "",
      discount: data?.discount || 0,
      totalDollarAmountDue: data?.totalDollarAmountDue,
    };
  },
};
