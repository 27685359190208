import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Paper,
  CircularProgress,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
  Tabs,
  Tab,
  Avatar,
  Chip,
  Link,
} from "@mui/material";
import { PieChart } from "@mui/x-charts/PieChart";
import { SelectChangeEvent } from "@mui/material/Select";
import { useAuth } from "hooks/auth/useAuth";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import { useFreelancerHomeStatistics } from "hooks/freelancer/useFreelancerHomeStatistics";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";
import TimeTrackingCharts from "./components/TimeTrackingCharts";
import { getCountsOfProjectAssignmentStatuses } from "core/projectAssignments/utils";
import { IndependentEngineerAgreement } from "types/user";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

interface WelcomeMessageState {
  message: React.ReactNode;
  type: "success" | "warning" | "info" | "error";
}

interface ProjectStatusData {
  id: number;
  value: number;
  label: string;
  color: string;
}

const getProjectStatusChartData = (counts: {
  interviewing: number;
  ready: number;
  signing: number;
  in_progress: number;
  complete: number;
  cancelled: number;
}): ProjectStatusData[] => {
  return [
    {
      id: 0,
      value: counts.in_progress,
      label: "In Progress",
      color: "#1976d2",
    },
    { id: 1, value: counts.complete, label: "Complete", color: "#2e7d32" },
    { id: 2, value: counts.signing, label: "Signing", color: "#ed6c02" },
    {
      id: 3,
      value: counts.interviewing,
      label: "Interviewing",
      color: "#9c27b0",
    },
    { id: 4, value: counts.ready, label: "Ready", color: "#0288d1" },
    { id: 5, value: counts.cancelled, label: "Cancelled", color: "#d32f2f" },
  ].filter((item) => item.value > 0); // Only show statuses with non-zero values
};

const getWelcomeMessage = (
  freelancerProfile: any,
  independentEngineerAgreement: IndependentEngineerAgreement | null,
  projectAssignmentStatusCounts: {
    interviewing: number;
    ready: number;
    signing: number;
    in_progress: number;
    complete: number;
    cancelled: number;
  }
): WelcomeMessageState => {
  // No Independent Engineer Agreement
  if (!freelancerProfile?.currentIndependentEngineerAgreementId) {
    return {
      type: "warning",
      message: (
        <>
          Welcome to FreeTech! To begin working on projects, you'll need to
          complete your Independent Engineer Agreement. You will receive an
          email notification when your agreement is ready for signature. In the
          meantime, please reach out to admin@freetech.co for further
          independent contractor onboarding information.
        </>
      ),
    };
  }

  // Independent Engineer Agreement is Sent but Not Signed by adminn
  if (
    independentEngineerAgreement &&
    independentEngineerAgreement.everSignDocumentStatus ===
      "awaiting_admin_signature"
  ) {
    return {
      type: "info",
      message: (
        <>
          Your Independent Engineer Agreement has been sent to a FreeTech
          representative for signature. You will receive an email notification
          when your agreement is ready for signature.
        </>
      ),
    };
  }

  // Independent Engineer Agreement is Sent but Not Signed by freelancer - is signed by admin
  if (
    independentEngineerAgreement &&
    independentEngineerAgreement.everSignDocumentStatus ===
      "awaiting_engineer_signature"
  ) {
    return {
      type: "info",
      message: (
        <>
          Great news! Your Independent Engineer Agreement is ready for your
          signature. You can find this under{" "}
          <Link component={RouterLink} to="/sign-agreements" color="primary">
            Sign Agreements
          </Link>{" "}
          and you should receive an email with signing instructions shortly.
          Need help? Send us a message at admin@freetech.co.
        </>
      ),
    };
  }

  // Has Agreement but No Project Assignments
  if (!freelancerProfile.projectAssignments) {
    return {
      type: "info",
      message:
        "Congratulations! You've completed your Independent Engineer Agreement. Before you can start working on FreeTech projects, you will need a Project Assignment. Please review your Independent Engineer Agreement and contact admin@freetech.co if you have any questions.",
    };
  }

  // Has Completed Projects but No Active Ones
  if (
    projectAssignmentStatusCounts.in_progress === 0 &&
    projectAssignmentStatusCounts.complete > 0
  ) {
    return {
      type: "info",
      message:
        "You currently have no active project assignments. Contact admin@freetech.co if you're interested in taking on new projects.",
    };
  }

  // Has Projects in Signing Phase
  if (projectAssignmentStatusCounts.signing > 0) {
    return {
      type: "info",
      message: `You have ${projectAssignmentStatusCounts.signing} project assignment${projectAssignmentStatusCounts.signing > 1 ? "s" : ""} pending signature. Please check your email for the signing link or contact admin@freetech.co for assistance.`,
    };
  }

  // Has Active Projects
  if (projectAssignmentStatusCounts.in_progress > 0) {
    return {
      type: "success",
      message: `You have ${projectAssignmentStatusCounts.in_progress} active project assignment${projectAssignmentStatusCounts.in_progress > 1 ? "s" : ""}. Check out your overview and time tracking information below.`,
    };
  }

  // Default State (should rarely hit this)
  return {
    type: "info",
    message:
      "Please contact admin@freetech.co for further information about becoming an independent contractor with FreeTech.",
  };
};

const FreelancerHome: React.FC = () => {
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [tabValue, setTabValue] = useState(0);
  const { stats, isLoading: isLoadingStats, error: statsError } =
    useFreelancerHomeStatistics(selectedYear);
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const {
    freelancerProfile,
    isLoadingFreelancerProfile,
    independentEngineerAgreement,
  } = useFreelancerProfile(userInfo?.id || "");

  useEffect(() => {
    if (userInfo?.role === "admin") {
      navigate("/admin");
    }
  }, [userInfo, navigate]);

  const handleYearChange = (event: SelectChangeEvent<number>) => {
    setSelectedYear(event.target.value as number);
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  if (isLoadingFreelancerProfile) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  const projectAssignmentStatusCounts = getCountsOfProjectAssignmentStatuses(
    freelancerProfile?.projectAssignments || []
  );

  const welcomeMessageState = getWelcomeMessage(
    freelancerProfile,
    independentEngineerAgreement || null,
    projectAssignmentStatusCounts
  );

  return (
    <>
      <Paper elevation={3} sx={{ padding: 4, marginTop: 4, borderRadius: 2 }}>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              mb: 2,
              background: "linear-gradient(45deg, primary.main, primary.dark)",
              WebkitBackgroundClip: "text",
            }}
          >
            Welcome, {freelancerProfile?.firstName}{" "}
            {freelancerProfile?.lastName}!
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: (theme) => theme.palette[welcomeMessageState.type].main,
              fontSize: "1.1rem",
              lineHeight: 1.6,
              maxWidth: "800px",
            }}
          >
            {welcomeMessageState.message}
          </Typography>
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="freelancer home tabs"
          >
            <Tab label="Overview" />
            {isLoadingStats ? (
              <Tab label="Loading timesheet stats..." disabled />
            ) : statsError ? (
              <Tab label="Timesheet stats error" disabled sx={{ color: 'red' }} />
            ) : (
              <Tab label="Time Tracking" />
            )}
          </Tabs>
        </Box>

        <TabPanel value={tabValue} index={0}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={4}>
              <Paper elevation={2} sx={{ p: 3, height: "100%" }}>
                <Box display="flex" alignItems="center" mb={3}>
                  <Avatar
                    src={freelancerProfile?.profilePicture}
                    alt={`${freelancerProfile?.firstName} ${freelancerProfile?.lastName}`}
                    sx={{ width: 80, height: 80, mr: 2 }}
                  />
                  <Box>
                    <Typography variant="h5">
                      {freelancerProfile?.firstName}{" "}
                      {freelancerProfile?.lastName}
                    </Typography>
                    <Typography color="textSecondary">
                      {freelancerProfile?.email}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="h6" gutterBottom>
                  Skills
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mb: 3 }}>
                  {freelancerProfile?.skills?.map((skill) => (
                    <Chip key={skill} label={skill} />
                  ))}
                </Box>
                {freelancerProfile?.employment && (
                  <>
                    <Typography variant="h6" gutterBottom>
                      Employment
                    </Typography>
                    <Typography variant="body2" color="textSecondary" paragraph>
                      {freelancerProfile.employment.hasFullTimeJob
                        ? "Full-time employed"
                        : "Not employed full-time"}
                      {freelancerProfile.employment.employer &&
                        ` at ${freelancerProfile.employment.employer}`}
                    </Typography>
                  </>
                )}
              </Paper>
            </Grid>
            <Grid item xs={12} md={8}>
              <Paper elevation={2} sx={{ p: 3, height: "100%" }}>
                <Typography variant="h6" gutterBottom>
                  Recent Activity
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={2} sx={{ p: 2, textAlign: "center" }}>
                      <Typography variant="h4" color="primary">
                        {stats.totalHours.toFixed(1)}
                      </Typography>
                      <Typography variant="body2">Hours This Month</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={2} sx={{ p: 2, textAlign: "center" }}>
                      <Typography variant="h4" color="primary">
                        {stats.uniqueClients}
                      </Typography>
                      <Typography variant="body2">Active Clients</Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <Paper elevation={2} sx={{ p: 2, textAlign: "center" }}>
                      <Typography variant="h4" color="primary">
                        {stats.averageHoursPerDay.toFixed(1)}
                      </Typography>
                      <Typography variant="body2">Avg Hours/Day</Typography>
                    </Paper>
                  </Grid>
                </Grid>

                <Box sx={{ mt: 4 }}>
                  <Typography variant="h6" gutterBottom>
                    Project Assignments
                  </Typography>
                  {getProjectStatusChartData(projectAssignmentStatusCounts)
                    .length > 0 ? (
                    <Box sx={{ width: "100%", height: 300 }}>
                      <PieChart
                        series={[
                          {
                            data: getProjectStatusChartData(
                              projectAssignmentStatusCounts
                            ),
                            highlightScope: {
                              faded: "global",
                              highlighted: "item",
                            },
                            faded: { innerRadius: 30, additionalRadius: -30 },
                          },
                        ]}
                        height={300}
                      />
                    </Box>
                  ) : (
                    <Typography
                      color="text.secondary"
                      sx={{ textAlign: "center", py: 4 }}
                    >
                      No project assignments yet
                    </Typography>
                  )}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 4,
            }}
          >
            <Typography variant="h5" sx={{ fontWeight: "bold" }}>
              Time Tracking Overview
            </Typography>
            <FormControl sx={{ minWidth: 120 }}>
              <InputLabel id="year-select-label">Year</InputLabel>
              <Select
                labelId="year-select-label"
                id="year-select"
                value={selectedYear}
                label="Year"
                onChange={handleYearChange}
              >
                {[...Array(5)].map((_, i) => {
                  const year = new Date().getFullYear() - i;
                  return (
                    <MenuItem key={year} value={year}>
                      {year}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          <Grid container spacing={2} sx={{ marginBottom: 4 }}>
            <Grid item xs={12} md={3}>
              <Paper elevation={2} sx={{ padding: 2, textAlign: "center" }}>
                <Typography variant="h6">
                  {stats.totalHours.toFixed(2)}
                </Typography>
                <Typography variant="body2">Total Hours Logged</Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  justifyContent="center"
                  mt={1}
                >
                  <Typography variant="caption" color="success.main">
                    {stats.approvedHours.toFixed(2)} approved
                  </Typography>
                  <Typography variant="caption" color="warning.main">
                    {stats.submittedHours.toFixed(2)} submitted
                  </Typography>
                </Stack>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper elevation={2} sx={{ padding: 2, textAlign: "center" }}>
                <Typography variant="h6">
                  {stats.averageHoursPerDay.toFixed(2)}
                </Typography>
                <Typography variant="body2">Average Hours per Day</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper elevation={2} sx={{ padding: 2, textAlign: "center" }}>
                <Typography variant="h6">{stats.uniqueClients}</Typography>
                <Typography variant="body2">Active Clients</Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} md={3}>
              <Paper elevation={2} sx={{ padding: 2, textAlign: "center" }}>
                <Typography variant="h6">
                  {stats.mostActiveClient?.clientName || "N/A"}
                </Typography>
                <Typography variant="body2">Most Active Client</Typography>
                {stats.mostActiveClient && (
                  <Typography variant="caption" color="textSecondary">
                    {stats.mostActiveClient.hours.toFixed(2)} hours
                  </Typography>
                )}
              </Paper>
            </Grid>
          </Grid>

          <TimeTrackingCharts selectedYear={selectedYear} />
        </TabPanel>
      </Paper>
    </>
  );
};

export default FreelancerHome;
