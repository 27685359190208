import React, { useState, useCallback, useEffect } from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  Box,
  Typography,
  SelectChangeEvent,
  IconButton,
  CircularProgress,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { useAdminClient } from "hooks/admin";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import {
  addFileToProjectAssignment,
  getProjectAssignmentBlobUrl,
} from "core/projectAssignments";
import { storage } from "config/firebase";
import { ref, getDownloadURL } from "firebase/storage";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  ProjectAssignment,
  Project,
  ConsultingServicesAgreement,
} from "types/projects";
interface AssignmentEditFormProps {
  assignment: ProjectAssignment;
  onSave: (updatedAssignment: ProjectAssignment) => void;
  onCancel: () => void;
  onDelete: (assignment: ProjectAssignment) => void;
  handleViewFile: (assignment: ProjectAssignment) => void;
  clientId: string;
}

const AssignmentEditForm: React.FC<AssignmentEditFormProps> = ({
  assignment,
  onSave,
  onCancel,
  onDelete,
  clientId,
  handleViewFile,
}) => {
  const [editedAssignment, setEditedAssignment] =
    useState<ProjectAssignment>(assignment);
  const [file, setFile] = useState<File | null>(null);
  const [fileUrl, setFileUrl] = useState<string | null>(null);
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const {
    client,
    isLoading: isClientLoading,
    isError: isClientError,
  } = useAdminClient(clientId);

  const projects = client?.projects;

  useEffect(() => {
    const fetchFileUrl = async () => {
      if (editedAssignment.fileUrl) {
        setIsLoadingFile(true);
        try {
          const url = await getDownloadURL(
            ref(storage, editedAssignment.fileUrl)
          );
          setFileUrl(url);
        } catch (error) {
          console.error("Error fetching file URL:", error);
          setFileUrl(null);
        }
        setIsLoadingFile(false);
      }
    };

    fetchFileUrl();
  }, [editedAssignment.fileUrl]);

  const handleChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent<string>
  ) => {
    const { name, value } = event.target;
    setEditedAssignment((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    if (file) {
      const url = await addFileToProjectAssignment(
        clientId,
        editedAssignment.id,
        file
      );

      setEditedAssignment((prev) => ({
        ...prev,
        fileUrl: url,
      }));
    }
    onSave(editedAssignment);
  };

  const filterCSAForProject = (projectId: string) => {
    return projects?.find((project: Project) => project.id === projectId)
      ?.consultingServicesAgreements;
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const [selectedFile, setSelectedFile] = useState<{
    url: string;
    name: string;
  } | null>(null);

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel>Project</InputLabel>
            <Select
              name="projectId"
              value={editedAssignment.projectId || ""}
              onChange={handleChange}
              label="Project"
            >
              {projects &&
                projects.map((project: Project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel>CSA</InputLabel>
            <Select
              name="csaId"
              value={editedAssignment.csaId || ""}
              onChange={handleChange}
              label="CSA"
              disabled={!editedAssignment.projectId}
            >
              {editedAssignment.projectId &&
                filterCSAForProject(editedAssignment.projectId)?.map(
                  (csa: ConsultingServicesAgreement) => (
                    <MenuItem key={csa.id} value={csa.id}>
                      {csa.name}
                    </MenuItem>
                  )
                )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="startDate"
            label="Start Date"
            type="date"
            size="small"
            value={editedAssignment.startDate || ""}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="endDate"
            label="End Date"
            type="date"
            size="small"
            value={editedAssignment.endDate || ""}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="billableRate"
            label="Rate"
            type="number"
            size="small"
            value={editedAssignment.billableRate || ""}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="budgetedHours"
            label="Hours"
            type="number"
            size="small"
            value={editedAssignment.budgetedHours || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="utilizedHours"
            label="Utilized"
            type="number"
            size="small"
            value={editedAssignment.utilizedHours || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="amountPaid"
            label="Amount Paid"
            type="number"
            size="small"
            value={editedAssignment.amountPaid || ""}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            name="paymentDate"
            label="Payment Date"
            type="date"
            size="small"
            value={editedAssignment.paymentDate || ""}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
          />
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth size="small">
            <InputLabel>Payment Method</InputLabel>
            <Select
              name="paymentMethod"
              value={editedAssignment.paymentMethod || ""}
              onChange={handleChange}
              label="Payment Method"
            >
              <MenuItem value="Quickbooks">Quickbooks</MenuItem>
              <MenuItem value="Zelle">Zelle</MenuItem>
              <MenuItem value="Venmo">Venmo</MenuItem>
              <MenuItem value="CashApp">CashApp</MenuItem>
              <MenuItem value="Check">Check</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Box
            {...getRootProps()}
            sx={{
              mt: 1,
              p: 1,
              border: "1px dashed grey",
              borderRadius: 1,
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.04)",
              },
            }}
          >
            <input {...getInputProps()} />
            {isLoadingFile ? (
              <CircularProgress size={24} />
            ) : (
              <Typography variant="caption">
                {file
                  ? `Selected: ${file.name}`
                  : fileUrl
                    ? `Current file: ${editedAssignment.fileUrl} (Click to view)`
                    : isDragActive
                      ? "Drop the file here ..."
                      : "Drop file here or click to select"}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: "flex", justifyContent: "space-around", p: 1 }}>
            <IconButton
              onClick={() => onDelete(editedAssignment)}
              size="small"
              color="error"
            >
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={onCancel} size="small">
              <CancelIcon />
            </IconButton>
            {editedAssignment.fileUrl && (
              <IconButton
                onClick={() => handleViewFile(editedAssignment)}
                size="small"
              >
                <VisibilityIcon />
              </IconButton>
            )}
            <IconButton type="submit" size="small" color="primary">
              <SaveIcon />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default AssignmentEditForm;
