import { db } from "config/firebase";
import { collection, addDoc } from "firebase/firestore";

export const sendMail = async (
  to: string,
  subject: string,
  body: string
): Promise<boolean> => {
  const mailRef = collection(db, "mail");
  try {
    await addDoc(mailRef, {
      to: to,
      message: {
        subject: subject,
        html: body,
      },
    });
    return true;
  } catch (error) {
    console.error("Error sending email:", error);
    return false;
  }
};
