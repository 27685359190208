import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { PrivateUserInfoType } from "../user";

export const privateUserConverter: FirestoreDataConverter<PrivateUserInfoType> =
  {
    toFirestore(user: WithFieldValue<PrivateUserInfoType>): DocumentData {
      return {
        ...user,
      };
    },
    fromFirestore(
      snapshot: DocumentSnapshot,
      options: SnapshotOptions
    ): PrivateUserInfoType {
      const data = snapshot.data(options);
      return {
        id: snapshot.id,
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        role: data?.role,
        permissions: data?.permissions,
        documents: data?.documents,
        disabled: data?.disabled,
        emailVerified: data?.emailVerified,
        displayName: data?.firstName + " " + data?.lastName,
        needsOnboarding: data?.needsOnboarding,
        onboardingCompletedAt: data?.onboardingCompletedAt,
        needsPasswordReset: data?.needsPasswordReset,        
        passwordResetAt: data?.passwordResetAt,
        lastLoginAt: data?.lastLoginAt,
        lastPasswordResetRequestAt: data?.lastPasswordResetRequestAt,
        createdAt: data?.createdAt,
        updatedAt: data?.updatedAt,
        needsInitialPasswordSet: data?.needsInitialPasswordSet,
        initialPasswordSetAt: data?.initialPasswordSetAt,
        lastVerificationEmailRequestAt: data?.lastVerificationEmailRequestAt,
        lastSignInLinkRequestAt: data?.lastSignInLinkRequestAt,
      };
    },
  };
