import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getAdminConfig } from "core/admin/config/getAdminConfig";
import { updateAdminConfig } from "core/admin/config/updateAdminConfig";
import { UpdateConfigParams } from "types/admin/config";

export const useAdminConfig = () => {
  const queryClient = useQueryClient();

  const { data: config, isLoading, error } = useQuery({
    queryKey: ["adminConfig"],
    queryFn: getAdminConfig,
  });

  const updateConfig = useMutation({
    mutationFn: updateAdminConfig,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["adminConfig"] });
    },
  });

  const setDefaultIndependentEngineerAgreement = (templateId: string) => {
    return updateConfig.mutate({
      field: "defaultIndependentEngineerAgreementId",
      value: templateId,
    });
  };

  return {
    config,
    isLoading,
    error,
    setDefaultIndependentEngineerAgreement,
    isUpdating: updateConfig.isPending,
    updateError: updateConfig.error,
  };
}; 