import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Button,
  Grid,
  Card,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import {
  Assignment as AssignmentIcon,
  Receipt as ReceiptIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";

const StakeholderHome = (): JSX.Element => {
  const { userInfo } = useAuth();
  const navigate = useNavigate();

  const handleNavigate = (path: string) => {
    navigate(path);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Welcome back, {userInfo?.firstName} {userInfo?.lastName}!
      </Typography>

      <Grid container spacing={3} sx={{ mt: 2 }}>
        <Grid item xs={12} md={4}>
          <Card>
            <CardActionArea
              onClick={() =>
                handleNavigate("/stakeholder/projects/my-projects")
              }
            >
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <AssignmentIcon
                    color="primary"
                    sx={{ fontSize: 40, mr: 2 }}
                  />
                  <Typography variant="h6">My Projects</Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  View and manage your active projects
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardActionArea
              onClick={() => handleNavigate("/stakeholder/projects/new-idea")}
            >
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <DescriptionIcon
                    color="primary"
                    sx={{ fontSize: 40, mr: 2 }}
                  />
                  <Typography variant="h6">Submit New Idea</Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  Submit a new project idea or proposal
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>

        <Grid item xs={12} md={4}>
          <Card>
            <CardActionArea
              onClick={() => handleNavigate("/stakeholder/invoices")}
            >
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                  <ReceiptIcon color="primary" sx={{ fontSize: 40, mr: 2 }} />
                  <Typography variant="h6">Invoices</Typography>
                </Box>
                <Typography variant="body2" color="text.secondary">
                  View and manage project invoices
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default StakeholderHome;
