import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { logoNoBackground, authBackground } from "assets";

// Basic Theme
export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#000000",
        light: "#BDBDBD",
        dark: "#424242",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#757575",
      },
      error: {
        main: "#f44336",
      },
      warning: {
        main: "#ff9800",
      },
      info: {
        // main: '#2196f3',
        main: "#000000",
        // dark: '#ffffff'
      },
      success: {
        main: "#4caf50",
      },
      background: {
        default: "#f5f5f5",
        paper: "#ffffff",
      },
      text: {
        primary: "#000000",
        secondary: "#757575",
      },
    },
    typography: {
      fontFamily: "Outfit, sans-serif",
      fontSize: 14,
      h1: {
        fontSize: "2.5rem", // 40px
        fontWeight: 500,
        lineHeight: 1.2,
      },
      h2: {
        fontSize: "2rem", // 32px
        fontWeight: 500,
        lineHeight: 1.3,
      },
      h3: {
        fontSize: "1.75rem", // 28px
        fontWeight: 500,
        lineHeight: 1.35,
      },
      h4: {
        fontSize: "1.5rem", // 24px
        fontWeight: 500,
        lineHeight: 1.4,
      },
      h5: {
        fontSize: "1.25rem", // 20px
        fontWeight: 500,
        lineHeight: 1.45,
      },
      h6: {
        fontSize: "1rem", // 16px
        fontWeight: 500,
        lineHeight: 1.6,
      },
      subtitle1: {
        fontSize: "1rem",
        fontWeight: 400,
        lineHeight: 1.75,
      },
      subtitle2: {
        fontSize: "0.875rem", // 14px
        fontWeight: 500,
        lineHeight: 1.57,
      },
      body1: {
        fontSize: "1rem", // 16px
        fontWeight: 400,
        lineHeight: 1.5,
      },
      body2: {
        fontSize: "0.875rem", // 14px
        fontWeight: 400,
        lineHeight: 1.43,
      },
      button: {
        fontSize: "0.875rem",
        fontWeight: 500,
        lineHeight: 1.75,
        textTransform: "uppercase",
      },
      caption: {
        fontSize: "0.75rem", // 12px
        fontWeight: 400,
        lineHeight: 1.66,
      },
      overline: {
        fontSize: "0.75rem",
        fontWeight: 400,
        lineHeight: 2.66,
        textTransform: "uppercase",
      },
    },
    spacing: 8, // default spacing
    shape: {
      borderRadius: 4, // default border radius
    },
    // ... other global style overrides
  })
);

// Custom Styles that I will resuse across the components bc I don't feel like adding another file

export const authTextFieldStyle = {
  // backgroundColor: 'white',
  borderRadius: theme.shape.borderRadius,
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    // '&:hover fieldset': {
    //   borderColor: 'rgba(0, 0, 0, 0.5)',
    // },
    "&.Mui-focused fieldset": {
      borderColor: "black",
    },
  },
  "& .MuiInputLabel-root": {
    color: "rgba(0, 0, 0, 0.6)",
    "&.Mui-focused": {
      color: "black",
    },
  },
  // '& .MuiInputLabel-root': {
  //   color: 'rgba(0, 0, 0, 0.6)',
  // },
  "& .MuiInputBase-input": {
    color: "black",
  },
};
