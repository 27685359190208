import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
  UseMutationResult,
} from "@tanstack/react-query";
import { DocumentReference, DocumentData } from "firebase/firestore";
import { adminController } from "controllers/adminController";
import { Client } from "types/projects";

export const useAdminClient = (clientId: string) => {
  const queryClient = useQueryClient();

  // Load a single client (GET)
  const {
    data: client,
    isLoading,
    isError,
  }: UseQueryResult<Client | null, Error> = useQuery<Client | null, Error>({
    queryKey: ["client", clientId],
    queryFn: () => adminController.clients.loadSingleNestedClient(clientId),
    staleTime: 5 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });

  // Update a client (PUT)
  const updateClientMutation = useMutation<void, Error, Partial<Client>>({
    mutationFn: (updatedClient: Partial<Client>) =>
      adminController.clients.updateClient(updatedClient),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
    },
  });

  // Delete a client (DELETE)
  const deleteClientMutation = useMutation<void, Error, void>({
    mutationFn: () => adminController.clients.deleteClient(clientId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["client", clientId] });
    },
  });

  // Add a new nested document
  const addNestedDocumentMutation = useMutation<
    DocumentReference<DocumentData>,
    Error,
    { collectionName: string; data: any }
  >({
    mutationFn: ({ collectionName, data }) =>
      adminController.clients.addNestedDocument(clientId, collectionName, data),
    onSuccess: (_, { collectionName }) => {
      queryClient.invalidateQueries({
        queryKey: ["client", clientId, collectionName],
      });
    },
  });

  // Update a nested document
  const updateNestedDocumentMutation = useMutation<
    void,
    Error,
    { collectionName: string; docId: string; data: any }
  >({
    mutationFn: ({ collectionName, docId, data }) =>
      adminController.clients.updateNestedDocument(
        clientId,
        collectionName,
        docId,
        data
      ),
    onSuccess: (_, { collectionName }) => {
      queryClient.invalidateQueries({
        queryKey: ["client", clientId, collectionName],
      });
    },
  });

  // Delete a nested document
  const deleteNestedDocumentMutation = useMutation<
    void,
    Error,
    { collectionName: string; docId: string }
  >({
    mutationFn: ({ collectionName, docId }) =>
      adminController.clients.deleteNestedDocument(
        clientId,
        collectionName,
        docId
      ),
    onSuccess: (_, { collectionName }) => {
      queryClient.invalidateQueries({
        queryKey: ["client", clientId, collectionName],
      });
    },
  });

  // Load nested documents

  return {
    client,
    isLoading,
    isError,
    updateClient: updateClientMutation,
    deleteClient: deleteClientMutation,
    addNestedDocument: addNestedDocumentMutation,
    updateNestedDocument: updateNestedDocumentMutation,
    deleteNestedDocument: deleteNestedDocumentMutation,
    getNestedDocuments: (collectionName: string) =>
      adminController.clients.loadNestedDocuments(clientId, collectionName),
  };
};
