import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Project, ProjectStatus } from "types/projects";
import { useAuth } from "hooks/auth/useAuth";

interface AddProjectDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (projectData: Omit<Project, "id">) => void;
  clientId: string;
}

const AddProjectDialog: React.FC<AddProjectDialogProps> = ({
  open,
  onClose,
  onAdd,
  clientId,
}) => {
  const [projectName, setProjectName] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [status, setStatus] = useState<ProjectStatus>("idea");
  const [ideaDate, setIdeaDate] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const { currentUser } = useAuth();

  if (!currentUser) {
    return null;
  }

  const handleAdd = () => {
    onAdd({
      name: projectName,
      description: projectDescription,
      status,
      ideaDate,
      startDate,
      endDate,
      clientId,
      createdByUserId: currentUser?.uid,
    });
    resetForm();
    onClose();
  };

  const resetForm = () => {
    setProjectName("");
    setProjectDescription("");
    setStatus("idea");
    setIdeaDate(null);
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Project</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Project Name"
          type="text"
          fullWidth
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Project Description"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={projectDescription}
          onChange={(e) => setProjectDescription(e.target.value)}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Status</InputLabel>
          <Select
            value={status}
            onChange={(e) => setStatus(e.target.value as ProjectStatus)}
          >
            <MenuItem value="idea">Idea</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="completed">Completed</MenuItem>
            <MenuItem value="on_hold">On Hold</MenuItem>
            <MenuItem value="cancelled">Cancelled</MenuItem>
          </Select>
        </FormControl>
        <TextField
          margin="dense"
          label="Idea Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={ideaDate || ""}
          onChange={(e) => setIdeaDate(e.target.value)}
        />
        <TextField
          margin="dense"
          label="Start Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={startDate || ""}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          margin="dense"
          label="End Date"
          type="date"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={endDate || ""}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleAdd} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddProjectDialog;
