import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";

export const getDefaultTemplateId = async (): Promise<string> => {
  const adminConfigRef = doc(db, "config", "admin");
  const adminConfigSnap = await getDoc(adminConfigRef);

  if (!adminConfigSnap.exists()) {
    throw new Error("Admin config document not found");
  }

  const adminConfig = adminConfigSnap.data();
  if (!adminConfig.defaultIndependentEngineerAgreementId) {
    throw new Error("Default independent engineer agreement ID not found");
  }
  return adminConfig.defaultIndependentEngineerAgreementId;
};
