import { collection, addDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { CreateLabelParams } from "types/freelo/crud";

export const createLabel = async (
  params: CreateLabelParams
): Promise<string> => {
  const { boardId, name, color } = params;
  const labelsRef = collection(db, "boards", boardId, "labels");
  const newLabelRef = await addDoc(labelsRef, {
    name,
    color,
    createdAt: DateTime.now().toISO(),
    updatedAt: DateTime.now().toISO(),
  });
  return newLabelRef.id;
};
