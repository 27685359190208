import { getEngineerAssignments } from "core/projectAssignments/getEngineerAssignments";
import { getProjectName } from "core/projects";
import { getCSAName } from "core/csa";
import { getIndependentEngineerAgreements } from "../independentEngineerAgreements/getIndependentEngineerAgreements";
import { ProjectAssignment } from "types/projects";
import { IndependentEngineerAgreement } from "types/user";

export interface AgreementToSign {
  id: string;
  type: "independentEngineerAgreement" | "projectAssignment";
  agreement: IndependentEngineerAgreement | ProjectAssignment;
}

export const getAgreementsToSign = async (
  freelancerId: string
): Promise<AgreementToSign[]> => {
  // Fetch project assignments
  const fetchedAssignments = await getEngineerAssignments(freelancerId);
  const signingAssignments = fetchedAssignments.filter(
    (a) =>
      (a.status === "signing" &&
        a.everSignDocumentStatus === "awaiting_engineer_signature") ||
      a.everSignDocumentStatus === "awaiting_admin_signature"
  );

  const enrichedAssignments = await Promise.all(
    signingAssignments.map(async (assignment) => {
      const projectName = await getProjectName(
        assignment.clientId,
        assignment.projectId
      );
      return {
        id: assignment.id,
        type: "projectAssignment" as const,
        agreement: {
          ...assignment,
          projectName,
        },
      };
    })
  );

  // Fetch independent engineer agreements
  const independentAgreements =
    await getIndependentEngineerAgreements(freelancerId);
  const mappedIndependentAgreements = independentAgreements.map(
    (agreement) => ({
      id: agreement.id,
      type: "independentEngineerAgreement" as const,
      agreement,
    })
  ) as AgreementToSign[];

  // Combine both types of agreements
  return [...enrichedAssignments, ...mappedIndependentAgreements];
};
