import { useQuery } from "@tanstack/react-query";
import { useAuth } from "hooks/auth/useAuth";
import { loadClientsNonAdmin } from "core/clients/loadClientsNonAdmin";

export const useFreelancerRoleClients = () => {
  const { userInfo } = useAuth();

  const clientsQuery = useQuery({
    queryKey: ["freelancer-clients", userInfo?.id],
    queryFn: async () => {
      const allClients = await loadClientsNonAdmin(userInfo);
      return allClients;
    },
    enabled: !!userInfo?.id,
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const getClientDetails = async (clientId: string) => {
    const client = clientsQuery.data?.find((client) => client.id === clientId);
    return client;
  };

  return {
    clients: clientsQuery.data || [],
    isLoading: clientsQuery.isLoading,
    isError: clientsQuery.isError,
    error: clientsQuery.error,
    useClientDetails: (clientId: string) =>
      useQuery({
        queryKey: ["freelancer-client", clientId, userInfo?.id],
        queryFn: () => getClientDetails(clientId),
        enabled: !!clientId && !!userInfo?.id,
        staleTime: 5 * 60 * 1000,
        refetchOnWindowFocus: false,
      }),
    refetch: clientsQuery.refetch,
  };
};
