import { collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import {
  KnowledgeBaseFolder,
  KnowledgeBaseFile,
  KnowledgeBaseStructure,
} from "types/knowledgeBase";

export const getKnowledgeBaseStructure =
  async (): Promise<KnowledgeBaseStructure> => {
    // Get all folders
    const foldersRef = collection(db, "knowledgeBaseFolders");
    const filesRef = collection(db, "knowledgeBaseFiles");

    const [folderDocs, fileDocs] = await Promise.all([
      getDocs(foldersRef),
      getDocs(filesRef),
    ]);

    // Create a map of folders by their path
    const folderMap = new Map<string, KnowledgeBaseFolder>();

    // Initialize root folder
    const rootFolder: KnowledgeBaseFolder = {
      id: "root",
      name: "Knowledge Base",
      path: "root",
      folders: [],
      files: [],
      createdAt: DateTime.now().toISO(),
      updatedAt: DateTime.now().toISO(),
      createdBy: "system",
    };
    folderMap.set("root", rootFolder);

    // Process all folders
    folderDocs.docs.forEach((doc) => {
      const folderData = doc.data();
      const folder: KnowledgeBaseFolder = {
        id: doc.id,
        name: folderData.name,
        path: folderData.path,
        folders: [],
        files: [],
        createdAt: folderData.createdAt,
        updatedAt: folderData.updatedAt,
        createdBy: folderData.createdBy,
      };
      folderMap.set(folder.path, folder);
    });

    // Build folder hierarchy
    folderMap.forEach((folder) => {
      if (folder.path !== "root") {
        const parentPath =
          folder.path.split("/").slice(0, -1).join("/") || "root";
        const parentFolder = folderMap.get(parentPath);
        if (parentFolder) {
          parentFolder.folders.push(folder);
        }
      }
    });

    // Process all files
    fileDocs.docs.forEach((doc) => {
      const fileData = doc.data();
      const file: KnowledgeBaseFile = {
        id: doc.id,
        name: fileData.name,
        content: fileData.content,
        path: fileData.path,
        storageUrl: fileData.storageUrl,
        createdAt: fileData.createdAt,
        updatedAt: fileData.updatedAt,
        createdBy: fileData.createdBy,
        lastModifiedBy: fileData.lastModifiedBy,
        metadata: fileData.metadata,
      };

      const parentPath = file.path.split("/").slice(0, -1).join("/") || "root";
      const parentFolder = folderMap.get(parentPath);
      if (parentFolder) {
        parentFolder.files.push(file);
      }
    });

    return { rootFolder: folderMap.get("root")! };
  };
