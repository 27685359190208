import { doc, setDoc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { AdminConfig, UpdateConfigParams } from "types/admin/config";

export const updateAdminConfig = async ({ field, value }: UpdateConfigParams): Promise<void> => {
  const configRef = doc(db, "config", "admin");
  const configSnap = await getDoc(configRef);
  
  let currentConfig: AdminConfig;
  
  if (!configSnap.exists()) {
    currentConfig = {
      defaultIndependentEngineerAgreementId: "",
    };
  } else {
    currentConfig = configSnap.data() as AdminConfig;
  }
  
  await setDoc(configRef, {
    ...currentConfig,
    [field]: value,
  });
}; 