import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Button,
  Grid,
  Chip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useProjectsNonAdmin } from "hooks/project/useProjectsNonAdmin";

export const StakeholderProjectOverview = () => {
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { data: projects, isLoading, error } = useProjectsNonAdmin();
  const project = projects?.find((p) => p.id === projectId);

  const handleBack = () => {
    navigate("/stakeholder/projects");
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      );
    }

    if (error || !project) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Paper
            elevation={3}
            sx={{ p: 4, textAlign: "center", maxWidth: 400 }}
          >
            <ErrorOutlineIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h5" gutterBottom>
              Error
            </Typography>
            <Typography color="error">
              {error?.message || "Project not found"}
            </Typography>
          </Paper>
        </Box>
      );
    }

    return (
      <Box>
        <Paper elevation={3} sx={{ p: 3, mb: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4" gutterBottom>
                {project.name}
              </Typography>
              <Chip
                label={project.status}
                color={project.status === "active" ? "success" : "default"}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Description
              </Typography>
              <Typography>{project.description}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Client
              </Typography>
              <Typography>{project.clientId}</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Start Date
              </Typography>
              <Typography>
                {project.startDate
                  ? new Date(project.startDate).toLocaleDateString()
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Box>
    );
  };

  return (
    <Box sx={{ p: 3 }}>
      <Button startIcon={<ArrowBackIcon />} onClick={handleBack} sx={{ mb: 3 }}>
        Back to Projects
      </Button>
      {renderContent()}
    </Box>
  );
};
