import { useState, useCallback } from "react";
import { createInvoice, deleteInvoice, getInvoicePDFUrl } from "core/invoices";
import { getClientProjects } from "core/projects";
import { getProjectCSAs } from "core/csa";
import { createAndSavePDF } from "core/invoices/createAndSavePDF";
import { ClientInvoiceData } from "@freetech/models/bank";
import { Project } from "@freetech/models/projects";
import { ConsultingServicesAgreement } from "@freetech/models/projects";
import { useAdminClients } from "./useAdminClients";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const initialInvoiceData: ClientInvoiceData = {
  invoiceNumber: "",
  invoiceDate: new Date().toISOString().split("T")[0],
  dueDate: "",
  billTo: { name: "", company: "" },
  shipTo: { name: "", company: "" },
  items: [{ description: "", quantity: 1, rate: 0 }],
  terms: "Net 10",
  discount: 0,
  id: "",
  projectId: "",
  csaId: "",
  clientId: "",
  clientName: "",
  projectName: "",
  csaName: "",
  totalDollarAmountDue: 0,
};

export const useAdminInvoicing = () => {
  const queryClient = useQueryClient();
  const { clients } = useAdminClients();
  const [invoiceData, setInvoiceData] = useState<ClientInvoiceData>(initialInvoiceData);
  const [selectedInvoice, setSelectedInvoice] = useState<ClientInvoiceData | null>(null);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  // Queries
  const getInvoices = useCallback(async () => {
    if (!clients) return [];
    return clients.flatMap(client => 
      (client.invoices || []).map(invoice => ({
        ...invoice,
        clientName: client.companyName,
      }))
    );
  }, [clients]);

  const {
    data: invoices = [],
    isLoading: isLoadingInvoices,
    isError: isErrorInvoices,
  } = useQuery<ClientInvoiceData[], Error>({
    queryKey: ["invoices", clients],
    queryFn: getInvoices,
    staleTime: 5 * 60 * 1000,
    enabled: !!clients,
  });

  const {
    data: projects = [],
    isLoading: isLoadingProjects,
    isError: isErrorProjects,
  } = useQuery<Project[], Error>({
    queryKey: ["projects", invoiceData.clientId],
    queryFn: async () => {
      if (!invoiceData.clientId) return [];
      return clients?.find(client => client.id === invoiceData.clientId)?.projects || [];
    },
    enabled: !!invoiceData.clientId && !!clients,
  });

  const {
    data: csas = [],
    isLoading: isLoadingCSAs,
    isError: isErrorCSAs,
  } = useQuery<ConsultingServicesAgreement[], Error>({
    queryKey: ["csas", invoiceData.clientId, invoiceData.projectId],
    queryFn: async () => {
      if (!invoiceData.clientId || !invoiceData.projectId) return [];
      return clients?.find(client => client.id === invoiceData.clientId)?.consultingServicesAgreements || [];
    },
    enabled: !!invoiceData.clientId && !!invoiceData.projectId && !!clients,
  });

  // Mutations
  const generateInvoiceMutation = useMutation({
    mutationFn: async (data: ClientInvoiceData) => {
      const invoiceId = await createInvoice(data.clientId, data);
      const pdfUrl = await createAndSavePDF(data, data.clientId, invoiceId);
      return { invoiceId, pdfUrl, data };
    },
    onSuccess: (result) => {
      // Update the invoices cache to include the new invoice
      queryClient.setQueryData(["invoices", clients], (oldData: ClientInvoiceData[] | undefined) => {
        if (!oldData) return [result.data];
        const newInvoice = {
          ...result.data,
          id: result.invoiceId,
          clientName: clients?.find(c => c.id === result.data.clientId)?.companyName || '',
        };
        return [...oldData, newInvoice];
      });

      // Invalidate queries to ensure consistency
      queryClient.invalidateQueries({ queryKey: ["invoices"] });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  const deleteInvoiceMutation = useMutation({
    mutationFn: async (params: { clientId: string; invoiceId: string }) => {
      await deleteInvoice(params.clientId, params.invoiceId);
      return params;
    },
    onSuccess: (params) => {
      // Update the clients cache to remove the deleted invoice
      queryClient.setQueryData(["invoices", clients], (oldData: ClientInvoiceData[] | undefined) => {
        if (!oldData) return [];
        return oldData.filter(invoice => invoice.id !== params.invoiceId);
      });

      // Invalidate the queries to ensure consistency
      queryClient.invalidateQueries({ queryKey: ["invoices"] });
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  const updateInvoiceMutation = useMutation({
    mutationFn: async (params: { invoiceId: string; invoiceData: Partial<ClientInvoiceData> }) => {
    //   await updateInvoice(params.invoiceId, params.invoiceData);
      return params;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["invoices"] });
    },
  });

  // Handlers
  const handleChange = useCallback((name: string, value: string | number) => {
    setInvoiceData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const handleAddressChange = useCallback(
    (type: "billTo" | "shipTo", field: "name" | "company", value: string) => {
      setInvoiceData((prev) => ({
        ...prev,
        [type]: { ...prev[type], [field]: value },
      }));
    },
    []
  );

  const handleItemChange = useCallback(
    (
      index: number,
      field: keyof ClientInvoiceData["items"][0],
      value: string | number
    ) => {
      setInvoiceData((prev) => ({
        ...prev,
        items: prev.items.map((item, i) =>
          i === index
            ? {
                ...item,
                [field]: field === "description" ? value : Number(value),
              }
            : item
        ),
      }));
    },
    []
  );

  const handleAddItem = useCallback(() => {
    setInvoiceData((prev) => ({
      ...prev,
      items: [...prev.items, { description: "", quantity: 1, rate: 0 }],
    }));
  }, []);

  const handleSelectClientDataChange = useCallback(
    async (name: string, value: string) => {
      setInvoiceData((prev) => {
        if (name === "clientId") {
          return { ...prev, [name]: value, projectId: "", csaId: "" };
        }
        if (name === "projectId") {
          return { ...prev, [name]: value, csaId: "" };
        }
        return { ...prev, [name]: value };
      });
    },
    []
  );

  const handleViewInvoice = useCallback(async (invoice: ClientInvoiceData) => {
    setSelectedInvoice(invoice);
    setIsViewDialogOpen(true);
    try {
      const url = await getInvoicePDFUrl(invoice.clientId, invoice.id);
      setPdfUrl(url);
    } catch (error) {
      console.error("Error fetching PDF URL:", error);
    }
  }, []);

  const handleCloseViewDialog = useCallback(() => {
    setIsViewDialogOpen(false);
    setSelectedInvoice(null);
    setPdfUrl(null);
  }, []);

  const calculateTotalAmount = useCallback((invoice: ClientInvoiceData): number => {
    if (!invoice.items || !Array.isArray(invoice.items)) {
      return 0;
    }
    return invoice.items.reduce(
      (sum: number, item) => sum + (item.quantity || 0) * (item.rate || 0),
      0
    );
  }, []);

  const resetInvoiceData = useCallback(() => {
    setInvoiceData(initialInvoiceData);
  }, []);

  return {
    // Form state and handlers
    invoiceData,
    clients,
    projects,
    csas,
    handleChange,
    handleAddressChange,
    handleItemChange,
    handleAddItem,
    handleSelectClientDataChange,
    resetInvoiceData,

    // List state and handlers
    invoices,
    selectedInvoice,
    isViewDialogOpen,
    pdfUrl,
    handleViewInvoice,
    handleCloseViewDialog,
    calculateTotalAmount,

    // Loading and error states
    isLoading: isLoadingInvoices || isLoadingProjects || isLoadingCSAs || 
               generateInvoiceMutation.isPending || deleteInvoiceMutation.isPending || 
               updateInvoiceMutation.isPending,
    isError: isErrorInvoices || isErrorProjects || isErrorCSAs || 
             generateInvoiceMutation.isError || deleteInvoiceMutation.isError || 
             updateInvoiceMutation.isError,

    // Mutations
    generateInvoice: (data: ClientInvoiceData) => generateInvoiceMutation.mutateAsync(data),
    handleDeleteInvoice: (params: { clientId: string; invoiceId: string }) => deleteInvoiceMutation.mutateAsync(params),
    handleUpdateInvoice: (params: { invoiceId: string; invoiceData: Partial<ClientInvoiceData> }) => 
      updateInvoiceMutation.mutateAsync(params),
  };
};
