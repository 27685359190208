import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DateTime } from "luxon";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { useAuth } from "hooks/auth/useAuth";
import { sendMail } from "core/mail";
import { freelancerOnboardingComplete } from "core/mail/mailTemplates/freelancerOnboardingComplete";
import { adminOnboardingNotification } from "core/mail/mailTemplates/adminOnboardingNotification";
import { Freelancer } from "types/user";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";

interface SnackbarState {
  open: boolean;
  message: string;
  severity: "success" | "error";
}

// Extended Freelancer type with required fields for onboarding
type FreelancerOnboardingData = Omit<
  Freelancer,
  "securityClearance" | "employment"
> & {
  legalFirstName: string;
  legalLastName: string;
  phone: string;
  address: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    formattedAddress: string;
  };
  citizenship: {
    isUsCitizen: boolean;
    hasWorkAuthorization: boolean;
    workAuthorizationType?: string;
  };
  securityClearance: {
    hasClearance: boolean;
    clearanceLevel: string;
    clearanceIssuer: string;
    expirationDate: DateTime | null;
    hasCac: boolean;
  };
  employment: {
    hasFullTimeJob: boolean;
    employer: string;
    position: string;
    startDate: DateTime | null;
    employerAwareOfFreelancing: boolean;
  };
  skills: string[];
};

const STORAGE_KEY = "freelancerOnboardingState";

export const useFreelancerOnboarding = () => {
  const { userInfo, refreshUser, currentUser } = useAuth();
  const { freelancerProfile, isLoadingFreelancerProfile } = useFreelancerProfile(userInfo?.id || "");
  const navigate = useNavigate();

  // Add loading state
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // If userInfo is not loaded yet or profile is still loading, keep loading state true
    if (!userInfo || isLoadingFreelancerProfile) {
      setIsLoading(true);
      return;
    }
    setIsLoading(false);
  }, [userInfo, isLoadingFreelancerProfile]);

  // Initialize state from local storage or defaults
  const [formData, setFormData] = useState<FreelancerOnboardingData>(() => {
    const savedState = localStorage.getItem(STORAGE_KEY);

    if (savedState) {
      const parsed = JSON.parse(savedState);
      if (parsed.firstName && parsed.lastName) {
        return {
          ...parsed,
          securityClearance: {
            ...parsed.securityClearance,
            hasCac: parsed.securityClearance?.hasCac || false,
            expirationDate: parsed.securityClearance?.expirationDate
              ? DateTime.fromISO(parsed.securityClearance.expirationDate)
              : null,
          },
          employment: {
            ...parsed.employment,
            startDate: parsed.employment?.startDate
              ? DateTime.fromISO(parsed.employment.startDate)
              : null,
          },
        };
      }
    }

    // Default initial state
    return {
      id: "",
      email: "",
      firstName: "",
      lastName: "",
      displayName: "",
      role: "freelancer",
      permissions: [],
      documents: [],
      legalFirstName: "",
      legalLastName: "",
      phone: "",
      address: {
        street: "",
        city: "",
        state: "",
        zipCode: "",
        country: "United States",
        formattedAddress: "",
      },
      citizenship: {
        isUsCitizen: true,
        hasWorkAuthorization: false,
        workAuthorizationType: "",
      },
      securityClearance: {
        hasClearance: false,
        clearanceLevel: "None",
        clearanceIssuer: "",
        expirationDate: null,
        hasCac: false,
      },
      employment: {
        hasFullTimeJob: false,
        employer: "",
        position: "",
        startDate: null,
        employerAwareOfFreelancing: false,
      },
      skills: [],
    };
  });

  // Update form data when freelancerProfile becomes available
  useEffect(() => {
    if (freelancerProfile && !isLoadingFreelancerProfile) {
      const updatedProfile: FreelancerOnboardingData = {
        ...freelancerProfile,
        id: freelancerProfile.id || "",
        email: freelancerProfile.email || "",
        firstName: freelancerProfile.firstName || "",
        lastName: freelancerProfile.lastName || "",
        displayName: freelancerProfile.displayName || "",
        role: freelancerProfile.role || "freelancer",
        permissions: freelancerProfile.permissions || [],
        documents: freelancerProfile.documents || [],
        legalFirstName: freelancerProfile.firstName || "",
        legalLastName: freelancerProfile.lastName || "",
        phone: freelancerProfile.phone || "",
        address: {
          street: freelancerProfile.address?.street || "",
          city: freelancerProfile.address?.city || "",
          state: freelancerProfile.address?.state || "",
          zipCode: freelancerProfile.address?.zipCode || "",
          country: freelancerProfile.address?.country || "United States",
          formattedAddress: freelancerProfile.address?.formattedAddress || "",
        },
        citizenship: {
          isUsCitizen: freelancerProfile.citizenship?.isUsCitizen ?? true,
          hasWorkAuthorization: freelancerProfile.citizenship?.hasWorkAuthorization ?? false,
          workAuthorizationType: freelancerProfile.citizenship?.workAuthorizationType || "",
        },
        securityClearance: {
          hasClearance: freelancerProfile.securityClearance?.hasClearance ?? false,
          clearanceLevel: freelancerProfile.securityClearance?.clearanceLevel || "None",
          clearanceIssuer: freelancerProfile.securityClearance?.clearanceIssuer || "",
          expirationDate: freelancerProfile.securityClearance?.expirationDate
            ? DateTime.fromISO(freelancerProfile.securityClearance.expirationDate as unknown as string)
            : null,
          hasCac: freelancerProfile.securityClearance?.hasCac ?? false,
        },
        employment: {
          hasFullTimeJob: freelancerProfile.employment?.hasFullTimeJob ?? false,
          employer: freelancerProfile.employment?.employer || "",
          position: freelancerProfile.employment?.position || "",
          startDate: freelancerProfile.employment?.startDate
            ? DateTime.fromISO(freelancerProfile.employment.startDate as unknown as string)
            : null,
          employerAwareOfFreelancing: freelancerProfile.employment?.employerAwareOfFreelancing ?? false,
        },
        skills: freelancerProfile.skills || [],
      };
      
      setFormData(updatedProfile);
    }
  }, [freelancerProfile, isLoadingFreelancerProfile]);

  const [activeStep, setActiveStep] = useState(() => {
    const savedState = localStorage.getItem(STORAGE_KEY);
    return savedState ? JSON.parse(savedState).activeStep || 0 : 0;
  });

  const [newSkill, setNewSkill] = useState("");
  const [snackbar, setSnackbar] = useState<SnackbarState>({
    open: false,
    message: "",
    severity: "success",
  });

  // Save state to local storage whenever it changes

  const handleNext = () => {
    const stateToSave = {
        ...formData,
        activeStep,
        securityClearance: {
          ...formData.securityClearance,
          expirationDate:
            formData.securityClearance.expirationDate?.toISO() || null,
        },
        employment: {
          ...formData.employment,
          startDate: formData.employment.startDate?.toISO() || null,
        },
      };
      localStorage.setItem(STORAGE_KEY, JSON.stringify(stateToSave));
    setActiveStep((prevStep: number) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep: number) => prevStep - 1);
  };

  const handleSkillAdd = (skill: string) => {
    if (skill && !formData.skills.includes(skill)) {
      setFormData((prev) => ({
        ...prev,
        skills: [...prev.skills, skill],
      }));
    }
    setNewSkill("");
  };

  const handleSkillDelete = (skillToDelete: string) => {
    setFormData((prev) => ({
      ...prev,
      skills: prev.skills.filter((skill) => skill !== skillToDelete),
    }));
  };

  const handleAddressChange =
    (field: keyof FreelancerOnboardingData["address"]) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setFormData((prev) => ({
        ...prev,
        address: {
          ...prev.address,
          [field]: event.target.value,
          formattedAddress: "",
        },
      }));
    };

  const handleComplete = async () => {
    if (!userInfo?.id || !userInfo.email) return;

    const formattedAddress = [
      formData.address.street,
      formData.address.city,
      formData.address.state,
      formData.address.zipCode,
      formData.address.country,
    ]
      .filter(Boolean)
      .join(", ");

    try {
      // Update user profile
      const userRef = doc(db, "users", userInfo.id);
      await updateDoc(userRef, {
        legalFirstName: formData.legalFirstName,
        legalLastName: formData.legalLastName,
        phone: formData.phone,
        address: {
          ...formData.address,
          formattedAddress,
        },
        citizenship: formData.citizenship,
        securityClearance: {
          ...formData.securityClearance,
          expirationDate:
            formData.securityClearance.expirationDate?.toISO() || null,
          hasCac: formData.securityClearance.hasCac,
        },
        employment: {
          ...formData.employment,
          startDate: formData.employment.startDate?.toISO() || null,
        },
        skills: formData.skills,
        needsOnboarding: false,
        onboardingCompletedAt: new Date().toISOString(),
      });

      // Send email to freelancer
      const freelancerEmail = freelancerOnboardingComplete({
        firstName: formData.legalFirstName,
        lastName: formData.legalLastName,
      });

      await sendMail(
        userInfo.email,
        freelancerEmail.subject,
        freelancerEmail.html
      );

      // Send email to admin
      const adminEmail = adminOnboardingNotification({
        freelancerName: `${formData.legalFirstName} ${formData.legalLastName}`,
        freelancerEmail: userInfo.email,
        hasClearance: formData.securityClearance.hasClearance,
        clearanceLevel: formData.securityClearance.hasClearance
          ? formData.securityClearance.clearanceLevel
          : undefined,
        isUsCitizen: formData.citizenship.isUsCitizen,
        hasWorkAuthorization: !formData.citizenship.isUsCitizen
          ? formData.citizenship.hasWorkAuthorization
          : undefined,
        workAuthorizationType: formData.citizenship.hasWorkAuthorization
          ? formData.citizenship.workAuthorizationType
          : undefined,
        skills: formData.skills,
      });

      await sendMail("admin@freetech.co", adminEmail.subject, adminEmail.html);

      // Clear local storage after successful completion
      localStorage.removeItem(STORAGE_KEY);

      setSnackbar({
        open: true,
        message: "Profile successfully updated!",
        severity: "success",
      });

      // Give time for the snackbar to show before redirecting
      if (currentUser) {
        await refreshUser(currentUser);
      }

      setTimeout(() => {
        navigate("/dashboard");
      }, 2000);
    } catch (error) {
      console.error("Error updating user data:", error);
      setSnackbar({
        open: true,
        message: "Failed to update profile. Please try again.",
        severity: "error",
      });
    }
  };

  const handleSnackbarClose = () => {
    setSnackbar((prev) => ({ ...prev, open: false }));
  };

  const isPersonalInfoComplete = () => {
    return (
      formData.legalFirstName.trim() !== "" &&
      formData.legalLastName.trim() !== "" &&
      formData.phone.trim() !== ""
    );
  };

  const isLocationComplete = () => {
    const { street, city, state, zipCode, country } = formData.address;
    return (
      street.trim() !== "" &&
      city.trim() !== "" &&
      state.trim() !== "" &&
      zipCode.trim() !== "" &&
      country.trim() !== ""
    );
  };

  const isCitizenshipComplete = () => {
    const { citizenship, securityClearance } = formData;
    // If they have work authorization, require the type
    if (
      citizenship.hasWorkAuthorization &&
      !citizenship.workAuthorizationType
    ) {
      return false;
    }
    // If they have security clearance, require all clearance fields
    if (securityClearance.hasClearance) {
      return (
        securityClearance.clearanceLevel !== "" &&
        securityClearance.clearanceIssuer !== "" &&
        securityClearance.expirationDate !== null
      );
    }
    return true;
  };

  const isEmploymentComplete = () => {
    const { employment } = formData;
    if (employment.hasFullTimeJob) {
      return (
        employment.employer.trim() !== "" &&
        employment.position.trim() !== "" &&
        employment.startDate !== null &&
        typeof employment.employerAwareOfFreelancing === "boolean"
      );
    }
    return true;
  };

  const isStepComplete = (step: number) => {
    switch (step) {
      case 0:
        return isPersonalInfoComplete() && isLocationComplete();
      case 1:
        return isCitizenshipComplete();
      case 2:
        return isEmploymentComplete();
      case 3:
        return true; // Skills are optional
      default:
        return true;
    }
  };

  const handleFormChange = (
    field: keyof FreelancerOnboardingData,
    value: any
  ) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSecurityClearanceChange = (
    field: keyof FreelancerOnboardingData["securityClearance"],
    value: any
  ) => {
    setFormData((prev) => ({
      ...prev,
      securityClearance: {
        ...prev.securityClearance,
        [field]: value,
      },
    }));
  };

  const handleEmploymentChange = (
    field: keyof FreelancerOnboardingData["employment"],
    value: any
  ) => {
    setFormData((prev) => ({
      ...prev,
      employment: {
        ...prev.employment,
        [field]: value,
      },
    }));
  };

  return {
    activeStep,
    formData,
    newSkill,
    snackbar,
    isLoading,
    handleNext,
    handleBack,
    handleSkillAdd,
    handleSkillDelete,
    handleAddressChange,
    handleComplete,
    handleSnackbarClose,
    isStepComplete,
    handleFormChange,
    handleSecurityClearanceChange,
    handleEmploymentChange,
    setNewSkill,
  };
};
