import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { timesheetConverter } from "@freetech/models/converters/timesheetConverter";
import { DateTime } from "luxon";
import { Timesheet } from "types/timesheet";
export const approveTimesheet = async (id: string, approverId: string): Promise<void> => {
  const timesheetRef = doc(db, "timesheets", id).withConverter(timesheetConverter);
  await updateDoc(timesheetRef, {
    approved: true,
    approvedAt: DateTime.now().toISO(),
    approvedBy: approverId,
  });
};

export const rejectTimesheet = async (
  id: string,
  rejectorId: string,
  reason?: string
): Promise<void> => {
  const timesheetRef = doc(db, "timesheets", id).withConverter(timesheetConverter);
  await updateDoc(timesheetRef, {
    submitted: false,
    rejected: true,
    rejectedAt: DateTime.now().toISO(),
    rejectedBy: rejectorId,
    rejectionReason: reason || null,
  });
};

export const updateTimesheet = async (updatedTimesheet: Partial<Timesheet>): Promise<void> => {
  const { id, ...updateData } = updatedTimesheet;
  if (!id) throw new Error("Timesheet ID is required for update");
  
  const timesheetRef = doc(db, "timesheets", id).withConverter(timesheetConverter);
  await updateDoc(timesheetRef, updateData);
}; 