import { BoardActivityEventType } from "types/freelo";

type UpdateData = {
  type: BoardActivityEventType;
  cardTitle?: string;
  cardHumanReadableId?: string;
  listName?: string;
  oldListName?: string;
  labelName?: string;
  userNames?: string[];
  commentContent?: string;
  mentionedUsers?: string[];
  fieldName?: string;
  oldValue?: string;
  newValue?: string;
  actorName?: string;
  assigneeName?: string;
  oldAssigneeName?: string;
  reporterName?: string;
  labelColor?: string;
  addedUsers?: string[];
  removedUsers?: string[];
  attachmentName?: string;
  attachmentType?: string;
};

export const generateActivityMessage = (updateData: UpdateData): string => {
  const {
    type,
    cardHumanReadableId,
    listName,
    oldListName,
    labelName,
    userNames,
    commentContent,
    mentionedUsers,
    fieldName,
    oldValue,
    newValue,
    assigneeName,
    oldAssigneeName,
    reporterName,
    addedUsers,
    removedUsers,
    attachmentName,
    attachmentType,
  } = updateData;

  const cardReference = cardHumanReadableId ? `#${cardHumanReadableId}` : '';
  const formatDate = (date: string) => date ? new Date(date).toLocaleDateString() : 'none';
  const withCard = (message: string) => `${message} (${cardReference})`;

  switch (type) {
    case 'card-create':
      return `Created card ${cardReference} in list '${listName}'`;

    case 'card-move':
      return withCard(`Moved from '${oldListName}' to '${listName}'`);

    case 'card-update':
      if (fieldName === 'assignee') {
        if (!newValue || newValue === '') {
          return withCard(`Unassigned primary assignee ${oldAssigneeName}`);
        }
        return withCard(`Set primary assignee to ${assigneeName}`);
      }
      if (fieldName === 'reporter') {
        if (!newValue || newValue === '') {
          return withCard(`Removed reporter ${oldValue}`);
        }
        return withCard(`Set reporter to ${reporterName}`);
      }
      if (fieldName === 'dueDate' || fieldName === 'startDate') {
        const dateType = fieldName === 'dueDate' ? 'due' : 'start';
        if (!newValue || newValue === '') {
          return withCard(`Removed ${dateType} date (was ${formatDate(oldValue!)})`);
        }
        if (oldValue) {
          return withCard(`Updated ${dateType} date from ${formatDate(oldValue)} to ${formatDate(newValue)}`);
        }
        return withCard(`Set ${dateType} date to ${formatDate(newValue)}`);
      }
      if (fieldName === 'description') {
        return withCard(`Updated description`);
      }
      if (fieldName === 'title') {
        return withCard(`Renamed to '${newValue}'`);
      }
      if (fieldName === 'additionalAssignees') {
        const messages: string[] = [];
        if (addedUsers?.length) {
          const addedMessage = addedUsers.length === 1 
            ? `Added additional assignee ${addedUsers[0]}`
            : `Added additional assignees ${addedUsers.join(', ')}`;
          messages.push(addedMessage);
        }
        if (removedUsers?.length) {
          const removedMessage = removedUsers.length === 1
            ? `Removed additional assignee ${removedUsers[0]}`
            : `Removed additional assignees ${removedUsers.join(', ')}`;
          messages.push(removedMessage);
        }
        if (messages.length === 0) {
          return withCard(`Updated additional assignees`);
        }
        return withCard(messages.join(' and '));
      }
      return withCard(`Updated ${fieldName}`);

    case 'card-archive':
      return `Archived card ${cardReference}`;

    case 'card-delete':
      return `Moved card ${cardReference} to trash`;

    case 'card-comment':
      return withCard(`"${commentContent}"`);

    case 'card-comment-mention':
      const mentionedUsersList = mentionedUsers?.join(', ');
      return withCard(`Mentioned ${mentionedUsersList}`);

    case 'list-archive':
      return `Archived list '${listName}'`;

    case 'label-create':
      return `Created label '${labelName}'`;

    case 'label-update':
      return `Updated label '${labelName}'`;

    case 'label-delete':
      return `Deleted label '${labelName}'`;

    case 'user-add':
      return `Added ${userNames?.join(', ')} to board`;

    case 'user-remove':
      return `Removed ${userNames?.join(', ')} from board`;

    case 'card-attachment-add':
      const fileType = attachmentType ? ` (${attachmentType})` : '';
      return withCard(`Added attachment "${attachmentName}"${fileType}`);

    case 'card-attachment-delete':
      return withCard(`Removed attachment "${attachmentName}"`);

    case 'card-github-link':
      return withCard(`Linked GitHub branch "${newValue}"`);

    case 'card-github-unlink':
      return withCard(`Unlinked GitHub branch "${oldValue}"`);

    default:
      return 'Activity logged';
  }
};