import { initializeWebSocket } from "./initializeWebSocket";
import { getAuthToken } from '../auth/getAuthToken';

interface PingData {
  userId: string;
  timestamp: number;
  userRole?: string;
  currentPage?: string;
}

export const pingUserStatus = async (data: PingData) => {
  const token = await getAuthToken();
  if (!token) return;
  const socket = await initializeWebSocket(token);
  socket.emit("userPing", data);
};
