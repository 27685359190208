import { Box, Typography, Tab, Tabs, Button, Tooltip } from '@mui/material';
import { useState } from 'react';
import { ContractorHeader } from './ContractorHeader';
import { ContractorDetails } from './ContractorDetails';
import { ContractorPayments } from './ContractorPayments';
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { useParams } from 'react-router-dom';
import React from 'react';
import { PaymentDialog } from './PaymentDialog';
import { ExternalPaymentDialog } from './ExternalPaymentDialog';

export const ContractorDetail = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
  const [externalPaymentDialogOpen, setExternalPaymentDialogOpen] = useState(false);
  const { contractorId } = useParams();
  const { data: contractors } = useAdminFreelancers();
  
  const contractor = contractors?.find(c => c.id === contractorId);

  if (!contractor || !contractorId) return null;


  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
        <ContractorHeader contractor={contractor} />
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button
            variant="outlined"
            onClick={() => setExternalPaymentDialogOpen(true)}
          >
            Add External Payment
          </Button>
          <Tooltip 
            title={!contractor.readyForPayroll ? "Contractor not ready for payroll" : ""}
            arrow
          >
            <span>
              <Button
                variant="contained"
                onClick={() => setPaymentDialogOpen(true)}
                disabled={!contractor.readyForPayroll}
              >
                Create Payment
              </Button>
            </span>
          </Tooltip>
        </Box>
      </Box>
      
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 3 }}>
        <Tabs 
          value={activeTab} 
          onChange={(_, newValue) => setActiveTab(newValue)}
        >
          <Tab label="Details" />
          <Tab label="Payments" />
        </Tabs>
      </Box>

      {activeTab === 0 && <ContractorDetails contractor={contractor} />}
      {activeTab === 1 && <ContractorPayments contractor={contractor} />}

      <PaymentDialog
        open={paymentDialogOpen}
        onClose={() => setPaymentDialogOpen(false)}
        contractor={contractor}
        contractorId={contractorId}
      />

      <ExternalPaymentDialog
        open={externalPaymentDialogOpen}
        onClose={() => setExternalPaymentDialogOpen(false)}
        contractor={contractor}
      />
    </Box>
  );
}; 