import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  collectionGroup,
} from "@firebase/firestore";
import { db } from "config/firebase";
import { Stakeholder } from "types/user";
import { PrivateUserInfoType } from "types/user";
import { stakeholderConverter } from "@freetech/models/converters/stakeholderConverter";

export const loadSingleStakeholder = async (
  stakeholderId: string
): Promise<Stakeholder> => {
  try {
    // Get user document
    const userDocRef = doc(db, "users", stakeholderId).withConverter(
      stakeholderConverter
    );
    const userDocSnap = await getDoc(userDocRef);

    if (!userDocSnap.exists()) {
      throw new Error("Stakeholder not found");
    }

    const userData = userDocSnap.data();

    // Combine user and stakeholder data
    return {
      ...userData,
      id: stakeholderId,
    };
  } catch (error) {
    console.error("Error loading stakeholder:", error);
    throw error;
  }
};
