import { CombinedUserData, GetAllUsersResponse } from "types/user";
import { getAuth } from "firebase/auth";
import { getHeaders } from "core/getHeaders";
import { portalFunctions } from "core/functions/portalFunctions";

interface GetPrivateUserProfilesParams {
  userIds?: string[];
  filters?: {
    role?: string;
  };
}

export const getAuthUserProfiles = async ({
  userIds,
  filters,
}: GetPrivateUserProfilesParams = {}): Promise<CombinedUserData[]> => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    if (!token) {
      throw new Error("No authentication token available");
    }

    const response = await portalFunctions.admin.getAllUsers();

    if (!response.ok) {
      throw new Error("Failed to fetch users");
    }

    const data: GetAllUsersResponse = await response.json();
    let users = data.users;

    // Apply filters if provided
    if (userIds) {
      users = users.filter((user) => userIds.includes(user.id));
    }

    if (filters?.role) {
      users = users.filter((user) => user.role === filters.role);
    }

    return users;
  } catch (error) {
    console.error("Error fetching user profiles:", error);
    throw error;
  }
};
