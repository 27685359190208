import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from "config/firebase";
import { BoardActivityEvent } from "types/freelo";
export const subscribeToBoardActivity = (
  boardId: string,
  onUpdate: (activities: BoardActivityEvent[]) => void
) => {
  if (!boardId) return () => {};

  const activityRef = collection(db, "boards", boardId, "activity");
  const q = query(activityRef, orderBy("dateTimeOccured", "desc"));

  return onSnapshot(q, (snapshot) => {
    const activities = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    })) as BoardActivityEvent[];

    onUpdate(activities);
  });
};
