import { useQuery } from "@tanstack/react-query";
import { getClientOverviewStatistics } from "core/admin/statistics";
import { ClientStatistics } from "types/admin";

export const useClientOverviewStatistics = (clientId: string) => {
  return useQuery<ClientStatistics, Error>({  
    queryKey: ['clientStatistics', clientId],
    queryFn: () => getClientOverviewStatistics(clientId),
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    networkMode: 'offlineFirst',
    retry: false,
    retryOnMount: false,
    refetchInterval: 5 * 60 * 1000, // 5 minutes
    refetchIntervalInBackground: false,
  });
};
