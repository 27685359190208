import { collection, query, orderBy, getDocs, onSnapshot } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { BoardActivityEvent } from "types/freelo";

export const getBoardActivity = async (boardId: string): Promise<BoardActivityEvent[]> => {
  if (!boardId) throw new Error("Board ID is required");

  const activityRef = collection(db, "boards", boardId, "activity");
  const q = query(activityRef, orderBy("dateTimeOccured", "desc"));
  const snapshot = await getDocs(q);

  return snapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as BoardActivityEvent[];
};

