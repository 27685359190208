import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
  Grid,
  Typography,
  Box,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import FreelancerCard from "./FreelancerCard";
import { useAdminTimesheet } from "hooks/admin/useAdminTimesheet";
import { KeyboardArrowDown as KeyboardArrowDownIcon } from "@mui/icons-material";
import { ProjectAssignment } from "types/projects";
import {
  ProjectAssignmentStatus,
  EverSignDocumentStatus,
} from "types/projects";
import { Freelancer } from "types/user";
import { Timesheet } from "types/timesheet";
interface FreelancerCardGroupProps {
  startDate: Date;
  endDate: Date;
  selectedProjects: string[];
  selectedClients: string[];
  selectedFreelancers: string[];
  selectedStatus: ProjectAssignmentStatus[];
  setProjects: (projects: string[]) => void;
  setClients: (clients: string[]) => void;
  setFreelancerNames: (names: string[]) => void;
  freelancers: Freelancer[];
  onUpdateAssignment?: (
    clientId: string,
    projectId: string,
    assignmentId: string,
    updates: Partial<ProjectAssignment>
  ) => void;
  onUpdateEverSignStatus: (
    clientId: string,
    projectId: string,
    assignmentId: string,
    currentStatus: EverSignDocumentStatus | undefined
  ) => void;
  onOpenEverSignDialog: (
    clientId: string,
    projectId: string,
    assignmentId: string,
    currentStatus: EverSignDocumentStatus | undefined
  ) => void;
}

const FreelancerCardGroup: React.FC<FreelancerCardGroupProps> = ({
  startDate,
  endDate,
  selectedProjects,
  selectedClients,
  selectedFreelancers,
  selectedStatus,
  setProjects,
  setClients,
  setFreelancerNames,
  freelancers,
  onUpdateAssignment,
  onUpdateEverSignStatus,
  onOpenEverSignDialog,
}) => {
  const adminTimesheet = useAdminTimesheet();
  const { data: timesheets, isLoading: isLoadingTimesheets } =
    adminTimesheet.getAllTimesheets();
  const [showAll, setShowAll] = useState(false);
  const [availableProjects, setAvailableProjects] = useState<string[]>([]);
  const [availableClients, setAvailableClients] = useState<string[]>([]);
  const [availableFreelancerNames, setAvailableFreelancerNames] = useState<
    string[]
  >([]);

  // Filter timesheets based on date range
  const filteredTimesheets = useMemo(() => {
    if (!timesheets) return {};
    return timesheets.reduce(
      (acc, timesheet) => {
        const timesheetDate = new Date(timesheet.date);
        if (timesheetDate >= startDate && timesheetDate <= endDate) {
          if (!acc[timesheet.userId]) {
            acc[timesheet.userId] = [];
          }
          acc[timesheet.userId].push(timesheet);
        }
        return acc;
      },
      {} as Record<string, Timesheet[]>
    );
  }, [timesheets, startDate, endDate]);

  // Calculate available filters once when freelancers change
  useEffect(() => {
    if (!freelancers?.length) return;

    const projects = Array.from(
      new Set(
        freelancers.flatMap((f) =>
          (f.projectAssignments || [])
            .map((pa) => pa.projectName || "")
            .filter(Boolean)
        )
      )
    ).sort();

    const clients = Array.from(
      new Set(
        freelancers.flatMap((f) =>
          (f.projectAssignments || [])
            .map((pa) => pa.clientName || "")
            .filter(Boolean)
        )
      )
    ).sort();

    const names = freelancers.map((f) => `${f.firstName} ${f.lastName}`).sort();

    setAvailableProjects(projects);
    setAvailableClients(clients);
    setAvailableFreelancerNames(names);
  }, [freelancers]);

  // Apply filters only when user clicks apply filters button
  const handleApplyFilters = useCallback(() => {
    setProjects(availableProjects);
    setClients(availableClients);
    setFreelancerNames(availableFreelancerNames);
  }, [
    availableProjects,
    availableClients,
    availableFreelancerNames,
    setProjects,
    setClients,
    setFreelancerNames,
  ]);

  const { filteredFreelancers } = useMemo(() => {
    if (!freelancers?.length) {
      return { filteredFreelancers: [] };
    }

    const filteredFreelancers = freelancers
      .map((freelancer) => {
        // First filter the assignments based on status
        const filteredAssignments =
          selectedStatus.length > 0
            ? (freelancer.projectAssignments || []).filter((assignment) =>
                selectedStatus.includes(assignment.status || "ready")
              )
            : freelancer.projectAssignments || [];

        const userTimesheets = filteredTimesheets[freelancer.id] || [];

        return {
          ...freelancer,
          timesheets: userTimesheets,
          projectAssignments: filteredAssignments,
        };
      })
      .filter((freelancer) => {
        const fullName = `${freelancer.firstName} ${freelancer.lastName}`;
        const hasMatchingTimesheet = freelancer.timesheets.length > 0;

        // Modified to handle non-assigned timesheets
        const hasRelevantAssignments =
          selectedStatus.length === 0 ||
          freelancer.projectAssignments.length > 0;

        return (
          hasMatchingTimesheet &&
          hasRelevantAssignments &&
          (selectedFreelancers.length === 0 ||
            selectedFreelancers.includes(fullName)) &&
          (selectedProjects.length === 0 ||
            freelancer.projectAssignments?.some((pa) =>
              selectedProjects.includes(pa.projectName || "")
            )) &&
          (selectedClients.length === 0 ||
            freelancer.projectAssignments?.some((pa) =>
              selectedClients.includes(pa.clientName || "")
            ))
        );
      });

    return { filteredFreelancers };
  }, [
    freelancers,
    selectedProjects,
    selectedClients,
    selectedFreelancers,
    filteredTimesheets,
    selectedStatus,
  ]);

  const displayedFreelancers = showAll
    ? filteredFreelancers
    : filteredFreelancers?.slice(0, 6);

  if (!freelancers?.length) {
    return (
      <Typography>No freelancers found for the selected period</Typography>
    );
  }

  if (!filteredFreelancers?.length) {
    return (
      <Typography>
        No matching assignments found for the selected filters
      </Typography>
    );
  }

  return (
    <Box>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
          position: "absolute",
        }}
        open={isLoadingTimesheets}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box mb={2}>
        <Button
          variant="contained"
          onClick={handleApplyFilters}
          disabled={isLoadingTimesheets}
        >
          Apply Filters
        </Button>
      </Box>

      <Grid container spacing={3}>
        {displayedFreelancers?.map((freelancer) => (
          <Grid item xs={12} md={4} key={freelancer.id}>
            <FreelancerCard
              freelancer={freelancer}
              onUpdateAssignment={onUpdateAssignment}
              onUpdateEverSignStatus={onUpdateEverSignStatus}
              onOpenEverSignDialog={onOpenEverSignDialog}
            />
          </Grid>
        ))}
      </Grid>
      {filteredFreelancers?.length > 6 && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Button
            onClick={() => setShowAll(!showAll)}
            variant="outlined"
            endIcon={<KeyboardArrowDownIcon />}
          >
            {showAll
              ? "Show Less"
              : `Show ${filteredFreelancers.length - 6} More`}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default FreelancerCardGroup;
