import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";

export const updateUserActiveTimer = async (
  userId: string,
  timesheetId: string | null
) => {
  const userRef = doc(db, "users", userId);
  await updateDoc(userRef, { activeTimerId: timesheetId });
};
