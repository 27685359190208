import { collectionGroup, getDocs, query } from "firebase/firestore";
import { db } from "config/firebase";
import { filterActiveProjectAssignments } from "core/timesheet/utils/filterActiveProjectAssignments";
// import { loadAllClients } from "controllers/clients/utils";
import { fetchAllFreelancerProjectAssignments } from "core/freelancer";
import { loadClientsNonAdmin } from "core/clients/loadClientsNonAdmin";
import { ProjectAssignment } from "types/projects";
import { Project } from "types/projects";
import { PrivateUserInfoType } from "types/user";

export const getClientsProjectsAndAssignmentsForFreelancer = async (
  userInfo?: PrivateUserInfoType
) => {
  try {
    if (!userInfo) {
      throw new Error("User info is required");
    }

    
    const clients = await loadClientsNonAdmin(userInfo);
    const projectsQuery = collectionGroup(db, "projects");
    const projectsSnapshot = await getDocs(projectsQuery);
    const projects = projectsSnapshot.docs.map(
      (doc) => ({ id: doc.id, ...doc.data() }) as Project
    );
    const projectAssignments = await fetchAllFreelancerProjectAssignments(
      userInfo.id,
      clients
    );
    // const projectAssignments: ProjectAssignment[] = [];
    const activeProjectAssignments: ProjectAssignment[] =
      filterActiveProjectAssignments(projectAssignments);
    const assignedClientIds = new Set(
      activeProjectAssignments.map((a) => a.clientId)
    );
    const assignedProjectIds = new Set(
      activeProjectAssignments.map((a) => a.projectId)
    );

    const filteredClients = clients.filter((client) =>
      assignedClientIds.has(client.id)
    );

    const filteredProjects = projects.filter((project) =>
      assignedProjectIds.has(project.id)
    );

    
    
    

    return {
      clients: filteredClients,
      projects: filteredProjects,
      projectAssignments: activeProjectAssignments,
    };
  } catch (error) {
    console.error("Error fetching clients, projects, and assignments:", error);
    throw error;
  }
};
