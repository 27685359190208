import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { timesheetConverter } from "@freetech/models/converters/timesheetConverter";
import { getClientCompanyName } from "core/clients/utils";
import { getProjectName } from "core/projects";
import { Timesheet } from "types/timesheet";

export const getTimesheet = async (id: string): Promise<Timesheet | null> => {
  const timesheetRef = doc(db, "timesheets", id).withConverter(timesheetConverter);
  const docSnap = await getDoc(timesheetRef);
  
  if (docSnap.exists()) {
    const timesheet = docSnap.data();
    const [clientName, projectName] = await Promise.all([
      getClientCompanyName(timesheet.clientId),
      getProjectName(timesheet.clientId, timesheet.projectId),
    ]);
    
    return {
      ...timesheet,
      clientName,
      projectName,
    };
  }
  
  return null;
}; 