import {
  MultiFactorResolver,
  TotpMultiFactorGenerator,
  getMultiFactorResolver,
  Auth,
  User,
  multiFactor,
  MultiFactorError,
  EmailAuthProvider,
  reauthenticateWithCredential,
} from "firebase/auth";
import { auth } from "config/firebase";
import { useState, useEffect } from "react";
import { useAuth } from "./useAuth";

interface MFAHookReturn {
  enrollMFA: (user: User) => Promise<{
    session: any;
    secret: any;
    qrCode: string;
  }>;
  verifyAndEnrollMFA: (secret: any, code: string) => Promise<boolean>;
  verifyCodeAndSignIn: (code: string) => Promise<boolean>;
  handleMFAError: (error: MultiFactorError) => MultiFactorResolver | null;
  disableMFA: (password: string) => Promise<boolean>;
  unenrollMFA: (
    mfaEnrollmentId: string,
    email: string,
    password: string
  ) => Promise<boolean>;
  resolver: MultiFactorResolver | null;
  handleLoginWithMfaCancel: () => void;
  isCurrentUserMfaEnrolled: () => boolean;
}

const isCurrentUserMfaEnrolled = () => {
  const user = auth.currentUser;
  if (!user) {
    return false;
  }
  const mfa = multiFactor(user);
  return mfa.enrolledFactors.length > 0;
};

export const useMFA = (): MFAHookReturn => {
  const { isLoading, setIsLoading } = useAuth();
  const [resolver, setResolver] = useState<MultiFactorResolver | null>(() => {
    const stored = sessionStorage.getItem("mfaResolver");
    if (stored) {
      try {
        const error = JSON.parse(stored);
        if (error?.code === "auth/multi-factor-auth-required") {
          return getMultiFactorResolver(auth, error);
        }
      } catch (e) {
        sessionStorage.removeItem("mfaResolver");
      }
    }
    return null;
  });

  const enrollMFA = async (user: User) => {
    const multiFactorSession = await multiFactor(user).getSession();

    const totpSecret =
      await TotpMultiFactorGenerator.generateSecret(multiFactorSession);
    const qrCodeUrl = totpSecret.generateQrCodeUrl(
      user.email || "user",
      "FreeTech Portal"
    );
    return {
      session: multiFactorSession,
      secret: totpSecret,
      qrCode: qrCodeUrl,
    };
  };

  const verifyAndEnrollMFA = async (secret: any, code: string) => {
    const user = auth.currentUser;
    if (!user) throw new Error("No authenticated user");

    const multiFactorAssertion =
      TotpMultiFactorGenerator.assertionForEnrollment(secret, code);
    await multiFactor(user).enroll(multiFactorAssertion, "Authenticator App");
    return true;
  };

  const verifyCodeAndSignIn = async (code: string) => {
    if (!resolver) {
      throw new Error("No MFA resolver found");
    }

    const multiFactorAssertion = TotpMultiFactorGenerator.assertionForSignIn(
      resolver.hints[0].uid,
      code
    );
    await resolver.resolveSignIn(multiFactorAssertion);
    setResolver(null);
    sessionStorage.removeItem("mfaResolver");
    return true;
  };

  const handleMFAError = (
    error: MultiFactorError
  ): MultiFactorResolver | null => {
    if (error?.code === "auth/multi-factor-auth-required") {
      const newResolver = getMultiFactorResolver(auth, error);
      setResolver(newResolver);
      // Store the error to recreate resolver after navigation
      sessionStorage.setItem("mfaResolver", JSON.stringify(error));
      return newResolver;
    }
    return null;
  };

  const disableMFA = async (password: string) => {
    const currentUser = auth.currentUser;
    if (!currentUser?.email) {
      throw new Error("User email not found");
    }

    const credential = EmailAuthProvider.credential(
      currentUser.email,
      password
    );
    try {
      await reauthenticateWithCredential(currentUser, credential);
    } catch (error: any) {
      if (error?.code === "auth/multi-factor-auth-required") {
        throw error; // Let the component handle this error
      }
      throw error;
    }

    const mfa = multiFactor(currentUser);
    const enrolledFactors = mfa.enrolledFactors;
    if (enrolledFactors.length === 0) {
      throw new Error("No MFA factors found to disable");
    }

    await mfa.unenroll(enrolledFactors[0]);
    return true;
  };

  const unenrollMFA = async (
    mfaEnrollmentId: string,
    email: string,
    password: string
  ) => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      throw new Error("No authenticated user");
    }

    try {
      // Attempt to unenroll from TOTP MFA
      await multiFactor(currentUser).unenroll(mfaEnrollmentId);
      return true;
    } catch (error: any) {
      if (
        error.code === "auth/user-token-expired" ||
        error.code === "auth/requires-recent-login"
      ) {
        try {
          // Re-authenticate the user
          const credential = EmailAuthProvider.credential(email, password);
          await reauthenticateWithCredential(currentUser, credential);

          // Try unenrolling again after re-authentication
          await multiFactor(currentUser).unenroll(mfaEnrollmentId);
          return true;
        } catch (reAuthError: any) {
          throw new Error(
            reAuthError.message || "Failed to re-authenticate user"
          );
        }
      }
      throw error;
    }
  };

  const handleLoginWithMfaCancel = () => {
    setResolver(null);
    sessionStorage.removeItem("mfaResolver");
  };

  return {
    enrollMFA,
    verifyAndEnrollMFA,
    verifyCodeAndSignIn,
    handleMFAError,
    disableMFA,
    unenrollMFA,
    resolver,
    handleLoginWithMfaCancel,
    isCurrentUserMfaEnrolled,
  };
};

export default useMFA;
