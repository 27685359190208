import { DateTime } from "luxon";
import { calculateDuration } from "./index";
import { Timesheet } from "types/timesheet";

export const formatDateForGrid = (date: string) => {
  return DateTime.fromISO(date).toFormat("yyyy-MM-dd");
};

export const formatTimeForGrid = (time: string) => {
  return DateTime.fromISO(time).toFormat("HH:mm:ss");
};

export const getUpdatedTimeValue = (
  value: string,
  currentDate: string,
  currentTime: string
) => {
  if (!value) return null;
  
  const [hours, minutes] = value.split(":");
  const selectedDate = DateTime.fromISO(currentDate);
  const newTime = DateTime.fromISO(currentTime).set({
    hour: parseInt(hours),
    minute: parseInt(minutes),
    year: selectedDate.year,
    month: selectedDate.month,
    day: selectedDate.day,
  });
  
  return newTime.toISO();
};

export const getWeekDateRange = (weekKey: string) => {
  const [startDate, endDate] = weekKey.split(" to ");
  const formattedStartDate = DateTime.fromISO(startDate).toFormat("MMMM d");
  const formattedEndDate = DateTime.fromISO(endDate).toFormat("MMMM d, yyyy");
  
  return {
    formattedStartDate,
    formattedEndDate,
  };
};

export const calculateWeekTotalTime = (weekEntries: Timesheet[]) => {
  const totalSeconds = weekEntries.reduce((total, entry) => {
    return total + entry.duration;
  }, 0);

  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  
  return `${hours}h ${minutes}m`;
}; 