import { clientConverter } from "@freetech/models/converters/clientConverter";
import { projectConverter } from "@freetech/models/converters/projectConverter";
import {
  collection,
  query,
  getDocs,
  getFirestore,
  where,
} from "firebase/firestore";
import { Client, ConsultingServicesAgreement } from "types/projects";
import { Project } from "types/projects";
import { PrivateUserInfoType, UserInfoType } from "types/user";
import { adminController } from "controllers/adminController";
import { consultingServicesAgreementConverter } from "@freetech/models/converters/consultingServicesAgreementConverter";

const db = getFirestore();

export const loadClientsNonAdmin = async (
  user?: UserInfoType
): Promise<Client[]> => {
  if (!user) {
    throw new Error("User is required");
  }

  if (user.role === "admin") {
    return adminController.clients.loadClientsWithProjectsAndCSAs();
  }

  const q = query(
    collection(db, "clients").withConverter(clientConverter),
    where(
      user.role === "freelancer"
        ? "associatedFreelancerIds"
        : "associatedStakeholderIds",
      "array-contains",
      user.id
    )
  );
  const querySnapshot = await getDocs(q);
  const clientsData: Client[] = [];

  for (const doc of querySnapshot.docs) {
    const clientData = { ...doc.data(), id: doc.id };
    const projectsSnapshot = await getDocs(
      collection(doc.ref, "projects").withConverter(projectConverter)
    );
    const projectsData: Project[] = projectsSnapshot.docs.map((projectDoc) => ({
      ...projectDoc.data(),
      id: projectDoc.id,
    }));
    clientData.projects = projectsData;

    if (user.role === "stakeholder") {
      const consultingServicesAgreementsSnapshot = await getDocs(
        collection(doc.ref, "consultingServicesAgreements").withConverter(
          consultingServicesAgreementConverter
        )
      );
      const consultingServicesAgreementsData: ConsultingServicesAgreement[] =
        consultingServicesAgreementsSnapshot.docs.map((csaDoc) => ({
          ...csaDoc.data(),
          id: csaDoc.id,
        }));

      console.log(
        "consultingServicesAgreementsData",
        consultingServicesAgreementsData
      );
      clientData.consultingServicesAgreements =
        consultingServicesAgreementsData;
    }
    clientsData.push(clientData);
  }

  // return clientsData.filter(client =>
  //   client.projectAssignments?.some(assignment =>
  //     assignment.engineerId === user.id
  //   )
  // );
  return clientsData;
};
