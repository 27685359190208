import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "config/firebase";
import { PerformanceReview } from "types/projects";

export const addPerformanceReview = async (clientId: string, assignmentId: string, reviewData: PerformanceReview) => {
  try {
    const assignmentRef = doc(db, 'clients', clientId, 'projectAssignments', assignmentId);
    await updateDoc(assignmentRef, {
      performanceReviews: arrayUnion({
        ...reviewData,
        reviewedBy: {
          id: reviewData.reviewedBy.id,
          email: reviewData.reviewedBy.email
        }
      })
    });
  } catch (error) {
    console.error("Error adding performance review:", error);
    throw error;
  }
};
