import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  browserPopupRedirectResolver,
  User,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { getAuthUserProfile } from "./getAuthUserProfile";
import { PrivateUserInfoType } from "types/user";
import { privateUserConverter } from "@freetech/models/converters/privateUserConverter";
import { serverTimestamp } from "firebase/firestore";
import { FirebaseError } from "firebase/app";
import { MultiFactorError } from "@firebase/auth";

export type GoogleLoginResponse = {
  success: boolean;
  error?: string;
  user?: PrivateUserInfoType;
};

export const handleLoginWithGoogle = async (
  setUserInfo: React.Dispatch<React.SetStateAction<PrivateUserInfoType>>
): Promise<GoogleLoginResponse | MultiFactorError> => {
  const auth = getAuth();
  const provider = new GoogleAuthProvider();

  try {
    const { user } = await signInWithPopup(
      auth,
      provider,
      browserPopupRedirectResolver
    );

    let userProfile = await getAuthUserProfile(user);

    if (!userProfile && user.email) {
      // Create new user profile if it doesn't exist
      const newUserData: PrivateUserInfoType = {
        email: user.email,
        firstName: user.displayName?.split(" ")[0] || "",
        lastName: user.displayName?.split(" ").slice(1).join(" ") || "",
        role: "non-admin",
        disabled: false,
        emailVerified: user.emailVerified,
        permissions: [],
        documents: [],
        id: user.uid,
        displayName: user.displayName || "",
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        lastLoginAt: serverTimestamp(),
        profilePicture: user.photoURL || "",
      };

      await setDoc(
        doc(db, `users/${user.uid}`).withConverter(privateUserConverter),
        newUserData
      );
      userProfile = await getAuthUserProfile(user);
    }

    if (!userProfile) {
      throw new Error("Failed to get or create user profile");
    }

    setUserInfo(userProfile);

    return {
      success: true,
      user: userProfile,
    };
  } catch (error: any) {
    console.error("Google login error details:", {
      error,
      code: error?.code,
      message: error?.message,
      name: error?.name,
    });

    if (error?.code === "auth/multi-factor-auth-required") {
      return error;
    }

    // Check if error has a code property (Firebase error) or is a FirebaseError instance
    if (error?.code || error instanceof FirebaseError) {
      return {
        success: false,
        error: error.code || "auth/internal-error",
      };
    }

    // For network errors or other types of errors
    if (error?.message?.toLowerCase().includes("network")) {
      return {
        success: false,
        error: "auth/network-request-failed",
      };
    }

    return {
      success: false,
      error: "auth/internal-error",
    };
  }
};
