import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { DateTime } from "luxon";
import {
  fetchTimesheets,
  getTimesheet,
  approveTimesheet,
  rejectTimesheet,
  updateTimesheet as updateTimesheetOperation,
} from "core/admin/timesheet";
import {
  revokeTimesheetApproval,
  bulkRevokeTimesheetApprovals,
} from "core/timesheet";
import { Timesheet } from "types/timesheet";

type MutationContext = {
  previousTimesheets?: Timesheet[];
};

export const useAdminTimesheet = () => {
  const queryClient = useQueryClient();
  const [timesheets, setTimesheets] = useState<Timesheet[]>([]);
  const { data: users } = usePublicUserList();

  const getAllTimesheets = () => {
    return useQuery<Timesheet[], Error>({
      queryKey: ["allTimesheets"],
      queryFn: () => fetchTimesheets({}, users),
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    });
  };

  const updateTimesheet = useMutation<void, Error, Partial<Timesheet>, MutationContext>({
    mutationFn: async (updatedTimesheet) => {
      if (!updatedTimesheet.id) throw new Error("Timesheet ID is required for update");
      await updateTimesheetOperation(updatedTimesheet);
    },
    onMutate: async (updatedTimesheet) => {
      await queryClient.cancelQueries({ queryKey: ["allTimesheets"] });
      const previousTimesheets = queryClient.getQueryData<Timesheet[]>(["allTimesheets"]);

      queryClient.setQueryData<Timesheet[]>(["allTimesheets"], (old) =>
        old?.map((timesheet) =>
          timesheet.id === updatedTimesheet.id
            ? { ...timesheet, ...updatedTimesheet }
            : timesheet
        )
      );

      return { previousTimesheets };
    },
    onError: (err, newTimesheet, context) => {
      if (context?.previousTimesheets) {
        queryClient.setQueryData(["allTimesheets"], context.previousTimesheets);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["allTimesheets"] });
    },
  });

  const approveTimesheetMutation = useMutation<void, Error, { id: string; approverId: string }>({
    mutationFn: ({ id, approverId }) => approveTimesheet(id, approverId),
    onMutate: ({ id, approverId }) => {
      const updatedTimesheets = timesheets.map((timesheet) =>
        timesheet.id === id
          ? {
              ...timesheet,
              approved: true,
              approvedAt: DateTime.now().toISO(),
              approvedBy: approverId,
            }
          : timesheet
      );
      setTimesheets(updatedTimesheets);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["allTimesheets"] });
    },
  });

  const rejectTimesheetMutation = useMutation<
    void,
    Error,
    { id: string; rejectorId: string; reason?: string }
  >({
    mutationFn: ({ id, rejectorId, reason }) => rejectTimesheet(id, rejectorId, reason),
    onMutate: ({ id, rejectorId, reason }) => {
      const updatedTimesheets = timesheets.map((timesheet) =>
        timesheet.id === id
          ? {
              ...timesheet,
              submitted: false,
              rejected: true,
              rejectedAt: DateTime.now().toISO(),
              rejectedBy: rejectorId,
              rejectionReason: reason || null,
            }
          : timesheet
      );
      setTimesheets(updatedTimesheets);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["allTimesheets"] });
    },
  });

  const revokeApprovalMutation = useMutation<void, Error, string>({
    mutationFn: (id: string) => revokeTimesheetApproval(id),
    onMutate: (id) => {
      const updatedTimesheets = timesheets.map((timesheet) =>
        timesheet.id === id
          ? {
              ...timesheet,
              approved: false,
              approvedAt: null,
              approvedBy: null,
              approvedByEmail: null,
            }
          : timesheet
      );
      setTimesheets(updatedTimesheets);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["allTimesheets"] });
    },
  });

  const bulkRevokeApprovalMutation = useMutation<void, Error, string[]>({
    mutationFn: (ids: string[]) => bulkRevokeTimesheetApprovals(ids),
    onMutate: (ids) => {
      const updatedTimesheets = timesheets.map((timesheet) =>
        ids.includes(timesheet.id)
          ? {
              ...timesheet,
              approved: false,
              approvedAt: null,
              approvedBy: null,
              approvedByEmail: null,
            }
          : timesheet
      );
      setTimesheets(updatedTimesheets);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["allTimesheets"] });
    },
  });

  return {
    timesheets,
    setTimesheets,
    getAllTimesheets,
    getTimesheet,
    updateTimesheet,
    approveTimesheet: approveTimesheetMutation,
    rejectTimesheet: rejectTimesheetMutation,
    revokeApproval: revokeApprovalMutation,
    bulkRevokeApproval: bulkRevokeApprovalMutation,
    refetch: () => queryClient.invalidateQueries({ queryKey: ["allTimesheets"] }),
  };
};
