import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Grid,
  Paper,
  Grow,
  IconButton,
  Chip,
  TextField,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import PaymentIcon from "@mui/icons-material/Payment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CloseIcon from "@mui/icons-material/Close";
// import PaymentCard from "./PaymentCard";
import { getProjectAssignmentsForCSA } from "core/projectAssignments";
import {
  getClientPayments,
  updateClientPayment,
  createClientPayment,
  deleteClientPayment,
} from "core/payments";
import { useLocalStorageBoolState } from "hooks/useLocalStorageBoolState";
import { Client } from "types/projects";
import { StakeholderPayment } from "@freetech/models/bank";
import { ProjectAssignment } from "@freetech/models/projects";


interface AssociatedPaymentsProps {
  client: Client;
}

const AssociatedPayments: React.FC<AssociatedPaymentsProps> = ({ client }) => {
  const [payments, setPayments] = useState<StakeholderPayment[]>([]);
  const [filteredPayments, setFilteredPayments] = useState<StakeholderPayment[]>([]);
  const [projectAssignments, setProjectAssignments] = useState<
    ProjectAssignment[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [newPayment, setNewPayment] = useState<Omit<StakeholderPayment, "id"> | null>(
    null
  );
  const [isVisible, setIsVisible] = useLocalStorageBoolState(
    "isAssociatedPaymentsVisible",
    false
  );
  const [statusFilters, setStatusFilters] = useState<string[]>([]);
  const fetchPayments = async () => {
    try {
      const fetchedPayments = await getClientPayments(client.id);
      setPayments(fetchedPayments);
      setFilteredPayments(fetchedPayments);

      // Fetch project assignments for all CSAs
      const allAssignments = await Promise.all(
        fetchedPayments.map((payment) =>
          getProjectAssignmentsForCSA(client.id, payment.csaId)
        )
      );
      setProjectAssignments(allAssignments.flat());
    } catch (error) {
      console.error("Error fetching payments:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayments();
  }, [client.id]);

  useEffect(() => {
    filterPayments();
  }, [payments, statusFilters]);

  const filterPayments = () => {
    if (statusFilters.length === 0) {
      setFilteredPayments(payments);
    } else {
      setFilteredPayments(
        payments.filter((payment) => statusFilters.includes(payment.status))
      );
    }
  };

  const handleAddPayment = () => {
    console.error("handleAddPayment is commeted out");
    // const emptyPayment: Omit<StakeholderPayment, "id"> = {
    //   amount: 0,
    //   paymentDate: "",
    //   dueDate: "",
    //   processedDate: "",
    //   status: "pending",
    //   invoiceNumber: "",
    //   projectId: "",
    //   projectName: "",
    //   csaId: "",
    //   paymentSource: "Quickbooks",
    //   projectAssignments: [],
    // };
    // setNewPayment(emptyPayment);
    // setIsVisible(true);
  };

  const handlePaymentAdded = async (addedPayment: StakeholderPayment) => {
    const newPayment = await createClientPayment(client.id, addedPayment);
    setPayments((prevPayments) => [newPayment, ...prevPayments]);
    setNewPayment(null);
  };

  const handlePaymentUpdated = async (updatedPayment: StakeholderPayment) => {
    try {
      await updateClientPayment(client.id, updatedPayment.id, updatedPayment);
      setPayments((prevPayments) =>
        prevPayments.map((p) =>
          p.id === updatedPayment.id ? updatedPayment : p
        )
      );
    } catch (error) {
      console.error("Error updating payment:", error);
    }
  };

  const handleCancelNewPayment = () => {
    setNewPayment(null);
  };

  const handlePaymentDeleted = (deletedPaymentId: string) => {
    setPayments((prevPayments) =>
      prevPayments.filter((p) => p.id !== deletedPaymentId)
    );
  };

  const toggleVisibility = () => {
    setIsVisible((prevIsVisible) => {
      const newValue = !prevIsVisible;

      return newValue;
    });
  };

  const getStatusColor = (status: StakeholderPayment["status"]) => {
    switch (status) {
      case "paid":
        return "#2ecc71"; // Green
      case "pending":
        return "#3498db"; // Blue
      case "overdue":
        return "#e74c3c"; // Red
      default:
        return "#95a5a6"; // Grey
    }
  };

  useEffect(() => {}, [isVisible]);

  return (
    <Box>
      <Paper
        elevation={3}
        sx={{
          mb: 3,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          background: "linear-gradient(45deg, #1a1a1a 30%, #1e2e1e 90%)",
          color: "white",
        }}
      >
        <Box display="flex" alignItems="center">
          <PaymentIcon sx={{ fontSize: 40, mr: 2, color: "white" }} />
          <Typography variant="h5" component="h2" sx={{ mr: 2 }}>
            Payments
          </Typography>
          <Autocomplete
            multiple
            options={["paid", "pending", "overdue"]}
            value={statusFilters}
            onChange={(event, newValue) => {
              setStatusFilters(newValue);
            }}
            renderInput={(params) =>
              isVisible && (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  placeholder={
                    statusFilters.length === 0 ? "Status filter..." : ""
                  }
                  sx={{
                    width: 300,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                      },
                      "&:hover fieldset": {
                        borderColor: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                    },
                    "& .MuiInputBase-input": {
                      color: "white",
                    },
                    "& .MuiAutocomplete-input": {
                      color: "white",
                    },
                  }}
                />
              )
            }
            renderTags={(value: string[], getTagProps) =>
              value.map((option: string, index: number) => (
                <Chip
                  label={option}
                  {...getTagProps({ index })}
                  sx={{
                    backgroundColor: getStatusColor(
                      option as StakeholderPayment["status"]
                    ),
                    color: "white",
                    "& .MuiChip-deleteIcon": {
                      color: "white",
                    },
                  }}
                  deleteIcon={<CloseIcon />}
                />
              ))
            }
          />
          <IconButton onClick={toggleVisibility} sx={{ color: "white", mr: 1 }}>
            {isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </IconButton>
        </Box>
        <Button
          onClick={handleAddPayment}
          startIcon={<AddIcon />}
          variant="contained"
          sx={{
            bgcolor: "#2e3e2e",
            color: "white",
            "&:hover": {
              bgcolor: "#3e4e3e",
            },
          }}
        >
          Add Payment
        </Button>
      </Paper>

      {isVisible && (
        <Grid container spacing={3}>
          {newPayment && (
            <Grow in={true}>
              <Grid item xs={12}>
              <h1> Removed Payment Card Here for Payment v2 typing</h1>

                {/* <PaymentCard
                  clientId={client.id}
                  payment={newPayment as StakeholderPayment}
                  projectAssignments={projectAssignments}
                  onAdd={handlePaymentAdded}
                  onUpdate={handlePaymentUpdated}
                  onCancel={handleCancelNewPayment}
                  isNew={true}
                /> */}
              </Grid>
            </Grow>
          )}
          {filteredPayments.map((payment) => (
            <Grid item xs={12} sm={6} md={4} key={payment.id}>
              <h1> Removed Payment Card Here for Payment v2 typing</h1>

              {/* <PaymentCard
                clientId={client.id}
                payment={payment}
                onAdd={handlePaymentAdded}
                projectAssignments={projectAssignments.filter(
                  (assignment) => assignment.csaId === payment.csaId
                )}
                onUpdate={handlePaymentUpdated}
                onCancel={handleCancelNewPayment}
                isNew={false}
              /> */}
            </Grid>
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default AssociatedPayments;
