import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { UpdateBoardParams } from "types/freelo/crud";

export const updateBoard = async (params: UpdateBoardParams): Promise<void> => {
  const { boardId, data } = params;
  const boardRef = doc(db, "boards", boardId);
  await updateDoc(boardRef, {
    ...data,
    updatedAt: DateTime.now().toISO(),
  });
};

export const updateListColor = async (params: { boardId: string; listId: string; color: string | null }): Promise<void> => {
  const { boardId, listId, color } = params;
  const listRef = doc(db, "boards", boardId, "lists", listId);
  await updateDoc(listRef, {
    color,
    updatedAt: DateTime.now().toISO(),
  });
}; 