import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { loadProjectsNonAdmin } from "core/projects/loadProjectsNonAdmin";
import { submitNewProjectIdea } from "core/stakeholder/submitNewProjectIdea";
import { useAuth } from "hooks/auth/useAuth";
import { Stakeholder } from "types/user";
import { ProjectIdeaData } from "@freetech/models/projects";

export const useProjectsNonAdmin = () => {
  const { userInfo } = useAuth();
  const queryClient = useQueryClient();

  const projectsQuery = useQuery({
    queryKey: ["projects"],
    queryFn: async () => {
      return await loadProjectsNonAdmin(userInfo);
    },
    refetchInterval: 1000 * 60 * 60 * 24, // 24 hours
    refetchOnMount: false,
    enabled: !!userInfo,
  });

  return {
    ...projectsQuery,
    
  };
};
