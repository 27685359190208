import { collection, addDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { CreateFolderParams } from "../types";
import { KnowledgeBaseFolder } from "types/knowledgeBase";
export const createFolder = async (params: CreateFolderParams): Promise<string> => {
  const { parentPath, name, createdBy } = params;
  
  const folderData: Omit<KnowledgeBaseFolder, "id" | "folders" | "files"> = {
    name,
    path: parentPath === "root" ? name : `${parentPath}/${name}`,
    createdAt: DateTime.now().toISO(),
    updatedAt: DateTime.now().toISO(),
    createdBy,
  };

  const folderRef = collection(db, "knowledgeBaseFolders");
  const newFolderDoc = await addDoc(folderRef, folderData);
  return newFolderDoc.id;
}; 