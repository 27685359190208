import { initializeWebSocket } from "./initializeWebSocket";
import { getAuthToken } from '../auth/getAuthToken';

export const checkUsersStatus = async (
  userIds: string[]
): Promise<Array<{ userId: string; online: boolean }>> => {
  const token = await getAuthToken();
  if (!token) return [];
  const socket = await initializeWebSocket(token);

  return new Promise((resolve) => {
    socket.emit("checkUsers", userIds);
    socket.once("usersStatus", (statuses) => {
      resolve(statuses);
    });
  });
};
