import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { UploadProfilePictureParams } from "../../../types/admin";

export const uploadProfilePicture = async ({ userId, file }: UploadProfilePictureParams): Promise<string> => {
  const storage = getStorage();
  const storageRef = ref(storage, `users/${userId}/profilePicture`);
  const snapshot = await uploadBytes(storageRef, file);
  const downloadURL = await getDownloadURL(snapshot.ref);
  
  await updateDoc(doc(db, `users/${userId}`), { profilePicture: downloadURL });
  return downloadURL;
}; 