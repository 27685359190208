import {
  collection,
  collectionGroup,
  getDocs,
  query,
} from "firebase/firestore";
import { db } from "config/firebase";
import { clientConverter } from "@freetech/models/converters/clientConverter";
import { projectConverter } from "@freetech/models/converters/projectConverter";
import { consultingServicesAgreementConverter } from "@freetech/models/converters/consultingServicesAgreementConverter";
import { invoiceConverter } from "@freetech/models/converters/invoiceConverter";
import { Client, ConsultingServicesAgreement, Project } from "@freetech/models/projects";
import { ClientInvoiceData } from "@freetech/models/bank";

export const loadClientsWithProjectsAndCSAs = async (): Promise<Client[]> => {
  const q = query(collection(db, "clients").withConverter(clientConverter));
  const querySnapshot = await getDocs(q);
  const projectsRef = collectionGroup(db, "projects").withConverter(
    projectConverter
  );

  const csaRef = collectionGroup(
    db,
    "consultingServicesAgreements"
  ).withConverter(consultingServicesAgreementConverter);

  const invoiceRef = collectionGroup(
    db,
    "invoices"
  ).withConverter(invoiceConverter);
  

  const clients: Client[] = querySnapshot.docs.map((doc) => doc.data());
  const projectsSnapshot = await getDocs(projectsRef);
  const projectsList = projectsSnapshot.docs.map((doc) => doc.data());

  const csaSnapshot = await getDocs(csaRef);
  const csaList = csaSnapshot.docs.map((doc) => doc.data());

  const invoiceSnapshot = await getDocs(invoiceRef);
  const invoiceList = invoiceSnapshot.docs.map((doc) => doc.data());
  
  const clientsWithProjects: Client[] = clients.map((client: Client) => {
    const clientProjects = projectsList.filter(
      (project: Project) => project.clientId === client.id
    );
    const clientCSAs = csaList.filter((csa: ConsultingServicesAgreement) => csa.clientId === client.id);
    const clientInvoices = invoiceList.filter((invoice: ClientInvoiceData) => invoice.clientId === client.id);
    return {
      ...client,
      projects: clientProjects,
      consultingServicesAgreements: clientCSAs,
      invoices: clientInvoices,
    };
  });

  return clientsWithProjects;
};
