import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "hooks/auth/useAuth";
import { fetchAllFreelancerProjectAssignments } from "core/freelancer";
import { useFreelancerRoleClients } from "./useFreelancerRoleClients";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "core/storage";
import { ProjectAssignment } from "@freetech/models/projects";
import { db } from "core/firestore";
import { collection, doc, updateDoc, getDoc } from "firebase/firestore";

export const useFreelancerProjectAssignments = () => {
  const { userInfo } = useAuth();
  const { clients: freelancerClients, isLoading: isLoadingClients } =
    useFreelancerRoleClients();

  const {
    data: projectAssignments,
    isLoading: isLoadingProjectAssignments,
    refetch: refetchProjectAssignments,
    error: errorProjectAssignments,
  } = useQuery({
    queryKey: ["freelancerProjectAssignments", userInfo?.id],
    queryFn: async () => {
      if (!userInfo?.id || !freelancerClients) {
        throw new Error("No user or clients found");
      }
      return await fetchAllFreelancerProjectAssignments(
        userInfo?.id,
        freelancerClients
      );
    },
    enabled: !isLoadingClients && !!userInfo?.id && !!freelancerClients,
  });

  //   const { mutate: updateProjectAssignment } = useMutation({
  //     mutationFn: (projectAssignmentId: string, data: Partial<ProjectAssignment>) =>

  //     });

  const handleViewProjectAssignment = async (
    clientId: string,
    projectAssignmentId: string
  ) => {
    const projectAssignment = projectAssignments?.find(
      (pa) => pa.id === projectAssignmentId
    );

    if (!projectAssignment) {
      throw new Error("Project assignment not found");
    }

    if (projectAssignment.fileUrl) {
      return projectAssignment.fileUrl;
    }

    const storageRef = ref(
      storage,
      `clients/${clientId}/projectAssignments/${projectAssignmentId}`
    );

    try {
      const fileUrl = await getDownloadURL(storageRef);

      // First verify we can access the document
      const projectAssignmentDoc = doc(
        db,
        `clients/${clientId}/projectAssignments/${projectAssignmentId}`
      );

      const docSnap = await getDoc(projectAssignmentDoc);
      if (!docSnap.exists()) {
        throw new Error("Project assignment document not found");
      }

      // Only update if we're the assigned engineer
      const data = docSnap.data();
      if (data.engineerId !== userInfo?.id) {
        throw new Error("Not authorized to update this project assignment");
      }
      // Update only the fileUrl field
      await updateDoc(projectAssignmentDoc, {
        fileUrl,
      });

      return fileUrl;
    } catch (error) {
      console.error("Error handling project assignment view:", error);
      throw error;
    }
  };

  return {
    projectAssignments,
    isLoadingProjectAssignments,
    errorProjectAssignments,
    refetchProjectAssignments,
    isLoadingClients,
    handleViewProjectAssignment,
  };
};
