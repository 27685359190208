import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  Divider,
  Grid,
  CircularProgress,
  Alert,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { logoNoBackground as logo } from "assets";
import { useAuth } from "hooks/auth/useAuth";
import { ClientInvoiceData } from "types/bank";
import { Stakeholder } from "@freetech/models/user";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";

interface PayInvoiceDialogProps {
  open: boolean;
  onClose: () => void;
  invoice: ClientInvoiceData;
  accountId: string;
}

const PayInvoiceDialog: React.FC<PayInvoiceDialogProps> = ({
  open,
  onClose,
  invoice,
  accountId,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { userInfo } = useAuth();
  const { clients } = useStakeholder(userInfo?.id);
  const client = clients?.find((c) => c.id === invoice.clientId);
  const { payMyStakeholderInvoice } = useStakeholderPayments();

  const externalAccountId = (userInfo as Stakeholder)?.increaseExternalAccountProfile?.externalAccountId;

  const handlePayment = async () => {
    if (!externalAccountId) {
      setError("No external account found. Please set up your payment profile first.");
      return;
    }

    setLoading(true);
    setError(null);

    try {
      if (!client || !userInfo?.id) {
        setError("An unexpected error occurred...");
        return;
      }

      const result = await payMyStakeholderInvoice(client.id, invoice.id);

      if (result?.success) {
        onClose();
      } else {
        setError("Failed to process payment");
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      setError("An error occurred while processing the payment");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Pay Invoice</Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box mb={3}>
              <img
                src={logo}
                alt="FreeTech Logo"
                style={{ maxWidth: "150px", height: "auto" }}
              />
            </Box>
            <Typography variant="h6" gutterBottom>
              PAYMENT AMOUNT
            </Typography>
            <Typography variant="h4" color="primary" gutterBottom>
              ${invoice.totalDollarAmountDue?.toLocaleString()}
            </Typography>

            <Divider sx={{ my: 3 }} />

            <Box>
              <Typography variant="h6" gutterBottom>
                Payment Method
              </Typography>
              {externalAccountId ? (
                <Alert severity="info" sx={{ mb: 2 }}>
                  Payment will be processed using your linked bank account (ID: {externalAccountId})
                </Alert>
              ) : (
                <Alert severity="warning">
                  Please set up your payment profile to link a bank account before making payments.
                </Alert>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box bgcolor="#f5f5f5" p={3} borderRadius={2}>
              <Typography variant="h6" gutterBottom>
                Invoice Details
              </Typography>
              <Typography variant="body1">
                Invoice #{invoice.invoiceNumber}
              </Typography>
              <Typography variant="body1">
                Due date: {new Date(invoice.dueDate).toLocaleDateString()}
              </Typography>
              <Typography variant="body1" gutterBottom>
                Invoice amount: ${invoice.totalDollarAmountDue?.toLocaleString()}
              </Typography>
              <Divider sx={{ my: 2 }} />
              <Typography variant="h6">
                Total: ${invoice.totalDollarAmountDue?.toLocaleString()}
              </Typography>

              <Box mt={4}>
                <Typography variant="subtitle1" gutterBottom>
                  Merchant details
                </Typography>
                <Typography variant="body2">FreeTech</Typography>
                <Typography variant="body2">
                  Email: admin@freetech.co
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handlePayment}
          variant="contained"
          color="primary"
          size="large"
          fullWidth
          disabled={loading || !externalAccountId}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            `Pay $${invoice.totalDollarAmountDue?.toLocaleString()}`
          )}
        </Button>
      </DialogActions>
      {error && (
        <Box px={3} pb={2}>
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        </Box>
      )}
    </Dialog>
  );
};

export default PayInvoiceDialog;
