import React, { useEffect } from "react";
import {
  Home as HomeIcon,
  Person2,
  Assignment as AssignmentIcon,
  AccessTime as AccessTimeIcon,
  BorderColor as BorderColorIcon,
} from "@mui/icons-material";
import {
  Home as FreelancerHome,
  ProjectAssignments,
  Manage,
  SignAgreements,
} from "views/Freelancer";
import { TimeTracker } from "views/SuperAdmin";
import { CustomNavigation } from "router/types/customNavigation";
import Freelo from "views/Freelo/Freelo";
import FreeloBoard from "views/Freelo/FreeloBoard/FreeloBoard";
import KnowledgeBase from "views/KnowledgeBase/KnowledgeBase";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import { Group as GroupIcon } from "@mui/icons-material";
import FreeloTicketDialog from "views/Freelo/FreeloTicketDialog/FreeloTicketDialog";
import BugReportIcon from "@mui/icons-material/BugReport";
import { BugReport } from "views/Shared/BugReport";
import TimesheetExplorer from "views/Freelancer/TimesheetExplorer/TimesheetExplorer";
import FreelancerPayroll from "views/Freelancer/FreelancerPayroll/FreelancerPayroll";
import { Money as MoneyIcon } from "@mui/icons-material";
import { NotificationCenter } from "views/Shared/Notifications/NotificationCenter";
import { Notifications as NotificationsIcon } from "@mui/icons-material";

const isStg = window.location.hostname.includes("stg");
const isLocal = window.location.hostname.includes("localhost");

export const betaFeatures: CustomNavigation = [
  { kind: "header", title: "Beta Features" },
  { kind: "divider" },
  {
    segment: "timesheet-explorer",
    title: "Timesheet Explorer",
    icon: <AccessTimeIcon />,
    component: <TimesheetExplorer />,
  },
  // {
  //   segment: "knowledge-base",
  //   title: "Knowledge Base",
  //   icon: <GroupIcon />,
  //   component: <KnowledgeBase />,
  // },
  {
    segment: "bug-report",
    title: "Report Bug",
    icon: <BugReportIcon />,
    component: <BugReport />,
  },
  {
    segment: "notifications",
    title: "Notifications",
    icon: <NotificationsIcon />,
    component: <NotificationCenter />,
  },
];

export const freelancerPages: CustomNavigation = [
  { kind: "header", title: "General" },
  {
    segment: "freelancer",
    title: "Home",
    icon: <HomeIcon />,
    component: <FreelancerHome />,
  },
  {
    segment: "freelancer-manage",
    title: "Profile",
    icon: <Person2 />,
    component: <Manage />,
  },
  {
    segment: "freelancer-payroll",
    title: "Payroll",
    icon: <MoneyIcon />,
    component: <FreelancerPayroll />,
  },
  {
    segment: "timesheet",
    title: "Time Tracker",
    icon: <AccessTimeIcon />,
    component: <TimeTracker />,
  },
  {
    segment: "project-assignments",
    title: "Project Assignments",
    icon: <AssignmentIcon />,
    component: <ProjectAssignments />,
  },
  {
    segment: "sign-agreements",
    title: "Sign Agreements",
    icon: <BorderColorIcon />,
    component: <SignAgreements />,
  },
  {
    kind: "page",
    title: "Freelo",
    segment: "freelo",
    icon: <ViewKanbanIcon />,
    component: <Freelo />,
    slugBabyPattern: ":boardId",
    slugBabyComponent: <FreeloBoard />,
  },
  ...(isStg || isLocal ? betaFeatures : []),
];
