import React, { useEffect } from "react";
import { AppProvider } from "@toolpad/core";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import type {
  NavigationDividerItem,
  NavigationPageItem,
  NavigationSubheaderItem,
  Router,
  Session,
} from "@toolpad/core";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import {
  Home as HomeIcon,
  Description as DescriptionIcon,
  Paid as PaidIcon,
  Group as GroupIcon,
  Person2,
  Receipt as ReceiptIcon,
  Assignment as AssignmentIcon,
  AccessTime as AccessTimeIcon,
  Approval as ApprovalIcon,
  Business as BusinessIcon,
  People as PeopleIcon,
  AccountBalance as AccountBalanceIcon,
  MonetizationOn as MonetizationOnIcon,
  Build as ToolsIcon,
  AssignmentInd as CSAIcon,
  Timer as ClockifyIcon,
  AssignmentTurnedIn as ProjectAssignmentIcon,
  BorderColor as BorderColorIcon,
  Notifications as NotificationsIcon,
  BugReport as BugReportIcon,
  MusicNote as MusicNoteIcon,
  Work as ContractorIcon,
} from "@mui/icons-material";
import { useAuth } from "hooks/auth/useAuth";
import {
  Home as FreelancerHome,
  ProjectAssignments,
  Manage,
  SignAgreements,
} from "views/Freelancer";
import { StakeholderPayments } from "views/Stakeholder";
import {
  UserManagement,
  ClientManagement,
  FreelancerManagement,
  SuperAdminHome,
  InvoiceManagement,
  // Bank,
  ProjectAssignmentGenerator,
  TimeTracker,
  TimesheetApprovals,
  AdminSignAgreements,
  AdminProjectAssginments,
  ClientOverview,
} from "views/SuperAdmin";
import FreelancerOverview from "views/SuperAdmin/FreelancerManagement/FreelancerOverview/FreelancerOverview";
import TimesheetExplorer from "views/SuperAdmin/TimesheetExplorer";
import { CustomNavigation } from "router/types/customNavigation";
import KnowledgeBase from "views/KnowledgeBase";
import { Freelo } from "views/Freelo";
import { FreeloBoard } from "views/Freelo/FreeloBoard";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import { FreeloTicketDialog } from "views/Freelo/FreeloTicketDialog";
import { NotificationCenter } from "views/Shared/Notifications/NotificationCenter";
import { BugReport } from "views/Shared/BugReport";
import { IndependentEngineerAgreements } from "views/SuperAdmin/IndependentEngineerAgreements/IndependentEngineerAgreements";
import { AdamsMusic } from "views/Shared/AdamsMusic/AdamsMusic";
import { AdminPayroll } from "views/SuperAdmin/AdminPayroll/AdminPayroll";
import { ContractorDetail } from "views/SuperAdmin/AdminPayroll/components/ContractorDetail";

export const superuserPages: CustomNavigation = [
  {
    kind: "page",
    title: "Home",
    segment: "admin",
    icon: <HomeIcon />,
    component: <SuperAdminHome />,
  },
  {
    segment: "project-assignments",
    title: "My Project Assignments",
    icon: <AssignmentIcon />,
    component: <ProjectAssignments />,
  },
  {
    segment: "freelancer-manage",
    title: "Profile",
    icon: <Person2 />,
    component: <Manage />,
  },
  { kind: "header", title: "Admin" },
  { kind: "divider" },
  {
    segment: "user-management",
    title: "User Management",
    icon: <GroupIcon />,
    component: <UserManagement />,
  },
  {
    segment: "admin/project-assignments",
    title: "Project Assignments",
    icon: <AccessTimeIcon />,
    children: [
      {
        segment: "sign-agreements",
        title: "Admin Sign Agreements",
        icon: <DescriptionIcon />,
        component: <AdminSignAgreements />,
      },
      {
        segment: "my-agreements-to-sign",
        title: "My Freelancer Agreements",
        icon: <DescriptionIcon />,
        component: <SignAgreements />,
      },
      {
        segment: "project-assignments/explorer",
        title: "PA Explorer",
        icon: <AssignmentIcon />,
        component: <AdminProjectAssginments />,
      },
      {
        segment: "project-assignment-generator",
        title: "Project Assignment Generator",
        icon: <ProjectAssignmentIcon />,
        component: <ProjectAssignmentGenerator />,
      },
    ],
  },
  {
    segment: "timesheet",
    title: "Timesheets",
    icon: <AccessTimeIcon />,
    children: [
      {
        segment: "tracker",
        title: "Time Tracker",
        icon: <AccessTimeIcon />,
        component: <TimeTracker />,
      },
      {
        segment: "approvals",
        title: "Approvals",
        icon: <ApprovalIcon />,
        component: <TimesheetApprovals />,
      },
      {
        segment: "explorer",
        title: "Timesheet Explorer",
        icon: <AccessTimeIcon />,
        component: <TimesheetExplorer />,
      },
    ],
  },
  {
    segment: "admin",
    title: "Operations",
    icon: <BusinessIcon />,
    children: [
      {
        segment: "client-management",
        title: "Manage Clients",
        icon: <PeopleIcon />,
        slugBabyPattern: ":clientId",
        slugBabyComponent: <ClientOverview />,
        component: <ClientManagement />,
      },
      {
        segment: "freelancer-management",
        title: "Manage Freelancers",
        icon: <PeopleIcon />,
        slugBabyPattern: ":freelancerId",
        slugBabyComponent: <FreelancerOverview />,
        component: <FreelancerManagement />,
      },
    ],
  },

  {
    segment: "admin",
    title: "Finance",
    icon: <AccountBalanceIcon />,
    children: [
      // {
      //   segment: "vault",
      //   title: "Vault",
      //   icon: <PaidIcon />,
      //   component: <Bank />,
      // },
      {
        segment: "invoice-management",
        title: "Invoicing",
        icon: <MonetizationOnIcon />,
        component: <InvoiceManagement />,
      },
      {
        segment: "client-invoices",
        title: "Invoices - Client View For Testing",
        icon: <ReceiptIcon />,
        component: <StakeholderPayments />,
      },
      {
        segment: "payroll",
        title: "Admin Payroll",
        icon: <ContractorIcon />,
        slugBabyPattern: ":contractorId",
        slugBabyComponent: <ContractorDetail />,
        component: <AdminPayroll />,
      },
    ],
  },
  { kind: "header", title: "Beta Features" },
  { kind: "divider" },
  {
    kind: "page",
    title: "Freelo",
    segment: "freelo",
    icon: <ViewKanbanIcon />,
    component: <Freelo />,
    slugBabyPattern: ":boardId",
    slugBabyComponent: <FreeloBoard />,
    slugGrandBabyPattern: "ticket/:cardId",
    slugGrandBabyComponent: <FreeloTicketDialog standalone />,
  },
  {
    segment: "knowledge-base",
    title: "Knowledge Base",
    icon: <GroupIcon />,
    component: <KnowledgeBase />,
  },
  {
    segment: "notifications",
    title: "Notifications",
    icon: <NotificationsIcon />,
    component: <NotificationCenter />,
  },
  {
    segment: "bug-report",
    title: "Report Bug",
    icon: <BugReportIcon />,
    component: <BugReport />,
  },
  {
    segment: "independent-engineer-agreements",
    title: "Independent Engineer Agreements",
    icon: <DescriptionIcon />,
    component: <IndependentEngineerAgreements />,
  },
  {
    segment: "music",
    title: "Music",
    icon: <MusicNoteIcon />,
    component: <AdamsMusic />,
  },
];
