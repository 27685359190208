import { doc, getDoc, setDoc, deleteDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { MoveToTrashParams } from "../types";

export const moveToTrash = async (params: MoveToTrashParams): Promise<string> => {
  const { knowledgeBaseDocumentId, deletedBy } = params;

  // Get the file data first
  const fileRef = doc(db, "knowledgeBaseFiles", knowledgeBaseDocumentId);
  const fileSnap = await getDoc(fileRef);
  const fileData = fileSnap.data();

  // Create new doc in trash collection
  const trashRef = doc(db, "knowledgeBaseFilesTrash", knowledgeBaseDocumentId);
  await setDoc(trashRef, {
    ...fileData,
    deletedAt: DateTime.now().toISO(),
    deletedBy,
  });

  // Delete original file
  await deleteDoc(fileRef);

  return knowledgeBaseDocumentId;
}; 