const authBackgroundUrl = "https://firebasestorage.googleapis.com/v0/b/freetech-production.appspot.com/o/public%2Fmountain.jpg?alt=media&token=ca95ca03-15ed-410d-82b7-92c7961db445"
const logoLightUrl = "https://firebasestorage.googleapis.com/v0/b/freetech-production.appspot.com/o/public%2Flogo-white.png?alt=media&token=04331a10-19b0-48f6-b075-d794ea4ec3ef"
const logoDarkUrl = "https://firebasestorage.googleapis.com/v0/b/freetech-production.appspot.com/o/public%2Flogo-white.png?alt=media&token=04331a10-19b0-48f6-b075-d794ea4ec3ef"
const logoNoBackgroundUrl = "https://firebasestorage.googleapis.com/v0/b/freetech-production.appspot.com/o/public%2Flogo-no-background.png?alt=media&token=14917ef1-04eb-4370-9b07-110de636adc9"

const preloadImage = (src: string): string => {
  const img = new Image();
  img.src = src;
  return src;
};

const authBackground = preloadImage(authBackgroundUrl);
const logoLight = preloadImage(logoLightUrl);
const logoDark = preloadImage(logoDarkUrl);
const logoNoBackground = preloadImage(logoNoBackgroundUrl);

export { authBackground, logoLight, logoDark, logoNoBackground };

