import { collectionGroup, getDocs } from "firebase/firestore";
import { db } from "config/firebase";
import { ClientInvoiceData } from "types/bank";

export const getInvoices = async (): Promise<ClientInvoiceData[]> => {
  const invoicesSnapshot = await getDocs(collectionGroup(db, "invoices"));
  return invoicesSnapshot.docs.map(
    (doc): ClientInvoiceData => ({
      id: doc.id,
      ...(doc.data() as Omit<ClientInvoiceData, "id">),
    })
  );
};
