import React from "react";
import { useTheme, Box, Button, Typography } from "@mui/material";
import { useAuth } from "hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { doc, setDoc } from "firebase/firestore";
import { db } from "config/firebase";

export const AccountDeactivated = () => {
  const navigate = useNavigate();
  const { logout, userInfo } = useAuth();
  const theme = useTheme();

  const handleLoginRedirect = async () => {
    await logout();
    navigate("/login");
  };

  const handleRequestActivation = async () => {
    
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: theme.spacing(2),
        textAlign: "center",
      }}
    >
      <Typography variant="h4" gutterBottom sx={{ color: "black" }}>
        Account Inactive
      </Typography>
      <Typography variant="body1" gutterBottom sx={{ color: "black" }}>
        Your account has either not yet been activated or an Admin has disabled
        your access. Contact
        admin@freetech.co for further information.
      </Typography>
      <Box sx={{ mt: theme.spacing(2), display: "flex", gap: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleLoginRedirect}
        >
          Back to Login
        </Button>
      </Box>
    </Box>
  );
};
