import { DateTime } from "luxon";
import { calculateDuration } from "./index";
import { ProjectAssignment } from "types/projects";

export const formatTime = (totalSeconds: number): string => {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  return [hours, minutes, seconds].map((v) => (v < 10 ? "0" + v : v)).join(":");
};

export const isValidDuration = (
  startTime: string,
  endTime: string
): boolean => {
  const durationInSeconds = calculateDuration(startTime, endTime);
  return durationInSeconds >= 60; // 60 seconds = 1 minute
};

export const updateDateTimeFields = (
  date: string,
  prevStartTime: string,
  prevEndTime: string
) => {
  const newDate = DateTime.fromISO(date);
  const currentStartTime = DateTime.fromISO(prevStartTime || "");
  const currentEndTime = DateTime.fromISO(prevEndTime || "");

  const newStartTime = newDate
    .set({
      hour: currentStartTime.hour,
      minute: currentStartTime.minute,
      second: currentStartTime.second,
    })
    .toISO();

  const newEndTime = newDate
    .set({
      hour: currentEndTime.hour,
      minute: currentEndTime.minute,
      second: currentEndTime.second,
    })
    .toISO();

  return {
    startTime: newStartTime || "",
    endTime: newEndTime || "",
  };
};

export const getCurrentTimeEntry = () => {
  const now = DateTime.now();
  return {
    date: now.toISODate(),
    startTime: now.toISO(),
    endTime: now.toISO(),
  };
};

export const getProjectAssignmentDetails = (
  assignmentId: string,
  projectAssignments: ProjectAssignment[]
) => {
  const selectedAssignment = projectAssignments.find(
    (assignment) => assignment.id === assignmentId
  );

  if (selectedAssignment) {
    return {
      clientId: selectedAssignment.clientId,
      projectId: selectedAssignment.projectId,
      billable: true,
    };
  }

  return null;
};
