import { collectionGroup, getDocs, query, where } from "firebase/firestore";
import { db } from "config/firebase";
import { PublicUserInfoType } from "types/user";

export const getPublicUserProfiles = async (): Promise<
  PublicUserInfoType[]
> => {
  // CollectionGroup query for all 'profile/public' documents
  try {
    const profilesRef = collectionGroup(db, "profile");
    const snapshot = await getDocs(profilesRef);

    const users = snapshot.docs
      .map((doc) => {
        const data = doc.data();
        if (doc.id !== "public") return null;

        const parentId = doc.ref.parent.parent?.id;
        if (!parentId) return null;

        return {
          id: parentId,
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          displayName: data.displayName,
          profilePicture: data.profilePicture,
        } as PublicUserInfoType;
      })
      .filter(
        (user): user is PublicUserInfoType =>
          user !== null
      );

    return users;
  } catch (error) {
    console.error("Error fetching public profiles:", error);
    throw error;
  }
};
