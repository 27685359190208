import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Alert,
  CircularProgress,
  SelectChangeEvent,
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Divider,
  Collapse,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { useStakeholderOnboarding } from "hooks/stakeholder/useStakeholderOnboarding";
import { useAuth } from "hooks/auth/useAuth";
import { Client, Project } from "types/projects";

interface ProjectSetupProps {
  onNext: (
    data: Omit<Project, 
      | "id" 
      | "status" 
      | "ideaDate" 
      | "endDate" 
      | "clientId" 
      | "consultingServicesAgreements" 
      | "clockifyProjectId" 
      | "bugReports" 
      | "associatedStakeholderIds" 
      | "associatedFreelancerIds" 
      | "createdByUserId"
    > | null
  ) => Promise<void>;
  onBack: () => void;
  initialData?: Omit<Project, 
    | "id" 
    | "status" 
    | "ideaDate" 
    | "endDate" 
    | "clientId" 
    | "consultingServicesAgreements" 
    | "clockifyProjectId" 
    | "bugReports" 
    | "associatedStakeholderIds" 
    | "associatedFreelancerIds" 
    | "createdByUserId"
  >;
}

const projectTypes = [
  "Web Development",
  "Mobile Development",
  "UI/UX Design",
  "Data Science",
  "DevOps",
  "Other",
];

const projectDurations = [
  "Less than 1 month",
  "1-3 months",
  "3-6 months",
  "6-12 months",
  "More than 12 months",
];

const budgetRanges = [
  "Under $5,000",
  "$5,000 - $10,000",
  "$10,000 - $25,000",
  "$25,000 - $50,000",
  "$50,000+",
];

export const ProjectSetup: React.FC<ProjectSetupProps> = ({
  onNext,
  onBack,
  initialData,
}) => {
  const { currentUser } = useAuth();
  const { clients, createProject, isCreatingProject, projectError } =
    useStakeholder(currentUser?.uid);
  const {
    updateProject,
    isUpdatingProject,
    projectError: updateError,
  } = useStakeholderOnboarding();
  const [showForm, setShowForm] = useState<string | null>(null);
  const [editingProject, setEditingProject] = useState<Project | null>(null);

  // Find first empty company created by user
  const emptyUserCompany = clients?.find(
    (client) =>
      client.createdByUserId === currentUser?.uid &&
      (!client.projects || client.projects.length === 0)
  );

  const hasEmptyUserCompany = !!emptyUserCompany;

  // Auto-open form for empty company
  React.useEffect(() => {
    if (emptyUserCompany && !showForm) {
      setShowForm(emptyUserCompany.id);
    }
  }, [emptyUserCompany?.id]);

  const initialFormData: Omit<Project, 
    | "id" 
    | "status" 
    | "ideaDate" 
    | "endDate" 
    | "clientId" 
    | "consultingServicesAgreements" 
    | "clockifyProjectId" 
    | "bugReports" 
    | "associatedStakeholderIds" 
    | "associatedFreelancerIds" 
    | "createdByUserId"
  > = {
    name: "",
    description: "",
    projectType: "",
    duration: "",
    budget: "",
    startDate: new Date().toISOString().split('T')[0],
  };

  const [formData, setFormData] = useState<typeof initialFormData>(initialData ?? initialFormData);

  const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleAddProject = async (clientId: string) => {
    if (!currentUser) {
      return;
    }

    try {
      await createProject({
        clientId,
        name: formData.name,
        description: formData.description,
        status: "idea",
        projectType: formData.projectType,
        duration: formData.duration,
        budget: formData.budget,
        createdByUserId: currentUser?.uid,
        ideaDate: new Date().toISOString(),
        startDate: formData.startDate || null,
        endDate: null,
      });
      setFormData({
        name: "",
        description: "",
        projectType: "",
        duration: "",
        budget: "",
        startDate: "",
      });
      setShowForm(null);
    } catch (err) {
      console.error("Error creating project:", err);
    }
  };

  const handleEditProject = async (project: Project) => {
    try {
      await updateProject({
        clientId: project.clientId,
        projectId: project.id,
        projectData: {
          name: formData.name,
          description: formData.description,
          projectType: formData.projectType,
          duration: formData.duration,
          budget: formData.budget,
          startDate: formData.startDate || null,
        },
      });

      setFormData({
        name: "",
        description: "",
        projectType: "",
        duration: "",
        budget: "",
        startDate: "",
      });
      setEditingProject(null);
      setShowForm(null);
    } catch (err) {
      console.error("Error updating project:", err);
    }
  };

  const startEditing = (project: Project) => {
    setEditingProject(project);
    setShowForm(project.clientId);
    setFormData({
      name: project.name,
      description: project.description,
      projectType: project.projectType ?? "",
      duration: project.duration ?? "",
      budget: project.budget ?? "",
      startDate: project.startDate ?? "",
    });
  };

  const renderProjectForm = (clientId: string, project?: Project) => (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          label="Project Name"
          name="name"
          value={formData.name}
          onChange={handleTextChange}
          disabled={isCreatingProject || isUpdatingProject}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          multiline
          rows={4}
          label="Project Description"
          name="description"
          value={formData.description}
          onChange={handleTextChange}
          disabled={isCreatingProject || isUpdatingProject}
          placeholder="Please describe your project requirements and goals"
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth required>
          <InputLabel>Project Type</InputLabel>
          <Select
            name="projectType"
            value={formData.projectType}
            onChange={handleSelectChange}
            label="Project Type"
            disabled={isCreatingProject || isUpdatingProject}
          >
            {projectTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl fullWidth required>
          <InputLabel>Expected Duration</InputLabel>
          <Select
            name="duration"
            value={formData.duration}
            onChange={handleSelectChange}
            label="Expected Duration"
            disabled={isCreatingProject || isUpdatingProject}
          >
            {projectDurations.map((duration) => (
              <MenuItem key={duration} value={duration}>
                {duration}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          fullWidth
          type="date"
          label="Start Date"
          name="startDate"
          value={formData.startDate}
          onChange={handleTextChange}
          disabled={isCreatingProject || isUpdatingProject}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <InputLabel>Budget Range</InputLabel>
          <Select
            name="budget"
            value={formData.budget}
            onChange={handleSelectChange}
            label="Budget Range"
            disabled={isCreatingProject || isUpdatingProject}
          >
            {budgetRanges.map((budget) => (
              <MenuItem key={budget} value={budget}>
                {budget}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 1 }}>
          <Button
            onClick={() => {
              setShowForm(null);
              setEditingProject(null);
              setFormData(initialFormData);
            }}
            disabled={isCreatingProject || isUpdatingProject}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => project ? handleEditProject(project) : handleAddProject(clientId)}
            disabled={
              (isCreatingProject || isUpdatingProject) ||
              !formData.name ||
              !formData.description ||
              !formData.projectType ||
              !formData.duration ||
              !formData.budget
            }
            startIcon={
              (isCreatingProject || isUpdatingProject) ? (
                <CircularProgress size={20} />
              ) : null
            }
          >
            {(isCreatingProject || isUpdatingProject)
              ? "Saving..."
              : project
              ? "Save Changes"
              : "Add Project"}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Project Setup
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        View existing projects and add new ones for your companies.
      </Typography>

      {hasEmptyUserCompany && (
        <Alert severity="info" sx={{ mb: 3 }}>
          <Typography variant="body1" sx={{ fontWeight: "medium" }}>
            Let's Create Your First Project!
          </Typography>
          <Typography variant="body2">
            Please add at least one project to {emptyUserCompany.companyName} to get started.
            {!showForm && " Click 'Add Project' to begin."}
          </Typography>
        </Alert>
      )}

      {projectError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Error creating project: {projectError.message}
        </Alert>
      )}

      {clients?.map((client: Client) => (
        <Card
          key={client.id}
          sx={{
            mb: 2,
            border: (theme) =>
              client.id === emptyUserCompany?.id
                ? `2px solid ${theme.palette.primary.main}`
                : undefined,
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Box>
                <Typography variant="h6">
                  {client.companyName}
                  {client.id === emptyUserCompany?.id && (
                    <Typography
                      component="span"
                      color="primary"
                      sx={{ ml: 1, typography: "caption" }}
                    >
                      • Needs first project
                    </Typography>
                  )}
                </Typography>
              </Box>
              {client.createdByUserId === currentUser?.uid &&
                !editingProject && (
                  <Button
                    startIcon={<AddIcon />}
                    onClick={() =>
                      setShowForm(showForm === client.id ? null : client.id)
                    }
                    color="primary"
                    variant={client.id === emptyUserCompany?.id ? "contained" : "outlined"}
                  >
                    Add Project
                  </Button>
                )}
            </Box>

            <Collapse in={showForm === client.id && !editingProject}>
              <Card variant="outlined" sx={{ mb: 2, bgcolor: "background.paper" }}>
                <CardContent>
                  <Typography variant="subtitle1" gutterBottom>
                    New Project for {client.companyName}
                  </Typography>
                  {renderProjectForm(client.id)}
                </CardContent>
              </Card>
            </Collapse>

            {client.projects && client.projects.length > 0 ? (
              <Box>
                {client.projects.map((project: Project) => (
                  <Accordion
                    key={project.id}
                    expanded={editingProject?.id === project.id || showForm === `accordion-${project.id}`}
                    onChange={(_, expanded) => {
                      if (editingProject?.id === project.id) {
                        setEditingProject(null);
                        setFormData(initialFormData);
                      }
                      setShowForm(expanded ? `accordion-${project.id}` : null);
                    }}
                    sx={{ mb: 1 }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        '& .MuiAccordionSummary-content': {
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          pr: 2,
                        },
                      }}
                    >
                      <Box>
                        <Typography variant="subtitle1">
                          {project.name}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Status: {project.status.charAt(0).toUpperCase() + project.status.slice(1)}
                        </Typography>
                      </Box>
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        {project.projectType && (
                          <Chip
                            label={project.projectType}
                            color="primary"
                            size="small"
                          />
                        )}
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 2 }}>
                        {project.createdByUserId === currentUser?.uid && !editingProject && (
                          <Button
                            startIcon={<EditIcon />}
                            onClick={() => startEditing(project)}
                            size="small"
                          >
                            Edit Project
                          </Button>
                        )}
                      </Box>
                      {editingProject?.id === project.id ? (
                        renderProjectForm(client.id, project)
                      ) : (
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <Typography
                              variant="body1"
                              sx={{
                                bgcolor: "background.default",
                                p: 2,
                                borderRadius: 1,
                                borderLeft: "4px solid",
                                borderColor: "primary.main",
                              }}
                            >
                              {project.description}
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <Box sx={{ p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                              <Typography variant="caption" color="text.secondary">
                                Project Type
                              </Typography>
                              <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                                {project.projectType ?? 'Not specified'}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <Box sx={{ p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                              <Typography variant="caption" color="text.secondary">
                                Duration
                              </Typography>
                              <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                                {project.duration ?? 'Not specified'}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <Box sx={{ p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                              <Typography variant="caption" color="text.secondary">
                                Budget
                              </Typography>
                              <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                                {project.budget ?? 'Not specified'}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <Box sx={{ p: 2, bgcolor: 'background.default', borderRadius: 1 }}>
                              <Typography variant="caption" color="text.secondary">
                                Start Date
                              </Typography>
                              <Typography variant="body2" sx={{ fontWeight: 'medium' }}>
                                {project.startDate ? new Date(project.startDate).toLocaleDateString() : 'Not set'}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      )}
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            ) : (
              <Typography variant="body2" color="text.secondary">
                {client.createdByUserId === currentUser?.uid
                  ? "No projects for this company yet. Click 'Add Project' to create one."
                  : "No projects for this company."}
              </Typography>
            )}
          </CardContent>
        </Card>
      ))}

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
        <Button onClick={onBack} disabled={isCreatingProject}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => onNext({
            name: formData.name,
            description: formData.description,
            projectType: formData.projectType,
            duration: formData.duration,
            budget: formData.budget,
            startDate: formData.startDate
          })}
          disabled={isCreatingProject || hasEmptyUserCompany}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default ProjectSetup;
