import React from "react";
import {
  Box,
  Typography,
  TextField,
  ButtonGroup,
  Button,
  IconButton,
  AvatarGroup,
  Avatar,
  Tooltip,
  Badge,
} from "@mui/material";
import DashboardIcon from "@mui/icons-material/Dashboard";
import HistoryIcon from "@mui/icons-material/History";
import ArchiveIcon from "@mui/icons-material/Archive";
import ShareIcon from "@mui/icons-material/Share";
import CrownIcon from "@mui/icons-material/WorkspacePremium";
import SettingsIcon from "@mui/icons-material/Settings";
import FilterListIcon from "@mui/icons-material/FilterList";
import { PublicUserInfoType } from "types/user";
import { Board } from "types/freelo";
import { BoardFilters } from "./FreeloBoardFilters";

interface FreeloBoardHeaderProps {
  board: Board;
  users?: PublicUserInfoType[];
  currentUser?: PublicUserInfoType;
  isAdmin: boolean;
  canShare: boolean;
  isArchiveView: boolean;
  isActivityView: boolean;
  editBoardTitle: string;
  isEditingBoardTitle: boolean;
  filters: BoardFilters;
  onEditBoardTitle: (title: string) => void;
  onBoardTitleSubmit: () => void;
  onSetIsEditingBoardTitle: (isEditing: boolean) => void;
  onViewChange: (view: "board" | "activity" | "archive") => void;
  onOpenShareDialog: () => void;
  onOpenSettings: () => void;
  onOpenFilters: (event: React.MouseEvent<HTMLElement>) => void;
}

export const FreeloBoardHeader: React.FC<FreeloBoardHeaderProps> = ({
  board,
  users,
  currentUser,
  isAdmin,
  canShare,
  isArchiveView,
  isActivityView,
  editBoardTitle,
  isEditingBoardTitle,
  filters,
  onEditBoardTitle,
  onBoardTitleSubmit,
  onSetIsEditingBoardTitle,
  onViewChange,
  onOpenShareDialog,
  onOpenSettings,
  onOpenFilters,
}) => {


  const getBoardUsers = () => {
    if (!board) return [];

    const allUsers = new Set<string>();
    board.readUserIds?.forEach((id) => allUsers.add(id));
    board.writeUserIds?.forEach((id) => allUsers.add(id));
    if (board.createdBy) allUsers.add(board.createdBy);

    return Array.from(allUsers);
  };

  return (
    <Box
      sx={{
        width: '100%',
        position: 'relative',
        zIndex: 1,
        px: { xs: 1, sm: 2 },
        pl: { 
          xs: 1,
          sm: (theme) => `calc(${theme.spacing(8)} + ${theme.spacing(1)})`,
          md: (theme) => `calc(${theme.spacing(30)} + ${theme.spacing(2)})` 
        },
        '&.sidebar-closed': {
          pl: { 
            xs: 1,
            sm: (theme) => `calc(${theme.spacing(8)} + ${theme.spacing(1)})`,
            md: (theme) => `calc(${theme.spacing(8)} + ${theme.spacing(2)})`
          },
        }
      }}
    >
      <Box
        sx={{
          maxWidth: '100%',
          mx: 'auto',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            bgcolor: 'rgba(255, 255, 255, 0.95)',
            backdropFilter: 'blur(12px)',
            borderRadius: { xs: 1, sm: 1 },
            border: 1,
            borderColor: 'divider',
            boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
            position: "relative",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: { xs: "stretch", sm: "center" },
            justifyContent: "space-between",
            minHeight: { xs: "auto", sm: 56 },
            gap: { xs: 1, sm: 2 },
            p: 1,
            boxSizing: "border-box",
            mb: { xs: 1, sm: 2 },
          }}
        >
          {/* Board Title */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              minWidth: 0,
              flex: { sm: '0 1 auto' },
            }}
          >
            {isEditingBoardTitle ? (
              <TextField
                autoFocus
                value={editBoardTitle}
                onChange={(e) => onEditBoardTitle(e.target.value)}
                onBlur={onBoardTitleSubmit}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    onBoardTitleSubmit();
                  }
                }}
                size="small"
                sx={{
                  flex: 1,
                  maxWidth: { xs: "100%", sm: 300 },
                  "& .MuiInputBase-input": {
                    fontSize: { xs: "1rem", sm: "1.125rem" },
                    fontWeight: 500,
                    py: 0.5,
                    px: 1,
                  },
                }}
              />
            ) : (
              <Typography
                variant="h6"
                onClick={() => {
                  onSetIsEditingBoardTitle(true);
                  onEditBoardTitle(board.name);
                }}
                sx={{
                  cursor: "pointer",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  fontSize: { xs: '1rem', sm: '1.125rem' },
                  fontWeight: 500,
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              >
                {board.name}
              </Typography>
            )}
          </Box>

          {/* Controls Section */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              flexWrap: { xs: "wrap", sm: "nowrap" },
              justifyContent: { xs: "space-between", sm: "flex-end" },
              flex: { sm: '1 1 auto' },
            }}
          >
            <Button
              startIcon={<FilterListIcon />}
              onClick={onOpenFilters}
              variant="outlined"
              size="small"
              sx={{ 
                height: 32,
                order: { xs: 2, sm: 1 },
                minWidth: 0,
                px: { xs: 1, sm: 1.5 },
              }}
            >
              <Box component="span" sx={{ display: { xs: "none", sm: "inline" } }}>
                Filters
              </Box>
              {(filters.members.length > 0 || 
                filters.labels.length > 0) && (
                <Badge
                  color="primary"
                  variant="dot"
                  sx={{ 
                    '& .MuiBadge-badge': {
                      right: -3,
                      top: 3,
                    }
                  }}
                />
              )}
            </Button>

            <ButtonGroup
              variant="outlined"
              size="small"
              sx={{
                order: { xs: 2, sm: 2 },
                flexGrow: { xs: 1, sm: 0 },
                height: 32,
              }}
            >
              <Button
                startIcon={<DashboardIcon />}
                color={!isArchiveView && !isActivityView ? "primary" : "inherit"}
                variant={!isArchiveView && !isActivityView ? "contained" : "outlined"}
                onClick={() => onViewChange("board")}
                sx={{ 
                  minWidth: 0,
                  px: { xs: 1, sm: 1.5 },
                  height: '100%',
                }}
              >
                <Box component="span" sx={{ display: { xs: "none", sm: "inline" } }}>
                  Board
                </Box>
              </Button>
              <Button
                startIcon={<HistoryIcon />}
                color={isActivityView ? "primary" : "inherit"}
                variant={isActivityView ? "contained" : "outlined"}
                onClick={() => onViewChange("activity")}
                sx={{ 
                  minWidth: 0,
                  px: { xs: 1, sm: 1.5 },
                  height: '100%',
                }}
              >
                <Box component="span" sx={{ display: { xs: "none", sm: "inline" } }}>
                  Activity
                </Box>
              </Button>
              <Button
                startIcon={<ArchiveIcon />}
                color={isArchiveView ? "primary" : "inherit"}
                variant={isArchiveView ? "contained" : "outlined"}
                onClick={() => onViewChange("archive")}
                sx={{ 
                  minWidth: 0,
                  px: { xs: 1, sm: 1.5 },
                  height: '100%',
                }}
              >
                <Box component="span" sx={{ display: { xs: "none", sm: "inline" } }}>
                  Archive
                </Box>
              </Button>
            </ButtonGroup>

            <AvatarGroup
              max={3}
              sx={{
                order: { xs: 1, sm: 2 },
                "& .MuiAvatar-root": {
                  width: 28,
                  height: 28,
                  fontSize: '0.75rem',
                  border: "2px solid white",
                },
              }}
            >
              {getBoardUsers()
                .sort((a, b) => {
                  if (a === board.createdBy) return -1;
                  if (b === board.createdBy) return 1;
                  return 0;
                })
                .map((userId) => {
                  const user = users?.find((u) => u.id === userId);
                  if (!user) return null;

                  const isOwner = userId === board.createdBy;

                  return (
                    <Tooltip
                      key={user.id}
                      title={
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                        >
                          <Typography variant="body2">
                            {`${user.firstName} ${user.lastName}`}
                          </Typography>
                          {isOwner && (
                            <Typography
                              variant="caption"
                              sx={{ color: "warning.main" }}
                            >
                              (Owner)
                            </Typography>
                          )}
                        </Box>
                      }
                    >
                      <Box sx={{ position: "relative" }}>
                        <Avatar
                          src={user.profilePicture || undefined}
                          alt={`${user.firstName} ${user.lastName}`}
                          sx={
                            isOwner
                              ? {
                                  border: "2px solid",
                                  borderColor: "warning.main",
                                }
                              : undefined
                          }
                        >
                          {user.firstName?.[0] || ""}
                        </Avatar>
                        {isOwner && (
                          <Box
                            sx={{
                              position: "absolute",
                              bottom: -2,
                              right: -2,
                              width: 16,
                              height: 16,
                              borderRadius: "50%",
                              bgcolor: "warning.main",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              border: "1px solid white",
                            }}
                          >
                            <CrownIcon sx={{ fontSize: 12, color: "white" }} />
                          </Box>
                        )}
                      </Box>
                    </Tooltip>
                  );
                })}
            </AvatarGroup>

            <Box 
              sx={{ 
                display: 'flex',
                gap: 0.5,
                order: { xs: 3, sm: 3 },
                ml: { xs: 0, sm: 1 },
              }}
            >
              {canShare && (
                <>
                  <Button
                    startIcon={<ShareIcon />}
                    onClick={onOpenShareDialog}
                    variant="outlined"
                    size="small"
                    sx={{
                      minWidth: 0,
                      height: 32,
                      px: 1,
                      "& .MuiButton-startIcon": {
                        mr: { xs: 0, sm: 0.5 },
                      },
                    }}
                  >
                    <Box component="span" sx={{ display: { xs: "none", sm: "inline" } }}>
                      Share
                    </Box>
                  </Button>
                  <IconButton
                    onClick={onOpenSettings}
                    size="small"
                    sx={{ 
                      color: 'inherit',
                      width: 32,
                      height: 32,
                    }}
                  >
                    <SettingsIcon fontSize="small" />
                  </IconButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
