import { doc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { AdminConfig } from "types/admin/config";

export const getAdminConfig = async (): Promise<AdminConfig> => {
  const configRef = doc(db, "config", "admin");
  const configSnap = await getDoc(configRef);
  
  if (!configSnap.exists()) {
    // Return default config if none exists
    return {
      defaultIndependentEngineerAgreementId: "",
    };
  }
  
  return configSnap.data() as AdminConfig;
}; 