import React from "react";
import { useTheme, Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        margin: theme.spacing(2),
        textAlign: "center",
      }}
    >
      <Typography
        component="h1"
        variant="h4"
        gutterBottom
        sx={{ color: "black" }}
      >
        404 - Page Not Found
      </Typography>
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{ color: "rgba(0, 0, 0, 0.7)" }}
      >
        Sorry, we couldn't find the page you're looking for.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => navigate("/")}
        sx={{ mt: theme.spacing(2) }}
      >
        Go Back
      </Button>
    </Box>
  );
}
