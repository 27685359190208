import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Paper,
  Alert,
} from "@mui/material";
import { useFreelancerPayroll } from "hooks/freelancer/useFreelancerPayroll";
import { useFreelancerProfile } from "hooks/freelancer/useFreelancerProfile";
import { useAuth } from "hooks/auth/useAuth";
import FreelancerPayrollForm from "./FreelancerPayrollForm";
import { IncreaseExternalAccountDetails } from "@freetech/models/bank/bank";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { PayrollFormData } from "./FreelancerPayrollForm";

interface FreelancerDirectDepositStepProps {
  onNext: () => void;
  onBack: () => void;
}

const FreelancerDirectDepositStep: React.FC<FreelancerDirectDepositStepProps> = ({
  onNext,
  onBack,
}) => {
  const { currentUser } = useAuth();
  const { freelancerProfile: freelancer } = useFreelancerProfile(currentUser?.uid || "");
  const {
    paymentProfile,
    createMyFreelancerPayrollProfile,
    updateMyFreelancerPayrollProfile,
  } = useFreelancerPayroll();

  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const [formData, setFormData] = useState<
    Partial<IncreaseExternalAccountDetails> & { nickname: string }
  >({
    accountHolderFullName: "",
    accountHolderType: "individual",
    routingNumber: "",
    accountNumber: "",
    funding: "checking",
    nickname: "",
  });

  useEffect(() => {
    if (paymentProfile && freelancer?.payrollProfileCompleted) {
      // Only show view mode if we have both completed flag and payment profile
      setIsUpdating(false);
      setFormData({
        accountHolderFullName: paymentProfile.accountHolderFullName,
        accountHolderType: paymentProfile.accountHolderType,
        funding: paymentProfile.funding,
        routingNumber: "",
        accountNumber: "",
        nickname: `${paymentProfile.accountHolderFullName}'s ${paymentProfile.funding} account`,
      });
    }
  }, [paymentProfile, freelancer?.payrollProfileCompleted]);

  const handleSubmit = async (data: PayrollFormData) => {
    try {
      setError(null);
      setSuccess("Creating payment profile...");

      const response = await createMyFreelancerPayrollProfile({
        ...data,
        nickname: `${data.accountHolderFullName}'s ${data.funding} account`,
      });

      if (!response.success) {
        setError(response.error || "Failed to create payment profile");
        return;
      }

      setSuccess("Payment profile created successfully");
      onNext();
    } catch (err: any) {
      setError(err.message || "Failed to create payment profile. Please try again.");
      console.error(err);
    }
  };

  const handleUpdate = async (data: PayrollFormData) => {
    try {
      setError(null);
      const response = await updateMyFreelancerPayrollProfile({
        ...data,
        nickname: `${data.accountHolderFullName}'s ${data.funding} account`,
      });

      if (!response.success) {
        setError(response.error || "Failed to update payment profile");
        return;
      }

      setSuccess("Payment profile updated successfully");
      onNext();
    } catch (err) {
      setError("Failed to update payment profile. Please try again.");
      console.error(err);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>
        Step 2: Direct Deposit
      </Typography>
      {freelancer?.payrollProfileCompleted && paymentProfile && !isUpdating ? (
        <>
          <Paper sx={{ p: 3, mb: 3 }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 2,
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <AccountBalanceIcon sx={{ mr: 1 }} />
                <Typography variant="h6">Direct Deposit Information</Typography>
              </Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setFormData({
                    accountHolderFullName: paymentProfile.accountHolderFullName,
                    accountHolderType: paymentProfile.accountHolderType,
                    funding: paymentProfile.funding,
                    routingNumber: "",
                    accountNumber: "",
                    nickname: `${paymentProfile.accountHolderFullName}'s ${paymentProfile.funding} account`,
                  });
                  setIsUpdating(true);
                }}
              >
                Update Payment Account
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "success.main",
                mb: 2,
              }}
            >
              <CheckCircleIcon sx={{ mr: 1 }} />
              <Typography>Direct Deposit Setup Complete</Typography>
            </Box>
            {paymentProfile && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2">
                  <strong>Account Holder:</strong>{" "}
                  {paymentProfile.accountHolderFullName}
                </Typography>
                <Typography variant="body2">
                  <strong>Account Type:</strong> {paymentProfile.funding}
                </Typography>
                <Typography variant="body2">
                  <strong>Financial Institution:</strong>{" "}
                  {freelancer?.increaseExternalAccountProfile?.financialInstitution}
                </Typography>
                <Typography variant="body2">
                  <strong>Account Number:</strong> ****
                  {paymentProfile.accountNumber
                    ? paymentProfile.accountNumber.slice(-4)
                    : "N/A"}
                </Typography>
              </Box>
            )}
          </Paper>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              onClick={onBack}
            >
              Back to Tax Form
            </Button>
            <Button
              variant="contained"
              endIcon={<ArrowForwardIcon />}
              onClick={onNext}
            >
              Continue to Verification
            </Button>
          </Box>
        </>
      ) : (
        <FreelancerPayrollForm
          formData={formData}
          onSubmit={isUpdating ? handleUpdate : handleSubmit}
          error={error}
          success={success}
          isUpdate={isUpdating}
          onBack={() => {
            if (isUpdating) {
              setIsUpdating(false);
            } else {
              onBack();
            }
          }}
        />
      )}
      {error && (
        <Alert severity="error" sx={{ mt: 5 }} onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      {success && (
        <Alert
          severity="success"
          sx={{ mt: 5, width: "25%" }}
          onClose={() => setSuccess(null)}
        >
          {success}
        </Alert>
      )}
    </Box>
  );
};

export default FreelancerDirectDepositStep; 