import { Timesheet } from "types/timesheet";
import { calculateDuration } from ".";

interface CreateTimeEntryParams {
  entry: Partial<Timesheet>;
  userId: string;
  selectedClient?: string;
  selectedProject?: string;
}


export const prepareTimeEntryData = ({
  entry,
  userId,
  selectedClient,
  selectedProject,
}: CreateTimeEntryParams): Omit<Timesheet, "id"> => {
  const duration = calculateDuration(entry.startTime!, entry.endTime!);

  return {
    ...entry,
    projectAssignmentId: entry.projectAssignmentId || undefined,
    userId,
    duration,
    billable: entry.projectAssignmentId ? entry.billable : false,
    submitted: false,
    description: entry.description || "",
    startTime: entry.startTime!,
    endTime: entry.endTime!,
    clientId: selectedClient || undefined,
    projectId: selectedProject || undefined,
  } as Omit<Timesheet, "id">;
};
