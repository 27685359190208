import React, { useEffect } from "react";
import { Account, DashboardLayout } from "@toolpad/core";
import { Box, Button, Typography } from "@mui/material";
import Footer from "components/Footer";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import NotificationAccordion from "./components/NotificationAccordion";
import { useAuth } from "hooks/auth/useAuth";
import { NotificationAdd, Person, Warning } from "@mui/icons-material";
import { OnlineUsersDisplay } from "components/OnlineUsersDisplay/OnlineUsersDisplay";
import { useOnlineUsers } from "hooks/websocket/useOnlineUsers";
import { useUserPing } from "hooks/websocket/useUserPing";
import { EXPIRATION_TIME_MS } from "contexts/AuthContext";
import { MFAWarning } from "components/MFAWarning";
// const ToolbarActions: React.FC = () => {
//   return (
//     <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
//       <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-start' }}>
//         <TimerDisplay />
//       </Box>
//     </Box>
//   );
// };

const SessionExpiringSoon = () => {
  const { remainingSessionTime, logout } = useAuth();
  const [timeLeft, setTimeLeft] = React.useState(remainingSessionTime);

  const oneHourInMilliseconds = 60 * 60 * 1000;

  React.useEffect(() => {
    if (!timeLeft || timeLeft > EXPIRATION_TIME_MS) return;

    const timer = setInterval(() => {
      setTimeLeft((prev) => {
        if (!prev || prev <= 1000) {
          logout();
          window.location.href = "/session-expired";
          return 0;
        }
        return prev - 1000;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, logout]);

  React.useEffect(() => {
    setTimeLeft(remainingSessionTime);
  }, [remainingSessionTime]);

  if (!timeLeft || timeLeft > oneHourInMilliseconds) {
    return null;
  }

  // Convert milliseconds to minutes and seconds
  const minutes = Math.floor(timeLeft / 60000);
  const seconds = Math.floor((timeLeft % 60000) / 1000);

  return (
    <>
      <Box
        sx={{
          display: "inline-flex",
          alignItems: "center",
          marginRight: 2,
          "&:hover .warning-text": {
            display: "block",
          },
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Warning
            sx={{
              color: "warning.main",
              display: "flex",
              alignItems: "center",
            }}
          />
          <Typography
            className="warning-text"
            sx={{
              position: "absolute",
              bottom: "-40px",
              right: 0,
              backgroundColor: "warning.main",
              color: "warning.contrastText",
              padding: "8px",
              borderRadius: "4px",
              whiteSpace: "nowrap",
              display: "none",
              zIndex: 1000,
            }}
          >
            Warning: Session expires in {minutes}m {seconds}s. You will be
            logged out.
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export const CoreLayout: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logActivity } = useOnlineUsers();
  const { userInfo } = useAuth();

  // Always call useUserPing regardless of userInfo
  useUserPing();

  useEffect(() => {
    if (userInfo) {
      logActivity(location.pathname);
    }
  }, [location.pathname, logActivity, userInfo]);

  // Redirect freelancers who need onboarding
  if (userInfo?.role === "freelancer" && userInfo.needsOnboarding === true) {
    return <Navigate to="/freelancer-onboarding" />;
  }

  // Redirect stakeholders who need onboarding
  if (userInfo?.role === "stakeholder" && userInfo.needsOnboarding === true) {
    return <Navigate to="/stakeholder-onboarding" />;
  }

  const isFreeloBoard =
    location.pathname.startsWith("/freelo/") && location.pathname !== "/freelo";
  const isKnowledgeBase = location.pathname.startsWith("/knowledge-base");

  return (
    <DashboardLayout
      slots={{
        toolbarAccount: () => (
          <>
            <Box display="flex" alignItems="center">
              <SessionExpiringSoon />
              {window.location.pathname.includes("stg") ||
              window.location.pathname.includes("localhost") ? null : (
                <>
                  <OnlineUsersDisplay />
                </>
              )}
              <Box sx={{ marginRight: 1 }}>
                <NotificationAccordion />
              </Box>
              <Account
                slots={{
                  menuItems: () => (
                    <Box
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Button
                        onClick={() => navigate("/account-settings")}
                        startIcon={<Person />}
                      >
                        Account Settings
                      </Button>
                    </Box>
                  ),
                }}
              />{" "}
            </Box>
          </>
        ),
      }}
      // slots={{
      //   toolbarActions: ToolbarActions,
      // }}
      defaultSidebarCollapsed={true}
      slotProps={{
        toolbarAccount: {
          slotProps: {
            signInButton: {
              sx: { display: "none" },
            },
          },
        },
      }}
      sx={{
        height: "100vh",
        overflow: isFreeloBoard || isKnowledgeBase ? "hidden" : "auto",
      }}
    >
      <MFAWarning />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          ...(isFreeloBoard || isKnowledgeBase
            ? {
                height: "calc(100vh - 64px)",
                margin: 0,
                padding: 0,
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                "& > *": {
                  flex: 1,
                  minHeight: 0,
                },
              }
            : {
                marginTop: 2,
                marginLeft: "auto",
                marginRight: "auto",
                paddingLeft: 2,
                paddingRight: 2,
                minHeight: "80vh",
              }),
        }}
      >
        {children}
      </Box>
      {isFreeloBoard || isKnowledgeBase ? null : <Footer />}
      {/* <ChatBubble /> */}
    </DashboardLayout>
  );
};

export default CoreLayout;
