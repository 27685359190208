import { getDocuments } from "core/firestore";
import { StakeholderPayment } from "@freetech/models/bank";
import { sortObjectArrayByColumn } from "utils/sortObjectArrayByColumn";

export const getClientPayments = async (clientId: string): Promise<StakeholderPayment[]> => {
    // const payments = await getDocuments<StakeholderPayment>(`clients/${clientId}/payments`);
    console.log("getClientPayments", clientId);
    throw new Error("Not implemented");
    // return sortObjectArrayByColumn(payments, 'sentDate', 'desc');

};