import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Paper,
  MenuItem,
  Alert,
  Snackbar,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import BugReportIcon from "@mui/icons-material/BugReport";
import { useAuth } from "hooks/auth/useAuth";
import { useProjectsNonAdmin } from "hooks/project/useProjectsNonAdmin";
import { useBugReport } from "hooks/bugReport/useBugReport";
import BugReportCards from "./components/BugReportCards";
import { IBugReport } from "@freetech/models/bugReport";
import { Project } from "types/projects";
import { BugReportFormData } from "types/bugReport";

const BugReport: React.FC = () => {
  const { userInfo } = useAuth();
  const { data: projects, isLoading: projectsLoading } = useProjectsNonAdmin();
  const { createReport } = useBugReport();

  const [formData, setFormData] = useState<BugReportFormData>({
    title: "",
    description: "",
    priority: "medium",
    status: "open",
    type: "bug",
    projectId: "",
  });

  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState<{
    open: boolean;
    message: string;
    severity: "success" | "error";
  }>({
    open: false,
    message: "",
    severity: "success",
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!formData.projectId) {
      setSnackbar({
        open: true,
        message: "Please select a project",
        severity: "error",
      });
      return;
    }

    setLoading(true);
    try {
      const reportData: Omit<IBugReport, "id" | "createdAt" | "updatedAt"> = {
        ...formData,
        reportedById: userInfo?.id || "",
      };

      await createReport.mutateAsync(reportData);

      setSnackbar({
        open: true,
        message: "Bug report submitted successfully",
        severity: "success",
      });

      // Reset form
      setFormData({
        title: "",
        description: "",
        priority: "medium",
        status: "open",
        type: "bug",
        projectId: "",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Failed to submit bug report",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent
  ) => {
    const { name, value } = e.target;
    setFormData((prev: BugReportFormData) => ({
      ...prev,
      [name]: value,
    }));
  };

  if (projectsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: { xs: 2, md: 4 },
        width: "100%",
        maxWidth: "100%",
        minHeight: "100vh",
        bgcolor: "grey.100",
      }}
    >

      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: 800,
          p: { xs: 2, md: 4 },
          mt: 4,
          mb: 4,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 4,
          }}
        >
          <BugReportIcon color="primary" sx={{ fontSize: 32 }} />
          <Typography variant="h4" component="h1">
            Report a Bug
          </Typography>
        </Box>

        <form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 3 }}>
            <FormControl fullWidth required>
              <InputLabel id="project-select-label">Project</InputLabel>
              <Select
                labelId="project-select-label"
                name="projectId"
                value={formData.projectId}
                label="Project"
                onChange={handleChange}
              >
                {projects?.map((project: Project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              required
              fullWidth
              label="Title"
              name="title"
              value={formData.title}
              onChange={handleChange}
              variant="outlined"
            />

            <TextField
              required
              fullWidth
              label="Description"
              name="description"
              value={formData.description}
              onChange={handleChange}
              multiline
              rows={4}
              variant="outlined"
            />

            <TextField
              select
              required
              fullWidth
              label="Priority"
              name="priority"
              value={formData.priority}
              onChange={handleChange}
              variant="outlined"
            >
              <MenuItem value="low">Low</MenuItem>
              <MenuItem value="medium">Medium</MenuItem>
              <MenuItem value="high">High</MenuItem>
              <MenuItem value="critical">Critical</MenuItem>
            </TextField>

            <TextField
              select
              required
              fullWidth
              label="Type"
              name="type"
              value={formData.type}
              onChange={handleChange}
              variant="outlined"
            >
              <MenuItem value="bug">Bug</MenuItem>
              <MenuItem value="feature">Feature Request</MenuItem>
              <MenuItem value="improvement">Improvement</MenuItem>
            </TextField>

            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
              disabled={loading}
              sx={{ mt: 2 }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                "Submit Report"
              )}
            </Button>
          </Box>
        </form>
      </Paper>

      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: 1200,
          p: { xs: 2, md: 4 },
        }}
      >
        <Typography variant="h5" component="h2" sx={{ mb: 3 }}>
          My Bug Reports
        </Typography>
        <BugReportCards />
      </Paper>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          variant="filled"
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default BugReport;
