import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Alert,
  AlertTitle,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { useAuth } from "hooks/auth/useAuth";
import { PaymentInfo } from "./types";

interface SetupPaymentProps {
  onNext: (data: PaymentInfo | null) => Promise<void>;
  onBack: () => void;
  initialData?: PaymentInfo;
}

export const SetupPayment: React.FC<SetupPaymentProps> = ({
  onNext,
  onBack,
  initialData,
}) => {
  const { currentUser } = useAuth();
  const {
    createMyStakeholderPaymentProfile,
    updateMyStakeholderPaymentProfile,
    paymentProfile,
  } = useStakeholderPayments();
  const { stakeholderProfile, isLoadingStakeholderProfile } = useStakeholder(
    currentUser?.uid
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);
  const [formData, setFormData] = useState({
    accountHolderFullName:
      initialData?.accountHolderFullName ||
      paymentProfile?.accountHolderFullName ||
      "",
    accountHolderType:
      initialData?.accountHolderType ||
      paymentProfile?.accountHolderType ||
      "individual",
    accountName:
      initialData?.nickname ||
      stakeholderProfile?.increaseExternalAccountProfile
        ?.externalAccountNickname ||
      "",
    accountNumber:
      initialData?.accountNumber || paymentProfile?.accountNumber || "",
    routingNumber:
      initialData?.routingNumber || paymentProfile?.routingNumber || "",
    funding: initialData?.funding || paymentProfile?.funding || "checking",
  });
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const [showRoutingNumber, setShowRoutingNumber] = useState(false);

  // Update form data when payment profile is loaded
  useEffect(() => {
    // Only set form data on initial load, not after updates
    if (paymentProfile && !isEditing && !formData.accountHolderFullName) {
      setFormData({
        accountHolderFullName: paymentProfile.accountHolderFullName || "",
        accountHolderType: paymentProfile.accountHolderType || "individual",
        accountName:
          stakeholderProfile?.increaseExternalAccountProfile
            ?.externalAccountNickname || "",
        accountNumber: paymentProfile.accountNumber || "",
        routingNumber: paymentProfile.routingNumber || "",
        funding: paymentProfile.funding || "checking",
      });
    }
  }, [paymentProfile, isEditing]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSelectChange = (e: {
    target: { name: string; value: string };
  }) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isFormValid = () => {
    return (
      formData.accountHolderFullName &&
      formData.accountHolderType &&
      formData.accountName &&
      formData.accountNumber &&
      formData.routingNumber
    );
  };

  const handleVerifyBankInfo = async () => {
    if (isFormValid()) {
      setIsSubmitting(true);
      setSubmitError(null);
      try {
        const paymentData: PaymentInfo = {
          accountNumber: formData.accountNumber,
          routingNumber: formData.routingNumber,
          accountHolderFullName: formData.accountHolderFullName,
          accountHolderType: formData.accountHolderType,
          funding: "checking",
          nickname: formData.accountName,
        };

        const response = isEditing
          ? await updateMyStakeholderPaymentProfile(paymentData)
          : await createMyStakeholderPaymentProfile(paymentData);

        if (response.success) {
          setSubmitError(null);
          setIsEditing(false);
        } else if (response.status === 500) {
          setSubmitError("An error occurred. Please try again in a minute.");
        } else {
          setSubmitError(response.error || "Failed to set up payment profile");
        }
      } catch (error) {
        console.error("Error submitting payment info:", error);
        setSubmitError("An error occurred. Please try again in a minute.");
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleUpdate = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    if (paymentProfile) {
      setFormData({
        accountHolderFullName: paymentProfile.accountHolderFullName || "",
        accountHolderType: paymentProfile.accountHolderType || "individual",
        accountName:
          stakeholderProfile?.increaseExternalAccountProfile
            ?.externalAccountNickname || "",
        accountNumber: paymentProfile.accountNumber || "",
        routingNumber: paymentProfile.routingNumber || "",
        funding: paymentProfile.funding || "checking",
      });
    }
  };

  const handleNext = async () => {
    await onNext({
      ...formData,
      nickname: formData.accountName,
    });
  };

  const handleSkip = async () => {
    setIsSubmitting(true);
    try {
      await onNext(null);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isVerified =
    stakeholderProfile?.increaseExternalAccountProfile?.externalAccountId !==
    undefined;
  console.log("stakeholderProfile", stakeholderProfile);

  if (isLoadingStakeholderProfile) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Payment Setup (Optional)
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Set up your payment information for submitting payments to FreeTech.
      </Typography>

      <Alert severity="info" sx={{ mb: 3 }}>
        Your payment information will be securely stored and used only for
        processing payments.
      </Alert>

      {submitError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {submitError}
        </Alert>
      )}

      {isVerified &&
        !isEditing &&
        stakeholderProfile?.increaseExternalAccountProfile && (
          <Alert severity="success" sx={{ mb: 3 }}>
            <AlertTitle>Bank Account Verified</AlertTitle>
            Your bank account has been successfully verified.
            {stakeholderProfile.increaseExternalAccountProfile
              .financialInstitution && (
              <Typography variant="body2" sx={{ mt: 1 }}>
                <strong>Financial Institution:</strong>{" "}
                {
                  stakeholderProfile.increaseExternalAccountProfile
                    .financialInstitution
                }
              </Typography>
            )}
          </Alert>
        )}

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <FormControl fullWidth required disabled={isVerified && !isEditing}>
            <InputLabel>Account Type</InputLabel>
            <Select
              name="accountHolderType"
              value={formData.accountHolderType}
              onChange={handleSelectChange}
              label="Account Type"
            >
              <MenuItem value="individual">Individual Account</MenuItem>
              <MenuItem value="business">Business Account</MenuItem>
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Account Holder Full Name"
            name="accountHolderFullName"
            value={formData.accountHolderFullName}
            onChange={handleChange}
            helperText="Enter the full name as it appears on your bank account"
            disabled={isVerified && !isEditing}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            required
            fullWidth
            label="Bank Account Name"
            name="accountName"
            value={formData.accountName}
            onChange={handleChange}
            helperText="Enter a name for this bank account (e.g., 'Business Checking')"
            disabled={isVerified && !isEditing}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            type={showAccountNumber ? "text" : "password"}
            label="Account Number"
            name="accountNumber"
            value={formData.accountNumber}
            onChange={handleChange}
            onFocus={() => setShowAccountNumber(true)}
            onBlur={() => setShowAccountNumber(false)}
            disabled={isVerified && !isEditing}
            InputProps={{
              endAdornment: (!isVerified || isEditing) && (
                <Button
                  size="small"
                  onClick={() => setShowAccountNumber(!showAccountNumber)}
                  sx={{ minWidth: "auto", p: 0.5 }}
                >
                  {showAccountNumber ? "Hide" : "Show"}
                </Button>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            required
            fullWidth
            type={showRoutingNumber ? "text" : "password"}
            label="Routing Number"
            name="routingNumber"
            value={formData.routingNumber}
            onChange={handleChange}
            onFocus={() => setShowRoutingNumber(true)}
            onBlur={() => setShowRoutingNumber(false)}
            disabled={isVerified && !isEditing}
            InputProps={{
              endAdornment: (!isVerified || isEditing) && (
                <Button
                  size="small"
                  onClick={() => setShowRoutingNumber(!showRoutingNumber)}
                  sx={{ minWidth: "auto", p: 0.5 }}
                >
                  {showRoutingNumber ? "Hide" : "Show"}
                </Button>
              ),
            }}
          />
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Button onClick={onBack} disabled={isSubmitting}>
          Back
        </Button>
        <Box sx={{ display: "flex", gap: 2 }}>
          <Button onClick={handleSkip} disabled={isSubmitting}>
            Skip for Now
          </Button>
          {isVerified ? (
            isEditing ? (
              <>
                <Button
                  variant="outlined"
                  onClick={handleCancel}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleVerifyBankInfo}
                  disabled={!isFormValid() || isSubmitting}
                  startIcon={
                    isSubmitting ? <CircularProgress size={20} /> : null
                  }
                >
                  {isSubmitting ? "Saving..." : "Save Changes"}
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={handleUpdate}
                disabled={isSubmitting}
              >
                Update
              </Button>
            )
          ) : (
            <Button
              variant="contained"
              color="primary"
              onClick={handleVerifyBankInfo}
              disabled={!isFormValid() || isSubmitting}
              startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
            >
              {isSubmitting ? "Verifying..." : "Verify Bank Information"}
            </Button>
          )}
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={!isVerified || isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SetupPayment;
