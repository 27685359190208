import React, { useState, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Freelancer } from "types/user";
import { useAdminPayroll } from "hooks/admin/useAdminPayroll";
import { useAdminClients } from "hooks/admin/useAdminClients";
import { Client, ConsultingServicesAgreement } from "types/projects";
import { Project, ProjectAssignment } from "types/projects";

interface PaymentDialogProps {
  open: boolean;
  onClose: () => void;
  contractor: Freelancer;
  contractorId: string;
}

export const PaymentDialog: React.FC<PaymentDialogProps> = ({
  open,
  onClose,
  contractor,
  contractorId,
}) => {
  const { makePayment } = useAdminPayroll();
  const { clients } = useAdminClients();

  const [amount, setAmount] = useState<string>("");
  const [description, setDescription] = useState("");
  const [selectedClientId, setSelectedClientId] = useState<string>("");
  const [selectedProjectId, setSelectedProjectId] = useState<string>("");
  const [selectedCsaId, setSelectedCsaId] = useState<string>("");

  // Get available projects for selected client
  const availableProjects = useMemo(() => {
    if (!selectedClientId || !clients) return [];
    const client = clients.find((c: Client) => c.id === selectedClientId);
    return client?.projects || [];
  }, [selectedClientId, clients]);

  // Get available CSAs for selected client
  const availableCsas = useMemo(() => {
    if (!selectedClientId || !clients) return [];
    const client = clients.find((c: Client) => c.id === selectedClientId);
    return client?.consultingServicesAgreements || [];
  }, [selectedClientId, clients]);

  // Get project assignment for selected project and client
  const projectAssignment = useMemo(() => {
    if (!selectedClientId || !selectedProjectId || !clients) return null;
    const client = clients.find((c: Client) => c.id === selectedClientId);
    const assignment = client?.projectAssignments?.find(
      (pa: ProjectAssignment) =>
        pa.projectId === selectedProjectId && pa.engineerId === contractor.id
    );
    return assignment?.id ? { id: assignment.id } : null;
  }, [selectedClientId, selectedProjectId, clients, contractor.id]);

  const handleSubmit = async () => {
    try {
      if (!selectedClientId) {
        throw new Error("No client selected");
      }
      console.log("userId", contractorId);
      await makePayment.mutateAsync({
        userId: contractorId,
        freelancerPayment: {
          clientId: selectedClientId,
          projectId: selectedProjectId || undefined,
          projectAssignmentId: projectAssignment?.id || undefined,
          csaId: selectedCsaId || undefined,
          amount: parseFloat(amount),
          memo: description,
          submittedByAdminUserId: contractorId,
          userId: contractorId,
          status: "pending",
          createdAt: "",
          updatedAt: "",
          paymentDate: "",
          direction: "outbound",
        },
      });
      onClose();
    } catch (error) {
      console.error("Error creating payment:", error);
    }
  };

  const isFormValid = Boolean(amount && description);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Create Payment for {contractor.firstName} {contractor.lastName}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Create a new payment for this contractor. The payment will be
            processed through Increase and will require manual approval.
          </Typography>

          <FormControl fullWidth>
            <InputLabel>Client</InputLabel>
            <Select
              value={selectedClientId}
              onChange={(e) => {
                setSelectedClientId(e.target.value);
                setSelectedProjectId("");
                setSelectedCsaId("");
              }}
              label="Client"
            >
              {clients?.map((client: Client) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.companyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth disabled={!selectedClientId}>
            <InputLabel>Project</InputLabel>
            <Select
              value={selectedProjectId}
              onChange={(e) => setSelectedProjectId(e.target.value)}
              label="Project"
            >
              {availableProjects.map((project: Project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
            {selectedClientId && availableProjects.length === 0 && (
              <FormHelperText>
                No projects available for this client
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth disabled={!selectedClientId}>
            <InputLabel>CSA</InputLabel>
            <Select
              value={selectedCsaId}
              onChange={(e) => setSelectedCsaId(e.target.value)}
              label="CSA"
            >
              {availableCsas.map((csa: ConsultingServicesAgreement) => (
                <MenuItem key={csa.id} value={csa.id}>
                  {csa.name}
                </MenuItem>
              ))}
            </Select>
            {selectedClientId && availableCsas.length === 0 && (
              <FormHelperText>No CSAs available for this client</FormHelperText>
            )}
          </FormControl>

          <TextField
            label="Amount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            fullWidth
            required
            inputProps={{ min: 0, step: 0.01 }}
            placeholder="0.00"
          />

          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            required
            multiline
            rows={3}
            placeholder="Payment description..."
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!isFormValid || makePayment.isPending}
        >
          {makePayment.isPending ? (
            <>
              <CircularProgress size={20} sx={{ mr: 1 }} />
              Creating Payment...
            </>
          ) : (
            "Create Payment"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
