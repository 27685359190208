import React, { useRef, useState } from 'react';
import {
  Box,
  Typography,
  Button,
  Divider,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LabelIcon from '@mui/icons-material/Label';
import TimerIcon from '@mui/icons-material/Timer';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TimelineIcon from '@mui/icons-material/Timeline';
import GitHubIcon from '@mui/icons-material/GitHub';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { DateTime } from 'luxon';
import { Card, CardAttachment } from "types/freelo";

interface CardSidebarProps {
  card: Card;
  showChecklists: boolean;
  showHillChart: boolean;
  showAttachments: boolean;
  isUploading: boolean;
  onMembersClick: () => void;
  onLabelsClick: (event: React.MouseEvent<HTMLElement>) => void;
  onDateClick: () => void;
  onChecklistToggle: () => void;
  onHillChartToggle: () => void;
  onArchiveClick: () => void;
  onGithubBranchClick: () => void;
  onAttachmentsToggle: () => void;
  onFileUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  renderMemberChip: (userId: string, role: string) => React.ReactNode;
  AttachmentList: React.ComponentType<{
    attachments?: CardAttachment[];
    onDelete: (attachmentId: string) => void;
    onDeleteClick: (attachment: CardAttachment) => void;
  }>;
}

const CardSidebar: React.FC<CardSidebarProps> = ({
  card,
  showChecklists,
  showHillChart,
  showAttachments,
  isUploading,
  onMembersClick,
  onLabelsClick,
  onDateClick,
  onChecklistToggle,
  onHillChartToggle,
  onArchiveClick,
  onGithubBranchClick,
  onAttachmentsToggle,
  onFileUpload,
  renderMemberChip,
  AttachmentList,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  return (
    <Box sx={{ width: 280 }}>
      <Typography variant="subtitle2" sx={{ mb: 2, color: "text.secondary" }}>
        Add to card
      </Typography>

      <Box>
        <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
          Members
        </Typography>

        <Box sx={{ display: "flex", flexWrap: "wrap", mb: 1 }}>
          {card.assignee && renderMemberChip(card.assignee, "Assignee")}
          {card.reporter && renderMemberChip(card.reporter, "Reporter")}
          {card.additionalAssignees?.map((userId) =>
            renderMemberChip(userId, "Additional Assignee")
          )}
        </Box>

        <Button
          startIcon={<PersonAddIcon />}
          fullWidth
          sx={{ justifyContent: "flex-start", mb: 1 }}
          onClick={onMembersClick}
        >
          Manage Members
        </Button>
      </Box>

      <Button
        startIcon={<LabelIcon />}
        fullWidth
        sx={{
          justifyContent: "flex-start",
          mb: 1,
          color: card.labels?.length ? "primary.main" : "inherit",
        }}
        onClick={onLabelsClick}
      >
        {card.labels?.length ? `Labels (${card.labels.length})` : "Labels"}
      </Button>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle2" sx={{ mb: 2, color: "text.secondary" }}>
        Actions
      </Typography>

      <Button
        startIcon={<TimerIcon />}
        fullWidth
        sx={{
          justifyContent: "flex-start",
          mb: 1,
          color: card.dueDate ? "primary.main" : "inherit",
        }}
        onClick={onDateClick}
      >
        {card.dueDate ? (
          <>
            {card.startDate &&
              `${DateTime.fromISO(card.startDate).toFormat("MMM d")} - `}
            Due {DateTime.fromISO(card.dueDate).toFormat("MMM d, yyyy")}
          </>
        ) : (
          "Set Date Range"
        )}
      </Button>

      <Button
        startIcon={<CheckCircleIcon sx={{ color: "success.main" }} />}
        fullWidth
        sx={{
          justifyContent: "flex-start",
          mb: 1,
          color: card.checklists?.length ? "primary.main" : "inherit",
        }}
        onClick={onChecklistToggle}
      >
        {card.checklists?.length
          ? showChecklists
            ? "Hide Checklist"
            : "Show Checklist"
          : "Add Checklist"}
      </Button>

      <Button
        startIcon={<TimelineIcon />}
        fullWidth
        sx={{
          justifyContent: "flex-start",
          mb: 1,
          color: card.hillProgress !== undefined ? "primary.main" : "inherit",
        }}
        onClick={onHillChartToggle}
      >
        {card.hillProgress !== undefined
          ? showHillChart
            ? "Hide Hill Chart"
            : "Show Hill Chart"
          : "Add Hill Chart"}
      </Button>

      <Button fullWidth sx={{ justifyContent: "flex-start", mb: 1 }}>
        Move (non impl)
      </Button>

      <Button fullWidth sx={{ justifyContent: "flex-start", mb: 1 }}>
        Copy (non impl, right click card)
      </Button>

      <Button
        fullWidth
        sx={{ justifyContent: "flex-start", mb: 1, color: "error.main" }}
        onClick={onArchiveClick}
      >
        Archive
      </Button>

      <Divider sx={{ my: 2 }} />

      <Typography variant="subtitle2" sx={{ mb: 2, color: "text.secondary" }}>
        Additional
      </Typography>

      <Button
        startIcon={<GitHubIcon />}
        fullWidth
        sx={{
          justifyContent: "flex-start",
          mb: 1,
          color: card.githubBranch ? "primary.main" : "inherit",
        }}
        onClick={onGithubBranchClick}
      >
        {card.githubBranch ? `Branch: ${card.githubBranch}` : "Link GitHub Branch"}
      </Button>

      <Button
        startIcon={<AttachFileIcon />}
        fullWidth
        sx={{
          justifyContent: "flex-start",
          mb: 1,
          color: card.attachments?.length ? "primary.main" : "inherit",
        }}
        onClick={onAttachmentsToggle}
      >
        ATTACHMENTS ({card.attachments?.length || 0}) {showAttachments ? "▼" : "▶"}
      </Button>

      {showAttachments && (
        <Box sx={{ pl: 4, mb: 2 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              mb: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="body2" color="text.secondary">
                {card.attachments?.length || 0} attachments
              </Typography>
            </Box>
            <Button
              variant="contained"
              size="small"
              onClick={() => fileInputRef.current?.click()}
              disabled={isUploading}
              sx={{
                minWidth: "unset",
                px: 2,
                backgroundColor: "black",
                "&:hover": {
                  backgroundColor: "black",
                  opacity: 0.8,
                },
              }}
            >
              ADD
            </Button>
          </Box>

          <input
            type="file"
            ref={fileInputRef}
            onChange={onFileUpload}
            style={{ display: "none" }}
          />

          <AttachmentList
            attachments={card.attachments}
            onDelete={() => {}}
            onDeleteClick={() => {}}
          />
        </Box>
      )}
    </Box>
  );
};

export default CardSidebar; 