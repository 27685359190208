import React from "react";
import {
  Paper,
  Button,
  TextField,
  Grid,
  Alert,
  CircularProgress,
  IconButton,
  InputAdornment,
  Typography,
} from "@mui/material";
import {
  Lock as LockIcon,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { EmailAuthProvider } from "firebase/auth";
import { updatePassword, reauthenticateWithCredential } from "firebase/auth";
import { useAuth } from "hooks/auth/useAuth";

interface FormResponse {
  message: string;
  type: "success" | "error";
}

const passwordSchema = z
  .object({
    currentPassword: z.string().min(1, "Current password is required"),
    newPassword: z
      .string()
      .min(8, "Password must be at least 8 characters")
      .regex(/[A-Z]/, "Password must contain at least one uppercase letter")
      .regex(/[a-z]/, "Password must contain at least one lowercase letter")
      .regex(/[0-9]/, "Password must contain at least one number")
      .regex(
        /[!@#$%^&*(),.?":{}|<>]/,
        "Password must contain at least one special character"
      ),
    confirmPassword: z.string(),
  })
  .refine((data) => data.newPassword === data.confirmPassword, {
    message: "Passwords do not match",
    path: ["confirmPassword"],
  })
  .refine((data) => data.currentPassword !== data.newPassword, {
    message: "New password must be different from current password",
    path: ["newPassword"],
  });

type PasswordFormData = z.infer<typeof passwordSchema>;

export const ResetCurrentUserPassword = () => {
  const { currentUser } = useAuth();
  const [showPasswords, setShowPasswords] = React.useState({
    current: false,
    new: false,
    confirm: false,
  });
  const [response, setResponse] = React.useState<FormResponse | null>(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    reset,
  } = useForm<PasswordFormData>({
    resolver: zodResolver(passwordSchema),
    defaultValues: {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const handleTogglePasswordVisibility =
    (field: keyof typeof showPasswords) => () => {
      setShowPasswords((prev) => ({ ...prev, [field]: !prev[field] }));
    };

  const preventCopyPaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    return false;
  };

  const onSubmit = async (data: PasswordFormData) => {
    try {
      if (!currentUser || !currentUser.email) {
        setResponse({
          type: "error",
          message: "User not found. Please try logging in again.",
        });
        return;
      }

      const credential = EmailAuthProvider.credential(
        currentUser.email,
        data.currentPassword
      );

      await reauthenticateWithCredential(currentUser, credential);
      await updatePassword(currentUser, data.newPassword);

      setResponse({
        type: "success",
        message: "Password updated successfully!",
      });

      reset();
    } catch (err) {
      console.error(err);

      if (err instanceof Error && "code" in err) {
        const errorCode = (err as { code: string }).code;

        switch (errorCode) {
          case "auth/network-request-failed":
            setResponse({
              type: "error",
              message: "Incorrect current password. Please try again.",
            });
            break;
          case "auth/too-many-requests":
            setResponse({
              type: "error",
              message: "Too many attempts. Please try again later.",
            });
            break;
          default:
            setResponse({
              type: "error",
              message:
                "An error occurred while updating your password. Please try again.",
            });
        }
      } else {
        setResponse({
          type: "error",
          message: "Something went wrong. Please try again.",
        });
      }
    }
  };

  return (
    <Paper sx={{ p: 3 }}>
      <Typography
        variant="h6"
        sx={{ mb: 2, display: "flex", alignItems: "center", gap: 1 }}
      >
        <LockIcon />
        Security
      </Typography>

      {response && (
        <Alert
          severity={response.type}
          sx={{ mb: 2 }}
          onClose={() => setResponse(null)}
        >
          {response.message}
        </Alert>
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Current Password"
              type={showPasswords.current ? "text" : "password"}
              error={!!errors.currentPassword}
              helperText={errors.currentPassword?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility("current")}
                      edge="end"
                    >
                      {showPasswords.current ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register("currentPassword")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="New Password"
              type={showPasswords.new ? "text" : "password"}
              error={!!errors.newPassword}
              helperText={errors.newPassword?.message}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility("new")}
                      edge="end"
                    >
                      {showPasswords.new ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register("newPassword")}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Confirm New Password"
              type={showPasswords.confirm ? "text" : "password"}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword?.message}
              onCopy={preventCopyPaste}
              onPaste={preventCopyPaste}
              onCut={preventCopyPaste}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleTogglePasswordVisibility("confirm")}
                      edge="end"
                    >
                      {showPasswords.confirm ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register("confirmPassword")}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              startIcon={
                isSubmitting ? <CircularProgress size={20} /> : <LockIcon />
              }
            >
              Update Password
            </Button>
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default ResetCurrentUserPassword;
