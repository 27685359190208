import { getStorage, ref, getDownloadURL, getMetadata } from "firebase/storage";

// TODO: Move this to core
export const fetchProfilePictureUrl = async (
  userId: string
): Promise<string | null> => {
  if (!userId) return null;

  try {
    const storage = getStorage();
    const storageRef = ref(storage, `users/${userId}/profilePicture`);

    // First check if file exists using metadata
    try {
      await getMetadata(storageRef);
    } catch (error) {
      // File doesn't exist, return null immediately without trying download
      return null;
    }

    // File exists, get download URL
    const url = await getDownloadURL(storageRef);
    return url;

  } catch (error) {
    return null;
  }
};
