import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  doc,
  updateDoc,
  getFirestore,
} from "firebase/firestore";
import {
  Typography,
  Box,
  CircularProgress,
  Paper,
  Grid,
  Avatar,
  Chip,
  Card,
  CardContent,
  CardActions,
  Button,
  Snackbar,
  Alert,
  TextField,
  Autocomplete,
  Divider,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  InputAdornment,
  MenuItem,
  Link,
} from "@mui/material";
import { DateTime } from 'luxon';
import ViewFileDialog from "components/ViewFileDialog";
import { getProjectName } from "core/projects";
import { useDropzone } from "react-dropzone";

import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import AssignmentIcon from "@mui/icons-material/Assignment";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DescriptionIcon from "@mui/icons-material/Description";
import SearchIcon from "@mui/icons-material/Search";
import DeleteIcon from "@mui/icons-material/Delete";

import { getProjectAssignmentAgreement } from "core/projectAssignments";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { Freelancer, IndependentEngineerAgreement } from "types/user";
import { ProjectAssignment } from "types/projects";
import { UserDocument } from "types/user";

const db = getFirestore();

// Add this array of predefined skills
const predefinedSkills = [
  "JavaScript",
  "React",
  "Node.js",
  "Python",
  "Java",
  "C#",
  "Ruby",
  "PHP",
  "Swift",
  "Kotlin",
  "Go",
  "Rust",
  "TypeScript",
  "Angular",
  "Vue.js",
  "Docker",
  "Kubernetes",
  "AWS",
  "Azure",
  "GCP",
  "MongoDB",
  "PostgreSQL",
  "MySQL",
  "Redis",
  "GraphQL",
  "REST API",
  "Machine Learning",
  "Data Science",
  "DevOps",
  "CI/CD",
];

// Add this function to generate a color based on the skill name
const getSkillColor = (skill: string) => {
  let hash = 0;
  for (let i = 0; i < skill.length; i++) {
    hash = skill.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  return `hsl(${hue}, 70%, 80%)`;
};

// Add this array of predefined document types
const predefinedDocumentTypes = [
  "Independent Engineer Agreement",
  "Driver's License",
  "Passport",
  "Birth Certificate",
  "Legacy Project Assignment",
  "Legal",
  "Independent Contractor Agreement",
  "Headshot",
  "Certificate",
  "Other",
];

export const FreelancerOverview = () => {
  const { freelancerId } = useParams<{ freelancerId: string }>();
  const [freelancer, setFreelancer] = useState<Freelancer | null>(null);
  const [editedFreelancer, setEditedFreelancer] = useState<Freelancer | null>(
    null
  );
  const [projectAssignments, setProjectAssignments] = useState<
    ProjectAssignment[]
  >([]);
  const [loading, setLoading] = useState(true);
  const [openViewFileDialog, setOpenViewFileDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<{
    url: string;
    name: string;
  } | null>(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error" as "error" | "success",
  });
  const [skills, setSkills] = useState<string[]>([]);
  const [newSkill, setNewSkill] = useState<string>("");
  const [editing, setEditing] = useState(false);
  const [documents, setDocuments] = useState<UserDocument[]>([]);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [newDocument, setNewDocument] = useState<Partial<UserDocument>>({
    name: "",
    documentType: "",
    documentDate: "",
  });
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [editingDocument, setEditingDocument] = useState<string | null>(null);
  const [editedDocument, setEditedDocument] = useState<UserDocument | null>(
    null
  );
  const [documentSearchTerm, setDocumentSearchTerm] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState<UserDocument[]>(
    []
  );
  const [deleteConfirmation, setDeleteConfirmation] = useState<{
    open: boolean;
    assignment: ProjectAssignment | null;
  }>({
    open: false,
    assignment: null,
  });

  const {
    data: adminFreelancers,
    isLoading: isLoadingFreelancers,
    deleteProjectAssignment,
    isDeletingAssignment,
    uploadDocument,
    updateDocument,
    getAgreement,
    isLoadingAgreement: isLoadingEngineerAgreement,
  } = useAdminFreelancers();

  useEffect(() => {
    const initializeFreelancerData = async () => {
      if (freelancerId && adminFreelancers) {
        const freelancerWithAssignments = adminFreelancers.find(
          (f) => f.id === freelancerId
        );

        if (freelancerWithAssignments) {
          setFreelancer(freelancerWithAssignments);
          setEditedFreelancer(freelancerWithAssignments);
          setSkills(freelancerWithAssignments.skills || []);
          setProjectAssignments(freelancerWithAssignments.projectAssignments || []);
          setDocuments(freelancerWithAssignments.documents || []);
          setFilteredDocuments(freelancerWithAssignments.documents || []);

          // Fetch the engineer agreement
          getAgreement.mutate(freelancerId);
        }

        setLoading(false);
      }
    };

    initializeFreelancerData();
  }, [freelancerId, adminFreelancers]);

  useEffect(() => {
    const filtered = documents.filter((doc) =>
      Object.values(doc).some(
        (value) =>
          value &&
          value
            .toString()
            .toLowerCase()
            .includes(documentSearchTerm.toLowerCase())
      )
    );
    setFilteredDocuments(filtered);
  }, [documentSearchTerm, documents]);

  const handleEdit = () => {
    setEditing(true);
  };

  const handleCancel = () => {
    setEditedFreelancer(freelancer);
    setEditing(false);
  };

  const handleSave = async () => {
    if (editedFreelancer && freelancerId) {
      try {
        const updatedFields = {
          firstName: editedFreelancer.firstName,
          lastName: editedFreelancer.lastName,
          phone: editedFreelancer.phone,
          skills: editedFreelancer.skills,
          // Add any other fields that can be edited
        };

        await updateDoc(doc(db, "users", freelancerId), updatedFields);
        setFreelancer(editedFreelancer);
        setEditing(false);
        setSnackbar({
          open: true,
          message: "Freelancer updated successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating freelancer:", error);
        setSnackbar({
          open: true,
          message: "Error updating freelancer",
          severity: "error",
        });
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setEditedFreelancer((prev) => (prev ? { ...prev, [name]: value } : null));
  };

  const handleAddSkill = (
    event: React.KeyboardEvent<HTMLDivElement>,
    value: string | null
  ) => {
    if (event.key === "Enter" && value && !skills.includes(value)) {
      const updatedSkills = [...skills, value];
      setSkills(updatedSkills);
      updateFreelancerSkills(updatedSkills);
      setNewSkill("");
    }
  };

  const handleDeleteSkill = (skillToDelete: string) => {
    const updatedSkills = skills.filter((skill) => skill !== skillToDelete);
    setSkills(updatedSkills);
    updateFreelancerSkills(updatedSkills);
  };

  const updateFreelancerSkills = async (updatedSkills: string[]) => {
    if (freelancerId) {
      const freelancerRef = doc(db, "users", freelancerId);
      try {
        await updateDoc(freelancerRef, { skills: updatedSkills });
        setSnackbar({
          open: true,
          message: "Skills updated successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating skills:", error);
        setSnackbar({
          open: true,
          message: "Error updating skills",
          severity: "error",
        });
      }
    }
  };

  const handleViewAgreement = async (assignment: ProjectAssignment) => {
    if (!freelancerId) {
      throw new Error("Freelancer ID is missing");
    }

    try {
      const fileUrl = await getProjectAssignmentAgreement(
        assignment.clientId,
        assignment.id
      );
      if (fileUrl) {
        setSelectedFile({
          url: fileUrl,
          name: `Agreement for ${assignment.projectName}`,
        });
        setOpenViewFileDialog(true);
      } else {
        setSnackbar({
          open: true,
          message: "Agreement file not found",
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error fetching agreement file:", error);
      setSnackbar({
        open: true,
        message: "Error fetching agreement file",
        severity: "error",
      });
    }
  };

  const handleUploadDocument = async () => {
    if (
      !freelancerId ||
      !uploadFile ||
      !newDocument.name ||
      !newDocument.documentDate ||
      !newDocument.documentType
    ) {
      return;
    }

    try {
      await uploadDocument.mutateAsync({
        freelancerId,
        file: uploadFile,
        documentData: {
          name: newDocument.name,
          documentType: newDocument.documentType,
          documentDate: newDocument.documentDate,
        },
      });

      setOpenUploadDialog(false);
      setNewDocument({
        name: "",
        documentType: "",
        documentDate: "",
      });
      setUploadFile(null);
      setSnackbar({
        open: true,
        message: "Document uploaded successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error uploading document:", error);
      setSnackbar({
        open: true,
        message: "Error uploading document",
        severity: "error",
      });
    }
  };

  const handleEditDocument = (doc: UserDocument) => {
    setEditingDocument(doc.id);
    setEditedDocument(doc);
  };

  const handleDocumentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editedDocument) {
      setEditedDocument({ ...editedDocument, [e.target.name]: e.target.value });
    }
  };

  const handleUpdateDocument = async () => {
    if (editedDocument && freelancerId) {
      try {
        await updateDocument.mutateAsync({
          freelancerId,
          documentId: editedDocument.id,
          updates: {
            name: editedDocument.name,
            documentType: editedDocument.documentType,
            documentDate: editedDocument.documentDate,
          },
        });

        setEditingDocument(null);
        setEditedDocument(null);
        setSnackbar({
          open: true,
          message: "Document updated successfully",
          severity: "success",
        });
      } catch (error) {
        console.error("Error updating document:", error);
        setSnackbar({
          open: true,
          message: "Error updating document",
          severity: "error",
        });
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleUpdateDocument();
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUploadFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleDeleteAssignmentClick = (assignment: ProjectAssignment) => {
    setDeleteConfirmation({
      open: true,
      assignment,
    });
  };

  const handleDeleteAssignment = async () => {
    if (
      !deleteConfirmation.open ||
      !deleteConfirmation.assignment ||
      !freelancerId
    )
      return;

    try {
      await deleteProjectAssignment.mutateAsync({
        clientId: deleteConfirmation.assignment.clientId,
        engineerId: freelancerId,
        assignmentId: deleteConfirmation.assignment.id,
      });

      setProjectAssignments((prev) =>
        prev.filter((a) => a.id !== deleteConfirmation.assignment!.id)
      );

      setSnackbar({
        open: true,
        message: "Project assignment deleted successfully",
        severity: "success",
      });
    } catch (error) {
      console.error("Error deleting project assignment:", error);
      setSnackbar({
        open: true,
        message: "Error deleting project assignment",
        severity: "error",
      });
    } finally {
      setDeleteConfirmation({ open: false, assignment: null });
    }
  };

  const handleViewEngineerAgreement = (e: React.MouseEvent) => {
    e.preventDefault();
    const agreement = getAgreement.data;
    if (agreement?.firebaseStorageUrl) {
      setSelectedFile({
        url: agreement.firebaseStorageUrl,
        name: "Independent Engineer Agreement"
      });
      setOpenViewFileDialog(true);
    }
  };

  if (loading || isLoadingFreelancers) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!freelancer || !editedFreelancer) {
    return (
      <>
        <Typography>No freelancer found</Typography>
      </>
    );
  }

  return (
    <>
      <Box py={4}>
        <Paper elevation={3} sx={{ mb: 4 }}>
          <Box p={4} position="relative">
            {!editing ? (
              <IconButton
                onClick={handleEdit}
                sx={{ position: "absolute", top: 16, right: 16 }}
              >
                <EditIcon />
              </IconButton>
            ) : (
              <Box sx={{ position: "absolute", top: 16, right: 16 }}>
                <IconButton onClick={handleSave} sx={{ mr: 1 }}>
                  <SaveIcon />
                </IconButton>
                <IconButton onClick={handleCancel}>
                  <CancelIcon />
                </IconButton>
              </Box>
            )}
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <Box display="flex" alignItems="center" mb={2}>
                  <Avatar
                    src={freelancer.profilePicture}
                    alt={`${freelancer.firstName} ${freelancer.lastName}`}
                    sx={{ width: 80, height: 80, mr: 2 }}
                  />
                  <Box>
                    {editing ? (
                      <>
                        <TextField
                          name="firstName"
                          value={editedFreelancer.firstName}
                          onChange={handleChange}
                          variant="standard"
                          sx={{ mb: 1 }}
                        />
                        <TextField
                          name="lastName"
                          value={editedFreelancer.lastName}
                          onChange={handleChange}
                          variant="standard"
                        />
                      </>
                    ) : (
                      <Typography variant="h4">{`${freelancer.firstName} ${freelancer.lastName}`}</Typography>
                    )}
                    <Typography variant="body1" color="textSecondary">
                      {freelancer.email}
                    </Typography>
                  </Box>
                </Box>
                {editing ? (
                  <TextField
                    fullWidth
                    name="phone"
                    label="Phone"
                    value={editedFreelancer.phone}
                    onChange={handleChange}
                    margin="normal"
                  />
                ) : (
                  <>
                    <Typography variant="body1">
                      <strong>Phone:</strong> {freelancer.phone}
                    </Typography>
                    <Typography variant="body1">
                      <strong>User Type:</strong> {freelancer.role}
                    </Typography>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="h6" gutterBottom>
                  Skills
                </Typography>
                <Autocomplete
                  freeSolo
                  options={predefinedSkills}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      placeholder="Add a skill"
                      InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "2px 4px",
                          display: "flex",
                          alignItems: "center",
                          width: "200px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                        },
                      }}
                    />
                  )}
                  value={newSkill}
                  onChange={(event, value) => setNewSkill(value || "")}
                  onKeyDown={(event) => handleAddSkill(event, newSkill)}
                  sx={{ mb: 2 }}
                />
                <Box display="flex" flexWrap="wrap" gap={1}>
                  {skills.map((skill, index) => (
                    <Chip
                      key={index}
                      label={skill}
                      onDelete={() => handleDeleteSkill(skill)}
                      sx={{
                        backgroundColor: getSkillColor(skill),
                        "&:hover": {
                          backgroundColor: getSkillColor(skill),
                          filter: "brightness(0.9)",
                        },
                      }}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Paper>

        <Paper elevation={3} sx={{ mb: 4 }}>
          <Box p={4}>
            <Typography variant="h6" gutterBottom>
              Independent Engineer Agreement
            </Typography>
            
            {isLoadingEngineerAgreement ? (
              <Box display="flex" justifyContent="center" p={2}>
                <CircularProgress />
              </Box>
            ) : getAgreement.data ? (
              <Box>
                <Typography variant="body1" gutterBottom>
                  Current Agreement:
                </Typography>
                <Box display="flex" alignItems="center" gap={2}>
                  <Link 
                    href={getAgreement.data.firebaseStorageUrl} 
                    onClick={handleViewEngineerAgreement}
                    sx={{ cursor: 'pointer' }}
                  >
                    Independent Engineer Agreement
                  </Link>
                  <Typography variant="body2" color="textSecondary">
                    {getAgreement.data.sentDate && 
                      `Signed on ${DateTime.fromISO(getAgreement.data.sentDate).toFormat('LLL dd, yyyy')}`
                    }
                  </Typography>
                  {getAgreement.data.status === "signing" && (
                    <Typography variant="body2" color="warning.main">
                      (Pending Signatures)
                    </Typography>
                  )}
                </Box>
              </Box>
            ) : (
              <Alert severity="info">
                No agreement has been uploaded yet.
              </Alert>
            )}
          </Box>
        </Paper>

        <Paper
          elevation={3}
          sx={{
            mb: 3,
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "linear-gradient(45deg, #757575 30%, #9E9E9E 90%)",
            color: "white",
          }}
        >
          <Box display="flex" alignItems="center" flexGrow={1}>
            <DescriptionIcon sx={{ fontSize: 40, mr: 2 }} />
            <TextField
              placeholder="Search documents"
              variant="outlined"
              size="small"
              value={documentSearchTerm}
              onChange={(e) => setDocumentSearchTerm(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                style: { color: "white" },
              }}
              sx={{
                flexGrow: 1,
                mr: 2,
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "white",
                  },
                  "&:hover fieldset": {
                    borderColor: "white",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "white",
                  },
                },
                "& .MuiInputBase-input::placeholder": {
                  color: "white",
                  opacity: 0.7,
                },
              }}
            />
          </Box>
          <Button
            variant="contained"
            startIcon={<UploadFileIcon />}
            onClick={() => setOpenUploadDialog(true)}
            sx={{
              bgcolor: "rgba(255, 255, 255, 0.1)",
              color: "white",
              "&:hover": {
                bgcolor: "rgba(255, 255, 255, 0.2)",
              },
            }}
          >
            Upload Document
          </Button>
        </Paper>

        <TableContainer component={Paper} sx={{ mb: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Document Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Upload Date</strong>
                </TableCell>
                <TableCell>
                  <strong>Document Date</strong>
                </TableCell>
                <TableCell>
                  <strong>Document Type</strong>
                </TableCell>
                <TableCell>
                  <strong>Action</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredDocuments.map((doc) => (
                <TableRow key={doc.id}>
                  <TableCell>
                    {editingDocument === doc.id ? (
                      <TextField
                        name="name"
                        value={editedDocument?.name || ""}
                        onChange={handleDocumentChange}
                        onKeyDown={handleKeyDown}
                        autoFocus
                      />
                    ) : (
                      <span onDoubleClick={() => handleEditDocument(doc)}>
                        {doc.name}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {new Date(doc.uploadDate).toLocaleDateString()}
                  </TableCell>
                  <TableCell>
                    {editingDocument === doc.id ? (
                      <TextField
                        name="documentDate"
                        type="date"
                        value={
                          editedDocument?.documentDate?.split("T")[0] || ""
                        }
                        onChange={handleDocumentChange}
                        onKeyDown={handleKeyDown}
                        InputLabelProps={{ shrink: true }}
                      />
                    ) : (
                      <span onDoubleClick={() => handleEditDocument(doc)}>
                        {new Date(doc.documentDate).toLocaleDateString()}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {editingDocument === doc.id ? (
                      <TextField
                        select
                        name="documentType"
                        value={editedDocument?.documentType || ""}
                        onChange={handleDocumentChange}
                        onKeyDown={handleKeyDown}
                        fullWidth
                      >
                        {predefinedDocumentTypes.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <span onDoubleClick={() => handleEditDocument(doc)}>
                        {doc.documentType}
                      </span>
                    )}
                  </TableCell>
                  <TableCell>
                    {editingDocument === doc.id ? (
                      <>
                        <Button size="small" onClick={handleUpdateDocument}>
                          Save
                        </Button>
                        <Button
                          size="small"
                          onClick={() => setEditingDocument(null)}
                        >
                          Cancel
                        </Button>
                      </>
                    ) : (
                      <Button
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          setSelectedFile({ url: doc.fileUrl, name: doc.name });
                          setOpenViewFileDialog(true);
                        }}
                      >
                        View
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Paper
          elevation={3}
          sx={{
            mb: 3,
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            background: "linear-gradient(45deg, #757575 30%, #9E9E9E 90%)",
            color: "white",
          }}
        >
          <Box display="flex" alignItems="center">
            <AssignmentIcon sx={{ fontSize: 40, mr: 2 }} />
            <Typography variant="h5" component="h2">
              Project Assignments
            </Typography>
          </Box>
        </Paper>

        <Grid container spacing={3}>
          {projectAssignments.map((assignment) => (
            <Grid item xs={12} sm={6} md={4} key={assignment.id}>
              <Card>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {assignment.projectName}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Client: {assignment.clientId}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Status:</strong> {assignment.status}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Start Date:</strong>{" "}
                    {assignment.startDate
                      ? new Date(assignment.startDate).toLocaleDateString()
                      : "N/A"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>End Date:</strong>{" "}
                    {assignment.endDate
                      ? new Date(assignment.endDate).toLocaleDateString()
                      : "Ongoing"}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Billable Rate:</strong> ${assignment.billableRate}
                    /hr
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: "space-between" }}>
                  <Button
                    size="small"
                    color="primary"
                    onClick={() => handleViewAgreement(assignment)}
                  >
                    View Agreement
                  </Button>
                  <Button
                    size="small"
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => handleDeleteAssignmentClick(assignment)}
                    disabled={isDeletingAssignment}
                  >
                    Delete
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      {selectedFile && (
        <ViewFileDialog
          open={openViewFileDialog}
          onClose={() => setOpenViewFileDialog(false)}
          fileUrl={selectedFile.url}
          fileName={selectedFile.name}
        />
      )}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
      >
        <DialogTitle>Upload Engineer Document</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Document Name"
            value={newDocument.name || ""}
            onChange={(e) =>
              setNewDocument({ ...newDocument, name: e.target.value })
            }
          />
          <TextField
            fullWidth
            margin="normal"
            label="Document Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={newDocument.documentDate || ""}
            onChange={(e) =>
              setNewDocument({ ...newDocument, documentDate: e.target.value })
            }
          />
          <TextField
            select
            fullWidth
            margin="normal"
            label="Document Type"
            value={newDocument.documentType || ""}
            onChange={(e) =>
              setNewDocument({ ...newDocument, documentType: e.target.value })
            }
          >
            {predefinedDocumentTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>
          <Box
            {...getRootProps()}
            sx={{
              border: "2px dashed #cccccc",
              borderRadius: "4px",
              padding: "20px",
              textAlign: "center",
              cursor: "pointer",
              mt: 2,
            }}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the PDF file here ...</Typography>
            ) : (
              <Typography>
                Drag and drop a PDF file here, or click to select a file
              </Typography>
            )}
          </Box>
          {uploadFile && (
            <Typography sx={{ mt: 2 }}>{uploadFile.name}</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUploadDialog(false)}>Cancel</Button>
          <Button
            onClick={handleUploadDocument}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteConfirmation.open}
        onClose={() => setDeleteConfirmation({ open: false, assignment: null })}
      >
        <DialogTitle sx={{ color: "error.main" }}>
          ⚠️ Warning: Destructive Action
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" gutterBottom>
            You are about to delete the project assignment for:
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
            {deleteConfirmation.assignment?.projectName}
          </Typography>
          <Typography variant="body2" color="error" sx={{ mt: 2 }}>
            This action cannot be undone. All associated data will be
            permanently deleted.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() =>
              setDeleteConfirmation({ open: false, assignment: null })
            }
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteAssignment}
            color="error"
            variant="contained"
            disabled={isDeletingAssignment}
            startIcon={
              isDeletingAssignment ? (
                <CircularProgress size={20} />
              ) : (
                <DeleteIcon />
              )
            }
          >
            {isDeletingAssignment ? "Deleting..." : "Delete Assignment"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FreelancerOverview;
