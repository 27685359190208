import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Paper,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { portalFunctions } from "core/functions/portalFunctions";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = z.object({
  name: z.string().min(1, "Project name is required"),
  description: z.string().min(1, "Project description is required"),
  expectedStartDate: z.string().min(1, "Expected start date is required"),
  expectedDuration: z.string().min(1, "Expected duration is required"),
  budget: z.number().positive("Budget must be positive"),
  techStack: z.string().min(1, "Tech stack is required"),
  teamSize: z.number().min(1, "Team size must be at least 1"),
  additionalNotes: z.string().optional(),
});

type ProjectIdeaForm = z.infer<typeof schema>;

export const StakeholderProjectIdea = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ProjectIdeaForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      name: "",
      description: "",
      expectedStartDate: "",
      expectedDuration: "",
      budget: undefined,
      techStack: "",
      teamSize: undefined,
      additionalNotes: "",
    },
  });

  const submitMutation = useMutation({
    mutationFn: (values: ProjectIdeaForm) => {
      console.log(values);
      return Promise.resolve();
      //   portalFunctions.submitProjectIdea(values);
    },
    onSuccess: () => {
      navigate("/stakeholder/projects");
    },
  });

  const onSubmit = (data: ProjectIdeaForm) => {
    submitMutation.mutate(data);
  };

  const handleBack = () => {
    navigate("/stakeholder/projects");
  };

  return (
    <Box sx={{ p: 3 }}>
      <Button startIcon={<ArrowBackIcon />} onClick={handleBack} sx={{ mb: 3 }}>
        Back to Projects
      </Button>

      <Paper elevation={3} sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Submit a New Project Idea
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Project Name"
                    error={!!errors.name}
                    helperText={errors.name?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="description"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Project Description"
                    multiline
                    rows={4}
                    error={!!errors.description}
                    helperText={errors.description?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="expectedStartDate"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Expected Start Date"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    error={!!errors.expectedStartDate}
                    helperText={errors.expectedStartDate?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <FormControl fullWidth error={!!errors.expectedDuration}>
                <InputLabel>Expected Duration</InputLabel>
                <Controller
                  name="expectedDuration"
                  control={control}
                  render={({ field }) => (
                    <Select {...field} label="Expected Duration">
                      <MenuItem value="1-3 months">1-3 months</MenuItem>
                      <MenuItem value="3-6 months">3-6 months</MenuItem>
                      <MenuItem value="6-12 months">6-12 months</MenuItem>
                      <MenuItem value="12+ months">12+ months</MenuItem>
                    </Select>
                  )}
                />
                {errors.expectedDuration && (
                  <FormHelperText>
                    {errors.expectedDuration.message}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="budget"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <TextField
                    {...field}
                    value={value || ""}
                    onChange={(e) =>
                      onChange(
                        e.target.value ? Number(e.target.value) : undefined
                      )
                    }
                    fullWidth
                    label="Budget (USD)"
                    type="number"
                    error={!!errors.budget}
                    helperText={errors.budget?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="teamSize"
                control={control}
                render={({ field: { onChange, value, ...field } }) => (
                  <TextField
                    {...field}
                    value={value || ""}
                    onChange={(e) =>
                      onChange(
                        e.target.value ? Number(e.target.value) : undefined
                      )
                    }
                    fullWidth
                    label="Desired Team Size"
                    type="number"
                    error={!!errors.teamSize}
                    helperText={errors.teamSize?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="techStack"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Required Tech Stack"
                    error={!!errors.techStack}
                    helperText={errors.techStack?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="additionalNotes"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Additional Notes"
                    multiline
                    rows={4}
                    error={!!errors.additionalNotes}
                    helperText={errors.additionalNotes?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                disabled={submitMutation.isPending}
                sx={{ mt: 2 }}
              >
                {submitMutation.isPending ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Submit Project Idea"
                )}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default StakeholderProjectIdea;
