import React from "react";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Divider,
  IconButton,
  Tooltip,
  Button,
} from "@mui/material";
import { useNotifications } from "../../../hooks/notifications/useNotifications";
import { useAuth } from "hooks/auth/useAuth";
import { useNavigate } from "react-router-dom";
import { getIcon, getNotificationMessage } from "./getNotificationDetails";
import { handleNotificationClick } from "./handleNotificationClick";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import { DateTime } from "luxon";

export const NotificationCenter = () => {
  const navigate = useNavigate();
  const { userInfo } = useAuth();
  const { notifications, markAsRead, markAllAsRead } = useNotifications(
    userInfo?.id || ""
  );

  const sortedNotifications = notifications.sort((a, b) => {
    const dateA = new Date(a.createdAt).getTime();
    const dateB = new Date(b.createdAt).getTime();
    return dateB - dateA;
  });

  const unreadCount = sortedNotifications.filter((n) => !n.read).length;

  return (
    <Box sx={{ padding: 3, maxWidth: 800, margin: "0 auto" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Typography variant="h4">
          Notifications
          {unreadCount > 0 && (
            <Typography component="span" sx={{ ml: 2, color: "primary.main" }}>
              ({unreadCount} unread)
            </Typography>
          )}
        </Typography>
        {unreadCount > 0 && (
          <Button
            startIcon={<DoneAllIcon />}
            onClick={() => markAllAsRead.mutate()}
            disabled={markAllAsRead.isPending}
            variant="outlined"
            size="small"
          >
            Mark all as read
          </Button>
        )}
      </Box>
      <List>
        {sortedNotifications.length > 0 ? (
          sortedNotifications.map((notification) => {
            const destination = handleNotificationClick(notification);
            const isClickable = !!destination;

            return (
              <ListItem
                key={notification.id}
                sx={{
                  padding: "16px",
                  cursor: isClickable ? "pointer" : "default",
                  backgroundColor: notification.read
                    ? "transparent"
                    : "action.hover",
                  borderRadius: 1,
                  mb: 1,
                  transition: "all 0.2s ease",
                  "&:hover": isClickable
                    ? {
                        backgroundColor: "action.selected",
                      }
                    : {},
                }}
                onClick={() => {
                  if (destination) {
                    navigate(destination);
                  }
                }}
              >
                <ListItemIcon>
                  <Avatar
                    sx={{
                      bgcolor: notification.read ? "grey.300" : "primary.main",
                      transition: "background-color 0.2s ease",
                    }}
                  >
                    {React.createElement(getIcon(notification.type) || "div")}
                  </Avatar>
                </ListItemIcon>
                <ListItemText
                  primary={getNotificationMessage(notification)}
                  secondary={
                    <Typography variant="caption" color="text.secondary">
                      {DateTime.fromISO(notification.createdAt as string).toLocaleString(DateTime.DATE_SHORT)}  
                    </Typography>
                  }
                  primaryTypographyProps={{
                    sx: {
                      fontWeight: notification.read ? "regular" : "medium",
                      color: notification.read
                        ? "text.secondary"
                        : "text.primary",
                    },
                  }}
                />
                {!notification.read && (
                  <Tooltip title="Mark as read">
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        if (notification.id) {
                          markAsRead.mutate(notification.id);
                        }
                      }}
                      size="small"
                      sx={{ ml: 1 }}
                    >
                      <VisibilityIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItem>
            );
          })
        ) : (
          <Typography
            sx={{
              padding: "32px",
              textAlign: "center",
              color: "text.secondary",
              bgcolor: "action.hover",
              borderRadius: 1,
            }}
          >
            No notifications
          </Typography>
        )}
      </List>
    </Box>
  );
};
