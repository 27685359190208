import { useState, useEffect, useCallback } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { initializeWebSocket } from "core/websocket/initializeWebSocket";
import { registerUser } from "core/websocket/registerUser";
import { checkUsersStatus } from "core/websocket/checkUsersStatus";
import { logUserActivity } from "core/websocket/logUserActivity";

interface OnlineStatusMap {
  [userId: string]: {
    online: boolean;
    activity?: UserActivity;
    lastPing?: number;
    userRole?: string;
    currentPage?: string;
  };
}

interface UserActivity {
  userId: string;
  userName?: string;
  lastPage?: string;
  lastSeen: number;
  action?: string;
}

export const useOnlineUsers = (userIdsToTrack?: string[]) => {
  const { userInfo, currentUser } = useAuth();
  const { data: users } = usePublicUserList();
  const [onlineStatus, setOnlineStatus] = useState<OnlineStatusMap>({});
  const [socket, setSocket] = useState<any>(null);

  useEffect(() => {
    const initSocket = async () => {
      const token = await currentUser?.getIdToken();
      if (!token) return;
      const newSocket = await initializeWebSocket(token);
      setSocket(newSocket);
    };
    initSocket();
  }, [currentUser]);

  useEffect(() => {
    if (!userInfo?.id || !socket) {
      return;
    }

    registerUser(userInfo.id);

    // Add heartbeat handlers
    socket.on('ping', () => {
      socket.emit('pong');
      setOnlineStatus((prev) => ({
        ...prev,
        [userInfo.id]: {
          ...prev[userInfo.id],
          online: true,
          lastPing: Date.now(),
        },
      }));
    });

    // Connection event handlers
    socket.on('connect', () => {
      
      setOnlineStatus((prev) => ({
        ...prev,
        [userInfo.id]: {
          ...prev[userInfo.id],
          online: true,
          lastPing: Date.now(),
        },
      }));
    });

    socket.on('disconnect', (reason: string) => {
      
      setOnlineStatus((prev) => ({
        ...prev,
        [userInfo.id]: {
          ...prev[userInfo.id],
          online: false,
          lastPing: Date.now(),
        },
      }));
    });

    socket.on(
      "userStatus",
      ({ userId, status }: { userId: string; status: string }) => {
        setOnlineStatus((prev) => ({
          ...prev,
          [userId]: {
            ...prev[userId],
            online: status === "online",
            lastPing: status === "online" ? Date.now() : prev[userId]?.lastPing,
          },
        }));
      }
    );

    socket.on("initialUsers", (userIds: string[]) => {
      const timestamp = Date.now();
      const initialStatuses = userIds.reduce(
        (acc, userId) => ({
          ...acc,
          [userId]: {
            online: true,
            lastPing: timestamp,
            activity: onlineStatus[userId]?.activity,
          },
        }),
        {} as OnlineStatusMap
      );

      setOnlineStatus((prev) => {
        const newStatus = {
          ...prev,
          ...initialStatuses,
        };
        return newStatus;
      });
    });

    socket.on(
      "userPing",
      ({
        userId,
        timestamp,
        userRole,
        currentPage,
      }: {
        userId: string;
        timestamp: number;
        userRole: string;
        currentPage: string;
      }) => {
        setOnlineStatus((prev) => ({
          ...prev,
          [userId]: {
            ...prev[userId],
            online: true,
            lastPing: timestamp,
            userRole,
            currentPage,
          },
        }));
      }
    );

    // Listen for user activity updates
    socket.on("userActivity", (activity: UserActivity) => {
      const timestamp = Date.now();
      setOnlineStatus((prev) => ({
        ...prev,
        [activity.userId]: {
          ...prev[activity.userId],
          online: true,
          lastPing: timestamp,
          activity: {
            ...activity,
            lastSeen: timestamp,
            userName: users?.find((u) => u.id === activity.userId)?.displayName,
          },
        },
      }));
    });

    if (userIdsToTrack?.length) {
      checkUsersStatus(userIdsToTrack).then((statuses) => {
        const newStatus: OnlineStatusMap = {};
        statuses.forEach(({ userId, online }) => {
          newStatus[userId] = {
            online,
            lastPing: online ? Date.now() : undefined,
            activity: onlineStatus[userId]?.activity,
          };
        });
        setOnlineStatus((prev) => ({
          ...prev,
          ...newStatus,
        }));
      });
    }

    return () => {
      socket.off('ping');
      socket.off('connect');
      socket.off('disconnect');
      socket.off("userStatus");
      socket.off("userActivity");
      socket.off("userPing");
      socket.off("initialUsers");
    };
  }, [userInfo?.id, socket]);

  const checkStatus = useCallback(
    async (userIds: string[]) => {
      const statuses = await checkUsersStatus(userIds);
      const newStatus: OnlineStatusMap = {};
      statuses.forEach(({ userId, online }) => {
        newStatus[userId] = {
          online,
          lastPing: online ? Date.now() : undefined,
          activity: onlineStatus[userId]?.activity,
        };
      });
      setOnlineStatus((prev) => ({
        ...prev,
        ...newStatus,
      }));
      return statuses;
    },
    [onlineStatus]
  );

  const logActivity = useCallback(
    (page: string, action?: string) => {
      if (!userInfo?.id) return;

      const activity = {
        userId: userInfo.id,
        page,
        timestamp: Date.now(),
        action,
      };

      logUserActivity(activity);
    },
    [userInfo?.id]
  );

  const isUserOnline = useCallback((userId: string) => {
    const status = onlineStatus[userId];
    if (!status) return false;

    // Reduced timeout to 90 seconds (30s heartbeat + buffer)
    const heartbeatTimeout = Date.now() - 90 * 1000;
    return status.online && status.lastPing && status.lastPing > heartbeatTimeout;
  }, [onlineStatus]);

  return {
    onlineStatus,
    checkStatus,
    logActivity,
    isUserOnline,
    getUserActivity: (userId: string) => onlineStatus[userId]?.activity,
    getLastPing: (userId: string) => onlineStatus[userId]?.lastPing,
    getAllUserActivities: () =>
      Object.entries(onlineStatus).reduce(
        (acc, [userId, data]) => {
          if (data.activity) {
            acc[userId] = {
              ...data.activity,
              lastSeen: data.lastPing || data.activity.lastSeen,
            };
          }
          return acc;
        },
        {} as Record<string, UserActivity>
      ),
  };
};
