import { functionsBaseUrl } from "config/functionsBaseUrl";
import { getHeaders } from "../getHeaders";
import { IncreaseExternalAccountDetails } from "types/bank";
import { encryptPayload } from "../security/encryptPayload";
import { FreelancerPayment } from "types/bank";

export const bankFunctions = {
  admin: {
    createExternalAccountOutboundTestPayment: async (
      userId: string,
      amount: number,
      memo: string,
      paymentDate: string,
      direction: "outbound" | "inbound"
    ) => {
      const url = `${functionsBaseUrl}/bank/admin/createExternalAccountOutboundTestPayment`;
      const method = "POST";

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
          amount: amount,
          memo: memo,
          paymentDate: paymentDate,
          direction: direction,
        }),
      });
      const data = await response.json();
      return data;
    },
    createFreelancerPayment: async (
      userId: string,
      freelancerPayment: Omit<FreelancerPayment, "id">
    ) => {
      const url = `${functionsBaseUrl}/bank/admin/createFreelancerPayment`;
      const method = "POST";

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
          freelancerPayment: freelancerPayment,
        }),
      });
      const data = await response.json();
      return data;
    },
    resubmitAchPrenotification: async (userId: string) => {
      const url = `${functionsBaseUrl}/bank/admin/resubmitAchPrenotification`;
      const method = "POST";

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
        }),
      });
      const data = await response.json();
      return data;
    },
  },

  freelancer: {
    createFreelancerPaymentProfile: async (
      userId: string,
      privatePaymentProfile: Partial<IncreaseExternalAccountDetails> & {
        nickname: string;
      }
    ): Promise<{ success: boolean; error?: string }> => {
      const url = `${functionsBaseUrl}/bank/freelancer/createMyFreelancerPaymentProfile`;
      const method = "POST";

      const encryptedPayload = await encryptPayload(privatePaymentProfile);

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
          encryptedPayload: encryptedPayload,
        }),
      });

      

      const data = await response.json();
      return data;
    },
    getMyFreelancerPaymentProfile:
      async (): Promise<IncreaseExternalAccountDetails> => {
        const url = `${functionsBaseUrl}/bank/freelancer/getMyFreelancerPaymentProfile`;
        const method = "GET";

        const response = await fetch(url, {
          method: method,
          headers: await getHeaders(),
        });
        const data = await response.json();
        return data;
      },

    updateFreelancerPaymentProfile: async (
      userId: string,
      privatePaymentProfile: Partial<IncreaseExternalAccountDetails> & {
        nickname?: string;
      }
    ) => {
      const url = `${functionsBaseUrl}/bank/freelancer/updateMyFreelancerPaymentProfile`;
      const method = "POST";

      const encryptedPayload = await encryptPayload(privatePaymentProfile);

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
          encryptedPayload: encryptedPayload,
        }),
      });
      const data = await response.json();
      return data;
    },
  },

  stakeholder: {
    createStakeholderPaymentProfile: async (
      userId: string,
      privatePaymentProfile: Partial<IncreaseExternalAccountDetails> & {
        nickname: string;
      }
    ) => {
      const url = `${functionsBaseUrl}/bank/stakeholder/createMyStakeHolderPaymentProfile`;
      const method = "POST";

      const encryptedPayload = await encryptPayload(privatePaymentProfile);

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
          encryptedPayload: encryptedPayload,
        }),
      });
      const data = await response.json();
      return data;
    },
    getMyStakeholderPaymentProfile:
      async (): Promise<IncreaseExternalAccountDetails> => {
        const url = `${functionsBaseUrl}/bank/stakeholder/getMyStakeHolderPaymentProfile`;
        const method = "GET";

        const response = await fetch(url, {
          method: method,
          headers: await getHeaders(),
        });
        const data = await response.json();
        return data;
      },
    payMyStakeholderInvoice: async (clientId: string, invoiceId: string) => {
      const url = `${functionsBaseUrl}/bank/stakeholder/payMyStakeholderInvoice`;
      const method = "POST";

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          clientId: clientId,
          invoiceId: invoiceId,
        }),
      });
      const data = await response.json();
      return data;
    },
    updateStakeholderPaymentProfile: async (
      userId: string,
      privatePaymentProfile: Partial<IncreaseExternalAccountDetails> & {
        nickname?: string;
      }
    ) => {
      const url = `${functionsBaseUrl}/bank/stakeholder/updateMyStakeHolderPaymentProfile`;
      const method = "POST";

      const encryptedPayload = await encryptPayload(privatePaymentProfile);

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
          encryptedPayload: encryptedPayload,
        }),
      });
      const data = await response.json();
      return data;
    },
  },
  shared: {
    getAchPrenotificationStatus: async (userId: string) => {
      const url = `${functionsBaseUrl}/bank/freelancer/getAchPrenotificationStatus`;
      const method = "POST";

      const response = await fetch(url, {
        method: method,
        headers: await getHeaders(),
        body: JSON.stringify({
          userId: userId,
        }),
      });
      const data = await response.json();
      return data;
    },
  },
};
