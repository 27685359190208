import React, { useState, useMemo } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Grid,
  IconButton,
  Tooltip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import DownloadIcon from "@mui/icons-material/Download";
import { useAdminTimesheet } from "../../../hooks/admin/useAdminTimesheet";
import TimesheetExplorerGrid from "./components/TimesheetExplorerGrid";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { useProjectsNonAdmin } from "hooks/project/useProjectsNonAdmin";
import { ClientFreelancer } from "types/projects";
import { Project } from "types/projects";
import { Freelancer } from "types/user";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const TimesheetExplorer: React.FC = () => {
  const [startDate, setStartDate] = useState<DateTime>(
    DateTime.now().startOf("month")
  );
  const [endDate, setEndDate] = useState<DateTime>(
    DateTime.now().endOf("month")
  );
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedClients, setSelectedClients] = useState<string[]>([]);
  const [selectedEngineers, setSelectedEngineers] = useState<string[]>([]);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [selectedBillableStatus, setSelectedBillableStatus] = useState<boolean | null>(null);

  const { getAllTimesheets } = useAdminTimesheet();
  const { data: fetchedTimesheets = [], isLoading: loading } =
    getAllTimesheets();
  const { data: freelancers = [] } = useAdminFreelancers();
  const { data: allProjects = [] } = useProjectsNonAdmin();

  const uniqueClients = useMemo(() => {
    const clients = new Set(
      fetchedTimesheets.map((t) => t.clientName).filter(Boolean)
    );
    return Array.from(clients).sort();
  }, [fetchedTimesheets]);

  const selectedEngineerObjects = useMemo(() => {
    return freelancers.filter((f) => selectedEngineers.includes(f.email));
  }, [freelancers, selectedEngineers]);

  const filteredTimesheets = useMemo(() => {
    return fetchedTimesheets.filter((timesheet) => {
      const timesheetDate = DateTime.fromISO(timesheet.date);

      const matchesDateRange =
        timesheetDate >= startDate.startOf("day") &&
        timesheetDate <= endDate.endOf("day");

      const matchesSearch =
        (timesheet.userName?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        ) ||
        (timesheet.projectName?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        ) ||
        (timesheet.clientName?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        ) ||
        (timesheet.description?.toLowerCase() || "").includes(
          searchTerm.toLowerCase()
        );

      const matchesClient =
        selectedClients.length === 0 ||
        (timesheet.clientName &&
          selectedClients.includes(timesheet.clientName));

      const matchesEngineer =
        selectedEngineers.length === 0 ||
        (timesheet.userEmail &&
          selectedEngineers.includes(timesheet.userEmail));

      const matchesProject =
        selectedProjects.length === 0 ||
        selectedProjects.includes(timesheet.projectId || "");

      const matchesBillable =
        selectedBillableStatus === null || timesheet.billable === selectedBillableStatus;

      return (
        matchesDateRange &&
        matchesSearch &&
        matchesClient &&
        matchesEngineer &&
        matchesProject &&
        matchesBillable
      );
    });
  }, [
    fetchedTimesheets,
    startDate,
    endDate,
    searchTerm,
    selectedClients,
    selectedEngineers,
    selectedProjects,
    selectedBillableStatus,
  ]);

  const billableFilter = (
    <FormControl size="small" fullWidth>
      <InputLabel>Billable Status</InputLabel>
      <Select
        value={selectedBillableStatus === null ? "" : String(selectedBillableStatus)}
        onChange={(e) => {
          const value = e.target.value;
          setSelectedBillableStatus(value === "" ? null : value === "true");
        }}
        label="Billable Status"
      >
        <MenuItem value="">All</MenuItem>
        <MenuItem value="true">Billable</MenuItem>
        <MenuItem value="false">Non-billable</MenuItem>
      </Select>
    </FormControl>
  );

  const handleExport = () => {
    // TODO: Implement export functionality
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        display: "flex",
        flexDirection: "column",
        width: { xs: "100vw", md: "100%" },
        boxSizing: "border-box",
      }}
    >
      {/* Main Container */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          p: { xs: 1, sm: 2 },
          flex: "1 1 auto",
          minHeight: 0,
          overflow: "auto",
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        {/* Filters Section */}
        <Paper
          elevation={3}
          sx={{
            p: { xs: 4, sm: 2 },
            width: { xs: "90vw", md: "100%" },
            mr: { xs: -2, sm: 0 },
            boxSizing: "border-box",
            "& > *": {
              maxWidth: "100%",
            },
          }}
        >
          {/* Input Container */}
          <Box
            sx={{
              width: { xs: "70vw", md: "100%" },
              maxWidth: { xs: "400px", md: "800px" },
              margin: "0 auto",
              display: "flex",
              flexDirection: "column",
              gap: 1.5,
              boxSizing: "border-box",
              px: { xs: 1, sm: 2 },
            }}
          >
            {/* Date Range Row */}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: { xs: "1fr", sm: "1fr 1fr" },
                gap: 1.5,
                width: "100%",
              }}
            >
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => newValue && setStartDate(newValue)}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    sx: { maxWidth: "100%" },
                  },
                }}
              />
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => newValue && setEndDate(newValue)}
                slotProps={{
                  textField: {
                    size: "small",
                    fullWidth: true,
                    sx: { maxWidth: "100%" },
                  },
                }}
              />
            </Box>

            {/* Filter Inputs */}
            <Autocomplete<Freelancer, true>
              multiple
              size="small"
              options={freelancers}
              getOptionLabel={(option) =>
                `${option.firstName} ${option.lastName}`
              }
              value={selectedEngineerObjects}
              onChange={(_, newValue) => {
                setSelectedEngineers(newValue.map((v) => v.email));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Filter by Engineer" />
              )}
              isOptionEqualToValue={(option, value) =>
                option.email === value.email
              }
            />

            <Autocomplete<string, true>
              multiple
              size="small"
              options={uniqueClients.filter(
                (client): client is string => client != null
              )}
              value={selectedClients}
              onChange={(_, newValue) => {
                setSelectedClients(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} label="Filter by Client" />
              )}
            />

            <Autocomplete<Project, true>
              multiple
              size="small"
              options={allProjects}
              getOptionLabel={(option) => option.name}
              value={allProjects.filter((p) => selectedProjects.includes(p.id))}
              onChange={(_, newValue) => {
                setSelectedProjects(newValue.map((v) => v.id));
              }}
              renderInput={(params) => (
                <TextField {...params} label="Filter by Project" />
              )}
            />

            {billableFilter}

            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
            />
          </Box>
        </Paper>

        {/* Grid Section */}
        <Box
          sx={{
            flex: "1 1 auto",
            minHeight: 0,
            overflow: "auto",
            width: { xs: "90vw", md: "100%" },
          }}
        >
          <TimesheetExplorerGrid
            timesheets={filteredTimesheets}
            loading={loading}
            startDate={startDate}
            endDate={endDate}
            selectedClients={selectedClients}
            selectedEngineers={selectedEngineers}
            selectedProjects={selectedProjects}
            selectedBillableStatus={selectedBillableStatus}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default TimesheetExplorer;
