import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Button,
  CircularProgress,
  Card,
  CardContent,
  Divider,
  Chip,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Link,
  FormControlLabel,
  Tooltip,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { useAuth } from "hooks/auth/useAuth";
import { Client, Project } from "types/projects";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import { useStakeholderOnboarding } from "hooks/stakeholder/useStakeholderOnboarding";
interface ReviewStepProps {
  stepData: any[];
  onNext: () => Promise<void>;
  onBack: () => void;
  isUpdating: boolean;
}

//change

export const ReviewStep: React.FC<ReviewStepProps> = ({
  stepData,
  onNext,
  onBack,
  isUpdating,
}) => {
  const navigate = useNavigate();
  const { paymentProfile } = useStakeholderPayments();
  const { refreshUser, currentUser } = useAuth();
  const { completeStakeholderOnboarding } = useStakeholderOnboarding();
  const { userInfo } = useAuth();
  const { clients, clientsIsLoading } = useStakeholder(userInfo?.id);
  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsRead, setTermsRead] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const handleOpenTerms = async () => {
    if (!pdfUrl) {
      const storage = getStorage();
      const pdfRef = ref(storage, "terms.pdf");
      const url = await getDownloadURL(pdfRef);
      setPdfUrl(url);
    }
    setTermsDialogOpen(true);
  };

  console.log("Review Step Data:", stepData);

  const personalInfo = stepData[0];
  const companyInfo = stepData[1];
  const paymentInfo = stepData[3];

  const renderInfoCard = (
    title: string,
    content: React.ReactNode,
    icon?: React.ReactNode
  ) => (
    <Card elevation={0} sx={{ height: "100%", bgcolor: "background.default" }}>
      <CardContent>
        <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 2 }}>
          {icon}
          <Typography variant="h6" color="primary">
            {title}
          </Typography>
        </Stack>
        <Divider sx={{ mb: 2 }} />
        {content}
      </CardContent>
    </Card>
  );

  const renderPersonalInfo = () => {
    if (!personalInfo) return null;
    const { firstName, lastName, phone, email } = personalInfo;

    return renderInfoCard(
      "Personal Information",
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary" display="block">
            Full Name
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "medium" }}>
            {firstName} {lastName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary" display="block">
            Email
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "medium" }}>
            {email}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary" display="block">
            Phone
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "medium" }}>
            {phone}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderCompanyInfo = () => {
    if (!clients || clients.length === 0) return null;

    return renderInfoCard(
      "Company Information",
      <Box>
        {clients.map((client: Client) => (
          <Box key={client.id}>
            <Grid container spacing={2} sx={{ mb: 3 }}>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                >
                  Company Name
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                  {client.companyName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                >
                  Point of Contact
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                  {client.pointOfContactName}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                >
                  Email
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                  {client.email}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                >
                  Phone
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                  {client.phone}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                >
                  Location
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                  {client.location}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography
                  variant="caption"
                  color="text.secondary"
                  display="block"
                >
                  Company Size
                </Typography>
                <Typography variant="body1" sx={{ fontWeight: "medium" }}>
                  {client.size}
                </Typography>
              </Grid>
            </Grid>

            {client.projects && client.projects.length > 0 && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" color="primary" gutterBottom>
                  Projects
                </Typography>
                {client.projects.map((project: Project) => (
                  <Accordion key={project.id}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="subtitle2">
                        {project.name}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            display="block"
                          >
                            Description
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{
                              bgcolor: "background.paper",
                              p: 2,
                              borderRadius: 1,
                              borderLeft: "4px solid",
                              borderColor: "primary.main",
                            }}
                          >
                            {project.description}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            display="block"
                          >
                            Project Type
                          </Typography>
                          <Chip
                            label={project.projectType}
                            color="primary"
                            size="small"
                            sx={{ mt: 0.5 }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            display="block"
                          >
                            Duration
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "medium" }}
                          >
                            {project.duration}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            display="block"
                          >
                            Budget Range
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "medium" }}
                          >
                            {project.budget}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography
                            variant="caption"
                            color="text.secondary"
                            display="block"
                          >
                            Start Date
                          </Typography>
                          <Typography
                            variant="body1"
                            sx={{ fontWeight: "medium" }}
                          >
                            {project.startDate
                              ? new Date(project.startDate).toLocaleDateString()
                              : "Not set"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Box>
            )}
            <Divider sx={{ my: 3 }} />
          </Box>
        ))}
      </Box>
    );
  };

  const renderPaymentInfo = () => {
    if (!paymentProfile) return null;

    return renderInfoCard(
      "Payment Information",
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary" display="block">
            Account Type
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "medium" }}>
            {paymentProfile.accountHolderType === "individual"
              ? "Individual Account"
              : "Business Account"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary" display="block">
            Account Holder
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "medium" }}>
            {paymentProfile.accountHolderFullName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary" display="block">
            Account Number
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "medium" }}>
            {paymentProfile.accountNumber
              ? "••••" + paymentProfile.accountNumber.slice(-4)
              : "Not provided"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="caption" color="text.secondary" display="block">
            Routing Number
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: "medium" }}>
            {paymentProfile.routingNumber || "Not provided"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" color="text.secondary" display="block">
            Account Status
          </Typography>
          <Chip
            label={
              paymentProfile.funding
                ? paymentProfile.funding.charAt(0).toUpperCase() +
                  paymentProfile.funding.slice(1)
                : "Pending"
            }
            color="primary"
            size="small"
            sx={{ mt: 0.5 }}
          />
        </Grid>
      </Grid>
    );
  };

  const handleComplete = async () => {
    try {
      await completeStakeholderOnboarding();
      if (!currentUser) {
        throw new Error("User not found");
      }
      await refreshUser(currentUser);
      // Only navigate if both operations complete successfully
      navigate("/");
    } catch (error) {
      console.error("Error completing setup:", error);
    }
  };

  if (clientsIsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Review Your Information
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        Please review all the information below before completing your setup.
      </Typography>

      <Grid container spacing={3}>
        {personalInfo && (
          <Grid item xs={12}>
            {renderPersonalInfo()}
          </Grid>
        )}
        <Grid item xs={12}>
          {renderCompanyInfo()}
        </Grid>
        {paymentProfile && (
          <Grid item xs={12}>
            {renderPaymentInfo()}
          </Grid>
        )}
        <Grid item xs={12}>
          <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
            <FormControlLabel
              control={
                <Tooltip
                  title={
                    !termsRead
                      ? "Please review the Terms and Conditions first"
                      : ""
                  }
                  placement="top"
                >
                  <span>
                    <Checkbox
                      checked={termsAccepted}
                      onChange={(e) => setTermsAccepted(e.target.checked)}
                      disabled={!termsRead}
                    />
                  </span>
                </Tooltip>
              }
              label={
                <Typography variant="body2">
                  I have read and agree to the{" "}
                  <Link
                    component="button"
                    variant="body2"
                    onClick={handleOpenTerms}
                  >
                    Terms and Conditions
                  </Link>
                </Typography>
              }
            />
          </Box>
        </Grid>
      </Grid>

      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Button onClick={onBack} disabled={isUpdating}>
          Back
        </Button>
        <Button
          variant="contained"
          onClick={handleComplete}
          disabled={isUpdating || !termsAccepted}
          startIcon={isUpdating ? <CircularProgress size={20} /> : null}
        >
          {isUpdating ? "Completing..." : "Complete Setup"}
        </Button>
      </Box>

      <Dialog
        open={termsDialogOpen}
        onClose={() => setTermsDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent>
          {pdfUrl && (
            <Box sx={{ width: "100%", height: "70vh" }}>
              <iframe
                src={`${pdfUrl}#view=FitH`}
                width="100%"
                height="100%"
                title="Terms and Conditions"
                onLoad={() => setTermsRead(true)}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTermsDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReviewStep;
