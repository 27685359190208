import { useState } from "react";
import { createLabel, updateCardLabels, deleteLabel } from "core/freelo/label";
import { CardLabel } from "types/freelo";

export const useLabelOperations = (boardId: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleCreateLabel = async (
    name: string,
    color: string
  ): Promise<string> => {
    setIsLoading(true);
    setError(null);
    try {
      const labelId = await createLabel({ boardId, name, color });
      return labelId;
    } catch (err) {
      setError("Failed to create label");
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateCardLabels = async (
    cardId: string,
    labels: CardLabel[]
  ): Promise<void> => {
    setIsLoading(true);
    setError(null);
    try {
      await updateCardLabels({ boardId, cardId, labels });
    } catch (err) {
      setError("Failed to update card labels");
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteLabel = async (labelId: string): Promise<void> => {
    setIsLoading(true);
    setError(null);
    try {
      await deleteLabel({ boardId, labelId });
    } catch (err) {
      setError("Failed to delete label");
      throw err;
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    error,
    createLabel: handleCreateLabel,
    updateCardLabels: handleUpdateCardLabels,
    deleteLabel: handleDeleteLabel,
  };
};
