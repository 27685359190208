// Get environment from NODE_ENV, defaulting to development
const nodeEnv = process.env.NODE_ENV;
const freeTechEnv = process.env.VITE_FREETECH_ENV;
let functionsEnv: string = nodeEnv;


console.log(freeTechEnv);

if (window.location.hostname === "localhost") {
  if (freeTechEnv === "staging") {
    functionsEnv = "test";
  } else {
    functionsEnv = "development";
  }
} else if (window.location.hostname.includes("stg")) {
  functionsEnv = "staging";
} else {
  functionsEnv = "production";
}

// Define base URLs for each environment
const envUrls = {
  production: "https://us-central1-freetech-production.cloudfunctions.net",
  staging: "https://us-central1-freetech-stg.cloudfunctions.net",
  development: "http://127.0.0.1:5001/freetech-production/us-central1",
  test: "http://127.0.0.1:5001/freetech-stg/us-central1",
};

// Export the appropriate URL based on environment
export const functionsBaseUrl =
  envUrls[functionsEnv as keyof typeof envUrls] || envUrls.development;
