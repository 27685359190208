import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { portalFunctions } from "core/functions/portalFunctions";

export const cancelIndependentEngineerAgreement = async (
  userId: string,
  agreementId: string,
  documentHash: string
): Promise<boolean> => {
  const agreementRef = doc(
    db,
    "users",
    userId,
    "independentEngineerAgreements",
    agreementId
  );

  const cancelDocumentResponse = await portalFunctions.eversign.cancelDocument(
    documentHash
  );

  const cancelDocumentData = await cancelDocumentResponse.json();

  if (!cancelDocumentData.success) {
    throw new Error("Failed to cancel document");
  }

  await updateDoc(agreementRef, {
    everSignDocumentStatus: "cancelled",
    status: "cancelled",
  });

  return true;
};
