import React, { memo } from 'react';
import { Box, Paper, Typography, Grid } from '@mui/material';
import { LineChart, PieChart, BarChart } from '@mui/x-charts';
import { useFreelancerHomeStatistics } from "hooks/freelancer/useFreelancerHomeStatistics";

interface TimeTrackingChartsProps {
  selectedYear: number;
}

const TimeTrackingCharts: React.FC<TimeTrackingChartsProps> = memo(({ selectedYear }) => {
  const { stats } = useFreelancerHomeStatistics(selectedYear);

  // Prepare data for pie chart
  const pieChartData = Object.entries(stats.hoursPerClient).map(([clientId, hours]) => ({
    id: clientId,
    value: hours,
    label: clientId,
  }));

  // Prepare data for status pie chart
  const statusPieData = [
    { id: 'approved', value: stats.approvedHours, label: 'Approved' },
    { id: 'submitted', value: stats.submittedHours, label: 'Submitted' },
    { id: 'pending', value: stats.pendingHours, label: 'Pending' },
  ];

  return (
    <Box sx={{ mt: 4 }}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Monthly Hours Distribution
            </Typography>
            <LineChart
              height={300}
              series={[
                {
                  data: stats.monthlyHours.map(item => item.hours),
                  area: true,
                  label: 'Total Hours',
                  color: '#2196f3',
                },
                {
                  data: stats.monthlyHours.map(item => item.approvedHours),
                  area: true,
                  label: 'Approved Hours',
                  color: '#4caf50',
                },
                {
                  data: stats.monthlyHours.map(item => item.submittedHours),
                  area: true,
                  label: 'Submitted Hours',
                  color: '#ff9800',
                },
              ]}
              xAxis={[{
                data: stats.monthlyHours.map(item => item.month),
                scaleType: 'band',
              }]}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Hours by Client
            </Typography>
            <PieChart
              series={[
                {
                  data: pieChartData,
                  highlightScope: { faded: 'global', highlighted: 'item' },
                  faded: { innerRadius: 30, additionalRadius: -30 },
                },
              ]}
              height={300}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={2} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Hours by Status
            </Typography>
            <PieChart
              series={[
                {
                  data: statusPieData,
                  highlightScope: { faded: 'global', highlighted: 'item' },
                  faded: { innerRadius: 30, additionalRadius: -30 },
                },
              ]}
              height={300}
              colors={['#4caf50', '#ff9800', '#f44336']}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
});

TimeTrackingCharts.displayName = 'TimeTrackingCharts';

export default TimeTrackingCharts; 