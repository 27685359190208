import { ProjectAssignment } from "types/projects";

export interface AdminProjectAssignmentStatusUpdatedProps {
  projectAssignment: ProjectAssignment;
}

export const adminProjectAssignmentStatusUpdated = (
  params: AdminProjectAssignmentStatusUpdatedProps
) => {
  const { projectAssignment } = params;
  return `
    <h1>Project Assignment Status Updated</h1>
    <p>Hello Admin,</p>
    <p>The status of a project assignment has been updated. Here are the details:</p>
    <ul>
      <li><strong>Project Name:</strong> ${projectAssignment.projectName || "N/A"}</li>
      <li><strong>Freelancer Name:</strong> ${projectAssignment.freelancerName || "N/A"}</li>
      <li><strong>Client Name:</strong> ${projectAssignment.clientName || "N/A"}</li>
      <li><strong>New Status:</strong> ${projectAssignment.status}</li>
      <li><strong>Start Date:</strong> ${projectAssignment.startDate || "Not set"}</li>
      <li><strong>End Date:</strong> ${projectAssignment.endDate || "Not set"}</li>
    </ul>
    <p>Please review this update and take any necessary actions.</p>
    <p>Thank you for your attention to this matter.</p>
    <p>Best regards,<br>Your Project Management System</p>
  `;
};
