/* eslint-disable */

// TODO: Remove eslint-disable and chanlde user info type in set state

import { getAuth, signInWithEmailAndPassword, MultiFactorError } from "@firebase/auth";
import React from "react";
import {
  doc,
  getDoc,
  getFirestore,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import { FirebaseError } from "firebase/app";
import { PrivateUserInfoType } from "types/user";
import { privateUserConverter } from "@freetech/models/converters/privateUserConverter";

interface LoginResponse {
  success: boolean;
  error?: string;
  user?: any;
}

export const handleLoginWithEmail = async (
  email: string,
  password: string,
  setUserInfo: React.Dispatch<any>
): Promise<LoginResponse | MultiFactorError> => {
  const auth = getAuth();
  const db = getFirestore();

  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    const userDoc = await getDoc(
      doc(db, "users", user.uid).withConverter(privateUserConverter)
    );
    if (!userDoc.exists()) {
      throw new Error("User data not found");
    }

    const userData = userDoc.data();
    setUserInfo(userData);

    return {
      success: true,
      user: userData,
    };
  } catch (error: any) {
    console.error("Email login error details:", {
      error,
      code: error?.code,
      message: error?.message,
      name: error?.name,
    });

    if (error?.code === "auth/multi-factor-auth-required") {
      return error as MultiFactorError;
    }

    // Check if error has a code property (Firebase error) or is a FirebaseError instance
    if (error?.code || error instanceof FirebaseError) {
      return {
        success: false,
        error: error.code || "auth/internal-error",
      };
    }

    // For network errors or other types of errors
    if (error?.message?.toLowerCase().includes("network")) {
      return {
        success: false,
        error: "auth/network-request-failed",
      };
    }

    return {
      success: false,
      error: "auth/internal-error",
    };
  }
};
