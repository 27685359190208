import { doc, getDoc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "config/firebase";
import { DateTime } from "luxon";
import { AttachmentParams } from "types/freelo/crud";
import { Card, CardAttachment } from "types/freelo";

export const uploadAttachment = async (
  params: AttachmentParams
): Promise<void> => {
  const { boardId, cardId, file, createdBy } = params;
  const uniqueFileName = `${Date.now()}-${file.name}`;
  const storageRef = ref(
    storage,
    `boards/${boardId}/cards/${cardId}/${uniqueFileName}`
  );

  await uploadBytes(storageRef, file);
  const downloadUrl = await getDownloadURL(storageRef);

  const thumbnailUrl = file.type.startsWith("image/") ? downloadUrl : undefined;

  const cardRef = doc(db, "boards", boardId, "cards", cardId);
  const cardDoc = await getDoc(cardRef);
  const card = cardDoc.data() as Card;

  const newAttachment: CardAttachment = {
    id: crypto.randomUUID(),
    fileName: file.name,
    url: downloadUrl,
    fileSize: file.size,
    fileType: file.type,
    createdAt: DateTime.now().toISO(),
    createdBy: createdBy || "system",
    thumbnailUrl,
  };

  await updateDoc(cardRef, {
    attachments: [...(card.attachments || []), newAttachment],
    updatedAt: DateTime.now().toISO(),
  });
};
