import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  IconButton,
  Grid,
  CircularProgress,
} from '@mui/material';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { storage } from "config/firebase";
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Board } from "types/freelo";
interface BoardSettingsDialogProps {
  open: boolean;
  onClose: () => void;
  board: Board;
  onUpdateBoard: (updates: Partial<Board>) => Promise<void>;
}

export const BoardSettingsDialog: React.FC<BoardSettingsDialogProps> = ({
  open,
  onClose,
  board,
  onUpdateBoard,
}) => {
  const [uploading, setUploading] = useState(false);

  const handleBackgroundUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    try {
      setUploading(true);
      const storageRef = ref(storage, `boards/${board.id}/background/${file.name}`);
      await uploadBytes(storageRef, file);
      const downloadUrl = await getDownloadURL(storageRef);
      
      await onUpdateBoard({ backgroundUrl: downloadUrl });
    } catch (error) {
      console.error('Error uploading background:', error);
    } finally {
      setUploading(false);
    }
  };

  const handleRemoveBackground = async () => {
    try {
      await onUpdateBoard({ backgroundUrl: null });
    } catch (error) {
      console.error('Error removing background:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Board Settings</DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" gutterBottom>
            Background Image
          </Typography>
          
          {board.backgroundUrl && (
            <Box sx={{ position: 'relative', mb: 2 }}>
              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  paddingTop: '56.25%', // 16:9 aspect ratio
                  borderRadius: 2,
                  overflow: 'hidden',
                  boxShadow: 2,
                }}
              >
                <Box
                  component="img"
                  src={board.backgroundUrl}
                  alt="Board background"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.2)',
                  }}
                />
                <IconButton
                  onClick={handleRemoveBackground}
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    bgcolor: 'rgba(0,0,0,0.5)',
                    '&:hover': { 
                      bgcolor: 'rgba(0,0,0,0.7)',
                    },
                    zIndex: 1,
                  }}
                >
                  <DeleteIcon sx={{ color: 'white' }} />
                </IconButton>
              </Box>
              <Typography 
                variant="caption" 
                color="text.secondary"
                sx={{ display: 'block', mt: 1, textAlign: 'center' }}
              >
                Preview of how the background will appear on your board
              </Typography>
            </Box>
          )}

          <Button
            component="label"
            variant="outlined"
            startIcon={uploading ? <CircularProgress size={20} /> : <CloudUploadIcon />}
            disabled={uploading}
            fullWidth
            sx={{
              height: 48,
              borderStyle: 'dashed',
              '&:hover': {
                borderStyle: 'dashed',
              },
            }}
          >
            {uploading ? 'Uploading...' : 'Upload Background Image'}
            <input
              type="file"
              hidden
              accept="image/*"
              onChange={handleBackgroundUpload}
            />
          </Button>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}; 