import React from 'react';
import { Box, Typography } from '@mui/material';
import { PublicUserInfoType } from "types/user";
interface MentionDisplayProps {
  content: string;
  mentionedUserIds?: string[];
  users?: PublicUserInfoType[];
}

export const MentionDisplay: React.FC<MentionDisplayProps> = ({ content, mentionedUserIds, users }) => {
  if (!mentionedUserIds?.length || !users?.length) {
    return <Typography variant="body2">{content}</Typography>;
  }

  const mentionedUsers = users.filter(user => mentionedUserIds.includes(user.id));
  const segments: { text: string; isMention: boolean; userId?: string }[] = [];
  let remainingText = content;

  // Sort mentions by length of name (longest first) to avoid partial replacements
  const sortedMentions = mentionedUsers.sort((a, b) => {
    const nameA = `${b.firstName} ${b.lastName}`;
    const nameB = `${a.firstName} ${a.lastName}`;
    return nameB.length - nameA.length;
  });

  sortedMentions.forEach(user => {
    const fullName = `@${user.firstName} ${user.lastName}`;
    const parts = remainingText.split(fullName);
    
    for (let i = 0; i < parts.length; i++) {
      if (i > 0) {
        segments.push({
          text: fullName,
          isMention: true,
          userId: user.id
        });
      }
      if (parts[i]) {
        segments.push({
          text: parts[i],
          isMention: false
        });
      }
    }
    remainingText = parts.join(fullName);
  });

  return (
    <Typography variant="body2" component="div">
      {segments.map((segment, index) => (
        <Box
          key={index}
          component="span"
          sx={segment.isMention ? {
            fontWeight: 'bold',
            color: 'primary.main',
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline'
            }
          } : undefined}
        >
          {segment.text}
        </Box>
      ))}
    </Typography>
  );
}; 