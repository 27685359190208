export const initializeEversignSDK = (): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    if (window.eversign) {
      resolve();
      return;
    }

    const script = document.createElement("script");
    script.src = "https://static.eversign.com/js/embedded-signing.js";
    script.async = true;

    const timeoutId = setTimeout(() => {
      const timeoutError = new Error("Eversign SDK load timed out");
      console.error(timeoutError);
      reject(timeoutError);
    }, 10000); // 10 second timeout

    script.onload = () => {
      clearTimeout(timeoutId);

      if (window.eversign) {
        resolve();
      } else {
        const initError = new Error("Eversign SDK failed to initialize properly");
        console.error(initError);
        reject(initError);
      }
    };

    script.onerror = (event) => {
      clearTimeout(timeoutId);
      const loadError = new Error("Failed to load Eversign SDK");
      console.error(loadError, event);
      reject(loadError);
    };

    document.body.appendChild(script);
  });
}; 