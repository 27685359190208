import React, { useState, useEffect } from "react";
import {
  collection,
  query,
  getFirestore,
  getDocs,
} from "firebase/firestore";
import {
  Button,
  Typography,
  Box,
  Paper,
  Stack,
  Avatar,
  CircularProgress,
  Chip,
  Tooltip,
  alpha,
} from "@mui/material";
import { 
  DataGridPro, 
  GridColDef, 
  GridRenderCellParams,
  GridToolbarQuickFilter,
  GridRowSelectionModel,
} from "@mui/x-data-grid-pro";
import { Add as AddIcon, Business as BusinessIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Freelancer } from "types/user";
import { projectAssignmentConverter } from "@freetech/models/converters/projectAssignmentConverter";
import { freelancerConverter } from "@freetech/models/converters/freelancerConverter";

const db = getFirestore();

interface FreelancerWithDetails extends Freelancer {
  fullName: string;
  projectCount: number;
  assignedClients: string[];
}

const FreelancerManagement: React.FC = () => {
  const [freelancers, setFreelancers] = useState<FreelancerWithDetails[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedFreelancers, setSelectedFreelancers] = useState<GridRowSelectionModel>([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchFreelancers = async () => {
      const q = query(collection(db, "users").withConverter(freelancerConverter));
      const querySnapshot = await getDocs(q);
      
      const freelancerPromises = querySnapshot.docs.map(async (doc) => {
        const userData = doc.data() as Freelancer;  
        
        const assignmentsQuery = query(collection(db, "users", doc.id, "projectAssignments").withConverter(projectAssignmentConverter));
        const assignmentsSnapshot = await getDocs(assignmentsQuery);
        
        const projectCount = assignmentsSnapshot.size;
        const assignedClients = new Set<string>();
        
        assignmentsSnapshot.forEach((assignmentDoc) => {
          const assignmentData = assignmentDoc.data();
          if (assignmentData.clientId) {
            assignedClients.add(assignmentData.clientId);
          }
        });
        
        return {
          ...userData,
          id: doc.id,
          fullName: `${userData.firstName} ${userData.lastName}`,
          projectCount,
          assignedClients: Array.from(assignedClients),
        };
      });
      
      const freelancerData = await Promise.all(freelancerPromises);
      setFreelancers(freelancerData);
      setLoading(false);
    };

    fetchFreelancers();
  }, []);

  const handleManageFreelancer = (freelancerId: string) => {
    navigate(`/admin/freelancer-management/${freelancerId}`);
  };

  const handleAddFreelancer = () => {
    navigate('/admin/freelancer-management/new');
  };

  const columns: GridColDef<FreelancerWithDetails>[] = [
    {
      field: 'profileInfo',
      headerName: 'Freelancer',
      width: 250,
      renderCell: (params: GridRenderCellParams<FreelancerWithDetails>) => (
        <Box display="flex" alignItems="center" gap={2}>
          <Avatar src={params.row.profilePicture} alt={params.row.fullName} />
          <Box>
            <Typography variant="body1">{params.row.fullName}</Typography>
            <Typography variant="body2" color="text.secondary">
              {params.row.email}
            </Typography>
          </Box>
        </Box>
      ),
      valueGetter: (value, row) => {
        if (!row) return '';
        return `${row.fullName} ${row.email}`;
      },
    },
    {
      field: 'projectCount',
      headerName: 'Projects',
      width: 120,
      renderCell: (params: GridRenderCellParams<FreelancerWithDetails>) => (
        <Chip 
          label={params.row.projectCount}
          size="small"
          color={params.row.projectCount > 0 ? "primary" : "default"}
        />
      ),
    },
    {
      field: 'assignedClients',
      headerName: 'Assigned Clients',
      width: 400,
      renderCell: (params: GridRenderCellParams<FreelancerWithDetails>) => {
        if (!params.row?.assignedClients?.length) {
          return null;
        }
        const clients = params.row.assignedClients;
        const totalClients = clients.length;
        const displayLimit = 3;
        const displayedClients = clients.slice(0, displayLimit);
        const remainingClients = clients.slice(displayLimit);

        return (
          <Box display="flex" alignItems="center" gap={1}>
            <BusinessIcon color="action" sx={{ fontSize: 20 }} />
            <Typography variant="body2" color="text.secondary" sx={{ minWidth: 45 }}>
              ({totalClients})
            </Typography>
            <Stack direction="row" spacing={1}>
              {displayedClients.map((clientId: string) => (
                <Chip
                  key={clientId}
                  label={clientId}
                  size="small"
                  variant="outlined"
                  sx={{ 
                    maxWidth: 150,
                    "& .MuiChip-label": {
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }
                  }}
                />
              ))}
              {remainingClients.length > 0 && (
                <Tooltip title={remainingClients.join(", ")} arrow>
                  <Chip
                    label={`+${remainingClients.length} more`}
                    size="small"
                    color="primary"
                    variant="outlined"
                  />
                </Tooltip>
              )}
            </Stack>
          </Box>
        );
      },
      valueGetter: (value, row) => {
        if (!row?.assignedClients) return '';
        return row.assignedClients.join(" ");
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      renderCell: (params: GridRenderCellParams<FreelancerWithDetails>) => (
        <Button
          size="small"
          variant="outlined"
          onClick={() => handleManageFreelancer(params.row.id)}
        >
          Manage
        </Button>
      ),
    },
  ];

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  const CustomGridToolbar = () => {
    return (
      <Box sx={{ p: 2 }}>
        <GridToolbarQuickFilter />
      </Box>
    );
  };

  return (
    <Box sx={{ maxWidth: 1200, margin: '0 auto', padding: '24px' }}>
      <Stack spacing={3}>
        {/* Header */}
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="h1">
            Freelancer Management
          </Typography>
        </Stack>

        {/* DataGrid Pro */}
        <Paper sx={{ height: 'calc(100vh - 180px)' }}>
          <DataGridPro
            rows={freelancers}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) => {
              setSelectedFreelancers(newSelection);
            }}
            rowSelectionModel={selectedFreelancers}
            autoHeight={false}
            density="comfortable"
            hideFooter
            slots={{ toolbar: CustomGridToolbar }}
            slotProps={{
              toolbar: {
                showQuickFilter: true,
                quickFilterProps: { 
                  debounceMs: 500,
                  placeholder: "Search freelancers..."
                },
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'fullName', sort: 'asc' }],
              },
              filter: {
                filterModel: {
                  items: [],
                  quickFilterValues: [],
                },
              },
            }}
          />
        </Paper>
      </Stack>
    </Box>
  );
};

export default FreelancerManagement;
