import {
  useQuery,
  useMutation,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";
import { DocumentReference, DocumentData } from "firebase/firestore";
import { adminController } from "controllers/adminController";
import { Client, ICreateClient } from "types/projects";

export const useAdminClients = () => {
  const queryClient = useQueryClient();

  // Load all clients (GET)
  const {
    data: clients,
    isLoading,
    isError,
  }: UseQueryResult<Client[], Error> = useQuery<Client[], Error>({
    queryKey: ["clients"],
    queryFn: () => adminController.clients.loadClientsWithProjectsAndCSAs(),
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  // Add new client (POST)
  const addClientMutation = useMutation<
    DocumentReference<DocumentData>,
    Error,
    ICreateClient
  >({
    mutationFn: (c: ICreateClient) => adminController.clients.createClient(c),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  // Update a client (PUT)
  const updateClientMutation = useMutation<
    void,
    Error,
    { id: string; client: Partial<Client> }
  >({
    mutationFn: ({ id, client }) =>
      adminController.clients.updateClient(client),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  // Delete a client (DELETE)
  const deleteClientMutation = useMutation<void, Error, string>({
    mutationFn: (clientId: string) =>
      adminController.clients.deleteClient(clientId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["clients"] });
    },
  });

  return {
    clients,
    isLoading,
    isError,
    addClient: addClientMutation,
    updateClient: updateClientMutation,
    deleteClient: deleteClientMutation,
  };
};
