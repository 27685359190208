import React from 'react';
import {
  DataGridPro,
  GridColDef,
  GridToolbar,
  GridValueGetter,
  GridValueFormatter,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';
import { Box, Paper, useTheme, alpha, Tooltip } from '@mui/material';
import { calculateDuration, formatDurationForEdit, parseDuration } from "core/timesheet/utils";
import { Timesheet } from "types/timesheet";
interface TimesheetExplorerGridProps {
  timesheets: Timesheet[];
  loading: boolean;
  startDate: DateTime;
  endDate: DateTime;
  selectedProjects: string[];
}

const TimesheetExplorerGrid: React.FC<TimesheetExplorerGridProps> = ({
  timesheets,
  loading,
  startDate,
  endDate,
  selectedProjects,
}) => {
  const theme = useTheme();

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      flex: 1,
      minWidth: 120,
    //   valueFormatter: (params: any) => {
    //     return params.value ? DateTime.fromISO(params.value).toFormat('dd LLL yyyy') : '';
    //   },
    },
    {
      field: 'projectName',
      headerName: 'Project',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'clientName',
      headerName: 'Client',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'duration',
      headerName: 'Duration',
      flex: 1,
      minWidth: 100,
      valueGetter: (value, row, column, apiRef) => {
        const durationInSeconds = calculateDuration(row.startTime, row.endTime);
        return formatDurationForEdit(durationInSeconds);
      },
      valueSetter: (value, row, column, apiRef) => {
        if (!value) return;
        const newDurationInSeconds = parseDuration(value);

        return { ...row, duration: newDurationInSeconds };
      },
    },
    {
      field: 'rate',
      headerName: 'Rate',
      flex: 1,
      minWidth: 100,
    //   valueFormatter: ({ value }: GridValueFormatterParams) => {
    //     if (!value) return '';
    //     return `$${value}/hr`;
    //   },
    },
    {
      field: 'earnings',
      headerName: 'Earnings',
      flex: 1,
      minWidth: 100,
    //   valueGetter: ({ row }: GridValueGetterParams<Timesheet>) => {
    //     if (!row) return 0;
    //     const hours = row.duration / 3600;
    //     return row.rate ? hours * row.rate : 0;
    //   },
    //   valueFormatter: ({ value }: GridValueFormatterParams) => {
    //     if (!value) return '';
    //     return `$${Number(value).toFixed(2)}`;
    //   },
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      minWidth: 200,
    },
  ];

  return (
    <Paper elevation={3}>
      <DataGridPro<Timesheet>
        rows={timesheets}
        columns={columns}
        loading={loading}
        autoHeight
        pagination
        density="comfortable"
        disableColumnFilter
        disableColumnResize
        disableColumnReorder
        disableColumnMenu
        disableColumnSelector
        disableChildrenSorting
        disableMultipleColumnsSorting
        disableDensitySelector
        disableRowSelectionOnClick
        columnHeaderHeight={40}
        rowHeight={50}
        slots={{
          toolbar: GridToolbar,
        }}
        initialState={{
          filter: {
            filterModel: {
              items: [],
              quickFilterValues: [],
            },
          },
          sorting: {
            sortModel: [{ field: 'date', sort: 'desc' }],
          },
        }}
        sx={{
          border: 'none',
          '& .MuiDataGrid-cell': {
            padding: '8px',
            borderBottom: 'none',
            color: theme.palette.text.primary,
          },
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: `1px solid ${theme.palette.divider}`,
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            color: theme.palette.text.primary,
          },
          '& .MuiDataGrid-virtualScroller': {
            marginTop: '0 !important',
          },
          '& .MuiDataGrid-row': {
            '&:nth-of-type(odd)': {
              backgroundColor: alpha(theme.palette.background.default, 0.5),
            },
            '&:nth-of-type(even)': {
              backgroundColor: alpha(theme.palette.background.paper, 0.5),
            },
            '&:hover': {
              backgroundColor: alpha(theme.palette.action.hover, 0.1),
            },
          },
          '& .MuiDataGrid-columnHeader': {
            fontWeight: 'bold',
          },
          '& .MuiDataGrid-cellEditable': {
            '& .MuiInputBase-input': {
              color: theme.palette.text.primary,
            },
          },
          '& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within': {
            outline: `2px solid ${theme.palette.primary.main}`,
            outlineOffset: '-2px',
          },
          '& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within': {
            outline: 'none',
          },
        }}
      />
    </Paper>
  );
};

export default TimesheetExplorerGrid; 