import React, { useState } from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Button,
  TextField,
  Autocomplete,
  Chip,
  Grid,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Checkbox,
  Link,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { useFreelancerOnboarding } from "hooks/user/useFreelancerOnboarding";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import InfoIcon from "@mui/icons-material/Info";

// Constants
const steps = [
  "Personal Information",
  "Citizenship",
  "Employment",
  "Skills",
  "Review",
];

const predefinedSkills = [
  "JavaScript",
  "TypeScript",
  "React",
  "Node.js",
  "Python",
  "Java",
  "C#",
  "AWS",
  "Azure",
  "GCP",
  "Docker",
  "Kubernetes",
  "DevOps",
  "Machine Learning",
  "Data Science",
];

const clearanceLevels = [
  "None",
  "Confidential",
  "Secret",
  "Top Secret",
  "TS/SCI",
];

const clearanceIssuers = ["DoD", "DHS", "DOE", "Other"];

const countries = [
  "United States",
  "Argentina",
  "Australia",
  "Austria",
  "Belgium",
  "Brazil",
  "Canada",
  "Chile",
  "Colombia",
  "Costa Rica",
  "Cuba",
  "Denmark",
  "Dominican Republic",
  "Ecuador",
  "El Salvador",
  "Finland",
  "France",
  "Spain",
  "Italy",
  "Netherlands",
  "Sweden",
  "Norway",
  "Denmark",
  "Finland",
  "Ireland",
  "New Zealand",
  "India",
  "Japan",
  "South Korea",
  "Singapore",
  "Brazil",
];

const usStates = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

const workAuthorizationTypes = [
  "Green Card",
  "H-1B",
  "L-1",
  "F-1 OPT",
  "F-1 CPT",
  "E-3",
  "TN",
  "Other",
];

export const FreelancerOnboarding: React.FC = () => {
  const {
    activeStep,
    formData,
    newSkill,
    snackbar,
    handleNext,
    handleBack,
    handleSkillAdd,
    handleSkillDelete,
    handleAddressChange,
    handleComplete,
    handleSnackbarClose,
    isStepComplete,
    handleFormChange,
    handleSecurityClearanceChange,
    handleEmploymentChange,
    setNewSkill,
  } = useFreelancerOnboarding();

  const [termsDialogOpen, setTermsDialogOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsRead, setTermsRead] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);

  const handleOpenTerms = async () => {
    if (!pdfUrl) {
      const storage = getStorage();
      const pdfRef = ref(storage, "terms.pdf");
      const url = await getDownloadURL(pdfRef);
      setPdfUrl(url);
    }
    setTermsDialogOpen(true);
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Personal Information
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Please provide your legal name as it appears on official
              documents.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Legal First Name"
                  value={formData.legalFirstName}
                  onChange={(e) =>
                    handleFormChange("legalFirstName", e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Legal Last Name"
                  value={formData.legalLastName}
                  onChange={(e) =>
                    handleFormChange("legalLastName", e.target.value)
                  }
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Phone Number"
                  value={formData.phone}
                  onChange={(e) => handleFormChange("phone", e.target.value)}
                  required
                  placeholder="+1 (555) 555-5555"
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
                  Location
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Street Address"
                  value={formData.address.street}
                  onChange={handleAddressChange("street")}
                  required
                  placeholder="123 Main St"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="City"
                  value={formData.address.city}
                  onChange={handleAddressChange("city")}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="State/Province"
                  value={formData.address.state}
                  onChange={handleAddressChange("state")}
                  required
                >
                  {formData.address.country === "United States" ? (
                    usStates.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="other">Other</MenuItem>
                  )}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="ZIP/Postal Code"
                  value={formData.address.zipCode}
                  onChange={handleAddressChange("zipCode")}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  select
                  label="Country"
                  value={formData.address.country}
                  onChange={handleAddressChange("country")}
                  required
                >
                  {countries.map((country) => (
                    <MenuItem key={country} value={country}>
                      {country}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Box>
        );
      case 1:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Citizenship & Security Clearance
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Are you a U.S. Citizen?
                  </FormLabel>
                  <RadioGroup
                    value={formData.citizenship.isUsCitizen.toString()}
                    onChange={(e) => {
                      const isUsCitizen = e.target.value === "true";
                      handleFormChange("citizenship", {
                        ...formData.citizenship,
                        isUsCitizen,
                        hasWorkAuthorization: isUsCitizen
                          ? false
                          : formData.citizenship.hasWorkAuthorization,
                      });
                      if (!isUsCitizen) {
                        // Reset security clearance if not US citizen
                        handleSecurityClearanceChange("hasClearance", false);
                        handleSecurityClearanceChange("clearanceLevel", "None");
                        handleSecurityClearanceChange("clearanceIssuer", "");
                        handleSecurityClearanceChange("expirationDate", null);
                      }
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {!formData.citizenship.isUsCitizen && (
                <>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Do you have work authorization?
                      </FormLabel>
                      <RadioGroup
                        value={formData.citizenship.hasWorkAuthorization.toString()}
                        onChange={(e) => {
                          handleFormChange("citizenship", {
                            ...formData.citizenship,
                            hasWorkAuthorization: e.target.value === "true",
                          });
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {formData.citizenship.hasWorkAuthorization && (
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        select
                        label="Work Authorization Type"
                        value={formData.citizenship.workAuthorizationType}
                        onChange={(e) => {
                          handleFormChange("citizenship", {
                            ...formData.citizenship,
                            workAuthorizationType: e.target.value,
                          });
                        }}
                        required
                      >
                        {workAuthorizationTypes.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  )}
                </>
              )}

              {formData.citizenship.isUsCitizen && (
                <>
                  <Grid item xs={12}>
                    <FormControl component="fieldset" sx={{ width: "100%" }}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <FormLabel component="legend">
                          Do you have a Common Access Card (CAC)?
                        </FormLabel>
                        <Tooltip
                          title="A Common Access Card (CAC) is a smart card used as the standard identification for active duty military personnel, civilian employees, and contractors. It's required for access to government facilities and computer networks."
                          placement="right"
                        >
                          <InfoIcon color="action" fontSize="small" />
                        </Tooltip>
                      </Box>
                      <RadioGroup
                        value={
                          formData.securityClearance.hasCac?.toString() ||
                          "false"
                        }
                        onChange={(e) => {
                          handleSecurityClearanceChange(
                            "hasCac",
                            e.target.value === "true"
                          );
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl component="fieldset" sx={{ width: "100%" }}>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <FormLabel component="legend">
                          Do you have a security clearance?
                        </FormLabel>
                        <Tooltip
                          title="A security clearance is an authorization that allows access to classified information or restricted areas after completion of a background check"
                          placement="right"
                        >
                          <InfoIcon color="action" fontSize="small" />
                        </Tooltip>
                      </Box>
                      <RadioGroup
                        value={formData.securityClearance.hasClearance.toString()}
                        onChange={(e) => {
                          const hasClearance = e.target.value === "true";
                          handleSecurityClearanceChange(
                            "hasClearance",
                            hasClearance
                          );
                          if (hasClearance) {
                            handleSecurityClearanceChange(
                              "clearanceLevel",
                              "None"
                            );
                          }
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>

                  {formData.securityClearance.hasClearance && (
                    <>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          select
                          label="Clearance Level"
                          value={formData.securityClearance.clearanceLevel}
                          onChange={(e) =>
                            handleSecurityClearanceChange(
                              "clearanceLevel",
                              e.target.value
                            )
                          }
                          required
                        >
                          {clearanceLevels.map((level) => (
                            <MenuItem key={level} value={level}>
                              {level}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          select
                          label="Clearance Issuer"
                          value={formData.securityClearance.clearanceIssuer}
                          onChange={(e) =>
                            handleSecurityClearanceChange(
                              "clearanceIssuer",
                              e.target.value
                            )
                          }
                          required
                        >
                          {clearanceIssuers.map((issuer) => (
                            <MenuItem key={issuer} value={issuer}>
                              {issuer}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={12}>
                        <DatePicker<DateTime>
                          label="Clearance Expiration Date"
                          value={formData.securityClearance.expirationDate}
                          onChange={(newDate) =>
                            handleSecurityClearanceChange(
                              "expirationDate",
                              newDate
                            )
                          }
                          slots={{
                            textField: (params) => (
                              <TextField {...params} fullWidth required />
                            ),
                          }}
                        />
                      </Grid>
                    </>
                  )}
                </>
              )}
            </Grid>
          </Box>
        );
      case 2:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Current Employment
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    Do you currently have a full-time job?
                  </FormLabel>
                  <RadioGroup
                    value={formData.employment.hasFullTimeJob.toString()}
                    onChange={(e) => {
                      const hasFullTimeJob = e.target.value === "true";
                      handleEmploymentChange("hasFullTimeJob", hasFullTimeJob);
                      if (!hasFullTimeJob) {
                        handleEmploymentChange("employer", "");
                        handleEmploymentChange("position", "");
                        handleEmploymentChange("startDate", null);
                      }
                    }}
                  >
                    <FormControlLabel
                      value="true"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="false"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {formData.employment.hasFullTimeJob && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Current Employer"
                      value={formData.employment.employer}
                      onChange={(e) =>
                        handleEmploymentChange("employer", e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      fullWidth
                      label="Current Position"
                      value={formData.employment.position}
                      onChange={(e) =>
                        handleEmploymentChange("position", e.target.value)
                      }
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DatePicker<DateTime>
                      label="Start Date"
                      value={formData.employment.startDate}
                      onChange={(newDate) =>
                        handleEmploymentChange("startDate", newDate)
                      }
                      slots={{
                        textField: (params) => (
                          <TextField {...params} fullWidth required />
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl component="fieldset">
                      <FormLabel component="legend">
                        Is your employer aware of your freelancing activities?
                      </FormLabel>
                      <RadioGroup
                        value={formData.employment.employerAwareOfFreelancing.toString()}
                        onChange={(e) => {
                          handleEmploymentChange(
                            "employerAwareOfFreelancing",
                            e.target.value === "true"
                          );
                        }}
                      >
                        <FormControlLabel
                          value="true"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="false"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                      <FormHelperText>
                        We recommend checking your employment contract regarding
                        any restrictions on freelance work.
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        );
      case 3:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Skills
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              Add any relevant technical skills. This step is optional.
            </Typography>
            <Autocomplete
              freeSolo
              options={predefinedSkills.filter(
                (skill) => !formData.skills.includes(skill)
              )}
              inputValue={newSkill}
              value={newSkill}
              onChange={(_, value) => {
                if (value) {
                  handleSkillAdd(value);
                  setNewSkill("");
                }
              }}
              onInputChange={(_, value) => setNewSkill(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Add Skills"
                  margin="normal"
                  fullWidth
                />
              )}
            />
            <Box sx={{ mt: 2, display: "flex", flexWrap: "wrap", gap: 1 }}>
              {formData.skills.map((skill) => (
                <Chip
                  key={skill}
                  label={skill}
                  onDelete={() => handleSkillDelete(skill)}
                />
              ))}
            </Box>
          </Box>
        );
      case 4:
        return (
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" gutterBottom>
              Review Your Information
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Personal Information
                </Typography>
                <Typography variant="body1">
                  <strong>Legal Name:</strong> {formData.legalFirstName}{" "}
                  {formData.legalLastName}
                  <br />
                  <strong>Phone:</strong> {formData.phone}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Address
                </Typography>
                <Typography variant="body1">
                  {formData.address.street}
                  <br />
                  {formData.address.city}, {formData.address.state}{" "}
                  {formData.address.zipCode}
                  <br />
                  {formData.address.country}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Citizenship & Clearance
                </Typography>
                <Typography variant="body1">
                  <strong>U.S. Citizen:</strong>{" "}
                  {formData.citizenship.isUsCitizen ? "Yes" : "No"}
                  {!formData.citizenship.isUsCitizen && (
                    <>
                      <br />
                      <strong>Work Authorization:</strong>{" "}
                      {formData.citizenship.hasWorkAuthorization ? "Yes" : "No"}
                      {formData.citizenship.hasWorkAuthorization && (
                        <>
                          <br />
                          <strong>Authorization Type:</strong>{" "}
                          {formData.citizenship.workAuthorizationType}
                        </>
                      )}
                    </>
                  )}
                  <br />
                  <strong>Security Clearance:</strong>{" "}
                  {formData.securityClearance.hasClearance ? "Yes" : "No"}
                  {formData.securityClearance.hasClearance && (
                    <>
                      <br />
                      <strong>Clearance Level:</strong>{" "}
                      {formData.securityClearance.clearanceLevel}
                      <br />
                      <strong>Issuer:</strong>{" "}
                      {formData.securityClearance.clearanceIssuer}
                      <br />
                      <strong>Expiration:</strong>{" "}
                      {formData.securityClearance.expirationDate?.toLocaleString(
                        DateTime.DATE_FULL
                      ) || "Not set"}
                    </>
                  )}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Employment
                </Typography>
                <Typography variant="body1">
                  <strong>Currently Employed Full-time:</strong>{" "}
                  {formData.employment.hasFullTimeJob ? "Yes" : "No"}
                  {formData.employment.hasFullTimeJob && (
                    <>
                      <br />
                      <strong>Employer:</strong> {formData.employment.employer}
                      <br />
                      <strong>Position:</strong> {formData.employment.position}
                      <br />
                      <strong>Start Date:</strong>{" "}
                      {formData.employment.startDate?.toLocaleString(
                        DateTime.DATE_FULL
                      ) || "Not set"}
                      <br />
                      <strong>Employer Aware of Freelancing:</strong>{" "}
                      {formData.employment.employerAwareOfFreelancing
                        ? "Yes"
                        : "No"}
                    </>
                  )}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1" gutterBottom>
                  Skills
                </Typography>
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                  {formData.skills.map((skill) => (
                    <Chip key={skill} label={skill} />
                  ))}
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mt: 3, display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      <Tooltip
                        title={
                          !termsRead
                            ? "Please review the Terms and Conditions first"
                            : ""
                        }
                        placement="top"
                      >
                        <span>
                          <Checkbox
                            checked={termsAccepted}
                            onChange={(e) => setTermsAccepted(e.target.checked)}
                            disabled={!termsRead}
                          />
                        </span>
                      </Tooltip>
                    }
                    label={
                      <Typography variant="body2">
                        I have read and agree to the{" "}
                        <Link
                          component="button"
                          variant="body2"
                          onClick={handleOpenTerms}
                        >
                          Terms and Conditions
                        </Link>
                      </Typography>
                    }
                  />
                </Box>
              </Grid>

              {!formData.employment.employerAwareOfFreelancing &&
                formData.employment.hasFullTimeJob && (
                  <Grid item xs={12}>
                    <Alert severity="warning">
                      Please be aware that your employer may have policies
                      regarding freelance work. We recommend reviewing your
                      employment contract and discussing this with your employer
                      to avoid any potential conflicts.
                    </Alert>
                  </Grid>
                )}
            </Grid>
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Box sx={{ width: "100%", maxWidth: 800, mx: "auto", p: 3 }}>
      <Typography variant="h4" gutterBottom align="center">
        Welcome to FreeTech!
      </Typography>
      <Typography variant="body1" gutterBottom align="center" sx={{ mb: 4 }}>
        Let's get your profile set up
      </Typography>

      <Stepper activeStep={activeStep} sx={{ mb: 4 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {renderStepContent(activeStep)}

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 4 }}>
        {activeStep > 0 && (
          <Button onClick={handleBack} sx={{ mr: 1 }}>
            Back
          </Button>
        )}
        {activeStep === 3 && (
          <Button onClick={handleNext} sx={{ mr: 1 }}>
            Skip
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <Button
            variant="contained"
            onClick={handleComplete}
            disabled={!isStepComplete(activeStep)}
          >
            Complete
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleNext}
            disabled={!isStepComplete(activeStep)}
          >
            Next
          </Button>
        )}
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      />

      <Dialog
        open={termsDialogOpen}
        onClose={() => setTermsDialogOpen(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Terms and Conditions</DialogTitle>
        <DialogContent>
          {pdfUrl && (
            <Box sx={{ width: "100%", height: "70vh" }}>
              <iframe
                src={`${pdfUrl}#view=FitH`}
                width="100%"
                height="100%"
                title="Terms and Conditions"
                onLoad={() => setTermsRead(true)}
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTermsDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
