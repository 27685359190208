import { doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { MoveCardParams } from "types/freelo/crud";
import { QueryClient } from "@tanstack/react-query";
import { Board, List, Card } from "types/freelo";

export const moveCard = async (
  params: MoveCardParams,
  queryClient: QueryClient
): Promise<void> => {
  const {
    boardId,
    cardId,
    sourceListId,
    destinationListId,
    destinationIndex = 0,
  } = params;

  // Cancel any outgoing refetches and pause any background refetches
  await queryClient.cancelQueries({
    queryKey: ["board", boardId],
    exact: true, // Only cancel this exact query
  });

  // Get the current board state
  const previousBoard = queryClient.getQueryData<Board>(["board", boardId]);

  if (!previousBoard) return;

  // Create a new board object for the optimistic update
  const newBoard = { ...previousBoard };
  const lists = [...(newBoard.lists || [])];

  // Find source and destination columns
  const sourceListIndex = lists.findIndex((list) => list.id === sourceListId);
  const destListIndex = lists.findIndex(
    (list) => list.id === destinationListId
  );

  if (sourceListIndex === -1 || destListIndex === -1) return;

  // Create new arrays to avoid mutation
  const sourceCards = [...(lists[sourceListIndex].cards || [])];
  const destCards =
    sourceListId === destinationListId
      ? sourceCards
      : [...(lists[destListIndex].cards || [])];

  // Find the card to move
  const cardToMove = sourceCards.find((card) => card.id === cardId);
  if (!cardToMove) return;

  // Remove card from source
  const newSourceCards = sourceCards.filter((card) => card.id !== cardId);

  // If moving to a different list, update the card's listId
  if (sourceListId !== destinationListId) {
    cardToMove.listId = destinationListId;
    destCards.splice(destinationIndex, 0, cardToMove);

    // Update both columns
    lists[sourceListIndex] = {
      ...lists[sourceListIndex],
      cards: newSourceCards,
      cardOrder: newSourceCards.map((card) => card.id),
    };

    lists[destListIndex] = {
      ...lists[destListIndex],
      cards: destCards,
      cardOrder: destCards.map((card) => card.id),
    };
  } else {
    // Same list, just reorder
    newSourceCards.splice(destinationIndex, 0, cardToMove);
    lists[sourceListIndex] = {
      ...lists[sourceListIndex],
      cards: newSourceCards,
      cardOrder: newSourceCards.map((card) => card.id),
    };
  }

  // Update the board with new columns
  newBoard.lists = lists;

  // Optimistically update the cache
  queryClient.setQueryData(["board", boardId], newBoard);

  try {
    // Update card's list reference
    const cardRef = doc(db, "boards", boardId, "cards", cardId);
    await updateDoc(cardRef, {
      listId: destinationListId,
      updatedAt: DateTime.now().toISO(),
    });

    // Get both source and destination lists
    const sourceListRef = doc(db, "boards", boardId, "lists", sourceListId);
    const destListRef = doc(db, "boards", boardId, "lists", destinationListId);

    // Don't get board data again, use the optimistically updated data
    const updatedBoard = queryClient.getQueryData<Board>(["board", boardId]);
    if (!updatedBoard) return;

    const sourceList = updatedBoard.lists?.find(
      (list: List) => list.id === sourceListId
    );
    const destList = updatedBoard.lists?.find(
      (list: List) => list.id === destinationListId
    );

    if (!sourceList || !destList) {
      throw new Error("Source or destination list not found");
    }

    // Initialize or get card orders
    const sourceCardOrder: string[] = Array.from(
      new Set(sourceList.cardOrder || [])
    );
    const destCardOrder: string[] = Array.from(
      new Set(destList.cardOrder || [])
    );

    // Remove card from source list order
    const newSourceOrder: string[] = sourceCardOrder.filter(
      (id) => id !== cardId
    );

    // Handle destination order
    let newDestOrder: string[];
    if (sourceListId === destinationListId) {
      newDestOrder = [...newSourceOrder];
      newDestOrder.splice(destinationIndex, 0, cardId);
    } else {
      newDestOrder = [...destCardOrder.filter((id) => id !== cardId)];
      newDestOrder.splice(destinationIndex, 0, cardId);
    }

    // Update both lists with new orders
    const updatePromises = [
      updateDoc(sourceListRef, {
        cardOrder: newSourceOrder,
        updatedAt: DateTime.now().toISO(),
      }),
    ];

    if (sourceListId !== destinationListId) {
      updatePromises.push(
        updateDoc(destListRef, {
          cardOrder: newDestOrder,
          updatedAt: DateTime.now().toISO(),
        })
      );
    }

    await Promise.all(updatePromises);

    // Don't invalidate or refetch, the optimistic update is enough
  } catch (error) {
    // If there was an error, rollback to the previous state
    queryClient.setQueryData(["board", boardId], previousBoard);
    throw error;
  }
};
