export const errorMessages: { [key: string]: string } = {
  "auth/email-already-in-use":
    "The provided email is already in use. Please log in to continue.",
  "auth/internal-error":
    "Something went wrong on our end. Please try again later.",
  "auth/invalid-email": "Please check the email address and try again.",
  "auth/invalid-credential": "Incorrect email or password.",
  "auth/wrong-password": "The password is incorrect. Please try again.",
  "auth/network-request-failed":
    "Something went wrong. Please try again later.",
  "auth/popup-blocked":
    "The popup was blocked. Please allow popups for this site.",
  "auth/popup-closed-by-user": "The popup was closed. Please try again.",
  "auth/too-many-requests":
    "Too many attempts have been made. Please try again later.",
  "auth/unverified-email":
    "Your email is not verified. Please check your email.",
  "auth/user-not-found": "No account found with that information.",
  "auth/user-disabled": "This account has been disabled.",
  "auth/weak-password":
    "The password is too weak. Please choose a stronger one.",
  "auth/requires-recent-login": "For security reasons, please log in again.",
  "auth/user-token-expired": "Please log in again as your session has expired.",
  "auth/missing-password": "Password is required.",
};
