import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  Avatar,
  Typography,
  Box,
  Divider,
  useTheme,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { collection, query, getDocs, where } from "firebase/firestore";
import { db } from "config/firebase";
import { useAdminClient } from "hooks/admin/useAdminClient";
import { PrivateUserInfoType } from "types/user";
import { Stakeholder } from "types/user";
import { stakeholderConverter } from "@freetech/models/converters/stakeholderConverter";
interface AddStakeholderDialogProps {
  open: boolean;
  onClose: () => void;
  onAdd: (
    stakeholderData: Omit<Stakeholder, "id" | "email" | "role">
  ) => Promise<void>;
  clientId: string;
  currentStakeholders: { userId: string }[];
}

export const AddStakeholderDialog: React.FC<AddStakeholderDialogProps> = ({
  open,
  onClose,
  onAdd,
  clientId,
  currentStakeholders,
}) => {
  const [stakeholders, setStakeholders] = useState<PrivateUserInfoType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const theme = useTheme();
  const { updateClient } = useAdminClient(clientId);

  useEffect(() => {
    const fetchStakeholders = async () => {
      setLoading(true);
      try {
        const q = query(
          collection(db, "users").withConverter(stakeholderConverter),
          where("role", "in", ["stakeholder"])
        );
        const querySnapshot = await getDocs(q);

        const stakeholdersList = querySnapshot.docs
          .map((doc) => ({
            ...doc.data(),
          }))
          .filter(
            (user) => !currentStakeholders.some((cs) => cs.userId === user.id)
          );

        setStakeholders(stakeholdersList);
      } catch (error) {
        console.error("Error fetching stakeholders:", error);
      } finally {
        setLoading(false);
      }
    };

    if (open) {
      fetchStakeholders();
    }
  }, [open, currentStakeholders]);

  const handleAdd = async (stakeholder: PrivateUserInfoType) => {
    try {
      // Update the client's associatedStakeholderIds
      const currentIds = currentStakeholders.map((s) => s.userId);
      if (!currentIds.includes(stakeholder.id)) {
        await updateClient.mutateAsync({
          id: clientId,
          associatedStakeholderIds: [...currentIds, stakeholder.id],
        });
      }

      onClose();
    } catch (error) {
      console.error("Error adding stakeholder:", error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Add Stakeholder</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height={200}
          >
            <CircularProgress />
          </Box>
        ) : (
          <List>
            {stakeholders.map((stakeholder) => (
              <React.Fragment key={stakeholder.id}>
                <ListItem>
                  <Box display="flex" alignItems="center" width="100%">
                    <Avatar
                      src={stakeholder.profilePicture}
                      alt={`${stakeholder.firstName} ${stakeholder.lastName}`}
                      sx={{ width: 60, height: 60, marginRight: 2 }}
                    />
                    <Box flexGrow={1}>
                      <Typography variant="h6">
                        {stakeholder.firstName} {stakeholder.lastName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {stakeholder.email}
                      </Typography>
                    </Box>
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        aria-label="add"
                        onClick={() => handleAdd(stakeholder)}
                        sx={{
                          color: theme.palette.primary.main,
                          "&:hover": {
                            backgroundColor: theme.palette.primary.light,
                          },
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </Box>
                </ListItem>
                <Divider variant="inset" component="li" />
              </React.Fragment>
            ))}
            {stakeholders.length === 0 && (
              <Typography variant="body1" align="center">
                No stakeholders available to add.
              </Typography>
            )}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddStakeholderDialog;
