import HyperDX from "@hyperdx/browser";
import { Attributes } from "@opentelemetry/api";

type AttributeValue =
  | string
  | number
  | boolean
  | Array<string | number | boolean>;

interface LogOptions {
  userId?: string;
  userRole?: "SuperUser" | "Stakeholder" | "Freelancer";
  additionalContext?: Record<string, AttributeValue>;
}

export const useHyperDxAction = () => {
  const logAction = (
    action: string,
    details: Record<string, AttributeValue>,
    options?: LogOptions
  ) => {
    try {
      const sanitizedDetails = Object.fromEntries(
        Object.entries(details).map(([k, v]) => [k, String(v)])
      );
      HyperDX.addAction(action, sanitizedDetails);
    } catch (error) {
      console.error("Logging failed:", error);
    }
  };

  const sanitizeContext = (
    context: Record<string, AttributeValue>
  ): Attributes =>
    Object.fromEntries(Object.entries(context).map(([k, v]) => [k, String(v)]));

  const logError = (
    error: Error,
    context: Record<string, AttributeValue>,
    options?: LogOptions
  ) => {
    try {
      HyperDX.recordException(error, sanitizeContext(context));
    } catch (err) {
      console.error("Error logging failed:", err);
    }
  };

  return {
    logAction,
    logError,
  };
};
