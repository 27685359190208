import { DateTime } from "luxon";
import { Timesheet } from "types/timesheet";
import { ProjectAssignment } from "types/projects";

export const groupEntriesByWeek = (entries: Timesheet[]) => {
  return entries.reduce(
    (acc, entry) => {
      const entryDate = DateTime.fromISO(entry.date);
      const weekStart = entryDate.startOf("week");
      const weekEnd = entryDate.endOf("week");
      const weekKey = `${weekStart.toFormat("yyyy-MM-dd")} to ${weekEnd.toFormat("yyyy-MM-dd")}`;

      if (!acc[weekKey]) {
        acc[weekKey] = [];
      }
      acc[weekKey].push(entry);
      return acc;
    },
    {} as Record<string, Timesheet[]>
  );
};

export const getUniqueClients = (projectAssignments: ProjectAssignment[]) => {
  const uniqueClients = new Map();
  projectAssignments.forEach((assignment) => {
    if (!uniqueClients.has(assignment.clientId)) {
      uniqueClients.set(assignment.clientId, {
        id: assignment.clientId,
        name: assignment.clientName,
      });
    }
  });
  return Array.from(uniqueClients.values());
};

export const getFilteredProjects = (
  projectAssignments: ProjectAssignment[],
  selectedClientId: string
) => {
  const uniqueProjects = new Map();
  projectAssignments
    .filter((assignment) => assignment.clientId === selectedClientId)
    .forEach((assignment) => {
      if (!uniqueProjects.has(assignment.projectId)) {
        uniqueProjects.set(assignment.projectId, {
          id: assignment.projectId,
          name: assignment.projectName,
        });
      }
    });
  return Array.from(uniqueProjects.values());
};

export const getFilteredAssignments = (
  projectAssignments: ProjectAssignment[],
  selectedClientId: string | null,
  selectedProjectId: string | null
) => {
  if (!selectedClientId && !selectedProjectId) {
    return projectAssignments;
  }
  if (selectedClientId && !selectedProjectId) {
    return projectAssignments.filter(
      (assignment) => assignment.clientId === selectedClientId
    );
  }
  return projectAssignments.filter(
    (assignment) =>
      assignment.clientId === selectedClientId &&
      assignment.projectId === selectedProjectId
  );
};

export const getPaginatedEntries = (
  entries: Timesheet[],
  currentPage: number,
  itemsPerPage: number
) => {
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  return entries.slice(startIndex, endIndex);
};

export const getSortedEntries = (
  entries: Timesheet[],
  itemsPerPage: number
) => {
  const sortedEntries = [...entries].sort(
    (a, b) =>
      DateTime.fromISO(b.date).toMillis() - DateTime.fromISO(a.date).toMillis()
  );
  return sortedEntries.slice(0, itemsPerPage * 5);
};

export const getPaginationInfo = (
  entries: Timesheet[],
  currentPage: number,
  itemsPerPage: number
) => {
  const totalPages = Math.ceil(entries.length / itemsPerPage);
  const totalItems = entries.length;
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);

  return {
    totalPages,
    totalItems,
    startItem,
    endItem,
  };
}; 