import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { UpdateListParams } from "types/freelo/crud";

export const updateList = async (params: UpdateListParams): Promise<void> => {
  const { boardId, listId, updates, cardOrder } = params;
  const listRef = doc(db, "boards", boardId, "lists", listId);

  const updateData: any = {
    ...updates,
    updatedAt: DateTime.now().toISO(),
  };

  if (cardOrder) {
    // Ensure no duplicate card IDs in the order
    updateData.cardOrder = Array.from(new Set(cardOrder));
  }

  await updateDoc(listRef, updateData);
}; 