import React, { useState } from "react";
import {
  Box,
  IconButton,
  Tooltip,
  Avatar,
  AvatarGroup,
  Popover,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
} from "@mui/material";
import { MoreHoriz } from "@mui/icons-material";
import { useOnlineUsers } from "hooks/websocket/useOnlineUsers";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { useNavigate } from "react-router-dom";
import { getBoard } from "core/freelo/board/getBoard";
import { useQueryClient } from "@tanstack/react-query";
import { Board } from "types/freelo";

export const OnlineUsersDisplay = () => {

  if (window.location.pathname.includes("stg") || window.location.pathname.includes("localhost")) {
    return null;
  }

  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { data: users } = usePublicUserList();
  const { onlineStatus, getAllUserActivities } = useOnlineUsers();
  const queryClient = useQueryClient();
  const [boardNames, setBoardNames] = useState<Record<string, string>>({});
  const [loadingBoardNames, setLoadingBoardNames] = useState<
    Record<string, boolean>
  >({});

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const onlineUsers = Object.entries(onlineStatus).filter(
    ([_, data]) => data.online
  );
  const activities = getAllUserActivities();

  const formatLastSeen = (timestamp?: number) => {
    if (!timestamp) return "Unknown";
    const now = Date.now();
    const diff = now - timestamp;

    if (diff < 60000) return "Just now";
    if (diff < 3600000) return `${Math.floor(diff / 60000)}m ago`;
    if (diff < 86400000) return `${Math.floor(diff / 3600000)}h ago`;
    return new Date(timestamp).toLocaleDateString();
  };

  const handleLocationClick = (path: string, e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent parent click handlers from firing
    navigate(path);
    handleClose(); // Close the popover after navigation
  };

  const getBoardName = async (boardId: string) => {
    if (boardNames[boardId]) return boardNames[boardId];

    // Try to get from React Query cache first
    const cachedBoard = queryClient.getQueryData<Board>(["board", boardId]);
    if (cachedBoard?.name) {
      setBoardNames((prev) => ({ ...prev, [boardId]: cachedBoard.name }));
      return cachedBoard.name;
    }

    // If not in cache, try boards list cache
    const cachedBoards = queryClient.getQueryData<Board[]>(["boards"]);
    const boardFromList = cachedBoards?.find((board) => board.id === boardId);
    if (boardFromList?.name) {
      setBoardNames((prev) => ({ ...prev, [boardId]: boardFromList.name }));
      return boardFromList.name;
    }

    // If not in any cache, fetch from server
    try {
      setLoadingBoardNames((prev) => ({ ...prev, [boardId]: true }));
      const board = await getBoard(boardId);
      setBoardNames((prev) => ({ ...prev, [boardId]: board.name }));
      return board.name;
    } catch (error) {
      console.error("Error fetching board name:", error);
      return boardId; // Fallback to ID if fetch fails
    } finally {
      setLoadingBoardNames((prev) => ({ ...prev, [boardId]: false }));
    }
  };

  const formatPagePath = (path: string) => {
    const parts = path.replace(/^\//, "").split("/");

    if (parts[0] === "freelo" && parts[1]) {
      const boardId = parts[1];
      const boardName = boardNames[boardId];

      if (loadingBoardNames[boardId]) {
        return "Freelo → Loading...";
      }

      if (!boardName) {
        getBoardName(boardId);
        return "Freelo → Loading...";
      }

      return `Freelo → ${boardName}`;
    }

    return parts
      .map((part) =>
        part
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      )
      .join(" → ");
  };

  const getTooltipContent = (userId: string, data: any) => {
    const user = users?.find((u) => u.id === userId);

    return (
      <Box
        sx={{
          p: 2,
          bgcolor: "#2C3E50",
          borderRadius: 2,
          minWidth: 220,
          boxShadow: "0 4px 20px rgba(0,0,0,0.15)",
          border: "1px solid rgba(255,255,255,0.1)",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", mb: 1.5, gap: 1.5 }}>
          <Avatar
            src={user?.profilePicture || undefined}
            sx={{
              width: 40,
              height: 40,
              bgcolor: "success.main",
              border: "2px solid rgba(255,255,255,0.2)",
            }}
          >
            {user?.displayName?.[0]}
          </Avatar>
          <Box>
            <Typography
              variant="subtitle2"
              sx={{
                color: "#fff",
                fontWeight: 600,
                lineHeight: 1.2,
              }}
            >
              {user?.displayName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                mt: 0.5,
              }}
            >
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  bgcolor: "success.main",
                }}
              />
              <Typography
                variant="caption"
                sx={{
                  color: "success.light",
                  fontWeight: 500,
                }}
              >
                Online Now
              </Typography>
            </Box>
          </Box>
        </Box>

        {data.currentPage && (
          <Box
            onClick={(e) => handleLocationClick(data.currentPage, e)}
            sx={{
              p: 1,
              borderRadius: 1,
              bgcolor: "rgba(255,255,255,0.05)",
              mt: 1.5,
              cursor: "pointer",
              transition: "all 0.2s ease",
              "&:hover": {
                bgcolor: "rgba(255,255,255,0.1)",
              },
            }}
          >
            <Typography
              variant="caption"
              sx={{
                color: "rgba(255,255,255,0.7)",
                display: "block",
                fontSize: "0.75rem",
              }}
            >
              Current Page
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#fff",
                fontWeight: 500,
                wordBreak: "break-word",
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              {formatPagePath(data.currentPage)}
              {loadingBoardNames[data.currentPage.split("/")[2]] && (
                <CircularProgress size={12} sx={{ color: "white" }} />
              )}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <AvatarGroup
        max={7}
        sx={{
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            fontSize: "0.875rem",
            border: "2px solid #fff",
            bgcolor: "success.main",
          },
        }}
      >
        {onlineUsers.slice(0, 3).map(([userId, data]) => {
          const user = users?.find((u) => u.id === userId);
          return (
            <Tooltip
              key={userId}
              title={getTooltipContent(userId, data)}
              arrow
              placement="bottom"
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: "transparent",
                    "& .MuiTooltip-arrow": {
                      color: "#2C3E50",
                    },
                  },
                },
              }}
            >
              <Avatar
                src={user?.profilePicture || undefined}
                alt={user?.displayName || ""}
                sx={{ bgcolor: "success.main" }}
              >
                {user?.displayName?.[0]}
              </Avatar>
            </Tooltip>
          );
        })}
      </AvatarGroup>

      {onlineUsers.length > 1 && (
        <>
          <Tooltip title="View all online users">
            <IconButton size="small" onClick={handleClick} sx={{ ml: 0.5 }}>
              <MoreHoriz />
            </IconButton>
          </Tooltip>

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            PaperProps={{
              elevation: 8,
              sx: {
                width: 360,
                maxHeight: 480,
                overflow: "hidden",
                borderRadius: 3,
                mt: 1.5,
                background: "linear-gradient(to bottom, #ffffff, #f8f9fa)",
                border: "1px solid",
                borderColor: "divider",
              },
            }}
          >
            <Box
              sx={{
                p: 3,
                borderBottom: "1px solid",
                borderColor: "divider",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 700, mb: 0.5 }}>
                  Online Users
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {onlineUsers.length} active now
                </Typography>
              </Box>
              <Box
                sx={{
                  width: 36,
                  height: 36,
                  borderRadius: "12px",
                  bgcolor: "success.main",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxShadow: "0 4px 12px rgba(76, 175, 80, 0.2)",
                  border: "2px solid",
                  borderColor: "success.light",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    color: "#fff",
                    fontWeight: 700,
                    fontSize: "1.1rem",
                  }}
                >
                  {onlineUsers.length}
                </Typography>
              </Box>
            </Box>

            <List
              sx={{
                py: 2,
                px: 1.5,
                maxHeight: 380,
                overflow: "auto",
                "&::-webkit-scrollbar": {
                  width: "8px",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#f1f1f1",
                  borderRadius: "4px",
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#c1c1c1",
                  borderRadius: "4px",
                  "&:hover": {
                    background: "#a8a8a8",
                  },
                },
              }}
            >
              {onlineUsers.map(([userId, data]) => {
                const user = users?.find((u) => u.id === userId);
                const activity = activities[userId];

                return (
                  <ListItem
                    key={userId}
                    sx={{
                      py: 1.5,
                      px: 2,
                      mb: 1,
                      borderRadius: 2,
                      transition: "all 0.2s ease",
                      cursor: "pointer",
                      "&:hover": {
                        bgcolor: "rgba(0, 0, 0, 0.02)",
                        transform: "translateX(4px)",
                      },
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        gap: 2,
                      }}
                    >
                      <Box sx={{ position: "relative" }}>
                        <Avatar
                          src={user?.profilePicture || undefined}
                          sx={{
                            width: 40,
                            height: 40,
                            bgcolor: "success.main",
                          }}
                        >
                          {user?.displayName?.[0]}
                        </Avatar>
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            right: 0,
                            width: 12,
                            height: 12,
                            borderRadius: "50%",
                            bgcolor: "success.main",
                            border: "2px solid #fff",
                          }}
                        />
                      </Box>

                      <Box sx={{ ml: 2, flex: 1, minWidth: 0 }}>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            fontWeight: 600,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {user?.displayName || user?.id}
                        </Typography>

                        {data.currentPage && (
                          <Typography
                            onClick={(e) =>
                              data.currentPage &&
                              handleLocationClick(data.currentPage, e)
                            }
                            variant="caption"
                            sx={{
                              display: "block",
                              color: "text.secondary",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              cursor: "pointer",
                              "&:hover": {
                                color: "primary.main",
                                textDecoration: "underline",
                              },
                            }}
                          >
                            {formatPagePath(data.currentPage)}
                          </Typography>
                        )}

                        <Typography
                          variant="caption"
                          sx={{
                            color: "success.main",
                            fontWeight: 500,
                          }}
                        >
                          {formatLastSeen(data.lastPing)}
                        </Typography>
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
            </List>
          </Popover>
        </>
      )}
    </Box>
  );
};
