import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  CircularProgress,
  IconButton,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ViewFileDialog from "components/ViewFileDialog";
import { useAdminInvoicing } from "hooks/admin/useAdminInvoicing";
import { ClientInvoiceData } from "@freetech/models/bank";

const InvoiceList: React.FC = () => {
  const {
    invoices,
    isLoading,
    isError,
    selectedInvoice,
    isViewDialogOpen,
    pdfUrl,
    handleViewInvoice,
    handleCloseViewDialog,
    calculateTotalAmount,
    handleDeleteInvoice,
    handleUpdateInvoice,
  } = useAdminInvoicing();

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [invoiceToDelete, setInvoiceToDelete] = useState<ClientInvoiceData | null>(null);

  const handleDeleteClick = (invoice: ClientInvoiceData) => {
    setInvoiceToDelete(invoice);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (invoiceToDelete) {
      try {
        await handleDeleteInvoice({ clientId: invoiceToDelete.clientId, invoiceId: invoiceToDelete.id });
        setDeleteDialogOpen(false);
        setInvoiceToDelete(null);
      } catch (error) {
        console.error("Error deleting invoice:", error);
      }
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setInvoiceToDelete(null);
  };

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <CircularProgress />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <Typography color="error">Error loading invoices. Please try again later.</Typography>
      </Box>
    );
  }

  if (!invoices?.length) {
    return (
      <Box display="flex" justifyContent="center" p={3}>
        <Typography>No invoices found.</Typography>
      </Box>
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Client</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoices.map((invoice) => (
              <TableRow key={invoice.id}>
                <TableCell>{invoice.invoiceNumber}</TableCell>
                <TableCell>{invoice.clientName}</TableCell>
                <TableCell>{invoice.invoiceDate}</TableCell>
                <TableCell>
                  ${calculateTotalAmount(invoice).toFixed(2)}
                </TableCell>
                <TableCell>{invoice.status}</TableCell>
                <TableCell>
                  <Button onClick={() => handleViewInvoice(invoice)}>
                    View Invoice
                  </Button>
                  <IconButton
                    onClick={() =>
                      handleUpdateInvoice({
                        invoiceId: invoice.id,
                        invoiceData: invoice,
                      })
                    }
                    size="small"
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleDeleteClick(invoice)}
                    size="small"
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* View Invoice Dialog */}
      {selectedInvoice && pdfUrl && (
        <ViewFileDialog
          open={isViewDialogOpen}
          onClose={handleCloseViewDialog}
          fileUrl={pdfUrl}
          fileName={`Invoice_${selectedInvoice.invoiceNumber}.pdf`}
        />
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">
          Confirm Delete Invoice
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete invoice {invoiceToDelete?.invoiceNumber}? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="error" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvoiceList;
