import React from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  Paper,
  Grid,
  MenuItem,
  Divider,
  CircularProgress,
} from "@mui/material";
import { IncreaseExternalAccountDetails } from "@freetech/models/bank/bank";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useForm, Controller } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

const payrollFormSchema = z.object({
  accountHolderFullName: z.string().min(1, "Account holder name is required"),
  funding: z.enum(["checking", "savings", "other"] as const, {
    required_error: "Please select an account type",
  }),
  routingNumber: z
    .string()
    .min(9, "Routing number must be 9 digits")
    .max(9, "Routing number must be 9 digits")
    .regex(/^\d+$/, "Routing number must contain only digits"),
  accountNumber: z
    .string()
    .min(4, "Account number must be at least 4 digits")
    .regex(/^\d+$/, "Account number must contain only digits"),
  accountHolderType: z.enum(["individual", "business"] as const, {
    required_error: "Please select an account holder type",
  }),
});

export type PayrollFormData = z.infer<typeof payrollFormSchema>;

interface FreelancerPayrollFormProps {
  formData: Partial<IncreaseExternalAccountDetails>;
  onSubmit: (data: PayrollFormData) => Promise<void>;
  error: string | null;
  success: string | null;
  isUpdate?: boolean;
  onBack?: () => void;
}

const FreelancerPayrollForm: React.FC<FreelancerPayrollFormProps> = ({
  formData,
  onSubmit,
  error,
  success,
  isUpdate = false,
  onBack,
}) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PayrollFormData>({
    resolver: zodResolver(payrollFormSchema),
    defaultValues: {
      accountHolderFullName: formData.accountHolderFullName || "",
      funding: (formData.funding || "checking") as
        | "checking"
        | "savings"
        | "other",
      routingNumber: formData.routingNumber || "",
      accountNumber: formData.accountNumber || "",
      accountHolderType: (formData.accountHolderType || "individual") as
        | "individual"
        | "business",
    },
  });

  const handleFormSubmit = async (data: PayrollFormData) => {
    setIsSubmitting(true);
    try {
      await onSubmit(data);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      {onBack && (
        <Button startIcon={<ArrowBackIcon />} onClick={onBack} sx={{ mb: 3 }}>
          Back
        </Button>
      )}

      <Paper sx={{ p: 3 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 3 }}>
          <AccountBalanceIcon sx={{ mr: 1 }} color="primary" />
          <Typography variant="h5">
            {isUpdate
              ? "Update Direct Deposit Information"
              : "Add Direct Deposit Information"}
          </Typography>
        </Box>

        {error && (
          <Alert severity="error" sx={{ mb: 3 }}>
            {error === "Missing required payment information" ? (
              <>
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  Please fill in all required fields:
                </Typography>
                <ul style={{ margin: 0, paddingLeft: "1.5rem" }}>
                  <li>Account Holder Full Name</li>
                  <li>Account Type</li>
                  <li>Routing Number</li>
                  <li>Account Number</li>
                </ul>
              </>
            ) : error === "Invalid routing number" ? (
              "The routing number you entered is invalid. Please check and try again."
            ) : (
              error
            )}
          </Alert>
        )}

        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Controller
                name="accountHolderFullName"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label="Account Holder Full Name"
                    error={!!errors.accountHolderFullName}
                    helperText={
                      errors.accountHolderFullName?.message ||
                      "Enter the name exactly as it appears on your bank account"
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="funding"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    fullWidth
                    label="Account Type"
                    error={!!errors.funding}
                    helperText={errors.funding?.message}
                  >
                    <MenuItem value="checking">Checking</MenuItem>
                    <MenuItem value="savings">Savings</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="routingNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label="Routing Number"
                    error={!!errors.routingNumber}
                    helperText={
                      errors.routingNumber?.message ||
                      "9-digit bank routing number"
                    }
                    inputProps={{ maxLength: 9 }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="accountNumber"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    fullWidth
                    label="Account Number"
                    error={!!errors.accountNumber}
                    helperText={
                      errors.accountNumber?.message ||
                      "Your bank account number"
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="accountHolderType"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    required
                    select
                    fullWidth
                    label="Account Holder Type"
                    error={!!errors.accountHolderType}
                    helperText={errors.accountHolderType?.message}
                  >
                    <MenuItem value="individual">Individual</MenuItem>
                    <MenuItem value="business">Business</MenuItem>
                  </TextField>
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                Please verify that all information is correct. This information
                will be used to process your payments.
              </Typography>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                size="large"
                fullWidth
                disabled={isSubmitting}
                startIcon={
                  isSubmitting ? (
                    <CircularProgress size={20} color="inherit" />
                  ) : null
                }
              >
                {isSubmitting
                  ? "Saving..."
                  : isUpdate
                    ? "Update Payment Information"
                    : "Save Payment Information"}
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default FreelancerPayrollForm;
