import React, { useEffect } from "react";
import { Box, Container, Paper, Button } from "@mui/material";
import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { logoNoBackground } from "assets";
import LogoutIcon from "@mui/icons-material/Logout";
import { MainLoading } from "../MainLoading";

const OnboardingLayout: React.FC = () => {
  const { isLoading, currentUser, userInfo, logout } = useAuth();
  const navigate = useNavigate();

  if (isLoading) {
    return <MainLoading />;
  }

  useEffect(() => {
    if (userInfo?.needsInitialPasswordSet) {
      navigate("/needs-reset-password");
    } else if (userInfo && userInfo.needsOnboarding === false) {
      navigate(`/${userInfo.role}`, { replace: true });
    }
  }, [userInfo?.needsInitialPasswordSet, userInfo?.role, navigate]);

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        backgroundColor: (theme) => theme.palette.grey[100],
        paddingTop: "5vh",
        position: "relative",
      }}
    >
      <Button
        onClick={handleLogout}
        startIcon={<LogoutIcon />}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          color: "black",
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        Sign Out
      </Button>
      <Container component="main" maxWidth="md">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={logoNoBackground}
            alt="FreeTech Logo"
            sx={{
              width: "200px",
              height: "auto",
              marginBottom: 4,
            }}
          />
          <Paper
            elevation={6}
            sx={{
              padding: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              bgcolor: "white",
              width: "100%",
              borderRadius: 2,
              boxShadow: "0 4px 20px rgba(0,0,0,0.1)",
            }}
          >
            <Outlet />
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default OnboardingLayout;
