import {
  QueryDocumentSnapshot,
  SnapshotOptions,
  DocumentData,
  FirestoreDataConverter,
} from "firebase/firestore";
import { Freelancer } from "../user";
import { DateTime } from "luxon";

export const freelancerConverter: FirestoreDataConverter<Freelancer> = {
  toFirestore(freelancer: Freelancer): DocumentData {
    return {
      ...freelancer,
      securityClearance: freelancer.securityClearance
        ? {
            ...freelancer.securityClearance,
            expirationDate:
              typeof freelancer.securityClearance.expirationDate === "string"
                ? freelancer.securityClearance.expirationDate
                : freelancer.securityClearance.expirationDate || null,
          }
        : undefined,
      employment: freelancer.employment
        ? {
            ...freelancer.employment,
            startDate:
              typeof freelancer.employment.startDate === "string"
                ? freelancer.employment.startDate
                : freelancer.employment.startDate || null,
          }
        : undefined,
      createdAt: freelancer.createdAt || new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
  },
  fromFirestore(
    snapshot: QueryDocumentSnapshot,
    options: SnapshotOptions
  ): Freelancer {
    const data = snapshot.data(options);
    return {
      ...data,
      id: snapshot.id,
      email: data.email || "",
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      displayName: data.displayName || "",
      role: data.role || "freelancer",
      permissions: data.permissions || [],
      documents: data.documents || [],
      needsOnboarding: data.needsOnboarding,
      securityClearance: data.securityClearance
        ? {
            ...data.securityClearance,
            expirationDate: data.securityClearance.expirationDate
              ? DateTime.fromISO(data.securityClearance.expirationDate)
              : null,
          }
        : undefined,
      employment: data.employment
        ? {
            ...data.employment,
            startDate: data.employment.startDate
              ? DateTime.fromISO(data.employment.startDate)
              : null,
          }
        : undefined,
      disabled: data.disabled,
      lastLoginAt: data.lastLoginAt,
      lastPasswordResetRequestAt: data.lastPasswordResetRequestAt,
      needsPasswordReset: data.needsPasswordReset,
      passwordResetAt: data.passwordResetAt,
      onboardingCompletedAt: data.onboardingCompletedAt,
      createdAt: data.createdAt,
      updatedAt: data.updatedAt,
      needsInitialPasswordSet: data.needsInitialPasswordSet,
      initialPasswordSetAt: data.initialPasswordSetAt,
      lastVerificationEmailRequestAt: data.lastVerificationEmailRequestAt,
      lastSignInLinkRequestAt: data.lastSignInLinkRequestAt,
    };
  },
};

