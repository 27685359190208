import {
  getFirestore,
  doc,
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  getDoc,
  getDocs,
  query,
  where,
  runTransaction,
  serverTimestamp,
  WhereFilterOp,
  setDoc,
} from "firebase/firestore";

export const db = getFirestore();

export const getDocRef = (path: string) => doc(db, path);
export const getCollectionRef = (path: string) => collection(db, path);

export const addDocument = async (collectionPath: string, data: any) => {
  const collectionRef = getCollectionRef(collectionPath);
  return await addDoc(collectionRef, data);
};

export const addDocumentWithId = async (
  collectionPath: string,
  docId: string,
  data: any
) => {
  const docRef = doc(getCollectionRef(collectionPath), docId);
  return await setDoc(docRef, data);
};

export const deleteDocument = async (docPath: string) => {
  const docRef = getDocRef(docPath);
  await deleteDoc(docRef);
};

export const updateDocument = async (docPath: string, data: any) => {
  const docRef = getDocRef(docPath);
  await updateDoc(docRef, data);
};

export const getDocument = async <T>(docPath: string): Promise<T | null> => {
  const docRef = getDocRef(docPath);
  const docSnap = await getDoc(docRef);
  return docSnap.exists() ? ({ id: docSnap.id, ...docSnap.data() } as T) : null;
};

export const getDocuments = async <T>(
  collectionPath: string,
  whereClause?: [string, WhereFilterOp, any]
): Promise<T[]> => {
  const collectionRef = getCollectionRef(collectionPath);
  const q = whereClause
    ? query(collectionRef, where(...whereClause))
    : collectionRef;
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }) as T);
};

export const runFirestoreTransaction = async (
  updateFunction: (transaction: any) => Promise<any>
) => {
  return runTransaction(db, updateFunction);
};

export const getServerTimestamp = () => serverTimestamp();
