import React from "react";
import { Box } from "@mui/material";
import { logoNoBackground } from "assets";
import * as motion from "motion/react-client";

interface MainLoadingProps {
  fullscreen?: boolean;
}

export const MainLoading: React.FC<MainLoadingProps> = ({
  fullscreen = true,
}) => (
  <Box
    sx={{
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "background.default",
      zIndex: 9999,
    }}
  >
    <motion.img
      src={logoNoBackground}
      alt="FreeTech Logo"
      initial={{ opacity: 0, scale: 0 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 0.4,
        scale: { type: "spring", visualDuration: 0.4, bounce: 0.5 },
      }}
      style={{
        width: "200px",
        height: "auto",
      }}
    />
  </Box>
);
