import { collection, addDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { ICreateClient } from "@freetech/models";
import { clientConverter } from "@freetech/models/converters";

import { Client } from "types/projects";
import { useAuth } from "hooks/auth/useAuth";

export const createClient = async (newClient: ICreateClient) => {
  const { currentUser   } = useAuth();

  if (!currentUser) {
    throw new Error("User not found");
  }

  const clientData: ICreateClient = {
    ...newClient,
    companyName: newClient.companyName!,
    pointOfContactName: newClient.pointOfContactName!,
    email: newClient.email!,
    phone: newClient.phone!,
    location: newClient.location!,
    size: newClient.size!,
    status: newClient.status || "opportunity",
    introductionDate: newClient.introductionDate || new Date().toISOString(),
    createdByUserId: currentUser?.uid || "",
  };

  const clientsRef = collection(db, "clients").withConverter(clientConverter);
  return await addDoc(clientsRef, clientData);
};
