import React from "react";
import {
  StakeholderPayments,
  StakeholderProjects,
  StakeholderHome,
} from "views/Stakeholder";
import {
  Receipt as ReceiptIcon,
  Assignment as AssignmentIcon,
  Home as HomeIcon,
  AttachMoney as AttachMoneyIcon,
} from "@mui/icons-material";
import BugReportIcon from "@mui/icons-material/BugReport";
import { BugReport } from "views/Shared/BugReport";
import { CustomNavigation } from "router/types/customNavigation";
import { StakeholderProjectOverview } from "views/Stakeholder/StakeholderProjectOverview/StakeholderProjectOverview";
import { StakeholderProjectIdea } from "views/Stakeholder/StakeholderProjectIdea/StakeholderProjectIdea";
import { Freelo } from "views/Freelo";

export const stakeholderPages: CustomNavigation = [
  { kind: "header", title: "General" },
  {
    segment: "stakeholder",
    title: "Home",
    icon: <HomeIcon />,
    component: <StakeholderHome />,
  },
  {
    segment: "stakeholder/bill-pay",
    title: "Bill Pay",
    icon: <AttachMoneyIcon />,
    component: <StakeholderPayments />,
  },
  {
    segment: "stakeholder/projects",
    title: "Projects",
    icon: <AssignmentIcon />,
    component: <StakeholderProjects />,
    slugBabyPattern: ":projectId",
    slugBabyComponent: <StakeholderProjectOverview />,
    // children: [
    //   {
    //     segment: "new-idea",
    //     title: "Submit New Project Idea",
    //     icon: <AssignmentIcon />,
    //     component: <StakeholderProjectIdea />,
    //   },
    // ],
  },
  {
    segment: "stakeholder/new-idea",
    title: "Submit New Project Idea",
    icon: <AssignmentIcon />,
    component: <StakeholderProjectIdea />,
  },
  {
    segment: "bugreport",
    title: "Report Bug",
    icon: <BugReportIcon />,
    component: <BugReport />,
  },
];
