import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
  DocumentData,
} from "firebase/firestore";
import { Client } from "../projects";

export const clientConverter: FirestoreDataConverter<Client> = {
  toFirestore(client: WithFieldValue<Client>): DocumentData {
    const firestoreData = {
      id: client.id,
      companyName: client.companyName,
      pointOfContactName: client.pointOfContactName,
      email: client.email,
      phone: client.phone,
      location: client.location,
      size: client.size,
      status: client.status,
      introductionDate: client.introductionDate,
      associatedFreelancerIds: client.associatedFreelancerIds,
      associatedStakeholderIds: client.associatedStakeholderIds,
      companyPhysicalAddress: client.companyPhysicalAddress,
    };
    return firestoreData;
  },
  fromFirestore(snapshot: DocumentSnapshot, options: SnapshotOptions): Client {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      createdByUserId: data?.createdByUserId,
      companyName: data?.companyName,
      pointOfContactName: data?.pointOfContactName,
      email: data?.email,
      phone: data?.phone,
      location: data?.location,
      size: data?.size,
      status: data?.status,
      introductionDate: data?.introductionDate,
      associatedFreelancerIds: data?.associatedFreelancerIds,
      associatedStakeholderIds: data?.associatedStakeholderIds,
      companyPhysicalAddress: data?.companyPhysicalAddress,
    };
  },
};

