import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Container,
  CircularProgress,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { useKnowledgeBase } from "hooks/knowledgeBase/useKnowledgeBase";
import KnowledgeTree from "./components/KnowledgeTree/KnowledgeTree";
import KnowledgeBaseDocument from "./components/KnowledgeBaseDocument/KnowledgeBaseDocument";
import { DateTime } from "luxon";
import { useQueryClient } from "@tanstack/react-query";
import { generateId } from "core/knowledgeBase/utils/generateId";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { KnowledgeBaseFile, KnowledgeBaseStructure, KnowledgeBaseFolder } from "types/knowledgeBase";

const KnowledgeBase: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPath, setCurrentPath] = useState<string>("root");
  const [newFolderName, setNewFolderName] = useState("");
  const [isCreatingDocument, setIsCreatingDocument] = useState(false);
  const [newDocumentTitle, setNewDocumentTitle] = useState("");
  const [newDocumentContent, setNewDocumentContent] = useState("");
  const [openFolderDialog, setOpenFolderDialog] = useState(false);
  const [selectedDocument, setSelectedDocument] =
    useState<KnowledgeBaseFile | null>(null);
  const [isViewingDocument, setIsViewingDocument] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingContent, setEditingContent] = useState("");
  const [editingTitle, setEditingTitle] = useState("");
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  const {
    useKnowledgeBaseStructure,
    createFolder,
    createFile,
    updateFile,
    moveToTrash,
  } = useKnowledgeBase();

  const {
    data: structure,
    isLoading,
    error,
  } = useKnowledgeBaseStructure() as {
    data: KnowledgeBaseStructure | undefined;
    isLoading: boolean;
    error: Error | null;
  };

  const queryClient = useQueryClient();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isMobile && (isViewingDocument || isCreatingDocument)) {
      setIsSidebarCollapsed(true);
    }
  }, [isMobile, isViewingDocument, isCreatingDocument]);

  const handleCreateFolder = async () => {
    if (!newFolderName.trim()) return;

    try {
      await createFolder.mutateAsync({
        parentPath: currentPath,
        name: newFolderName.trim(),
      });
      setNewFolderName("");
      setOpenFolderDialog(false);
    } catch (error) {
      console.error("Error creating folder:", error);
    }
  };

  const handleCreateDocument = () => {
    setIsCreatingDocument(true);
    const timestamp = DateTime.now().toFormat("MM-dd-yyyy HH:mm");
    const defaultTitle = `Untitled (${timestamp})`;
    const tempId = `temp-${Date.now()}`;

    // Optimistically update the tree structure
    if (structure) {
      const updatedStructure = { ...structure };

      const addFileToFolder = (folder: KnowledgeBaseFolder) => {
        if (folder.path === currentPath) {
          folder.files = [
            ...folder.files,
            {
              id: tempId,
              name: defaultTitle,
              content: "",
              path: `${currentPath}/${defaultTitle}`,
              createdAt: DateTime.now().toISO(),
              updatedAt: DateTime.now().toISO(),
              createdBy: "current-user",
              lastModifiedBy: "current-user",
              metadata: { tags: [], description: "" },
              storageUrl: "",
            },
          ];
          return true;
        }
        for (const subFolder of folder.folders) {
          if (addFileToFolder(subFolder)) return true;
        }
        return false;
      };

      addFileToFolder(updatedStructure.rootFolder);
      queryClient.setQueryData(["knowledgeBase"], updatedStructure);
    }

    setNewDocumentTitle(defaultTitle);
    setNewDocumentContent("");
    setIsEditing(true);
  };

  const handleTitleChange = (newTitle: string, isNew: boolean = false) => {
    if (isNew) {
      setNewDocumentTitle(newTitle);
    } else {
      setEditingTitle(newTitle);

      // Update the tree item title immediately
      if (structure && selectedDocument) {
        const updatedStructure = { ...structure };
        const updateFileInFolder = (folder: KnowledgeBaseFolder) => {
          const fileIndex = folder.files.findIndex(
            (f) => f.id === selectedDocument.id
          );
          if (fileIndex !== -1) {
            folder.files[fileIndex] = {
              ...folder.files[fileIndex],
              name: newTitle,
              path: `${currentPath}/${newTitle}`,
            };
            return true;
          }
          for (const subFolder of folder.folders) {
            if (updateFileInFolder(subFolder)) return true;
          }
          return false;
        };
        updateFileInFolder(updatedStructure.rootFolder);
        queryClient.setQueryData(["knowledgeBase"], updatedStructure);
      }
    }
  };

  const handleSaveDocument = async (newContent: string) => {
    if (!newDocumentTitle.trim()) return;

    try {
      const savedFileId = await createFile.mutateAsync({
        folderPath: currentPath,
        name: newDocumentTitle.trim(),
        content: newDocumentContent.trim(),
      });

      // Find the newly created file in the structure
      const findNewFile = (
        folder: KnowledgeBaseFolder
      ): KnowledgeBaseFile | null => {
        const file = folder.files.find((f) => f.id === savedFileId);
        if (file) return file;

        for (const subFolder of folder.folders) {
          const found = findNewFile(subFolder);
          if (found) return found;
        }
        return null;
      };

      const newFile =
        structure?.rootFolder && findNewFile(structure.rootFolder);
      if (newFile) {
        setSelectedDocument(newFile);
        setIsViewingDocument(true);
      }

      setNewDocumentTitle("");
      setNewDocumentContent("");
      setIsCreatingDocument(false);
      setIsEditing(false);
    } catch (error) {
      console.error("Error creating document:", error);
    }
  };

  const handleCancelDocument = () => {
    setIsCreatingDocument(false);
    setNewDocumentTitle("");
    setNewDocumentContent("");
  };

  const handleFolderClick = (folderPath: string) => {
    setCurrentPath(folderPath);
    setSelectedDocument(null);
    setIsViewingDocument(false);
    if (folderPath === "root") {
      window.history.replaceState(null, "", window.location.pathname);
    } else {
      window.history.replaceState(
        null,
        "",
        `${window.location.pathname}#folder-${folderPath}`
      );
    }
  };

  const handleDocumentClick = (document: KnowledgeBaseFile) => {
    setSelectedDocument(document);
    setIsViewingDocument(true);
    setIsCreatingDocument(false);
    setEditingContent(document.content);
    setEditingTitle(document.name);
    window.history.replaceState(
      null,
      "",
      `${window.location.pathname}#${document.id}`
    );
  };

  const handleCloseDocument = () => {
    setSelectedDocument(null);
    setIsViewingDocument(false);
  };

  const handleUpdateDocument = async () => {
    if (!selectedDocument || !editingTitle.trim() || !editingContent.trim())
      return;

    try {
      // Update the document on the server
      await updateFile.mutateAsync({
        fileId: selectedDocument.id,
        name: editingTitle.trim(),
        content: editingContent,
      });

      const updatedAt = DateTime.now().toISO();

      // Create updated document with new title and content
      const updatedDocument = {
        ...selectedDocument,
        name: editingTitle.trim(),
        content: editingContent,
        updatedAt,
      };

      // Update local state
      setSelectedDocument(updatedDocument);

      // Update the structure
      if (structure) {
        const updatedStructure = { ...structure };
        const updateFileInFolder = (folder: KnowledgeBaseFolder) => {
          const fileIndex = folder.files.findIndex(
            (f) => f.id === selectedDocument.id
          );
          if (fileIndex !== -1) {
            folder.files[fileIndex] = updatedDocument;
            return true;
          }
          for (const subFolder of folder.folders) {
            if (updateFileInFolder(subFolder)) return true;
          }
          return false;
        };
        updateFileInFolder(updatedStructure.rootFolder);
        queryClient.setQueryData(["knowledgeBase"], updatedStructure);
      }

      // Invalidate and refetch to ensure fresh data
      await queryClient.invalidateQueries({ queryKey: ["knowledgeBase"] });

      setIsEditing(false);
    } catch (error) {
      console.error("Error updating document:", error);
      throw error;
    }
  };

  const handleCancelEdit = () => {
    if (selectedDocument) {
      setEditingContent(selectedDocument.content);
      setEditingTitle(selectedDocument.name);
      setIsEditing(false);
    }
  };

  const handleContentChange = (content: string) => {
    setEditingContent(content);
  };

  const handleMoveToTrash = async (documentId: string) => {
    try {
      await moveToTrash.mutateAsync({ knowledgeBaseDocumentId: documentId });

      // Update the local state
      if (structure) {
        const updatedStructure = { ...structure };
        const removeFileFromFolder = (folder: KnowledgeBaseFolder) => {
          const fileIndex = folder.files.findIndex((f) => f.id === documentId);
          if (fileIndex !== -1) {
            folder.files.splice(fileIndex, 1);
            return true;
          }
          for (const subFolder of folder.folders) {
            if (removeFileFromFolder(subFolder)) return true;
          }
          return false;
        };
        removeFileFromFolder(updatedStructure.rootFolder);
        queryClient.setQueryData(["knowledgeBase"], updatedStructure);
      }

      // Close the document view
      setSelectedDocument(null);
      setIsViewingDocument(false);

      // Show success message
      // You might want to add a Snackbar component for this

      // Invalidate queries to refresh the data
      await queryClient.invalidateQueries({ queryKey: ["knowledgeBase"] });
    } catch (error) {
      console.error("Error moving document to trash:", error);
      // Show error message
    }
  };

  // Add this effect to handle browser back/forward navigation
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.slice(1);

      if (!hash) {
        setCurrentPath("root");
        setSelectedDocument(null);
        setIsViewingDocument(false);
        return;
      }

      if (hash.startsWith("folder-")) {
        const folderName = hash.replace("folder-", "");
        const folder = structure?.rootFolder.folders.find(
          (f) => generateId(f.name) === folderName
        );
        if (folder) {
          setCurrentPath(folder.path);
          setSelectedDocument(null);
          setIsViewingDocument(false);
        }
      } else {
        // Look for file
        for (const folder of structure?.rootFolder.folders || []) {
          const file = folder.files.find((f) => generateId(f.name) === hash);
          if (file) {
            setSelectedDocument(file);
            setIsViewingDocument(true);
            setIsCreatingDocument(false);
            break;
          }
        }
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    return () => window.removeEventListener("hashchange", handleHashChange);
  }, [structure]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleClose = () => {
    setSelectedDocument(null);
    setIsViewingDocument(false);
    setIsEditing(false);
    // Reset URL hash when closing document
    window.history.replaceState(null, "", window.location.pathname);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />

        <Typography variant="h6">Loading knowledge base...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error">
          Error loading knowledge base: {error.message}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        overflow: "hidden",
        position: "relative",
      }}
    >
      {/* Left Sidebar */}
      <Paper
        elevation={3}
        sx={{
          display: "flex",
          flexDirection: "column",
          width: isSidebarCollapsed ? "0px" : "280px",
          minWidth: isSidebarCollapsed ? "0px" : "280px",
          height: "100%",
          borderRadius: 0,
          borderRight: "1px solid",
          borderColor: "divider",
          zIndex: isMobile ? 1200 : 2,
          transition: "all 0.3s ease",
          overflow: "hidden",
          position: isMobile ? "absolute" : "relative",
          visibility: isSidebarCollapsed ? "hidden" : "visible",
          bgcolor: "background.paper",
          ...(isMobile && {
            width: isSidebarCollapsed ? "0px" : "100%",
            minWidth: isSidebarCollapsed ? "0px" : "100%",
            maxWidth: "100%",
          }),
        }}
      >
        {/* Search Bar */}
        <Box
          sx={{
            p: 2,
            pb: 1,
            flexShrink: 0,
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {isMobile && (
            <IconButton
              onClick={() => setIsSidebarCollapsed(true)}
              edge="start"
              sx={{ ml: -1 }}
            >
              <ChevronLeftIcon />
            </IconButton>
          )}
          <TextField
            size="small"
            variant="outlined"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            InputProps={{
              startAdornment: (
                <SearchIcon sx={{ mr: 1, color: "text.secondary" }} />
              ),
            }}
            fullWidth
            sx={{ maxWidth: "100%" }}
          />
        </Box>

        {/* Tree Navigation */}
        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            minHeight: 0,
          }}
        >
          <KnowledgeTree
            structure={structure}
            isLoading={isLoading}
            error={error}
            onSelectFile={handleDocumentClick}
            onSelectFolder={handleFolderClick}
            currentPath={currentPath}
            onCreateFolder={(parentPath) => {
              setCurrentPath(parentPath);
              setOpenFolderDialog(true);
            }}
            onCreateDocument={(parentPath) => {
              setCurrentPath(parentPath);
              handleCreateDocument();
            }}
          />
        </Box>
      </Paper>

      {/* Document Area */}
      <Box
        sx={{
          flex: 1,
          height: "100%",
          overflow: "auto",
          bgcolor: "background.default",
          position: "relative",
          minWidth: 0,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Mobile Header when viewing document */}
        {isMobile && (isViewingDocument || isCreatingDocument) && (
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid",
              borderColor: "divider",
              bgcolor: "background.paper",
            }}
          >
            <IconButton
              onClick={() => {
                setIsSidebarCollapsed(false);
                if (isViewingDocument) {
                  setIsViewingDocument(false);
                  setSelectedDocument(null);
                }
                if (isCreatingDocument) {
                  setIsCreatingDocument(false);
                }
              }}
              edge="start"
              sx={{ mr: 2, ml: -1 }}
            >
              <ChevronLeftIcon />
            </IconButton>
            <Typography noWrap variant="subtitle1">
              {isCreatingDocument ? "New Document" : selectedDocument?.name}
            </Typography>
          </Box>
        )}

        {/* Hover zone with button when collapsed - Only show on desktop */}
        {!isMobile && isSidebarCollapsed && (
          <Box
            sx={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "24px",
              height: "100%",
              zIndex: 3,
              display: "flex",
              alignItems: "center",
              "&:hover": {
                "& .collapse-button": {
                  opacity: 1,
                },
              },
            }}
            onMouseEnter={(e) => {
              setIsHovering(true);
              const timeout = setTimeout(() => {
                if (isHovering) {
                  setIsSidebarCollapsed(false);
                }
              }, 500);
              return () => {
                clearTimeout(timeout);
              };
            }}
            onMouseLeave={() => {
              setIsHovering(false);
            }}
          >
            <IconButton
              className="collapse-button"
              sx={{
                position: "absolute",
                left: 0,
                backgroundColor: "background.paper",
                border: "1px solid",
                borderColor: "divider",
                borderRadius: "0 4px 4px 0",
                width: 24,
                height: 48,
                "&:hover": {
                  backgroundColor: "action.hover",
                },
              }}
              onClick={() => setIsSidebarCollapsed(false)}
            >
              <ChevronRightIcon fontSize="small" />
            </IconButton>
          </Box>
        )}

        {/* Collapse Button - Only show on desktop */}
        {!isMobile && !isSidebarCollapsed && (
          <IconButton
            onClick={() => {
              setIsSidebarCollapsed(true);
              setIsHovering(false);
            }}
            sx={{
              position: "absolute",
              left: 0,
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "background.paper",
              border: "1px solid",
              borderColor: "divider",
              borderRadius: "0 4px 4px 0",
              zIndex: 4,
              width: 24,
              height: 48,
              opacity: 0,
              transition: "opacity 0.2s ease",
              "&:hover": {
                backgroundColor: "action.hover",
                opacity: "1 !important",
              },
              ".MuiBox-root:hover &": {
                opacity: 1,
              },
            }}
          >
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
        )}

        {/* Document Content Area */}
        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            minHeight: 0,
            width: "100%",
            maxWidth: "100%",
            position: "relative",
          }}
        >
          {isCreatingDocument ? (
            <Box
              sx={{
                minHeight: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <KnowledgeBaseDocument
                document={{
                  id: "new",
                  name: newDocumentTitle,
                  content: newDocumentContent,
                  path: `${currentPath}/${newDocumentTitle}`,
                  createdAt: new Date().toISOString(),
                  updatedAt: new Date().toISOString(),
                  createdBy: "current-user",
                  lastModifiedBy: "current-user",
                  metadata: { tags: [], description: "" },
                  storageUrl: "",
                }}
                isEditing={true}
                editingTitle={newDocumentTitle}
                editingContent={newDocumentContent}
                onEditingTitleChange={(title) => handleTitleChange(title, true)}
                onCancel={handleCancelDocument}
                onEdit={() => {}}
                onClose={handleCancelDocument}
                onContentChange={setNewDocumentContent}
              />
            </Box>
          ) : isViewingDocument && selectedDocument ? (
            <Box
              sx={{
                minHeight: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <KnowledgeBaseDocument
                document={selectedDocument}
                isEditing={isEditing}
                editingTitle={editingTitle}
                editingContent={editingContent}
                onEditingTitleChange={setEditingTitle}
                onContentChange={handleContentChange}
                onCancel={handleCancelEdit}
                onEdit={() => setIsEditing(true)}
                onClose={handleCloseDocument}
                onDelete={() =>
                  selectedDocument && handleMoveToTrash(selectedDocument.id)
                }
                setSelectedDocument={setSelectedDocument}
                collaborationDocId={selectedDocument?.id}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "100%",
                p: 3,
                maxWidth: "100%",
              }}
            >
              <Typography color="text.secondary" sx={{ textAlign: "center" }}>
                Select a document from the tree to view or edit
              </Typography>
            </Box>
          )}
        </Box>
      </Box>

      {/* Folder Dialog */}
      <Dialog
        open={openFolderDialog}
        onClose={() => setOpenFolderDialog(false)}
      >
        <DialogTitle>Create New Folder</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Folder Name"
            fullWidth
            value={newFolderName}
            onChange={(e) => setNewFolderName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFolderDialog(false)}>Cancel</Button>
          <Button onClick={handleCreateFolder} variant="contained">
            Create
          </Button>
        </DialogActions>
      </Dialog>

      {isMobile && isSidebarCollapsed && (
        <IconButton
          onClick={() => setIsSidebarCollapsed(false)}
          sx={{
            position: "fixed",
            left: 0,
            top: "50%",
            transform: "translateY(-50%)",
            backgroundColor: "background.paper",
            border: "1px solid",
            borderColor: "divider",
            borderRadius: "0 4px 4px 0",
            zIndex: 1300,
            width: 24,
            height: 48,
            "&:hover": {
              backgroundColor: "action.hover",
            },
          }}
        >
          <ChevronRightIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};

export default KnowledgeBase;
