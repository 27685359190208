import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import ProtectedRoute from "./ProtectedRoute";

const StakeholderRoute: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { userInfo } = useAuth();

  return (
    <ProtectedRoute>
      {userInfo?.role === "stakeholder" ? (
        children
      ) : (
        <Navigate to="/not-found" replace />
      )}
    </ProtectedRoute>
  );
};

export default StakeholderRoute;
