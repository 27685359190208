import { collection, doc, deleteDoc, getDocs, query, where } from "firebase/firestore";
import { QueryClient } from "@tanstack/react-query";
import { db } from "config/firebase";
import { DeleteListParams } from "types/freelo/crud";
import { Board, List } from "types/freelo";
export const deleteList = async (params: DeleteListParams, queryClient: QueryClient): Promise<void> => {
  const { boardId, listId } = params;

  // Optimistically update the UI
  queryClient.setQueryData<Board>(["board", boardId], (oldBoard: Board | undefined) => {
    if (!oldBoard) return oldBoard;

    return {
      ...oldBoard,
      lists: oldBoard.lists?.filter((list: List) => list.id !== listId) || [],
      listOrder: oldBoard.listOrder?.filter((id: string) => id !== listId) || []
    };
  });

  try {
    // Delete all cards associated with this list
    const cardsRef = collection(db, "boards", boardId, "cards");
    const q = query(cardsRef, where("listId", "==", listId));
    const cardsSnapshot = await getDocs(q);

    await Promise.all(
      cardsSnapshot.docs.map((cardDoc) =>
        deleteDoc(doc(db, "boards", boardId, "cards", cardDoc.id))
      )
    );

    // Delete the list itself
    const listRef = doc(db, "boards", boardId, "lists", listId);
    await deleteDoc(listRef);
  } catch (error) {
    // If there's an error, rollback the optimistic update
    queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    throw error;
  }
}; 