import { INotification, ProjectAssignmentNotificationData, CardNotificationData, FreelancerPaymentNotificationData } from "@freetech/models";
import { NotificationType } from "@freetech/models/notifications/enums";
export const handleNotificationClick = (notification: INotification): string | undefined => {
  const { type, data } = notification;

  switch (type) {
    case NotificationType.MENTION:
    case NotificationType.COMMENT:
    case NotificationType.ASSIGNMENT:
      const cardData = data as CardNotificationData;
      return `/freelo/${cardData.boardId}#${cardData.humanReadableId}`;
    
    case NotificationType.PROJECT_ASSIGNMENT:
      const projectData = data as ProjectAssignmentNotificationData;
      return `/projects/${projectData.projectId}`;
    
    case NotificationType.FREELANCER_PAYMENT:
      const paymentData = data as FreelancerPaymentNotificationData;
      return `/payments/${paymentData.projectAssignmentId}`;
    
    case NotificationType.INVOICE_SENT:
    case NotificationType.INVOICE_STATUS:
      return data.link;
    
    default:
      return data.link;
  }
};
