import { Timesheet } from "types/timesheet";
import { isValidDuration } from "./timeEntryUtils";

interface ValidationResult {
  isValid: boolean;
  error?: string;
  isBelowMinDuration?: boolean;
}

export const validateTimeEntry = (
  entry: Partial<Timesheet>
): ValidationResult => {
  if (!entry.startTime || !entry.endTime || !entry.date) {
    return {
      isValid: false,
      error: "Please fill in all required fields",
    };
  }

  if (!isValidDuration(entry.startTime, entry.endTime)) {
    return {
      isValid: false,
      isBelowMinDuration: true,
    };
  }

  return { isValid: true };
};
