import React, { useState, useCallback } from 'react';
import { Box, Typography, Paper, Grid, Button, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, Select, MenuItem } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import SearchIcon from '@mui/icons-material/Search';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useDropzone } from 'react-dropzone';
import { UseMutateFunction } from '@tanstack/react-query';

import { useAuth } from "hooks/auth/useAuth";
import ViewFileDialog from "components/ViewFileDialog";
import { UserDocument } from "types/user";

interface DocumentsSectionProps {
  documents: UserDocument[];
  onUploadDocument: UseMutateFunction<UserDocument, Error, { file: File; document: UserDocument; }>;
}

const predefinedDocumentTypes = [
  "Independent Engineer Agreement",
  "Driver's License",
  "Passport",
  "Birth Certificate",
  "Legacy Project Assignment",
  "Legal",
  "Independent Contractor Agreement",
  "Headshot",
  "Certificate",
  "Other"
];

const DocumentsSection: React.FC<DocumentsSectionProps> = ({ documents, onUploadDocument }) => {
  const [documentSearchTerm, setDocumentSearchTerm] = React.useState("");
  const [filteredDocuments, setFilteredDocuments] = React.useState<UserDocument[]>(documents);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [newDocument, setNewDocument] = useState<Partial<UserDocument>>({});
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [viewFileDialogOpen, setViewFileDialogOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<UserDocument | null>(null);

  const { userInfo } = useAuth();

  React.useEffect(() => {
    const filtered = documents.filter(doc => 
      Object.values(doc).some(value => 
        value && value.toString().toLowerCase().includes(documentSearchTerm.toLowerCase())
      )
    );
    setFilteredDocuments(filtered);
  }, [documentSearchTerm, documents]);

  const handleUploadDocument = async () => {
    if (!uploadFile || !newDocument.name || !newDocument.documentDate || !newDocument.documentType) {
      // Show error message
      return;
    }

    try {
      onUploadDocument({ 
        file: uploadFile, 
        document: newDocument as UserDocument 
      });
      setOpenUploadDialog(false);
      setNewDocument({});
      setUploadFile(null);
      // Show success message
    } catch (error) {
      console.error("Error uploading document:", error);
      // Show error message
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    setUploadFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleViewDocument = (document: UserDocument) => {
    setSelectedDocument(document);
    setViewFileDialogOpen(true);
  };

  const handleCloseViewFileDialog = () => {
    setViewFileDialogOpen(false);
    setSelectedDocument(null);
  };

  return (
    <Paper 
      elevation={3}
      sx={{
        mb: 3,
        p: 2,
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center" flexGrow={1}>
          <DescriptionIcon sx={{ fontSize: 40, mr: 2 }} />
          <TextField
            placeholder="Search documents"
            variant="outlined"
            size="small"
            value={documentSearchTerm}
            onChange={(e) => setDocumentSearchTerm(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            sx={{ flexGrow: 1, mr: 2 }}
          />
        </Box>
        <Button
          variant="contained"
          startIcon={<UploadFileIcon />}
          onClick={() => setOpenUploadDialog(true)}
        >
          Upload Document
        </Button>
      </Box>
      <Grid container spacing={2}>
        {filteredDocuments.map((doc) => (
          <Grid item xs={12} sm={6} md={4} key={doc.id}>
            <Paper elevation={2} sx={{ p: 2 }}>
              <Typography variant="h6" gutterBottom>{doc.name}</Typography>
              <Typography variant="body2">Upload Date: {new Date(doc.uploadDate).toLocaleDateString()}</Typography>
              <Typography variant="body2">Document Date: {new Date(doc.documentDate).toLocaleDateString()}</Typography>
              <Typography variant="body2">Document Type: {doc.documentType}</Typography>
              <Button
                size="small"
                onClick={() => handleViewDocument(doc)}
              >
                View Document
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
      <Dialog open={openUploadDialog} onClose={() => setOpenUploadDialog(false)}>
        <DialogTitle>Upload Document</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            margin="normal"
            label="Document Name"
            value={newDocument.name || ''}
            onChange={(e) => setNewDocument({...newDocument, name: e.target.value})}
          />
          <TextField
            fullWidth
            margin="normal"
            label="Document Date"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={newDocument.documentDate || ''}
            onChange={(e) => setNewDocument({...newDocument, documentDate: e.target.value})}
          />
          <Select
            fullWidth
            margin="dense"
            label="Document Type"
            value={newDocument.documentType}
            onChange={(e) => setNewDocument({...newDocument, documentType: e.target.value})}
          >
            {predefinedDocumentTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </Select>
          <Box {...getRootProps()} sx={{
            border: '2px dashed #cccccc',
            borderRadius: '4px',
            padding: '20px',
            textAlign: 'center',
            cursor: 'pointer',
            mt: 2
          }}>
            <input {...getInputProps()} />
            {isDragActive ? (
              <Typography>Drop the file here ...</Typography>
            ) : (
              <Typography>Drag and drop a file here, or click to select a file</Typography>
            )}
          </Box>
          {uploadFile && <Typography sx={{ mt: 2 }}>{uploadFile.name}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUploadDialog(false)}>Cancel</Button>
          <Button onClick={handleUploadDocument} variant="contained" color="primary">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
      {selectedDocument && (
        <ViewFileDialog
          open={viewFileDialogOpen}
          onClose={handleCloseViewFileDialog}
          fileUrl={selectedDocument.fileUrl}
          fileName={selectedDocument.name || 'Unknown Document'}
        />
      )}
    </Paper>
  );
};

export default DocumentsSection;
