import { collection, getDocs } from "firebase/firestore";
import { db } from "config/firebase";
import { UserDocument } from "types/user";

export const fetchStakeholderDocuments = async (stakeholderId: string): Promise<UserDocument[]> => {
  if (!stakeholderId) return [];
  
  const documentsRef = collection(db, "users", stakeholderId, "documents");
  const snapshot = await getDocs(documentsRef);
  
  return snapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data()
  })) as UserDocument[];
}; 