import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { Board, List } from "types/freelo";
interface MoveListParams {
  boardId: string;
  listId: string;
  destinationIndex: number;
}

export const moveList = async (params: MoveListParams): Promise<void> => {
  const { boardId, listId, destinationIndex } = params;

  try {
    // Get the current board
    const boardRef = doc(db, "boards", boardId);
    const boardDoc = await getDoc(boardRef);
    
    if (!boardDoc.exists()) {
      throw new Error("Board not found");
    }

    const board = boardDoc.data() as Board;
    const currentListOrder = board.listOrder || [];

    // Find current index
    const currentIndex = currentListOrder.indexOf(listId);
    if (currentIndex === -1) return;

    // Create new order
    const newListOrder = Array.from(currentListOrder);
    newListOrder.splice(currentIndex, 1);
    newListOrder.splice(destinationIndex, 0, listId);

    // Update the board document
    await updateDoc(boardRef, {
      listOrder: newListOrder,
      updatedAt: DateTime.now().toISO(),
    });

  } catch (error) {
    console.error('Error moving list:', error);
    throw error;
  }
}; 