interface FreelancerOnboardingCompleteParams {
  firstName: string;
  lastName: string;
}

export const freelancerOnboardingComplete = (params: FreelancerOnboardingCompleteParams) => {
  const { firstName, lastName } = params;

  return {
    subject: 'Welcome to FreeTech - Onboarding Complete!',
    html: `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h2>Welcome to FreeTech, ${firstName}!</h2>
        
        <p>Thank you for completing your onboarding process. We're excited to have you join our network of talented freelancers!</p>
        
        <h3>What's Next?</h3>
        <ul>
          <li>Our team will review your profile and qualifications</li>
          <li>You'll receive notifications about projects that match your skills</li>
          <li>You can start browsing available projects in your dashboard</li>
        </ul>
        
        <p>If you have any questions, please don't hesitate to reach out to our support team.</p>
        
        <div style="margin-top: 30px;">
          <p>Best regards,</p>
          <p>The FreeTech Team</p>
        </div>
      </div>
    `,
  };
}; 