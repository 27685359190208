import React from "react";
import { Box, Typography, Avatar, AvatarGroup, Tooltip } from "@mui/material";
import { DateTime } from "luxon";
import { useProfilePicture } from "hooks/user/useProfilePicture";
import { CircularProgress } from "@mui/material";
import { KnowledgeBaseFile } from "types/knowledgeBase";

interface AwarenessState {
  user: {
    name: string;
    color: string;
    email?: string;
    uid?: string;
  };
}

interface CollaboratorAvatarProps {
  user: {
    name: string;
    color: string;
    email?: string;
    uid?: string;
  };
}

const CollaboratorAvatar: React.FC<CollaboratorAvatarProps> = ({ user }) => {
  const { url: profilePicUrl, isLoading } = useProfilePicture(user.uid || "");

  return (
    <Tooltip title={user.email || "No email"} arrow>
      <Avatar
        src={profilePicUrl || undefined}
        sx={{
          bgcolor: user.color,
          ...(isLoading && {
            opacity: 0.7,
          }),
        }}
      >
        {isLoading ? (
          <CircularProgress size={16} color="inherit" />
        ) : (
          user.name.charAt(0).toUpperCase()
        )}
      </Avatar>
    </Tooltip>
  );
};

interface DocumentHeaderProps {
  document: KnowledgeBaseFile;
  isTitleEditing: boolean;
  localTitle: string;
  editingTitle: string;
  isEditing: boolean;
  isSaving: boolean;
  provider?: any;
  onTitleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onTitleBlur: () => void;
  onTitleKeyDown: (e: React.KeyboardEvent) => void;
  onTitleClick: () => void;
  ConnectionStatusIndicator: React.FC;
}

export const DocumentHeader: React.FC<DocumentHeaderProps> = ({
  document,
  isTitleEditing,
  localTitle,
  editingTitle,
  isEditing,
  isSaving,
  provider,
  onTitleChange,
  onTitleBlur,
  onTitleKeyDown,
  onTitleClick,
  ConnectionStatusIndicator,
}) => {
  const getActiveUsers = () => {
    if (!provider?.awareness?.getStates()) return [];

    const states = Array.from(provider.awareness.getStates().entries());
    return states.filter((entry): entry is [number, AwarenessState] => {
      const [_, state] = entry as [number, any];
      return Boolean(state?.user);
    });
  };

  const activeUsers = getActiveUsers();

  const lastUpdated = document.updatedAt
    ? DateTime.fromISO(document.updatedAt).toRelative()
    : "Never";

  return (
    <Box className="document-header">
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        {isTitleEditing ? (
          <input
            className="document-title-input"
            value={localTitle}
            onChange={onTitleChange}
            onBlur={onTitleBlur}
            onKeyDown={onTitleKeyDown}
            disabled={isSaving}
          />
        ) : (
          <Typography
            className="document-title"
            onClick={onTitleClick}
            sx={{
              cursor: isEditing ? "text" : "default",
              "&:hover": {
                backgroundColor: isEditing ? "#f3f4f6" : "transparent",
              },
            }}
          >
            {editingTitle || document.name}
          </Typography>
        )}
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
        <Typography className="document-meta">
          Last updated {lastUpdated}
          {isSaving && " • Saving..."}
        </Typography>

        <ConnectionStatusIndicator />

        {provider && (
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography variant="body2" color="text.secondary">
              •
            </Typography>
            {activeUsers.length > 0 ? (
              <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                <AvatarGroup
                  max={4}
                  sx={{
                    "& .MuiAvatar-root": {
                      width: 24,
                      height: 24,
                      fontSize: "0.75rem",
                    },
                  }}
                >
                  {activeUsers.map(([clientId, state]) => (
                    <CollaboratorAvatar key={clientId} user={state.user} />
                  ))}
                </AvatarGroup>
                <Typography variant="body2" color="text.secondary">
                  {activeUsers.length === 1
                    ? "1 active user"
                    : `${activeUsers.length} active users`}
                </Typography>
              </Box>
            ) : (
              <Typography variant="body2" color="text.secondary">
                No active users
              </Typography>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default DocumentHeader;
