import { doc, deleteDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DeleteProjectAssignmentParams } from "types/admin";

export const deleteProjectAssignment = async ({
  clientId,
  engineerId,
  assignmentId
}: DeleteProjectAssignmentParams): Promise<boolean> => {
  await deleteDoc(doc(db, "clients", clientId, "projectAssignments", assignmentId));
  await deleteDoc(doc(db, "users", engineerId, "projectAssignments", assignmentId));
  return true;
}; 