import { DateTime } from "luxon";
import {
  KnowledgeBaseFile,
  KnowledgeBaseFolder,
  KnowledgeBaseStructure,
} from "types/knowledgeBase";

export const createOptimisticFile = (
  newFile: { folderPath: string; name: string; content: string },
  createdBy: string
): KnowledgeBaseFile => {
  return {
    id: `temp-${Date.now()}`,
    name: newFile.name,
    content: newFile.content,
    path: `${newFile.folderPath}/${newFile.name}`,
    createdAt: DateTime.now().toISO(),
    updatedAt: DateTime.now().toISO(),
    createdBy,
    lastModifiedBy: createdBy,
    metadata: { tags: [], description: "" },
    storageUrl: "",
  };
};

export const addFileToFolderStructure = (
  structure: KnowledgeBaseStructure,
  file: KnowledgeBaseFile,
  folderPath: string
): KnowledgeBaseStructure => {
  const updatedData = { ...structure };

  const addFileToFolder = (folder: KnowledgeBaseFolder): boolean => {
    if (folder.path === folderPath) {
      folder.files = [...folder.files, file];
      return true;
    }
    for (const subFolder of folder.folders) {
      if (addFileToFolder(subFolder)) return true;
    }
    return false;
  };

  addFileToFolder(updatedData.rootFolder);
  return updatedData;
};
