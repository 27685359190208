import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Card,
  CardContent,
  Grid,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useAuth } from "hooks/auth/useAuth";
import { useManageUsers } from "hooks/admin/useManageUsers";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { useAdminClients } from "hooks/admin/useAdminClients";
import { useSnackbar } from "hooks/common/useSnackbar";
import { AppSnackbar } from "components/common/AppSnackbar";
import ViewFileDialog from "components/ViewFileDialog";
import { ProjectAssignment } from "types/projects";
import { Client } from "types/projects";
import { PrivateUserInfoType } from "types/user";
import { DateTime } from "luxon";
import { GenerateProjectAssignmentTemplateRequestBody } from "@freetech/models/eversign/params";

const emptyDraft = (): GenerateProjectAssignmentTemplateRequestBody => ({
  userId: "",
  adminId: "",
  projectAssignment: {
    engineerId: "",
    clientId: "",
    projectId: "",
    csaId: "",
    billableRate: 0,
    budgetedHours: 0,
    budgetedDollarAmount: 0,
    startDate: "",
    endDate: "",
    scopeOfWork: "",
    status: "signing",
    id: "",
  },
});

const ProjectAssignmentGenerator: React.FC = () => {
  const [adminUsers, setAdminUsers] = useState<PrivateUserInfoType[]>([]);
  const [freelancerUsers, setFreelancerUsers] = useState<PrivateUserInfoType[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedAssignment, setSelectedAssignment] =
    useState<ProjectAssignment | null>(null);
  const [draftAssignments, setDraftAssignments] = useState<
    GenerateProjectAssignmentTemplateRequestBody[]
  >([]);

  const { privateUsers: users } = useManageUsers();
  const {
    data: freelancers,
    previousMonthAssignments,
    isLoadingPreviousMonthAssignments,
    previousMonthAssignmentsError,
    generateProjectAssignment,
  } = useAdminFreelancers();
  const { clients } = useAdminClients();
  const { snackbar, showSnackbar, hideSnackbar } = useSnackbar();

  useEffect(() => {
    if (users) {
      setAdminUsers(users.filter((user) => user.role === "admin"));
      setFreelancerUsers(
        users.filter(
          (user) => user.role === "freelancer" || user.role === "admin"
        )
      );
    }
  }, [users]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setConfirmDialogOpen(true);
  };

  const handleConfirmGenerate = async () => {
    setConfirmDialogOpen(false);
    setLoading(true);

    try {
      // Validate all assignments before processing
      const validationErrors: string[] = [];
      draftAssignments.forEach((draft, index) => {
        if (!draft.projectAssignment.sentByAdminUserId) {
          validationErrors.push(`Assignment ${index + 1}: Admin is required`);
        }
        if (!draft.projectAssignment.engineerId) {
          validationErrors.push(
            `Assignment ${index + 1}: Freelancer is required`
          );
        }
        if (!draft.projectAssignment.clientId) {
          validationErrors.push(`Assignment ${index + 1}: Client is required`);
        }
        if (!draft.projectAssignment.projectId) {
          validationErrors.push(`Assignment ${index + 1}: Project is required`);
        }
        if (!draft.projectAssignment.csaId) {
          validationErrors.push(`Assignment ${index + 1}: CSA is required`);
        }
        if (!draft.projectAssignment.startDate) {
          validationErrors.push(
            `Assignment ${index + 1}: Start date is required`
          );
        }
        if (!draft.projectAssignment.endDate) {
          validationErrors.push(
            `Assignment ${index + 1}: End date is required`
          );
        }
        if (!draft.projectAssignment.billableRate) {
          validationErrors.push(
            `Assignment ${index + 1}: Billable rate is required`
          );
        }
        if (!draft.projectAssignment.budgetedHours) {
          validationErrors.push(
            `Assignment ${index + 1}: Budgeted hours is required`
          );
        }
        if (!draft.projectAssignment.scopeOfWork) {
          validationErrors.push(
            `Assignment ${index + 1}: Scope of work is required`
          );
        }
      });

      if (validationErrors.length > 0) {
        throw new Error(`Validation failed:\n${validationErrors.join("\n")}`);
      }

      // Process assignments sequentially
      const results = [];
      for (const draft of draftAssignments) {
        try {
          const result = await generateProjectAssignment.mutateAsync({
            userId: draft.userId,
            adminId: draft.adminId,
            projectAssignment: {
              engineerId: draft.projectAssignment.engineerId,
              clientId: draft.projectAssignment.clientId,
              projectId: draft.projectAssignment.projectId,
              csaId: draft.projectAssignment.csaId,
              billableRate: draft.projectAssignment.billableRate,
              budgetedHours: draft.projectAssignment.budgetedHours,
              budgetedDollarAmount:
                draft.projectAssignment.budgetedDollarAmount,
              startDate: draft.projectAssignment.startDate,
              endDate: draft.projectAssignment.endDate,
              scopeOfWork: draft.projectAssignment.scopeOfWork,
              status: draft.projectAssignment.status,
              id: draft.projectAssignment.id,
              sentByAdminUserId: draft.projectAssignment.sentByAdminUserId,
            },
          });
          results.push(result);
        } catch (error) {
          // If any assignment fails, throw error with details
          const errorMessage =
            error instanceof Error ? error.message : String(error);
          throw new Error(
            `Failed to generate assignment for ${draft.projectAssignment.engineerId}: ${errorMessage}`
          );
        }
      }

      console.log("Successfully generated assignments:", results);
      showSnackbar(
        `Successfully generated ${results.length} project assignment(s)`,
        "success"
      );
      setDraftAssignments([]);
    } catch (error) {
      console.error("Error:", error);
      const errorMessage =
        error instanceof Error ? error.message : String(error);
      showSnackbar(
        errorMessage || "Error generating project assignments",
        "error"
      );
    } finally {
      setLoading(false);
    }
  };

  const addDraft = () => {
    setDraftAssignments([...draftAssignments, emptyDraft()]);
  };

  const removeDraft = (index: number) => {
    setDraftAssignments(draftAssignments.filter((_, i) => i !== index));
  };

  const updateDraft = (
    index: number,
    updates: Partial<GenerateProjectAssignmentTemplateRequestBody>
  ) => {
    setDraftAssignments(
      draftAssignments.map((draft, i) =>
        i === index ? { ...draft, ...updates } : draft
      )
    );
  };

  const handleAdminChange = (index: number, userId: string) => {
    const admin = adminUsers.find((user) => user.id === userId);
    if (admin) {
      updateDraft(index, {
        projectAssignment: {
          ...draftAssignments[index].projectAssignment,
          sentByAdminUserId: admin.id,
        },
      });
    }
  };

  const handleEngineerChange = (index: number, userId: string) => {
    const engineer = freelancerUsers.find((user) => user.id === userId);
    if (engineer) {
      updateDraft(index, {
        projectAssignment: {
          ...draftAssignments[index].projectAssignment,
          engineerId: engineer.id,
        },
      });
    }
  };

  const handleClientChange = (index: number, clientId: string) => {
    updateDraft(index, {
      projectAssignment: {
        ...draftAssignments[index].projectAssignment,
        clientId,
        projectId: "", // Reset project when client changes
        csaId: "", // Reset CSA when client changes
      },
    });
  };

  const handleProjectChange = (index: number, projectId: string) => {
    updateDraft(index, {
      projectAssignment: {
        ...draftAssignments[index].projectAssignment,
        projectId,
        csaId: "", // Reset CSA when project changes
      },
    });
  };

  const renderEditView = (
    draft: GenerateProjectAssignmentTemplateRequestBody,
    draftIndex: number
  ) => {
    const selectedClient = clients?.find(
      (c) => c.id === draft.projectAssignment.clientId
    );
    const activeCSAs =
      selectedClient?.consultingServicesAgreements?.filter(
        (csa) => csa.status === "active"
      ) || [];

    if (isLoadingPreviousMonthAssignments) {
      return <CircularProgress />;
    }

    if (previousMonthAssignmentsError) {
      return <Typography>Error loading previous month assignments</Typography>;
    }

    return (
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Maximum Dollar Budget: $
                  {(
                    (draft.projectAssignment.billableRate || 0) *
                    (draft.projectAssignment.budgetedHours || 0)
                  ).toFixed(2)}
                </Typography>
                <IconButton
                  onClick={() => {
                    const newDraft = {
                      ...draft,
                      projectAssignment: {
                        ...draft.projectAssignment,
                        id: "", // Reset ID for new assignment
                      },
                    };
                    setDraftAssignments([
                      ...draftAssignments.slice(0, draftIndex + 1),
                      newDraft,
                      ...draftAssignments.slice(draftIndex + 1),
                    ]);
                  }}
                  color="primary"
                  size="small"
                >
                  <ContentCopyIcon />
                </IconButton>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Client</InputLabel>
              <Select
                value={draft.projectAssignment.clientId}
                onChange={(e) => handleClientChange(draftIndex, e.target.value)}
                label="Client"
              >
                {clients?.map((client) => (
                  <MenuItem key={client.id} value={client.id}>
                    {client.companyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Project</InputLabel>
              <Select
                value={draft.projectAssignment.projectId}
                onChange={(e) =>
                  handleProjectChange(draftIndex, e.target.value)
                }
                label="Project"
                disabled={!draft.projectAssignment.clientId}
              >
                {selectedClient?.projects?.map((project) => (
                  <MenuItem key={project.id} value={project.id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>CSA</InputLabel>
              <Select
                value={draft.projectAssignment.csaId}
                onChange={(e) =>
                  updateDraft(draftIndex, {
                    projectAssignment: {
                      ...draft.projectAssignment,
                      csaId: e.target.value,
                    },
                  })
                }
                label="CSA"
                disabled={
                  !draft.projectAssignment.projectId || activeCSAs.length === 0
                }
              >
                {activeCSAs.map((csa) => (
                  <MenuItem key={csa.id} value={csa.id}>
                    {csa.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Effective Date"
              type="date"
              value={draft.projectAssignment.startDate}
              onChange={(e) =>
                updateDraft(draftIndex, {
                  projectAssignment: {
                    ...draft.projectAssignment,
                    startDate: e.target.value,
                  },
                })
              }
              required
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Conclusion Date"
              type="date"
              value={draft.projectAssignment.endDate}
              onChange={(e) =>
                updateDraft(draftIndex, {
                  projectAssignment: {
                    ...draft.projectAssignment,
                    endDate: e.target.value,
                  },
                })
              }
              required
              size="small"
              InputLabelProps={{ shrink: true }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Billable Rate"
              type="number"
              value={draft.projectAssignment.billableRate || ""}
              onChange={(e) =>
                updateDraft(draftIndex, {
                  projectAssignment: {
                    ...draft.projectAssignment,
                    billableRate: parseFloat(e.target.value),
                  },
                })
              }
              required
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              label="Maximum Billable Hours"
              type="number"
              value={draft.projectAssignment.budgetedHours || ""}
              onChange={(e) => {
                const hours = parseFloat(e.target.value);
                updateDraft(draftIndex, {
                  projectAssignment: {
                    ...draft.projectAssignment,
                    budgetedHours: hours,
                    budgetedDollarAmount:
                      hours * (draft.projectAssignment.billableRate || 0),
                  },
                });
              }}
              required
              size="small"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Admin</InputLabel>
              <Select
                value={draft.projectAssignment.sentByAdminUserId}
                onChange={(e) => handleAdminChange(draftIndex, e.target.value)}
                required
              >
                {adminUsers.map((user) => (
                  <MenuItem key={user.id} value={user.id}>
                    {`${user.firstName} ${user.lastName} (${user.email})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl fullWidth size="small">
              <InputLabel>Freelancer</InputLabel>
              <Select
                value={draft.projectAssignment.engineerId}
                onChange={(e) =>
                  handleEngineerChange(draftIndex, e.target.value)
                }
                required
                disabled={!draft.projectAssignment.sentByAdminUserId}
              >
                <MenuItem value="">
                  <em>Select Freelancer</em>
                </MenuItem>
                {freelancerUsers
                  .filter((user) => user.id !== draft.adminId)
                  .map((user) => (
                    <MenuItem key={user.id} value={user.id}>
                      {`${user.firstName} ${user.lastName} (${user.email})`}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Scope of Work"
              multiline
              rows={4}
              value={draft.projectAssignment.scopeOfWork}
              onChange={(e) =>
                updateDraft(draftIndex, {
                  projectAssignment: {
                    ...draft.projectAssignment,
                    scopeOfWork: e.target.value,
                  },
                })
              }
              required
              size="small"
            />
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Project Assignment Generator
        </Typography>
        <Box sx={{ mb: 3 }}>
          <Typography variant="h6" gutterBottom>
            Previous Month's Assignments
          </Typography>
          <Grid container spacing={2}>
            {previousMonthAssignments?.map((assignment: ProjectAssignment) => {
              const freelancer = freelancers?.find(
                (f) => f.email === assignment.engineerId
              );
              return (
                <Grid item xs={12} sm={6} md={4} lg={3} key={assignment.id}>
                  <Card variant="outlined" sx={{ height: "100%" }}>
                    <CardContent sx={{ p: 2 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          mb: 1,
                        }}
                      >
                        <Typography
                          variant="subtitle2"
                          sx={{ fontWeight: "bold" }}
                        >
                          {freelancer
                            ? `${freelancer.firstName} ${freelancer.lastName}`
                            : assignment.engineerId}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 1 }}>
                          <Button
                            size="small"
                            onClick={() => setSelectedAssignment(assignment)}
                          >
                            View
                          </Button>
                          <Button
                            size="small"
                            startIcon={<ContentCopyIcon />}
                            onClick={() => {
                              const draft: GenerateProjectAssignmentTemplateRequestBody =
                                {
                                  userId: "",
                                  adminId: "",
                                  projectAssignment: {
                                    engineerId: String(
                                      assignment.engineerId ?? ""
                                    ),
                                    clientId: String(assignment.clientId ?? ""),
                                    projectId: String(
                                      assignment.projectId ?? ""
                                    ),
                                    csaId: String(assignment.csaId ?? ""),
                                    billableRate: Number(
                                      assignment.billableRate ?? 0
                                    ),
                                    budgetedHours: Number(
                                      assignment.budgetedHours ?? 0
                                    ),
                                    budgetedDollarAmount: Number(
                                      assignment.budgetedDollarAmount ?? 0
                                    ),
                                    startDate: String(
                                      assignment.startDate ?? ""
                                    ),
                                    endDate: String(assignment.endDate ?? ""),
                                    scopeOfWork: String(
                                      assignment.scopeOfWork ?? ""
                                    ),
                                    status: "signing",
                                    id: "",
                                  },
                                };
                              setDraftAssignments([...draftAssignments, draft]);
                            }}
                            sx={{ minWidth: 0, p: 0.5 }}
                          >
                            Copy
                          </Button>
                        </Box>
                      </Box>
                      <Typography variant="body2" color="text.secondary" noWrap>
                        {assignment.projectName}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 0.5,
                          mt: 1,
                        }}
                      >
                        <Typography variant="caption" color="text.secondary">
                          Start:{" "}
                          {assignment.startDate
                            ? DateTime.fromISO(assignment.startDate).toFormat(
                                "dd LLL yyyy"
                              )
                            : "Not set"}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          End:{" "}
                          {assignment.endDate
                            ? DateTime.fromISO(assignment.endDate).toFormat(
                                "dd LLL yyyy"
                              )
                            : "Not set"}
                        </Typography>
                        <Box sx={{ display: "flex", gap: 1, mt: 0.5 }}>
                          <Typography variant="body2" color="text.secondary">
                            ${assignment.billableRate || 0}/hr
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            • {assignment.budgetedHours || 0}h
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Card sx={{ mb: 3, cursor: "pointer" }} onClick={addDraft}>
          <CardContent
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: 100,
            }}
          >
            <Typography variant="h6">
              <AddIcon sx={{ verticalAlign: "middle", mr: 1 }} />
              Generate New Project Assignment
            </Typography>
          </CardContent>
        </Card>
        <form onSubmit={handleSubmit}>
          {draftAssignments.map((draft, draftIndex) => (
            <Card key={draftIndex} sx={{ mb: 3 }}>
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  <Typography variant="h6">
                    Project Assignment {draftIndex + 1}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <IconButton
                      onClick={() => removeDraft(draftIndex)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
                {renderEditView(draft, draftIndex)}
              </CardContent>
            </Card>
          ))}
          {draftAssignments.length > 0 && (
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={loading}
              startIcon={loading ? <CircularProgress size={20} /> : null}
            >
              {loading ? "Submitting..." : "Generate Assignments"}
            </Button>
          )}
        </form>
      </Box>
      <AppSnackbar
        open={snackbar.open}
        message={snackbar.message}
        severity={snackbar.severity}
        onClose={hideSnackbar}
      />
      {selectedAssignment && (
        <ViewFileDialog
          open={!!selectedAssignment}
          onClose={() => setSelectedAssignment(null)}
          fileUrl={selectedAssignment.fileUrl || ""}
          fileName={`${selectedAssignment.projectName} - Project Assignment`}
        />
      )}
      <Dialog
        open={confirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogTitle>Confirm Generation</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to generate {draftAssignments.length} project
            assignment(s)?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setConfirmDialogOpen(false)}>Cancel</Button>
          <Button
            onClick={handleConfirmGenerate}
            variant="contained"
            color="primary"
          >
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ProjectAssignmentGenerator;
