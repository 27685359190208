import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "config/firebase";
import { IBugReport } from "@freetech/models/bugReport";
import { bugReportConverter } from "@freetech/models/converters/bugReportConverter";

export const getMyBugReports = async (
  userId: string
): Promise<IBugReport[]> => {
  try {
    const bugReportsRef = collection(db, "bugReports");
    const q = query(
      bugReportsRef,
      where("reportedById", "==", userId)
    ).withConverter(bugReportConverter);

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  } catch (error) {
    console.error("Error fetching my bug reports:", error);
    throw error;
  }
};
