import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { getIndependentEngineerAgreement } from "../../core/independentEngineerAgreements/getIndependentEngineerAgreement";
import { Freelancer, UserDocument } from "types/user";
import { fetchFreelancerDocuments, fetchMyFreelancerProfile, updateFreelancerProfile as updateProfile } from "core/freelancer";
import { uploadDocument } from "core/freelancer";

export const useFreelancerProfile = (freelancerId: string) => {
  const queryClient = useQueryClient();

  const { data: independentEngineerAgreement, isLoading: isLoadingIndependentEngineerAgreement } = useQuery({
    queryKey: ["freelancerAgreement", freelancerId],
    queryFn: () => getIndependentEngineerAgreement(freelancerId),
  });

  const { data: freelancerProfile, isLoading: isLoadingFreelancerProfile } = useQuery({
    queryKey: ["freelancerProfile", freelancerId],
    queryFn: () => fetchMyFreelancerProfile(freelancerId),
  });

  const { data: freelancerDocuments, isLoading: isLoadingFreelancerDocuments } = useQuery({
    queryKey: ["freelancerDocuments", freelancerId],
    queryFn: () => fetchFreelancerDocuments(freelancerId),
  });

  const { mutate: updateFreelancerProfile } = useMutation({
    mutationFn: (updatedFreelancer: Partial<Freelancer>) => 
      updateProfile(freelancerId, updatedFreelancer),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["freelancerProfile", freelancerId] });
    }
  });

  const { mutate: uploadFreelancerDocument } = useMutation({
    mutationFn: (data: { file: File, document: UserDocument }) => 
      uploadDocument(freelancerId, data.file, data.document),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["freelancerDocuments", freelancerId] });
    }
  });

  return {
    independentEngineerAgreement,
    isLoadingIndependentEngineerAgreement,
    freelancerProfile,
    isLoadingFreelancerProfile,
    freelancerDocuments,
    isLoadingFreelancerDocuments,
    updateFreelancerProfile,
    uploadFreelancerDocument
  };
};
