import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { UpdateFileParams } from "../types";

export const updateFile = async (params: UpdateFileParams): Promise<string> => {
  const { fileId, name, content, lastModifiedBy } = params;
  
  const fileRef = doc(db, "knowledgeBaseFiles", fileId);
  const fileData = {
    name,
    content,
    updatedAt: DateTime.now().toISO(),
    lastModifiedBy,
  };

  await updateDoc(fileRef, fileData);
  return fileId;
}; 