import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Divider,
  Chip,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "hooks/auth/useAuth";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";

export const RecentPayments: React.FC = () => {
  const { currentUser } = useAuth();
  const { clients } = useStakeholder(currentUser?.uid);
  const { stakeholderPayments, stakeholderPaymentsLoading } = useStakeholderPayments();


  if (stakeholderPaymentsLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", p: 3 }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <List sx={{ p: 0 }}>
      {stakeholderPayments?.length && stakeholderPayments.length > 0 ? (
        stakeholderPayments.map((payment, index) => {
          const client = clients?.find((c) => c.id === payment.clientId);
          return (
            <React.Fragment key={payment.id}>
              <ListItem sx={{ px: 3, py: 2 }}>
                <ListItemText
                  primary={
                    <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}>
                      <Typography variant="subtitle1">
                        ${payment.amount}
                      </Typography>
                      {payment.status === "paid" ? (
                        <Chip
                          label="PAID"
                          color="success"
                        size="small"
                      />
                      ) : (
                        <Chip
                          label="PENDING"
                          color="warning"
                        size="small"
                      />
                      )}
                    </Box>
                  }
                  secondary={
                    <Box>
                      <Typography variant="body2" color="text.secondary">
                        {client?.companyName || payment.clientId || "Client"}
                      </Typography> 
                      <Typography variant="body2" color="text.secondary">
                        Invoice #{payment.invoiceId} • {new Date(payment.createdAt).toLocaleDateString()}
                      </Typography>
                    </Box>
                  }
                />
              </ListItem>
              {index < stakeholderPayments.length - 1 && <Divider />}
            </React.Fragment>
          );
        })
      ) : (
        <ListItem sx={{ px: 3, py: 2 }}>
          <ListItemText
            primary={
              <Typography variant="body1" color="text.secondary">
                No payment history
              </Typography>
            }
          />
        </ListItem>
      )}
    </List>
  );
}; 