import { doc, updateDoc, FieldValue } from "firebase/firestore";
import { Stakeholder } from "types/user";
import { db } from "config";

export const updateStakeholderProfile = async (
  stakeholderId: string,
  updatedData: Partial<Stakeholder>
): Promise<void> => {
  if (!stakeholderId) throw new Error("Stakeholder ID is required");

  const docRef = doc(db, "users", stakeholderId);

  await updateDoc(docRef, updatedData);
};
