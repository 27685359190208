import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { UpdateCardLabelsParams } from "types/freelo/crud";

export const updateCardLabels = async (params: UpdateCardLabelsParams): Promise<void> => {
  const { boardId, cardId, labels } = params;
  const cardRef = doc(db, "boards", boardId, "cards", cardId);
  await updateDoc(cardRef, {
    labels,
    updatedAt: DateTime.now().toISO(),
  });
}; 