import { db } from "config/firebase";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { GetBugReportsResponse } from "types/bugReport";
import { bugReportConverter } from "@freetech/models/converters/bugReportConverter";

export const getBugReports = async (): Promise<GetBugReportsResponse> => {
  try {
    const q = query(
      collection(db, "bugReports"),
      orderBy("createdAt", "desc")
    ).withConverter(bugReportConverter);

    const querySnapshot = await getDocs(q);
    return querySnapshot.docs.map((doc) => doc.data());
  } catch (error) {
    console.error("Error fetching bug reports:", error);
    throw error;
  }
};
