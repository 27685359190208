import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Avatar,
  Box,
} from "@mui/material";
import { PrivateUserInfoType, PublicUserInfoType } from "types/user";
import { ClientStakeholder } from "types/projects";
import { usePublicUserList } from "hooks/user/usePublicUserList";
interface StakeholderCardProps {
  stakeholder: ClientStakeholder;
  onRemove: () => void;
}

const StakeholderCard: React.FC<StakeholderCardProps> = ({
  stakeholder,
  onRemove,
}) => {
  const [userInfo, setUserInfo] = useState<PublicUserInfoType | null>(null);

  const { data: users, isLoading, isError } = usePublicUserList();

  useEffect(() => {
    const fetchUserInfo = async () => {
      const info = users?.find((user) => user.id === stakeholder.id);
      if (info) {
        setUserInfo(info);
      }
    };
    fetchUserInfo();
  }, [stakeholder.id]);

  if (!userInfo) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Card>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar
            src={userInfo.profilePicture}
            alt={`${userInfo.firstName} ${userInfo.lastName}`}
            sx={{ width: 60, height: 60, marginRight: 2 }}
          />
          <Box>
            <Typography variant="h6">
              {userInfo.firstName} {userInfo.lastName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {userInfo.email}
            </Typography>
          </Box>
        </Box>
        <Typography variant="body2" color="textSecondary">
          Associated:{" "}
          {new Date(stakeholder.associationDate).toLocaleDateString()}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" onClick={onRemove}>
          Remove
        </Button>
      </CardActions>
    </Card>
  );
};

export default StakeholderCard;
