import { loadFreelancers } from "./loadFreelancers";
import { getProjectAssignments } from "./getProjectAssignments";
import { getUserDocuments } from "../users/getUserDocuments";
import { Freelancer } from "types/user";
export const loadNestedFreelancers = async (): Promise<Freelancer[]> => {
  // Fetch all base user data
  const users = await loadFreelancers();

  // Fetch all project assignments in parallel
  const assignmentsByEngineerId = await getProjectAssignments();

  // Fetch documents for each user in parallel
  const adminFreelancers = await Promise.all(
    users.map(async (user) => {
      const documents = await getUserDocuments(user.id);

      return {
        ...user,
        projectAssignments: assignmentsByEngineerId[user.id] || [],
        documents: documents,
      } as Freelancer;
    })
  );

  return adminFreelancers;
};
