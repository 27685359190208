import React, { useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress
} from '@mui/material';
import {
  Box,
  TextField,
  Typography,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";
import { useAdminInvoicing } from "hooks/admin/useAdminInvoicing";

interface GenerateInvoiceDialogProps {
  open: boolean;
  onClose: () => void;
}

const GenerateInvoiceDialog: React.FC<GenerateInvoiceDialogProps> = ({
  open,
  onClose,
}) => {
  const {
    invoiceData,
    clients,
    projects,
    csas,
    handleChange,
    handleAddressChange,
    handleItemChange,
    handleAddItem,
    handleSelectClientDataChange,
    generateInvoice,
    resetInvoiceData,
  } = useAdminInvoicing();

  // Update billing info when client changes
  useEffect(() => {
    if (invoiceData.clientId) {
      const selectedClient = clients?.find(client => client.id === invoiceData.clientId);
      if (selectedClient) {
        handleAddressChange("billTo", "name", selectedClient.pointOfContactName || '');
        handleAddressChange("billTo", "company", selectedClient.companyName);
      }
    }
  }, [invoiceData.clientId, clients]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await generateInvoice(invoiceData);
      onClose();
      resetInvoiceData();
    } catch (error) {
      console.error("Error creating invoice:", error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    handleChange(name, value);
  };

  const handleSelectChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent
  ) => {
    const { name, value } = e.target;
    handleSelectClientDataChange(name, value);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Generate New Invoice</DialogTitle>
      <DialogContent>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
          <Typography variant="h6" gutterBottom>
            Invoice Details
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Client</InputLabel>
                <Select
                  name="clientId"
                  value={invoiceData.clientId}
                  onChange={handleSelectChange}
                >
                  {clients?.map((client) => (
                    <MenuItem key={client.id} value={client.id}>
                      {client.companyName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Project</InputLabel>
                <Select
                  name="projectId"
                  value={invoiceData.projectId}
                  onChange={handleSelectChange}
                  disabled={!invoiceData.clientId}
                >
                  {projects.map((project) => (
                    <MenuItem key={project.id} value={project.id}>
                      {project.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Consulting Services Agreement</InputLabel>
                <Select
                  name="csaId"
                  value={invoiceData.csaId}
                  onChange={handleSelectChange}
                  disabled={!invoiceData.projectId}
                >
                  {csas.map((csa) => (
                    <MenuItem key={csa.id} value={csa.id}>
                      {csa.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Invoice Number"
                name="invoiceNumber"
                value={invoiceData.invoiceNumber}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Invoice Date"
                name="invoiceDate"
                type="date"
                value={invoiceData.invoiceDate}
                onChange={handleInputChange}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Due Date"
                name="dueDate"
                type="date"
                value={invoiceData.dueDate}
                onChange={handleInputChange}
                required
              />
            </Grid>
          </Grid>

          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Bill To Information
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Contact Name"
                value={invoiceData.billTo.name}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ bgcolor: 'action.hover' }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Company"
                value={invoiceData.billTo.company}
                InputProps={{
                  readOnly: true,
                }}
                sx={{ bgcolor: 'action.hover' }}
              />
            </Grid>
            {invoiceData.clientId && (
              <Grid item xs={12}>
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Client ID: {invoiceData.clientId}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Items
          </Typography>
          {invoiceData.items.map((item, index) => (
            <Grid container spacing={2} key={index} sx={{ mb: 2 }}>
              <Grid item xs={12} sm={6}>
                <TextField
                  fullWidth
                  label="Description"
                  value={item.description}
                  onChange={(e) => handleItemChange(index, "description", e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Quantity"
                  type="number"
                  value={item.quantity}
                  onChange={(e) => handleItemChange(index, "quantity", e.target.value)}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <TextField
                  fullWidth
                  label="Rate"
                  type="number"
                  value={item.rate}
                  onChange={(e) => handleItemChange(index, "rate", e.target.value)}
                  required
                />
              </Grid>
            </Grid>
          ))}
          <Button onClick={handleAddItem} variant="outlined" sx={{ mt: 1, mb: 2 }}>
            Add Item
          </Button>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <TextField
                fullWidth
                label="Discount (%)"
                name="discount"
                type="number"
                value={invoiceData.discount}
                onChange={handleInputChange}
                required
                InputProps={{
                  inputProps: { min: 0, max: 100 }
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ mt: 2 }}>
          Generate Invoice
        </Button>
        <Button onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GenerateInvoiceDialog;