import React from 'react';
import { Box, Typography, Paper, Grid, Chip, Divider } from '@mui/material';
import { Freelancer } from "types/user";
import { DateTime } from "luxon";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import PublicIcon from '@mui/icons-material/Public';
import SecurityIcon from '@mui/icons-material/Security';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PersonIcon from '@mui/icons-material/Person';

interface FreelancerReadOnlyInfoProps {
  freelancer: Freelancer;
}

const FreelancerReadOnlyInfo: React.FC<FreelancerReadOnlyInfoProps> = ({ freelancer }) => {
  const renderStatus = (status: boolean | undefined) => {
    return status ? (
      <Chip label="Yes" color="success" size="small" sx={{ minWidth: 60 }} />
    ) : (
      <Chip label="No" color="error" size="small" sx={{ minWidth: 60 }} />
    );
  };

  const renderDate = (date: string | undefined) => {
    return date ? DateTime.fromISO(date).toFormat('LLL dd, yyyy') : 'Not available';
  };

  const SectionHeader = ({ icon, title }: { icon: React.ReactNode; title: string }) => (
    <Box display="flex" alignItems="center" mb={1}>
      <Box color="primary.main" mr={1} display="flex" alignItems="center">
        {icon}
      </Box>
      <Typography variant="subtitle1" color="primary" fontWeight="medium">
        {title}
      </Typography>
    </Box>
  );

  const InfoRow = ({ label, value }: { label: string; value: React.ReactNode }) => (
    <Box display="flex" alignItems="center" mb={0.5}>
      <Typography variant="body2" component="span" color="text.secondary" minWidth={140}>
        {label}:
      </Typography>
      <Box ml={1}>
        {typeof value === 'string' ? (
          <Typography variant="body2" component="span">
            {value}
          </Typography>
        ) : (
          value
        )}
      </Box>
    </Box>
  );

  const AddressDisplay = ({ address }: { address: NonNullable<Freelancer['address']> }) => (
    <Box sx={{ pl: 0.5 }}>
      <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
        {address.street}
        {'\n'}
        {address.city}, {address.state} {address.zipCode}
        {'\n'}
        {address.country}
      </Typography>
    </Box>
  );

  return (
    <Paper elevation={3} sx={{ p: 3, mb: 4 }}>
      <Typography variant="h6" gutterBottom color="primary">Profile Information</Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <SectionHeader icon={<PersonIcon />} title="Account Status" />
            <Box>
              <InfoRow label="Account Active" value={renderStatus(!freelancer.disabled)} />
              <InfoRow label="Email Verified" value={renderStatus(freelancer.emailVerified)} />
              <InfoRow label="Created" value={renderDate(freelancer.createdAt?.toString())} />
              <InfoRow label="Last Login" value={renderDate(freelancer.lastLoginAt?.toString())} />
              <InfoRow label="Onboarding Status" value={renderStatus(!freelancer.needsOnboarding)} />
              {freelancer.onboardingCompletedAt && (
                <InfoRow label="Completed On" value={renderDate(freelancer.onboardingCompletedAt?.toString())} />
              )}
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box mb={2}>
            <SectionHeader icon={<LocationOnIcon />} title="Address" />
            {freelancer.address ? (
              <AddressDisplay address={freelancer.address} />
            ) : (
              <Typography variant="body2" color="text.secondary">
                No address provided
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box mb={2}>
            <SectionHeader icon={<PublicIcon />} title="Citizenship & Security" />
            <Box>
              <InfoRow label="US Citizen" value={renderStatus(freelancer.citizenship?.isUsCitizen)} />
              {!freelancer.citizenship?.isUsCitizen && (
                <>
                  <InfoRow label="Work Authorization" value={renderStatus(freelancer.citizenship?.hasWorkAuthorization)} />
                  {freelancer.citizenship?.workAuthorizationType && (
                    <InfoRow label="Authorization Type" value={freelancer.citizenship.workAuthorizationType} />
                  )}
                </>
              )}
              <InfoRow label="Has Clearance" value={renderStatus(freelancer.securityClearance?.hasClearance)} />
              {freelancer.securityClearance?.hasClearance && (
                <>
                  <InfoRow label="Level" value={freelancer.securityClearance.clearanceLevel} />
                  <InfoRow label="Issuer" value={freelancer.securityClearance.clearanceIssuer} />
                  {freelancer.securityClearance.expirationDate && (
                    <InfoRow label="Expires" value={renderDate(freelancer.securityClearance.expirationDate)} />
                  )}
                  <InfoRow label="CAC Card" value={renderStatus(freelancer.securityClearance.hasCac)} />
                </>
              )}
            </Box>
          </Box>

          <Divider sx={{ my: 2 }} />

          <Box>
            <SectionHeader icon={<WorkIcon />} title="Employment & Payroll" />
            <Box>
              <InfoRow label="Full-time Job" value={renderStatus(freelancer.employment?.hasFullTimeJob)} />
              {freelancer.employment?.hasFullTimeJob && (
                <>
                  <InfoRow label="Employer" value={freelancer.employment.employer || 'N/A'} />
                  <InfoRow label="Position" value={freelancer.employment.position || 'N/A'} />
                  {freelancer.employment.startDate && (
                    <InfoRow label="Start Date" value={renderDate(freelancer.employment.startDate)} />
                  )}
                  <InfoRow label="Employer Aware" value={renderStatus(freelancer.employment.employerAwareOfFreelancing)} />
                </>
              )}
              <InfoRow label="Payroll Profile" value={renderStatus(freelancer.payrollProfileCompleted)} />
              {freelancer.payrollProfileCompleted && freelancer.payrollProfileCompletedAt && (
                <InfoRow label="Profile Completed" value={renderDate(freelancer.payrollProfileCompletedAt)} />
              )}
              <InfoRow label="Tax Form" value={renderStatus(freelancer.taxFormCompleted)} />
              {freelancer.taxFormCompleted && (
                <>
                  <InfoRow label="Tax Form Type" value={freelancer.taxFormType?.toUpperCase() || 'N/A'} />
                  {freelancer.taxFormCompletedAt && (
                    <InfoRow label="Form Completed" value={renderDate(freelancer.taxFormCompletedAt)} />
                  )}
                </>
              )}
              <InfoRow label="Ready for Payroll" value={renderStatus(freelancer.readyForPayroll)} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FreelancerReadOnlyInfo; 