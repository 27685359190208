import { useQuery } from "@tanstack/react-query";
import { getAdminStatistics } from "core/admin/statistics";

export const useAdminStatistics = () => {
  return useQuery({
    queryKey: ["adminStatistics"],
    queryFn: getAdminStatistics,
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};
