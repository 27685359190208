import { collection, doc, addDoc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { CreateCardParams } from "types/freelo/crud";
import { generateHumanReadableCardId } from "core/freelo/utils/generateHumanReadableCardId";
import { List } from "types/freelo";
export const createCard = async (
  params: CreateCardParams,
  userId: string
): Promise<string> => {
  const {
    boardId,
    listId,
    title,
    description,
    assignee,
    reporter,
    additionalAssignees,
    labels,
    dueDate,
    startDate,
    comments,
  } = params;

  // Get the list first to check its current cardOrder
  const listRef = doc(db, "boards", boardId, "lists", listId);
  const listDoc = await getDoc(listRef);
  if (!listDoc.exists()) {
    throw new Error("List not found");
  }
  const list = listDoc.data() as List;

  // Create the new card
  const cardsCollectionRef = collection(db, "boards", boardId, "cards");
  const humanReadableId = await generateHumanReadableCardId(boardId);

  const newCardRef = await addDoc(cardsCollectionRef, {
    title: title,
    humanReadableId,
    listId,
    description: description || "",
    assignee: assignee || "",
    reporter: reporter || "",
    additionalAssignees: additionalAssignees || [],
    labels: labels || [],
    dueDate: dueDate || null,
    startDate: startDate || null,
    comments: comments || [],
    createdAt: DateTime.now().toISO(),
    updatedAt: DateTime.now().toISO(),
    createdBy: userId,
    archived: false,
  });

  // Initialize cardOrder if it doesn't exist
  const currentCardOrder = list.cardOrder || [];

  // Add new card to cardOrder
  const updatedCardOrder = [...currentCardOrder, newCardRef.id];

  // Update the list with new cardOrder
  await updateDoc(listRef, {
    cardOrder: updatedCardOrder,
    updatedAt: DateTime.now().toISO(),
  });

  return newCardRef.id;
};
