import { db } from "config/firebase";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { CreateBugReportInput } from "types/bugReport";

export const createBugReport = async (
  data: CreateBugReportInput
): Promise<string> => {
  try {
    const docRef = await addDoc(collection(db, "bugReports"), {
      ...data,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      status: "open",
    });

    return docRef.id;
  } catch (error) {
    console.error("Error creating bug report:", error);
    throw error;
  }
};
