import { INotification } from "@freetech/models";
import { NotificationType } from "@freetech/models/notifications/enums";
import {
  Payment as PaymentIcon,
  Assignment as AssignmentIcon,
  Description as DescriptionIcon,
  Comment as CommentIcon,
  Receipt as ReceiptIcon,
} from "@mui/icons-material";

export const getIcon = (type: string) => {
  switch (type) {
    case NotificationType.INVOICE_SENT:
    case NotificationType.INVOICE_STATUS:
      return ReceiptIcon;
    case NotificationType.PROJECT_ASSIGNMENT:
    case NotificationType.ASSIGNMENT:
      return AssignmentIcon;
    case NotificationType.MENTION:
    case NotificationType.COMMENT:
      return CommentIcon;
    case NotificationType.FREELANCER_PAYMENT:
      return PaymentIcon;
    default:
      return DescriptionIcon;
  }
};

export const getNotificationMessage = (notification: INotification): string => {
  const { type, data } = notification;

  switch (type) {
    case NotificationType.INVOICE_SENT:
      return data.title || "New invoice has been sent for review";
    case NotificationType.INVOICE_STATUS:
      return data.title || `Invoice status updated: ${data.description}`;
    case NotificationType.MENTION:
      const mentionData = data as { boardId: string; humanReadableId: string; content?: string };
      return mentionData.content || "You have been mentioned in a comment";
    case NotificationType.ASSIGNMENT:
      const assignmentData = data as { boardId: string; humanReadableId: string; title: string };
      return assignmentData.title || "You have been assigned to a task";
    case NotificationType.PROJECT_ASSIGNMENT:
      return data.title || "You have been assigned to a new project";
    case NotificationType.COMMENT:
      const commentData = data as { boardId: string; humanReadableId: string; content?: string };
      return commentData.content || "New comment on your task";
    case NotificationType.FREELANCER_PAYMENT:
      const paymentData = data as { title: string; paymentStatus: string };
      return paymentData.title || `Payment status updated: ${paymentData.paymentStatus}`;
    default:
      return data.title || "New notification";
  }
};
