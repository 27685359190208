import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getBoardActivity,
  subscribeToBoardActivity,
} from "core/freelo/activity";
import { useEffect } from "react";

export const useBoardActivity = (boardId: string) => {
  const queryClient = useQueryClient();
  const queryKey = ["boardActivities", boardId];

  // Initial fetch and cache setup
  const {
    data: activities,
    isLoading,
    error,
  } = useQuery({
    queryKey,
    queryFn: () => getBoardActivity(boardId),
    enabled: !!boardId,
    staleTime: Infinity, // Don't mark data as stale since we're using real-time updates
  });

  // Set up real-time updates
  useEffect(() => {
    if (!boardId) return;

    // Subscribe to real-time updates
    const unsubscribe = subscribeToBoardActivity(boardId, (newActivities) => {
      // Update the cache with new activities
      queryClient.setQueryData(queryKey, newActivities);
    });

    return () => {
      unsubscribe();
    };
  }, [boardId, queryClient]);

  return {
    activities,
    isLoading,
    error,
  };
};
