import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { AddCommentParams } from "types/freelo/crud";
import { Card, CardComment } from "types/freelo";
export const addComment = async (params: AddCommentParams): Promise<void> => {
  const { boardId, cardId, content, mentionedUserIds, createdBy } = params;
  const cardRef = doc(db, "boards", boardId, "cards", cardId);
  const cardDoc = await getDoc(cardRef);
  const card = cardDoc.data() as Card;

  const newComment: CardComment = {
    id: crypto.randomUUID(),
    content,
    createdAt: DateTime.now().toISO(),
    updatedAt: DateTime.now().toISO(),
    createdBy: createdBy || "system",
    mentionedUserIds,
  };

  await updateDoc(cardRef, {
    comments: [...(card.comments || []), newComment],
    updatedAt: DateTime.now().toISO(),
  });
};
