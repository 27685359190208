import { getFirestore, doc, getDoc } from '@firebase/firestore'
const db = getFirestore()

export const checkUserRole = async (userId: string | undefined) => {
  const userRef = doc(db, `users/${userId}`)
  const docSnap = await getDoc(userRef)

  if (docSnap.exists()) {
    return docSnap.data().role
  } else {
    return null
  }
}
