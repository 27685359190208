import { logoNoBackground } from "assets";

export interface MiscNotificationTemplateProps {
    name: string;
    title: string;
    message: string;
    actionUrl?: string;
    actionText?: string;
}

const miscNotificationTemplate = ({ name, title, message, actionUrl, actionText }: MiscNotificationTemplateProps) => {
  return `
    <!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${title}</title>
        <style>
            body {
                font-family: Arial, sans-serif;
                line-height: 1.6;
                color: #000000;
                background-color: #ffffff;
                margin: 0;
                padding: 0;
            }
            .container {
                max-width: 600px;
                margin: 0 auto;
                padding: 20px;
            }
            .logo {
                text-align: center;
                margin-bottom: 20px;
            }
            .logo img {
                max-width: 200px;
            }
            h1 {
                color: #000000;
                text-align: center;
            }
            .content {
                background-color: #f5f5f5;
                border: 1px solid #e0e0e0;
                padding: 20px;
                margin-bottom: 20px;
            }
            .button {
                display: inline-block;
                background-color: #000000;
                color: #ffffff;
                text-decoration: none;
                padding: 10px 20px;
                border-radius: 5px;
                text-align: center;
            }
        </style>
    </head>
    <body>
        <div class="container">
            <div class="logo">
                <img src="${logoNoBackground}" alt="FreeTech Logo">
            </div>
            <h1>${title}</h1>
            <div class="content">
                <p>Hello ${name},</p>
                <p>${message}</p>
                ${actionUrl && actionText ? `
                <p style="text-align: center;">
                    <a href="${actionUrl}" class="button">${actionText}</a>
                </p>
                ` : ''}
            </div>
            <p style="text-align: center; font-size: 12px; color: #666666;">
                This is an automated message. Please do not reply to this email.
            </p>
        </div>
    </body>
    </html>
  `;
};

export { miscNotificationTemplate };