import { collection, query, getDocs, where } from "firebase/firestore";
import { db } from "config/firebase";
import { Freelancer } from "types/user";

export const loadFreelancers = async (): Promise<Freelancer[]> => {
  const usersQuery = query(
    collection(db, "users"),
    where("role", "in", ["freelancer", "admin"])
  );
  const usersSnapshot = await getDocs(usersQuery);
  return usersSnapshot.docs.map((doc) => ({
    ...(doc.data() as Freelancer),
    id: doc.id,
  }));
};
