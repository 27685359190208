import { collection, doc, getDocs, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { Board, List, Card } from "types/freelo";

export const getBoard = async (boardId: string): Promise<Board> => {
  const boardRef = doc(db, "boards", boardId);
  const boardDoc = await getDoc(boardRef);

  if (!boardDoc.exists()) {
    throw new Error("Board not found");
  }

  const boardData = boardDoc.data() as Board;
  const listsRef = collection(db, "boards", boardId, "lists");
  const listsSnapshot = await getDocs(listsRef);
  const lists = listsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as List[];

  const cardsRef = collection(db, "boards", boardId, "cards");
  const cardsSnapshot = await getDocs(cardsRef);
  const allCards = cardsSnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  })) as Card[];

  const listsWithCards = lists.map((list) => ({
    ...list,
    cards: allCards.filter((card) => card.listId === list.id),
  }));

  return {
    ...boardData,
    id: boardDoc.id,
    lists: listsWithCards,
  };
};
