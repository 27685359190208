import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "hooks/auth/useAuth";
import { DateTime } from "luxon";
import { logBoardActivity } from "core/freelo/activity/logBoardActivity";
import {
  createList,
  updateList,
  deleteList,
  archiveList,
  moveList,
} from "core/freelo/list";
import { createCard, updateCard } from "core/freelo/card";
import {
  getBoards,
  createBoard,
  updateBoardOrder,
  getBoard,
} from "core/freelo/board";
import { createLabel, updateCardLabels, deleteLabel } from "core/freelo/label";
import {
  addComment,
  uploadAttachment,
  deleteAttachment,
} from "core/freelo/attachment";
import { moveCardToTrash } from "core/freelo/card";
import { updateListColor, updateBoard } from "core/freelo/board";
import {
  CreateCardParams,
  UpdateCardParams,
  MoveCardParams,
  UpdateListParams,
  BoardData,
  CreateLabelParams,
  UpdateCardLabelsParams,
  DeleteLabelParams,
  MoveCardToTrashParams,
  AddCommentParams,
  AttachmentParams,
  UpdateBoardParams,
  DeleteListParams,
} from "types/freelo/crud";
import { moveCard } from "core/freelo/card/moveCard";
import { Board } from "types/freelo";

const getListNameById = (board: Board | undefined, listId: string): string => {
  return board?.lists?.find((list) => list.id === listId)?.name || listId;
};

export const useFreelo = () => {
  const queryClient = useQueryClient();
  const { userInfo } = useAuth();

  // Query hook for fetching boards
  const {
    data: boards,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["boards"],
    queryFn: getBoards,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  // Create new board
  const createBoardMutation = useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      const boardData: BoardData = {
        name,
        order: (boards?.length || 0) + 1,
        createdBy: userInfo?.id || "system",
        lists: [],
        createdAt: DateTime.now().toISO(),
        updatedAt: DateTime.now().toISO(),
      };
      return createBoard(boardData);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["boards"] });
    },
  });

  // Update board order
  const updateBoardOrderMutation = useMutation({
    mutationFn: updateBoardOrder,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["boards"] });
    },
  });

  // Get specific board with its lists and cards
  const useBoard = (boardId: string) => {
    return useQuery<Board, Error>({
      queryKey: ["board", boardId],
      queryFn: () => getBoard(boardId),
      enabled: !!boardId,
      staleTime: Infinity,
      gcTime: 1000 * 60 * 30,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    });
  };

  // Update list
  const updateListMutation = useMutation({
    mutationFn: updateList,
    onMutate: async ({ boardId, listId, updates }: UpdateListParams) => {
      await queryClient.cancelQueries({ queryKey: ["board", boardId] });
      const previousBoard = queryClient.getQueryData<Board>(["board", boardId]);

      if (previousBoard) {
        const updatedBoard = {
          ...previousBoard,
          lists: previousBoard.lists?.map((list) =>
            list.id === listId ? { ...list, ...updates } : list
          ),
        };
        queryClient.setQueryData(["board", boardId], updatedBoard);
      }

      return { previousBoard };
    },
    onError: (
      err: Error,
      variables: UpdateListParams,
      context: { previousBoard?: Board } | undefined
    ) => {
      if (context?.previousBoard) {
        queryClient.setQueryData(
          ["board", variables.boardId],
          context.previousBoard
        );
      }
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  // Delete list
  const deleteListMutation = useMutation({
    mutationFn: async ({ boardId, listId }: DeleteListParams) => {
      await deleteList({ boardId, listId }, queryClient);
      return { boardId, listId };
    },
    // No need for onSuccess since we're handling the update optimistically
    onError: (error, { boardId }) => {
      console.error("Error deleting list:", error);
      // Error handling is done in the deleteList function itself
    },
  });

  // Archive list
  const archiveListMutation = useMutation({
    mutationFn: async ({
      boardId,
      listId,
    }: {
      boardId: string;
      listId: string;
    }) => {
      const board = await getBoard(boardId);
      const listName = getListNameById(board, listId);

      await archiveList({ boardId, listId });

      await logBoardActivity(
        boardId,
        "list-archive",
        userInfo?.id || "system",
        `Archived list '${listName}'`
      );
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  // Create new card
  const createCardMutation = useMutation({
    mutationFn: async (params: CreateCardParams) => {
      return createCard(params, userInfo?.id || "system");
    },
    onMutate: async ({ boardId, listId, title }) => {
      await queryClient.cancelQueries({ queryKey: ["board", boardId] });
      const previousBoard = queryClient.getQueryData<Board>(["board", boardId]);

      if (previousBoard) {
        // Create optimistic update logic here
        return { previousBoard };
      }
      return { previousBoard: undefined };
    },
    onError: (
      err: Error,
      variables: CreateCardParams,
      context: { previousBoard?: Board } | undefined
    ) => {
      if (context?.previousBoard) {
        queryClient.setQueryData(
          ["board", variables.boardId],
          context.previousBoard
        );
      }
    },
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  // Update card
  const updateCardMutation = useMutation({
    mutationFn: async ({ boardId, cardId, updates }: UpdateCardParams) => {
      const card = await updateCard(boardId, cardId, updates);
      return card;
    },
    onMutate: async ({ boardId, cardId, updates }) => {
      await queryClient.cancelQueries({ queryKey: ["board", boardId] });
      const previousBoard = queryClient.getQueryData<Board>(["board", boardId]);
      return { previousBoard };
    },
    onError: (
      err: Error,
      variables: UpdateCardParams,
      context: { previousBoard?: Board } | undefined
    ) => {
      if (context?.previousBoard) {
        queryClient.setQueryData(
          ["board", variables.boardId],
          context.previousBoard
        );
      }
    },
  });

  // Move card
  const moveCardMutation = useMutation<void, Error, MoveCardParams>({
    mutationFn: (params) => moveCard(params, queryClient),
  });

  // Create mutations for the new functionality
  const createLabelMutation = useMutation({
    mutationFn: (params: CreateLabelParams) => createLabel(params),
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const updateCardLabelsMutation = useMutation({
    mutationFn: (params: UpdateCardLabelsParams) => updateCardLabels(params),
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const deleteLabelMutation = useMutation({
    mutationFn: (params: DeleteLabelParams) => deleteLabel(params),
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const moveCardToTrashMutation = useMutation({
    mutationFn: (params: MoveCardToTrashParams) => moveCardToTrash(params),
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const updateListColorMutation = useMutation({
    mutationFn: ({
      boardId,
      listId,
      color,
    }: {
      boardId: string;
      listId: string;
      color: string | null;
    }) => updateListColor({ boardId, listId, color }),
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const updateBoardMutation = useMutation({
    mutationFn: (params: UpdateBoardParams) => updateBoard(params),
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
      queryClient.invalidateQueries({ queryKey: ["boards"] });
    },
  });

  const addCommentMutation = useMutation({
    mutationFn: (params: AddCommentParams) => addComment(params),
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const uploadAttachmentMutation = useMutation({
    mutationFn: (params: AttachmentParams) => uploadAttachment(params),
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const deleteAttachmentMutation = useMutation({
    mutationFn: ({
      boardId,
      cardId,
      attachmentId,
    }: {
      boardId: string;
      cardId: string;
      attachmentId: string;
    }) => deleteAttachment({ boardId, cardId, attachmentId }),
    onSuccess: (_, { boardId }) => {
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  const moveListMutation = useMutation({
    mutationFn: async ({
      boardId,
      listId,
      destinationIndex,
    }: {
      boardId: string;
      listId: string;
      destinationIndex: number;
    }) => {
      await moveList({
        boardId,
        listId,
        destinationIndex,
      });
    },
    onError: (error) => {
      console.error("Error moving list:", error);
    },
  });

  const addListMutation = useMutation({
    mutationFn: (params: { boardId: string; name: string }) =>
      createList(params, queryClient),
    onError: (error) => {
      console.error("Error adding list:", error);
    },
    onSettled: (_, __, { boardId }) => {
      // Force a refetch to ensure sync
      queryClient.invalidateQueries({ queryKey: ["board", boardId] });
    },
  });

  return {
    useBoard,
    boards,
    isLoading,
    error,
    createBoard: createBoardMutation,
    updateBoardOrder: updateBoardOrderMutation,
    updateBoard: updateBoardMutation,

    createList: addListMutation,
    updateList: updateListMutation,
    deleteList: deleteListMutation,
    archiveList: archiveListMutation,
    moveList: moveListMutation,
    addList: addListMutation,
    updateListColor: updateListColorMutation,

    createCard: createCardMutation,
    updateCard: updateCardMutation,
    moveCard: moveCardMutation,
    moveCardToTrash: moveCardToTrashMutation,
    createLabel: createLabelMutation,
    updateCardLabels: updateCardLabelsMutation,
    deleteLabel: deleteLabelMutation,
    addComment: addCommentMutation,
    uploadAttachment: uploadAttachmentMutation,
    deleteAttachment: deleteAttachmentMutation,
  };
};
