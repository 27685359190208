import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Button,
  Box,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  CircularProgress,
  Alert,
} from "@mui/material";
import { collection, getDocs, getFirestore } from "firebase/firestore";
import { Client } from "types/projects";
import { CreateUserType } from "types/user";
import { clientConverter } from "@freetech/models/converters/clientConverter";
import { useManageUsers } from "hooks/admin/useManageUsers";
import { useSnackbar } from "notistack";

const db = getFirestore();

export const CreateUser: React.FC = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { createUserMutation } = useManageUsers();
  const [formError, setFormError] = useState<string | null>(null);
  const [userData, setUserData] = useState<CreateUserType>({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    role: "freelancer",
    permissions: [],
    emailVerified: false,
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    needsOnboarding: true,
    disabled: false,
    documents: [],
  });
  const [open, setOpen] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isUserTypeSelected, setIsUserTypeSelected] = useState(true);
  const [clients, setClients] = useState<Client[]>([]);

  const handleOpen = () => {
    setOpen(true);
    setFormError(null);
  };
  
  const handleClose = () => {
    setIsEmailValid(true);
    setFormError(null);
    setOpen(false);
  };

  useEffect(() => {
    const fetchClients = async () => {
      const querySnapshot = await getDocs(
        collection(db, "clients").withConverter(clientConverter)
      );
      const clientsList: Client[] = [];
      querySnapshot.forEach((doc) => {
        // @ts-expect-error want to overwrite
        clientsList.push({ id: doc.id, ...doc.data() } as Client);
      });
      setClients(clientsList);
    };

    fetchClients();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormError(null);
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    setFormError(null);
    if (event.target.name === "role") {
      setUserData({
        ...userData,
        role: event.target.value as CreateUserType["role"],
      });
    } else {
      setUserData({ ...userData, [event.target.name]: event.target.value });
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError(null);

    // Validate required fields
    if (
      !userData.role ||
      !userData.firstName ||
      !userData.lastName ||
      !userData.email
    ) {
      setFormError("Missing required fields: role, firstName, lastName, email");
      return;
    }

    createUserMutation.mutate(userData, {
      onSuccess: () => {
        enqueueSnackbar("User created successfully", {
          variant: "success",
          anchorOrigin: { vertical: "top", horizontal: "center" },
        });
        setUserData({
          email: "",
          password: "",
          firstName: "",
          lastName: "",
          role: "freelancer",
          permissions: [],
          emailVerified: false,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          needsOnboarding: true,
          disabled: false,
          documents: [],
        });
        handleClose();
      },
      onError: (error) => {
        console.error("Error creating user", error);
        setFormError(error instanceof Error ? error.message : "Error creating user");
      },
    });
  };

  return (
    <>
      <Box sx={{ marginBottom: 4 }}>
        <Button variant="outlined" onClick={handleOpen}>
          Create User
        </Button>
      </Box>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Create New User</DialogTitle>
        <DialogContent>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={userData.email}
                  onChange={handleChange}
                  error={!isEmailValid}
                  helperText={!isEmailValid ? "Invalid email address" : ""}
                  disabled={createUserMutation.isPending}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  name="firstName"
                  autoComplete="fname"
                  value={userData.firstName}
                  onChange={handleChange}
                  disabled={createUserMutation.isPending}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={userData.lastName}
                  onChange={handleChange}
                  disabled={createUserMutation.isPending}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required>
                  <InputLabel>Role</InputLabel>
                  <Select
                    label="Role"
                    name="role"
                    value={userData.role}
                    onChange={handleSelectChange}
                    disabled={createUserMutation.isPending}
                  >
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="freelancer">Freelancer</MenuItem>
                    <MenuItem value="stakeholder">Stakeholder</MenuItem>
                    <MenuItem value="non-admin">Non-Admin</MenuItem>
                  </Select>
                  {!isUserTypeSelected && (
                    <Typography color="error" variant="caption">
                      Please select a role.
                    </Typography>
                  )}
                </FormControl>
              </Grid>
              {formError && (
                <Grid item xs={12}>
                  <Alert severity="error" sx={{ mt: 2 }}>
                    {formError}
                  </Alert>
                </Grid>
              )}
              <Grid item xs={12}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={createUserMutation.isPending}
                >
                  {createUserMutation.isPending ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    "Create User"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};
