import React, { useEffect } from "react";
import {
  Container,
  Box,
  Paper,
  CircularProgress,
  Typography,
} from "@mui/material";
import { authBackground, logoNoBackground } from "assets";
import { useTheme } from "@mui/material/styles";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { useMFA } from "hooks/auth/useMFA";

const AuthLayout: React.FC = () => {
  const theme = useTheme();

  const { isLoading, currentUser, userInfo, logout } = useAuth();
  const { resolver } = useMFA();
  const navigate = useNavigate();
  const location = useLocation();


  // force user to stay on login-mfa-code if they triggered it they can click cancel
  if (resolver && location.pathname !== "/login-mfa-code") {
    return <Navigate to="/login-mfa-code" replace />;
  }


  // force user to stay on login if they are not logged in
  if (location.pathname === "/" && !currentUser) {
    return <Navigate to="/login" replace />;
  }

  if (currentUser && userInfo) {
    if (userInfo?.disabled) {
      navigate("/account-deactivated");
    }

    if (userInfo?.role) {
      return <Navigate to={`/${userInfo?.role}`} replace />;
    }

    // logout();
    // return <Navigate to="/login" replace />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "center",
        backgroundImage: `url(${authBackground})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        paddingTop: "15vh",
      }}
    >
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            component="img"
            src={logoNoBackground}
            alt="FreeTech Logo"
            sx={{
              width: "200px",
              height: "auto",
              marginBottom: 4,
            }}
          />
          <Paper
            elevation={6}
            sx={{
              padding: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              bgcolor: "white",
              width: "100%",
              borderRadius: 2,
              boxShadow: "0 4px 20px  black", //rgba(0,0,0,0.1)',
            }}
          >
            <Outlet />
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default AuthLayout;
