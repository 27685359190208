import {
  doc,
  collection,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { db } from "config/firebase";
import {
  AddNestedDocumentParams,
  UpdateNestedDocumentParams,
  DeleteNestedDocumentParams,
  GetNestedDocumentsParams,
} from "types/admin";

export const addNestedDocument = async ({
  clientId,
  collectionName,
  data,
}: AddNestedDocumentParams) => {
  const clientRef = doc(db, "clients", clientId);
  const nestedCollectionRef = collection(clientRef, collectionName);
  const docRef = doc(nestedCollectionRef, data.id);
  await setDoc(docRef, data);
  return docRef;
};

export const updateNestedDocument = async ({
  clientId,
  collectionName,
  docId,
  data,
}: UpdateNestedDocumentParams) => {
  const docRef = doc(db, "clients", clientId, collectionName, docId);
  await updateDoc(docRef, data);
};

export const deleteNestedDocument = async ({
  clientId,
  collectionName,
  docId,
}: DeleteNestedDocumentParams) => {
  const docRef = doc(db, "clients", clientId, collectionName, docId);
  await deleteDoc(docRef);
};

export const loadNestedDocuments = async ({
  clientId,
  collectionName,
}: GetNestedDocumentsParams) => {
  const clientRef = doc(db, "clients", clientId);
  const nestedCollectionRef = collection(clientRef, collectionName);
  const snapshot = await getDocs(nestedCollectionRef);
  return snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
};
