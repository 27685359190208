import React, { useState, useEffect } from 'react';
import {
  Popover,
  Box,
  Typography,
  IconButton,
  TextField,
  Stack,
  Chip,
  Button,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChromePicker } from 'react-color';
import { useLabels } from "hooks/freelo/useLabels";
import { useLabelOperations } from "hooks/freelo/useLabelOperations";
import { Card, CardLabel } from "types/freelo";
interface LabelManagerProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  boardId?: string;
  card: Card;
  onUpdate?: (labels: CardLabel[]) => Promise<void>;
}

export const LabelManager: React.FC<LabelManagerProps> = ({
  open,
  anchorEl,
  onClose,
  boardId,
  card,
  onUpdate
}) => {
  if (!boardId) return null;
  
  const [search, setSearch] = useState('');
  const [newLabelName, setNewLabelName] = useState('');
  const [selectedColor, setSelectedColor] = useState('#2196f3');
  const [showColorPicker, setShowColorPicker] = useState(false);
  
  const { labels, loading: labelsLoading } = useLabels(boardId);
  const { createLabel, deleteLabel, updateCardLabels } = useLabelOperations(boardId);

  const handleCreateLabel = async () => {
    if (!newLabelName.trim()) return;
    try {
      await createLabel(newLabelName.trim(), selectedColor);
      setNewLabelName('');
      setShowColorPicker(false);
    } catch (error) {
      console.error('Error creating label:', error);
    }
  };

  const handleDeleteLabel = async (labelId: string) => {
    try {
      await deleteLabel(labelId);
    } catch (error) {
      console.error('Error deleting label:', error);
    }
  };

  const handleToggleLabel = async (label: CardLabel) => {
    if (!onUpdate || !card.id) return;
    
    const currentLabels = card.labels || [];
    const labelExists = currentLabels.some(l => l.id === label.id);
    
    const newLabels = labelExists
      ? currentLabels.filter(l => l.id !== label.id)
      : [...currentLabels, label];
    
    try {
      await updateCardLabels(card.id, newLabels);
      await onUpdate(newLabels);
      onClose();
    } catch (error) {
      console.error('Error updating labels:', error);
    }
  };

  const filteredLabels = labels?.filter(label =>
    label.name.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: {
          width: 300,
          p: 2,
          ml: 1,
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography variant="subtitle1">Labels</Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>

      <TextField
        fullWidth
        size="small"
        placeholder="Search labels..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        sx={{ mb: 2 }}
      />

      {labelsLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 2 }}>
          <CircularProgress size={24} />
        </Box>
      ) : (
        <Stack spacing={1} sx={{ mb: 2 }}>
          {filteredLabels?.map((label) => (
            <Box
              key={label.id}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Chip
                label={label.name}
                sx={{
                  bgcolor: label.color,
                  color: 'white',
                  flex: 1,
                  cursor: 'pointer',
                  '&:hover': {
                    bgcolor: label.color,
                    opacity: 0.8,
                  },
                }}
                onClick={() => handleToggleLabel(label)}
              />
              <IconButton
                size="small"
                onClick={() => handleDeleteLabel(label.id)}
                sx={{ ml: 1 }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          ))}
        </Stack>
      )}

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>
          Create new label
        </Typography>
        <TextField
          fullWidth
          size="small"
          placeholder="Label name"
          value={newLabelName}
          onChange={(e) => setNewLabelName(e.target.value)}
          sx={{ mb: 1 }}
        />
        <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
          <Button
            variant="contained"
            size="small"
            sx={{ bgcolor: selectedColor }}
            onClick={() => setShowColorPicker(!showColorPicker)}
          >
            Color
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={handleCreateLabel}
            disabled={!newLabelName.trim()}
          >
            Create
          </Button>
        </Box>
        {showColorPicker && (
          <Box sx={{ mt: 1 }}>
            <ChromePicker
              color={selectedColor}
              onChange={(color: any) => setSelectedColor(color.hex)}
            />
          </Box>
        )}
      </Box>
    </Popover>
  );
}; 