import { ProjectAssignment, ProjectAssignmentStatus } from "types/projects";

export interface ProjectAssignmentStatusCounts {
  interviewing: number;
  ready: number;
  signing: number;
  in_progress: number;
  complete: number;
  cancelled: number;
}

export const getCountsOfProjectAssignmentStatuses = (
  projectAssignments: ProjectAssignment[]
) => {
  const counts: ProjectAssignmentStatusCounts = {
    interviewing: 0,
    ready: 0,
    signing: 0,
    in_progress: 0,
    complete: 0,
    cancelled: 0,
  };

  
  projectAssignments.forEach((assignment) => {
    switch (assignment.status) {
      case "interviewing":
        counts.interviewing++;
        break;
      case "ready":
        counts.ready++;
        break;
      case "signing":
        counts.signing++;
        break;
      case "in_progress":
        counts.in_progress++;
        break;
      case "complete":
        counts.complete++;
        break;
      case "cancelled":
        counts.cancelled++;
        break;
    }
  });
  

  return counts;
};
