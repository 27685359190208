import React, { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import InvoiceList from "./components/InvoiceList";
import GenerateInvoiceDialog from "./components/GenerateInvoiceDialog";
import { useAdminInvoicing } from "hooks/admin/useAdminInvoicing";
import { ClientInvoiceData } from "@freetech/models";

const InvoiceManagement: React.FC = () => {
  const [isGenerateDialogOpen, setIsGenerateDialogOpen] = useState(false);
  const {
    invoices,
    handleUpdateInvoice,
    handleDeleteInvoice,
    generateInvoice,
    isLoading,
  } = useAdminInvoicing();

  const handleGenerateInvoice = () => {
    setIsGenerateDialogOpen(true);
  };

  const handleCloseGenerateDialog = () => {
    setIsGenerateDialogOpen(false);
  };

  const handleSubmitInvoice = async (data: ClientInvoiceData) => {
    try {
        await generateInvoice(data);
      setIsGenerateDialogOpen(false);
    } catch (error) {
      console.error('Error creating invoice:', error);
    }
  };

  return (
    <>
      <Box p={3}>
        <Typography variant="h4" gutterBottom>
          Invoice Management
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenerateInvoice}
          sx={{ mb: 2 }}
        >
          Generate New Invoice
        </Button>
        <InvoiceList/>
        <GenerateInvoiceDialog
          open={isGenerateDialogOpen}
          onClose={handleCloseGenerateDialog}
        />
      </Box>
    </>
  );
};

export default InvoiceManagement;
