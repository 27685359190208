import React, { useEffect } from "react";
import { AppProvider } from "@toolpad/core";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import type { Router, Session } from "@toolpad/core";
import { useNavigate, useLocation, Outlet, Navigate } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import CoreLayout from "./CoreLayout";
import {
  superuserPages,
  freelancerPages,
  stakeholderPages,
} from "router/pages";

export const DashboardLayoutProvider = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo, logout, currentUser } = useAuth();

  const router = React.useMemo<Router>(
    () => ({
      pathname: location.pathname,
      searchParams: new URLSearchParams(location.search),
      navigate: (path) => navigate(path),
    }),
    [location, navigate]
  );

  useEffect(() => {
    if (userInfo) {
      setSession({
        user: {
          id: userInfo?.id,
          name: userInfo?.firstName + " " + userInfo?.lastName,
          email: userInfo?.email,
          image: userInfo?.profilePicture,
        },
      });
    }
  }, [userInfo]);

  const userType = userInfo?.role;

  const pages = React.useMemo(() => {
    switch (userType) {
      case "admin":
        return superuserPages;
      case "freelancer":
        return freelancerPages;
      case "stakeholder":
        return stakeholderPages;
      default:
        return [];
    }
  }, [userType]);

  const [session, setSession] = React.useState<Session | null>({
    user: {
      id: userInfo?.id,
      name: userInfo?.firstName + " " + userInfo?.lastName,
      email: userInfo?.email,
      image: userInfo?.profilePicture,
    },
  });

  const authentication = React.useMemo(() => {
    return {
      signIn: () => {
        setSession({
          user: {
            id: userInfo?.id,
            name: userInfo?.firstName + " " + userInfo?.lastName,
            email: userInfo?.email,
            image: userInfo?.profilePicture,
          },
        });
      },
      signOut: async () => {
        setSession(null);
        await logout();
        window.location.href = "/login";
      },
    };
  }, [userInfo, logout]);

  // if (!currentUser) {
  //   logout();
  //   return <Navigate to="/login" replace />;
  // }

  return (
    <AppProvider
      authentication={authentication}
      session={session}
      navigation={pages}
      theme={theme}
      router={router}
      defaultSidebarCollapsed={true}
      branding={{
        logo: (
          <Box sx={{ paddingLeft: 2 }}>
            <Box
              component="img"
              src={
                "https://raw.githubusercontent.com/the-freetech-company/freetech-image-content/refs/heads/main/freeteh-cropped-larger.png"
              }
              alt="FreeTech"
              sx={{
                maxWidth: {
                  xs: "200px", // for extra small screens
                  sm: "250px", // for small screens
                  md: "300px", // for medium screens
                  lg: "350px", // for large screens
                  xl: "400px", // for extra large screens
                },
                width: "100%",
                height: "auto",
                borderRadius: "10px",
              }}
            />
          </Box>
        ),
        title: "",
      }}
    >
      <CoreLayout>
        <Outlet />
      </CoreLayout>
    </AppProvider>
  );
};
