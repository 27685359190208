import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "hooks/auth/useAuth";
import { DateTime } from "luxon";
import { getKnowledgeBaseStructure } from "core/knowledgeBase/structure";
import { createFolder } from "core/knowledgeBase/folder";
import { createFile, updateFile, moveToTrash } from "core/knowledgeBase/file";
import {
  createOptimisticFile,
  addFileToFolderStructure,
} from "core/knowledgeBase/utils/optimisticFileUpdate";
import { KnowledgeBaseStructure } from "types/knowledgeBase";
export const useKnowledgeBase = () => {
  const queryClient = useQueryClient();
  const { userInfo } = useAuth();

  // Query hook for fetching structure
  const useKnowledgeBaseStructure = () => {
    return useQuery<KnowledgeBaseStructure, Error>({
      queryKey: ["knowledgeBase"],
      queryFn: getKnowledgeBaseStructure,
    });
  };

  // Create new folder
  const createFolderMutation = useMutation({
    mutationFn: async ({
      parentPath,
      name,
    }: {
      parentPath: string;
      name: string;
    }) => {
      return createFolder({
        parentPath,
        name,
        createdBy: userInfo?.id || "system",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["knowledgeBase"] });
    },
  });

  // Create new file
  const createFileMutation = useMutation({
    mutationFn: async (data: {
      folderPath: string;
      name: string;
      content: string;
    }) => {
      return createFile({
        ...data,
        createdBy: userInfo?.id || "system",
      });
    },
    onMutate: async (newFile) => {
      await queryClient.cancelQueries({ queryKey: ["knowledgeBase"] });
      const previousData = queryClient.getQueryData<KnowledgeBaseStructure>([
        "knowledgeBase",
      ]);

      if (previousData) {
        const optimisticFile = createOptimisticFile(
          newFile,
          userInfo?.id || "system"
        );
        const updatedStructure = addFileToFolderStructure(
          previousData,
          optimisticFile,
          newFile.folderPath
        );
        queryClient.setQueryData(["knowledgeBase"], updatedStructure);
      }

      return { previousData };
    },
    onError: (_err, _newFile, context) => {
      if (context?.previousData) {
        queryClient.setQueryData(["knowledgeBase"], context.previousData);
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ["knowledgeBase"] });
    },
  });

  // Update existing file
  const updateFileMutation = useMutation({
    mutationFn: async ({
      fileId,
      name,
      content,
    }: {
      fileId: string;
      name: string;
      content: string;
    }) => {
      return updateFile({
        fileId,
        name,
        content,
        lastModifiedBy: userInfo?.id || "system",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["knowledgeBase"] });
    },
  });

  // Move file to trash
  const moveToTrashMutation = useMutation({
    mutationFn: async ({
      knowledgeBaseDocumentId,
    }: {
      knowledgeBaseDocumentId: string;
    }) => {
      return moveToTrash({
        knowledgeBaseDocumentId,
        deletedBy: userInfo?.id || "system",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["knowledgeBase"] });
    },
  });

  return {
    useKnowledgeBaseStructure,
    createFolder: createFolderMutation,
    createFile: createFileMutation,
    updateFile: updateFileMutation,
    moveToTrash: moveToTrashMutation,
  };
};
