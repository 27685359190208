import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Stack,
  CircularProgress,
  Alert,
  Card,
  CardContent,
  CardActions,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Chip,
  Snackbar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Avatar,
} from "@mui/material";
import {
  Visibility as VisibilityIcon,
  Check as CheckIcon,
  Send as SendIcon,
} from "@mui/icons-material";
import { useAdminConfig } from "hooks/admin/useAdminConfig";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";
import { EversignTemplate } from "types/eversign/eversign";
import { Freelancer, IndependentEngineerAgreement } from "types/user";
import {
  AdminAgreementToSign,
  useAdminSignAgreements,
} from "hooks/admin/useAdminSignAgreements";
import { portalFunctions } from "core/functions/portalFunctions";

const isIndependentEngineerAgreement = (
  agreement: AdminAgreementToSign
): agreement is AdminAgreementToSign & {
  type: "independentEngineerAgreement";
  agreement: IndependentEngineerAgreement;
} => {
  return agreement.type === "independentEngineerAgreement";
};

export const IndependentEngineerAgreements: React.FC = () => {
  const {
    config,
    isLoading: configLoading,
    setDefaultIndependentEngineerAgreement,
    isUpdating,
  } = useAdminConfig();
  const { data: freelancers, isLoading: freelancersLoading } =
    useAdminFreelancers();
  const {
    templates,
    isLoadingTemplates,
    templatesError,
    isSending,
    sendAgreements,
    data: existingAgreements,
    cancelIndependentEngineerAgreement,
    isCancellingIndependentEngineerAgreement,
  } = useAdminSignAgreements();

  const [selectedTemplate, setSelectedTemplate] =
    useState<EversignTemplate | null>(null);
  const [viewDialogOpen, setViewDialogOpen] = useState(false);
  const [viewLoading, setViewLoading] = useState(false);
  const [viewError, setViewError] = useState<string | null>(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState<"success" | "error">(
    "success"
  );
  const [selectFreelancersOpen, setSelectFreelancersOpen] = useState(false);
  const [selectedFreelancers, setSelectedFreelancers] = useState<string[]>([]);
  const [isSandbox, setIsSandbox] = useState(false);
  const [cancelDialogOpen, setCancelDialogOpen] = useState(false);
  const [
    freelancersWithExistingAgreements,
    setFreelancersWithExistingAgreements,
  ] = useState<
    {
      freelancer: Freelancer;
      agreement: AdminAgreementToSign & {
        type: "independentEngineerAgreement";
        agreement: IndependentEngineerAgreement;
      };
    }[]
  >([]);

  const handleViewTemplate = async (
    templateId: string,
    template: EversignTemplate
  ) => {
    setViewLoading(true);
    setViewError(null);
    try {
      const templateDetails =
        await portalFunctions.eversign.viewTemplate(templateId);
      setSelectedTemplate(templateDetails);
      setViewDialogOpen(true);
    } catch (err) {
      setViewError(
        err instanceof Error ? err.message : "Failed to load template details"
      );
    } finally {
      setViewLoading(false);
    }
  };

  const handleSetDefault = (templateId: string) => {
    setDefaultIndependentEngineerAgreement(templateId);
  };

  const handleToggleFreelancer = (freelancerId: string) => {
    setSelectedFreelancers((prev) =>
      prev.includes(freelancerId)
        ? prev.filter((id) => id !== freelancerId)
        : [...prev, freelancerId]
    );
  };

  const handleSendUpdatedAgreements = async () => {
    if (
      !config?.defaultIndependentEngineerAgreementId ||
      !freelancers ||
      !existingAgreements
    )
      return;

    const freelancersToUpdate = freelancers.filter((f) =>
      selectedFreelancers.includes(f.id)
    );

    // Check for existing agreements
    const freelancersWithAgreements = freelancersToUpdate
      .filter((freelancer) => {
        return existingAgreements.some((agreement) => {
          if (isIndependentEngineerAgreement(agreement)) {
            return (
              agreement.agreement.userId === freelancer.id &&
              (agreement.agreement.everSignDocumentStatus ===
                "awaiting_admin_signature" ||
                agreement.agreement.everSignDocumentStatus ===
                  "awaiting_engineer_signature")
            );
          }
          return false;
        });
      })
      .map((freelancer) => {
        const agreement = existingAgreements.find(
          (agreement) =>
            isIndependentEngineerAgreement(agreement) &&
            agreement.agreement.userId === freelancer.id
        );
        if (!agreement || !isIndependentEngineerAgreement(agreement)) {
          throw new Error("Agreement not found or invalid type");
        }
        return {
          freelancer,
          agreement,
        };
      });

    if (freelancersWithAgreements.length > 0) {
      setFreelancersWithExistingAgreements(freelancersWithAgreements);
      setCancelDialogOpen(true);
      return;
    }

    await sendAgreementsToFreelancers(freelancersToUpdate);
  };

  const handleCancelAndSendAgreements = async () => {
    try {
      // Cancel existing agreements
      await Promise.all(
        freelancersWithExistingAgreements.map(({ freelancer, agreement }) =>
          cancelIndependentEngineerAgreement(
            freelancer.id,
            agreement.id,
            agreement.agreement.eversignDocumentHash
          )
        )
      );

      // Send new agreements
      const freelancersToUpdate = freelancers!.filter((f) =>
        selectedFreelancers.includes(f.id)
      );
      await sendAgreementsToFreelancers(freelancersToUpdate);

      setCancelDialogOpen(false);
    } catch (error) {
      console.error("Error cancelling and sending agreements:", error);
      setSnackbarMessage(
        error instanceof Error
          ? error.message
          : "Failed to cancel and send agreements"
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  const sendAgreementsToFreelancers = async (
    freelancersToUpdate: Freelancer[]
  ) => {
    try {
      await sendAgreements(freelancersToUpdate, isSandbox);

      setSnackbarMessage(
        `Successfully sent ${freelancersToUpdate.length} agreements`
      );
      setSnackbarSeverity("success");
      setSnackbarOpen(true);
      setSelectFreelancersOpen(false);
      setSelectedFreelancers([]);
    } catch (error) {
      console.error("Error sending agreements:", error);
      setSnackbarMessage(
        error instanceof Error ? error.message : "Failed to send agreements"
      );
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    }
  };

  if (isLoadingTemplates || configLoading || freelancersLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (templatesError) {
    return (
      <Box sx={{ p: 3 }}>
        <Alert severity="error">
          Failed to load templates:{" "}
          {templatesError instanceof Error
            ? templatesError.message
            : "Unknown error"}
        </Alert>
      </Box>
    );
  }

  const hasDefaultTemplate = Boolean(
    config?.defaultIndependentEngineerAgreementId
  );

  return (
    <Box sx={{ maxWidth: 1200, margin: "0 auto", padding: "24px" }}>
      <Stack spacing={3}>
        <Typography variant="h4" component="h1">
          Independent Engineer Agreements
        </Typography>

        {!hasDefaultTemplate && (
          <Alert severity="warning">
            Please select a default template before selecting engineers.
          </Alert>
        )}

        <Stack spacing={3} direction="row">
          <Paper sx={{ flex: 1, p: 3 }}>
            <Stack spacing={2}>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="h6">Select Engineers</Typography>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Checkbox
                    checked={isSandbox}
                    onChange={(e) => setIsSandbox(e.target.checked)}
                    disabled={isSending}
                  />
                  <Typography variant="body2" color="text.secondary">
                    Sandbox Mode
                  </Typography>
                </Box>
              </Stack>

              <List sx={{ width: "100%" }}>
                {freelancers?.map((freelancer: Freelancer) => (
                  <ListItem
                    key={freelancer.id}
                    disablePadding
                    sx={{
                      cursor: !isSending ? "pointer" : "default",
                      opacity: isSending ? 0.5 : 1,
                    }}
                    onClick={() =>
                      !isSending && handleToggleFreelancer(freelancer.id)
                    }
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={selectedFreelancers.includes(freelancer.id)}
                        tabIndex={-1}
                        disableRipple
                        disabled={isSending}
                      />
                    </ListItemIcon>
                    <ListItemIcon>
                      <Avatar
                        src={freelancer.profilePicture}
                        alt={`${freelancer.firstName} ${freelancer.lastName}`}
                      >
                        {freelancer.firstName[0]}
                      </Avatar>
                    </ListItemIcon>
                    <ListItemText
                      primary={`${freelancer.firstName} ${freelancer.lastName}`}
                      secondary={freelancer.email}
                    />
                  </ListItem>
                ))}
              </List>

              {selectedFreelancers.length > 0 && (
                <Box
                  sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    onClick={handleSendUpdatedAgreements}
                    disabled={!hasDefaultTemplate || isSending}
                    startIcon={<SendIcon />}
                  >
                    {isSending
                      ? `Sending (${selectedFreelancers.length} remaining)...`
                      : `Send to ${selectedFreelancers.length} Engineer${selectedFreelancers.length === 1 ? "" : "s"}`}
                  </Button>
                </Box>
              )}
            </Stack>
          </Paper>

          <Paper sx={{ flex: 1, p: 3 }}>
            <Typography variant="h6" gutterBottom>
              Available Templates
            </Typography>

            <Stack spacing={2}>
              {templates?.map((template) => {
                const isDefault =
                  template.document_hash ===
                  config?.defaultIndependentEngineerAgreementId;

                return (
                  <Card key={template.template_id} variant="outlined">
                    <CardContent>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                      >
                        <Box>
                          <Typography variant="h6" gutterBottom>
                            {template.title}
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            Created:{" "}
                            {new Date(template.created).toLocaleDateString()}
                          </Typography>
                          {template.message && (
                            <Typography variant="body2" sx={{ mt: 1 }}>
                              {template.message}
                            </Typography>
                          )}
                        </Box>
                        {isDefault && (
                          <Chip
                            label="Default"
                            color="primary"
                            size="small"
                            icon={<CheckIcon />}
                          />
                        )}
                      </Stack>
                    </CardContent>
                    <CardActions>
                      <Button
                        size="small"
                        variant="outlined"
                        startIcon={<VisibilityIcon />}
                        onClick={() =>
                          handleViewTemplate(template.document_hash, template)
                        }
                        disabled={viewLoading}
                      >
                        View Template
                      </Button>
                      <Button
                        size="small"
                        variant={isDefault ? "contained" : "outlined"}
                        onClick={() => handleSetDefault(template.document_hash)}
                        disabled={isDefault || isUpdating}
                      >
                        {isDefault ? "Default Template" : "Set as Default"}
                      </Button>
                    </CardActions>
                  </Card>
                );
              })}

              {templates?.length === 0 && (
                <Alert severity="info">
                  No templates found. Please create a template in Eversign
                  first.
                </Alert>
              )}
            </Stack>
          </Paper>
        </Stack>

        {/* Template View Dialog */}
        <Dialog
          open={viewDialogOpen}
          onClose={() => setViewDialogOpen(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle>Template Details</DialogTitle>
          <DialogContent>
            {viewLoading ? (
              <Box display="flex" justifyContent="center" p={3}>
                <CircularProgress />
              </Box>
            ) : viewError ? (
              <Alert severity="error" sx={{ mt: 2 }}>
                {viewError}
              </Alert>
            ) : (
              selectedTemplate && (
                <Stack spacing={2} sx={{ mt: 2 }}>
                  <Typography variant="h6">{selectedTemplate.title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    Template ID: {selectedTemplate.template_id}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Created:{" "}
                    {new Date(selectedTemplate.created).toLocaleString()}
                  </Typography>
                  {selectedTemplate.message && (
                    <Typography variant="body1">
                      {selectedTemplate.message}
                    </Typography>
                  )}
                </Stack>
              )
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setViewDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setSnackbarOpen(false)}
            severity={snackbarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>

        {/* Cancel Confirmation Dialog */}
        <Dialog
          open={cancelDialogOpen}
          onClose={() => setCancelDialogOpen(false)}
          aria-labelledby="cancel-dialog-title"
          aria-describedby="cancel-dialog-description"
        >
          <DialogTitle id="cancel-dialog-title">
            Cancel Existing Agreements?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="cancel-dialog-description">
              The following engineers have existing agreements that need to be
              signed:
              <List>
                {freelancersWithExistingAgreements.map(({ freelancer }) => (
                  <ListItem key={freelancer.id}>
                    <ListItemText
                      primary={`${freelancer.firstName} ${freelancer.lastName}`}
                      secondary={freelancer.email}
                    />
                  </ListItem>
                ))}
              </List>
              Do you want to cancel their existing agreements and send new ones?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setCancelDialogOpen(false)}
              color="primary"
              disabled={isCancellingIndependentEngineerAgreement}
            >
              No, Keep Existing
            </Button>
            <Button
              onClick={handleCancelAndSendAgreements}
              color="primary"
              variant="contained"
              disabled={isCancellingIndependentEngineerAgreement}
            >
              {isCancellingIndependentEngineerAgreement
                ? "Cancelling..."
                : "Yes, Cancel and Send New"}
            </Button>
          </DialogActions>
        </Dialog>
      </Stack>
    </Box>
  );
};
