import React, { useMemo, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Avatar,
  Stack,
  Select,
  MenuItem,
  Button,
  IconButton,
  Menu,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@mui/material';
import { DateTime } from 'luxon';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewFileDialog from "components/ViewFileDialog";
import { ProjectAssignment, ProjectAssignmentStatus, EverSignDocumentStatus } from "types/projects";
import { Freelancer } from "types/user";

interface FreelancerCardProps {
  freelancer: Freelancer;
  onUpdateAssignment?: (
    clientId: string,
    projectId: string,
    assignmentId: string,
    updates: Partial<ProjectAssignment>
  ) => void;
  onUpdateEverSignStatus: (
    clientId: string,
    projectId: string,
    assignmentId: string,
    currentStatus: EverSignDocumentStatus | undefined
  ) => void;
  onOpenEverSignDialog: (
    clientId: string,
    projectId: string,
    assignmentId: string,
    currentStatus: EverSignDocumentStatus | undefined
  ) => void;
}

const FreelancerCard: React.FC<FreelancerCardProps> = ({
  freelancer,
  onUpdateAssignment,
  onUpdateEverSignStatus,
  onOpenEverSignDialog,
}) => {
  const [showAllAssignments, setShowAllAssignments] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedAssignment, setSelectedAssignment] = useState<ProjectAssignment | null>(null);
  const [viewFileDialogOpen, setViewFileDialogOpen] = useState(false);
  const [isLoadingFile, setIsLoadingFile] = useState(false);

  const totalHours = freelancer.timesheets?.reduce((total, timesheet) => {
    return total + (timesheet.duration || 0);
  }, 0) || 0;

  const sortedAssignments = useMemo(() => {
    const uniqueAssignments = freelancer.projectAssignments?.reduce((acc, curr) => {
      const key = `${curr.clientId}-${curr.projectId}-${curr.id}`;
      if (!acc[key]) {
        acc[key] = curr;
      }
      return acc;
    }, {} as Record<string, typeof freelancer.projectAssignments[0]>);

    return Object.values(uniqueAssignments || {}).sort((a, b) => {
      const dateA = a.startDate ? new Date(a.startDate).getTime() : 0;
      const dateB = b.startDate ? new Date(b.startDate).getTime() : 0;
      return dateB - dateA;
    });
  }, [freelancer.projectAssignments]);

  const displayedAssignments = showAllAssignments 
    ? sortedAssignments 
    : sortedAssignments.slice(0, 3);

  const getStatusColor = (status?: ProjectAssignmentStatus) => {
    switch (status) {
      case 'interviewing': return '#1976d2';  // info
      case 'ready': return '#2e7d32';         // success
      case 'signing': return '#ed6c02';       // warning
      case 'in_progress': return '#1976d2';   // primary
      case 'complete': return '#757575';      // grey
      case 'cancelled': return '#d32f2f';     // error
      default: return '#ed6c02';              // warning
    }
  };

  const getStatusLabel = (status?: ProjectAssignmentStatus) => {
    switch (status) {
      case 'interviewing': return 'Interviewing';
      case 'ready': return 'Ready';
      case 'signing': return 'Signing';
      case 'in_progress': return 'In Progress';
      case 'complete': return 'Complete';
      case 'cancelled': return 'Cancelled';
      default: return 'Pending';
    }
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>, assignment: ProjectAssignment) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
    setSelectedAssignment(assignment);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedAssignment(null);
  };

  const handleUpdateEverSignStatus = () => {
    if (selectedAssignment) {
      onOpenEverSignDialog(
        selectedAssignment.clientId,
        selectedAssignment.projectId,
        selectedAssignment.id,
        selectedAssignment.everSignDocumentStatus
      );
    }
    handleMenuClose();
  };

  const handleViewAssignment = () => {
    if (selectedAssignment?.fileUrl) {
      setIsLoadingFile(true);
      setViewFileDialogOpen(true);
      handleMenuClose();
    }
  };

  const handleCloseViewDialog = () => {
    setViewFileDialogOpen(false);
    setSelectedAssignment(null);
    setIsLoadingFile(false);
  };

  return (
    <Card elevation={2}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar
            src={freelancer.profilePicture}
            sx={{ width: 56, height: 56, mr: 2 }}
          >
            {freelancer.firstName?.[0]}
            {freelancer.lastName?.[0]}
          </Avatar>
          <Box>
            <Typography variant="h6">
              {freelancer.firstName} {freelancer.lastName}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {freelancer.email}
            </Typography>
          </Box>
        </Box>

        <Typography variant="subtitle1" gutterBottom>
          Total Hours: {(totalHours / 3600).toFixed(2)}
        </Typography>

        <Typography variant="subtitle2" gutterBottom sx={{ mt: 2 }}>
          Project Assignments:
        </Typography>
        <Stack spacing={2}>
          {displayedAssignments.map((assignment) => (
            <Box
              key={`${freelancer.id}-${assignment.clientId}-${assignment.projectId}-${assignment.id}`}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 2,
                borderRadius: 1,
                border: '1px solid',
                borderColor: 'divider',
                bgcolor: 'background.paper',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                <Box>
                  <Typography variant="subtitle2" fontWeight="bold">
                    {assignment.projectName}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {assignment.clientName}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', gap: 1 }}>
                  <IconButton
                    size="small"
                    onClick={(e) => handleMenuClick(e, assignment)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                </Box>
              </Box>

              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Box>
                  <Typography variant="caption" display="block" color="textSecondary">
                    Start: {assignment.startDate ? 
                      DateTime.fromISO(assignment.startDate).toFormat('dd MMM yyyy') : 
                      'Not set'}
                  </Typography>
                  <Typography variant="caption" display="block" color="textSecondary">
                    End: {assignment.endDate ? 
                      DateTime.fromISO(assignment.endDate).toFormat('dd MMM yyyy') : 
                      'Not set'}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex', gap: 2, ml: 'auto', alignItems: 'flex-start' }}>
                  <Box>
                    <Typography variant="caption" display="block" color="textSecondary">
                      Status:
                    </Typography>
                    <Select
                      size="small"
                      value={assignment.status || ''}
                      onChange={(e) => {
                        onUpdateAssignment?.(
                          assignment.clientId,
                          assignment.projectId,
                          assignment.id,
                          { status: e.target.value as ProjectAssignmentStatus }
                        );
                      }}
                      sx={{ 
                        color: getStatusColor(assignment.status),
                        fontWeight: 500,
                        fontSize: '0.75rem',
                        '.MuiSelect-select': {
                          padding: '2px 24px 2px 6px',
                          paddingRight: '24px !important',
                        },
                        '.MuiOutlinedInput-notchedOutline': {
                          border: 'none'
                        },
                        '&:hover .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid rgba(0, 0, 0, 0.23)'
                        },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                          border: '1px solid rgba(0, 0, 0, 0.23)'
                        },
                      }}
                    >
                      <MenuItem value="interviewing">Interviewing</MenuItem>
                      <MenuItem value="ready">Ready</MenuItem>
                      <MenuItem value="signing">Signing</MenuItem>
                      <MenuItem value="in_progress">In Progress</MenuItem>
                      <MenuItem value="complete">Complete</MenuItem>
                      <MenuItem value="cancelled">Cancelled</MenuItem>
                    </Select>
                  </Box>
                  <Box>
                    <Typography variant="caption" display="block" color="textSecondary">
                      Document:
                    </Typography>
                    <Typography variant="caption" sx={{ fontWeight: 500 }}>
                      {assignment.everSignDocumentStatus || 'No Document'}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          ))}
          {sortedAssignments.length > 3 && (
            <Button
              onClick={() => setShowAllAssignments(!showAllAssignments)}
              size="small"
              sx={{ alignSelf: 'center' }}
              endIcon={<KeyboardArrowDownIcon />}
            >
              {showAllAssignments 
                ? 'Show Less' 
                : `Show ${sortedAssignments.length - 3} More Assignments`}
            </Button>
          )}
        </Stack>

        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem 
            onClick={handleViewAssignment} 
            disabled={!selectedAssignment?.fileUrl || isLoadingFile}
          >
            <ListItemIcon>
              {isLoadingFile ? (
                <CircularProgress size={20} />
              ) : (
                <VisibilityIcon fontSize="small" />
              )}
            </ListItemIcon>
            <ListItemText>
              {isLoadingFile ? 'Loading...' : 'View Assignment'}
            </ListItemText>
          </MenuItem>
          <MenuItem onClick={handleUpdateEverSignStatus}>
            <ListItemIcon>
              <EditIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              Update EverSign Status
            </ListItemText>
          </MenuItem>
        </Menu>

        <Typography variant="subtitle2" gutterBottom sx={{ mt: 3 }}>
          Recent Timesheets:
        </Typography>
        <Stack spacing={1}>
          {freelancer.timesheets?.slice(0, 3).map((timesheet, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                bgcolor: 'background.default',
                p: 1,
                borderRadius: 1,
              }}
            >
              <Typography variant="body2">
                {DateTime.fromISO(timesheet.date).toLocaleString()}
              </Typography>
              <Typography variant="body2">
                {(timesheet.duration / 3600).toFixed(2)} hours
              </Typography>
            </Box>
          ))}
        </Stack>

        {freelancer.timesheets?.length || 0 > 0 && (
          <Typography variant="caption" color="textSecondary" sx={{ mt: 1, display: 'block' }}>
            Last updated {DateTime.fromISO(freelancer.timesheets?.[0]?.date || '')
              .toRelative({ base: DateTime.now() })}
          </Typography>
        )}
      </CardContent>

      {selectedAssignment && (
        <ViewFileDialog
          open={viewFileDialogOpen}
          onClose={handleCloseViewDialog}
          fileUrl={selectedAssignment.fileUrl || ''}
          fileName={`${selectedAssignment.projectName || 'Project'} Assignment`}
          isLoading={isLoadingFile}
          onLoad={() => setIsLoadingFile(false)}
        />
      )}
    </Card>
  );
};

export default FreelancerCard;
