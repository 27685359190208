import { useAuth } from "hooks/auth/useAuth";
import { useTimesheet } from "hooks/timesheet/useTimesheet";

export interface MonthlyHours {
  month: string;
  hours: number;
  approvedHours: number;
  submittedHours: number;
}

export interface YearlyStats {
  totalHours: number;
  approvedHours: number;
  submittedHours: number;
  pendingHours: number;
  hoursPerClient: { [clientId: string]: number };
  uniqueClients: number;
  averageHoursPerDay: number;
  mostActiveClient: {
    clientName: string;
    hours: number;
  } | null;
  monthlyHours: MonthlyHours[];
}

export const useFreelancerHomeStatistics = (selectedYear: number) => {
  const { userInfo } = useAuth();
  const { timesheetsQuery, clientsProjectsAndAssignmentsQuery, timesheetsError, clientsProjectsAndAssignmentsError } = useTimesheet(userInfo?.id || '');
  

  const calculateStats = (): YearlyStats => {
    if (!timesheetsQuery.data || !clientsProjectsAndAssignmentsQuery.data || timesheetsError || clientsProjectsAndAssignmentsError) {
      return {
        totalHours: 0,
        approvedHours: 0,
        submittedHours: 0,
        pendingHours: 0,
        hoursPerClient: {},
        uniqueClients: 0,
        averageHoursPerDay: 0,
        mostActiveClient: null,
        monthlyHours: []
      };
    }

    const yearTimesheets = timesheetsQuery.data.filter(timesheet => {
      const timesheetYear = new Date(timesheet.date).getFullYear();
      return timesheetYear === selectedYear;
    });

    const hoursPerClient: { [clientId: string]: number } = {};
    const clientNames: { [clientId: string]: string } = {};
    const monthlyHoursMap: { [month: string]: MonthlyHours } = {};

    // Initialize monthly hours
    for (let month = 0; month < 12; month++) {
      const monthStr = new Date(selectedYear, month).toLocaleString('default', { month: 'short' });
      monthlyHoursMap[monthStr] = {
        month: monthStr,
        hours: 0,
        approvedHours: 0,
        submittedHours: 0
      };
    }

    let totalApprovedHours = 0;
    let totalSubmittedHours = 0;

    yearTimesheets.forEach(timesheet => {
      const hours = (timesheet.duration || 0) / 3600; // Convert seconds to hours
      
      // Update hours per client
      if (timesheet.clientId) {
        hoursPerClient[timesheet.clientId] = (hoursPerClient[timesheet.clientId] || 0) + hours;
        
        // Get client name from the assignments data
        const client = clientsProjectsAndAssignmentsQuery.data.clients.find(c => c.id === timesheet.clientId);
        if (client) {
          clientNames[timesheet.clientId] = client.companyName;
        }
      }

      // Update monthly hours based on approval status
      const month = new Date(timesheet.date).toLocaleString('default', { month: 'short' });
      monthlyHoursMap[month].hours += hours;

      if (timesheet.approved) {
        monthlyHoursMap[month].approvedHours += hours;
        totalApprovedHours += hours;
      } else if (timesheet.submitted) {
        monthlyHoursMap[month].submittedHours += hours;
        totalSubmittedHours += hours;
      }
    });

    const totalHours = Object.values(hoursPerClient).reduce((sum, hours) => sum + hours, 0);
    const uniqueClients = Object.keys(hoursPerClient).length;
    const pendingHours = totalHours - totalApprovedHours - totalSubmittedHours;

    // Calculate average hours per day (considering only days with entries)
    const uniqueDays = new Set(yearTimesheets.map(t => t.date)).size;
    const averageHoursPerDay = uniqueDays > 0 ? totalHours / uniqueDays : 0;

    // Find most active client
    let mostActiveClient = null;
    let maxHours = 0;
    Object.entries(hoursPerClient).forEach(([clientId, hours]) => {
      if (hours > maxHours) {
        maxHours = hours;
        mostActiveClient = {
          clientName: clientNames[clientId] || 'Unknown Client',
          hours: hours
        };
      }
    });

    // Convert monthly hours map to array
    const monthlyHours = Object.values(monthlyHoursMap);

    return {
      totalHours,
      approvedHours: totalApprovedHours,
      submittedHours: totalSubmittedHours,
      pendingHours,
      hoursPerClient,
      uniqueClients,
      averageHoursPerDay,
      mostActiveClient,
      monthlyHours
    };
  };

  const stats = calculateStats();

  return {
    stats,
    isLoading: timesheetsQuery.isLoading || clientsProjectsAndAssignmentsQuery.isLoading,
    error: timesheetsQuery.error || clientsProjectsAndAssignmentsQuery.error
  };
}; 