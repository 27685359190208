import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Button,
  Tabs,
  Tab,
  Avatar,
  Divider,
} from "@mui/material";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import SendIcon from "@mui/icons-material/Send";
import { DateTime } from "luxon";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { MentionInput } from "./MentionInput";
import { MentionDisplay } from "./MentionDisplay";
import { BoardActivityEvent } from "types/freelo";
import { Card, CardComment } from "types/freelo";

interface ActivitySectionProps {
  card: Card;
  activities: BoardActivityEvent[];
  onAddComment: (comment: string, mentionedUsers: string[]) => Promise<void>;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`activity-tabpanel-${index}`}
      aria-labelledby={`activity-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 2 }}>{children}</Box>}
    </div>
  );
}

export const ActivitySection: React.FC<ActivitySectionProps> = ({
  card,
  activities,
  onAddComment,
}) => {
  const [activeTab, setActiveTab] = useState(1);
  const [newComment, setNewComment] = useState("");
  const [mentionedUsers, setMentionedUsers] = useState<string[]>([]);
  const { data: users } = usePublicUserList();

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleCommentKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" && (e.ctrlKey || e.metaKey)) {
      e.preventDefault();
      handleAddComment();
    }
  };

  const handleCommentChange = (value: string, mentionedUserIds?: string[]) => {
    setNewComment(value);
    if (mentionedUserIds) {
      setMentionedUsers(mentionedUserIds);
    }
  };

  const handleAddComment = async () => {
    if (!newComment.trim()) return;
    await onAddComment(newComment, mentionedUsers);
    setNewComment("");
    setMentionedUsers([]);
  };

  const getUserById = (userId: string) => {
    return users?.find((user) => user.id === userId);
  };

  const renderCommentContent = (content: string) => {
    // No need to split on @[userId] pattern anymore since we're displaying names directly
    const parts = content.split(/(@[\w\s]+)/g);
    return parts.map((part, index) => {
      if (part.startsWith("@")) {
        // The part will already contain the user's name in the format "@firstName lastName"
        return (
          <Typography
            key={index}
            component="span"
            sx={{
              color: "primary.main",
              fontWeight: "medium",
            }}
          >
            {part}
          </Typography>
        );
      }
      return part;
    });
  };

  const renderComment = (comment: CardComment) => {
    const commenter = getUserById(comment.createdBy);

    return (
      <Box key={comment.id} sx={{ mb: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
          <Avatar
            src={commenter?.profilePicture || ""}
            alt={
              commenter
                ? `${commenter.firstName} ${commenter.lastName}`
                : "Unknown"
            }
            sx={{ width: 32, height: 32 }}
          >
            {commenter?.firstName[0]}
          </Avatar>
          <Box>
            <Typography variant="subtitle2">
              {commenter
                ? `${commenter.firstName} ${commenter.lastName}`
                : "Unknown User"}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {DateTime.fromISO(comment.createdAt).toFormat(
                "MMM d, yyyy h:mm a"
              )}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ pl: 5 }}>
          <MentionDisplay
            content={comment.content}
            mentionedUserIds={comment.mentionedUserIds}
            users={users}
          />
        </Box>
      </Box>
    );
  };

  const renderActivity = (activity: BoardActivityEvent) => {
    const actor = getUserById(activity.actor);

    return (
      <Box key={activity.id} sx={{ mb: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
          <Avatar
            src={actor?.profilePicture || ""}
            alt={actor ? `${actor.firstName} ${actor.lastName}` : "Unknown"}
            sx={{ width: 32, height: 32 }}
          >
            {actor?.firstName[0]}
          </Avatar>
          <Box>
            <Typography variant="subtitle2">
              {actor ? `${actor.firstName} ${actor.lastName}` : "Unknown User"}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {DateTime.fromISO(activity.dateTimeOccured).toFormat(
                "MMM d, yyyy h:mm a"
              )}
            </Typography>
          </Box>
        </Box>
        <Box sx={{ pl: 5 }}>
          <Typography variant="body2">{activity.additionalInfo}</Typography>
        </Box>
      </Box>
    );
  };

  const cardActivities = activities?.filter((activity) =>
    activity.additionalInfo?.includes(card.humanReadableId)
  );

  const renderTimelineItems = () => {
    const allItems = [
      ...(card.comments?.map((comment) => ({
        ...comment,
        type: "comment" as const,
        dateTimeOccured: comment.createdAt,
      })) || []),
      ...(cardActivities?.map((activity) => ({
        ...activity,
        type: activity.type,
      })) || []),
    ].sort(
      (a, b) =>
        DateTime.fromISO(b.dateTimeOccured).toMillis() -
        DateTime.fromISO(a.dateTimeOccured).toMillis()
    );

    return allItems.map((item) =>
      "content" in item ? renderComment(item) : renderActivity(item)
    );
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}>
        <FormatListBulletedIcon />
        <Typography variant="h6">Activity</Typography>
      </Box>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label="All" />
          <Tab label={`Comments (${card.comments?.length || 0})`} />
          <Tab label={`Activity (${cardActivities?.length || 0})`} />
        </Tabs>
      </Box>

      <TabPanel value={activeTab} index={0}>
        <Box sx={{ mb: 3 }}>{renderTimelineItems()}</Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <MentionInput
            value={newComment}
            onChange={handleCommentChange}
            onKeyDown={handleCommentKeyDown}
            placeholder="Write a comment... (Ctrl+Enter to save)"
          />
          <Button
            variant="contained"
            onClick={handleAddComment}
            disabled={!newComment.trim()}
            sx={{ alignSelf: "flex-end" }}
          >
            <SendIcon />
          </Button>
        </Box>
      </TabPanel>

      <TabPanel value={activeTab} index={1}>
        <Box sx={{ mb: 3 }}>{card.comments?.map(renderComment)}</Box>
        <Box sx={{ display: "flex", gap: 1 }}>
          <MentionInput
            value={newComment}
            onChange={handleCommentChange}
            onKeyDown={handleCommentKeyDown}
            placeholder="Write a comment... (Ctrl+Enter to save)"
          />
          <Button
            variant="contained"
            onClick={handleAddComment}
            disabled={!newComment.trim()}
            sx={{ alignSelf: "flex-end" }}
          >
            <SendIcon />
          </Button>
        </Box>
      </TabPanel>

      <TabPanel value={activeTab} index={2}>
        <Box sx={{ mb: 3 }}>{cardActivities?.map(renderActivity)}</Box>
      </TabPanel>
    </Box>
  );
};
