import { DateTime } from "luxon";

export const formatProjectAssignmentDisplayName = (
  projectName: string,
  startDate: string | null,
  endDate: string | null,
  assignmentId: string
): string => {
  if (!startDate || !endDate) {
    return `${projectName} (Starting soon..) #${assignmentId}`;
  }
  
  const start = DateTime.fromISO(startDate).toFormat("MM/dd/yyyy");
  const end = DateTime.fromISO(endDate).toFormat("MM/dd/yyyy");
  return `${projectName} (${start} - ${end}) #${assignmentId}`;
}; 