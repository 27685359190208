import React from 'react';
import { NodeViewWrapper, NodeViewContent } from '@tiptap/react';
import { Select, MenuItem, Box } from '@mui/material';

const SUPPORTED_LANGUAGES = [
  'javascript',
  'typescript',
  'html',
  'css',
  'python',
  'java',
  'cpp',
  'csharp',
  'go',
  'rust',
  'sql',
  'bash',
  'json',
  'yaml',
  'markdown',
] as const;

type SupportedLanguage = typeof SUPPORTED_LANGUAGES[number];

interface CodeBlockProps {
  node: {
    attrs: {
      language: SupportedLanguage;
    };
  };
  updateAttributes: (attrs: { language: SupportedLanguage }) => void;
  extension: any; // We're not using this prop but Tiptap requires it
}

export const CodeBlock: React.FC<CodeBlockProps> = ({ node, updateAttributes }) => {
  return (
    <NodeViewWrapper className="code-block">
      <Box sx={{ 
        position: 'relative',
        backgroundColor: '#1e1e1e',
        borderRadius: '4px',
        mb: 2 
      }}>
        <Select
          value={node.attrs.language || 'javascript'}
          onChange={e => updateAttributes({ language: e.target.value as SupportedLanguage })}
          size="small"
          sx={{
            position: 'absolute',
            right: '8px',
            top: '8px',
            minWidth: 120,
            backgroundColor: 'background.paper',
            '& .MuiSelect-select': {
              py: 0.5,
              pr: 3,
            },
          }}
        >
          {SUPPORTED_LANGUAGES.map(lang => (
            <MenuItem key={lang} value={lang}>
              {lang}
            </MenuItem>
          ))}
        </Select>
        <pre>
          <NodeViewContent as="code" />
        </pre>
      </Box>
    </NodeViewWrapper>
  );
}; 