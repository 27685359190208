import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Chip,
} from "@mui/material";
import StakeholderProjectCard from "../components/StakeholderProjectCard";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import { useProjectsNonAdmin } from "hooks/project/useProjectsNonAdmin";

const StakeholderProjects = () => {
  const navigate = useNavigate();
  const { data: projects, isLoading, error } = useProjectsNonAdmin();
  const [selectedClient, setSelectedClient] = useState<string>("all");

  // Get unique client IDs from projects
  const clients = useMemo(() => {
    if (!projects) return [];
    const uniqueClients = new Set(projects.map((project) => project.clientId));
    return Array.from(uniqueClients);
  }, [projects]);

  const filteredProjects = useMemo(() => {
    if (!projects) return [];
    if (selectedClient === "all") return projects;
  return projects.filter((project) => project.clientId === selectedClient);
  }, [projects, selectedClient]);

  const handleSubmitNewIdea = () => {
    navigate("/stakeholder/new-idea");
  };

  const handleProjectClick = (projectId: string) => {
    navigate(`/stakeholder/projects/${projectId}`);
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <Paper
            elevation={3}
            sx={{ p: 4, textAlign: "center", maxWidth: 400 }}
          >
            <ErrorOutlineIcon color="error" sx={{ fontSize: 60, mb: 2 }} />
            <Typography variant="h5" gutterBottom>
              Error
            </Typography>
            <Typography color="error">{error.message}</Typography>
          </Paper>
        </Box>
      );
    }

    return (
      <Box sx={{ p: 3 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={3}
        >
          <Typography variant="h5">Stakeholder Projects</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<LightbulbIcon />}
            onClick={handleSubmitNewIdea}
          >
            Submit a New Idea
          </Button>
        </Box>

        <Box mb={3}>
          <FormControl fullWidth variant="outlined">
            <InputLabel>Filter by Client</InputLabel>
            <Select
              value={selectedClient}
              onChange={(e) => setSelectedClient(e.target.value)}
              label="Filter by Client"
            >
              <MenuItem value="all">All Clients</MenuItem>
              {clients.map((clientId) => (
                <MenuItem key={clientId} value={clientId}>
                  {clientId}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {!filteredProjects?.length ? (
          <Typography>No projects found for this stakeholder.</Typography>
        ) : (
          <Grid container spacing={3}>
            {filteredProjects.map((project) => (
              <Grid item xs={12} sm={6} md={4} key={project.id}>
                <StakeholderProjectCard
                  project={project}
                  onClick={() => handleProjectClick(project.id)}
                />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    );
  };

  return <>{renderContent()}</>;
};

export default StakeholderProjects;
