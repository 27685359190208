import { initializeWebSocket } from './initializeWebSocket';
import { getAuthToken } from '../auth/getAuthToken';

interface UserActivity {
  userId: string;
  page: string;
  timestamp: number;
  action?: string;
}

export const logUserActivity = async (activity: UserActivity) => {
  const token = await getAuthToken();
  if (!token) return;
  const socket = await initializeWebSocket(token);
  socket.emit('userActivity', activity);
}; 