import React from 'react';
import { Box, Typography, Chip, TextField, Autocomplete } from '@mui/material';
import { Freelancer } from "types/user";
interface SkillsSectionProps {
  freelancer: Freelancer;
  onUpdate: (updatedFreelancer: Freelancer) => void;
}

const predefinedSkills = [
  "JavaScript", "React", "Node.js", "Python", "Java", "C#", "Ruby", "PHP", "Swift",
  "Kotlin", "Go", "Rust", "TypeScript", "Angular", "Vue.js", "Docker", "Kubernetes",
  "AWS", "Azure", "GCP", "MongoDB", "PostgreSQL", "MySQL", "Redis", "GraphQL",
  "REST API", "Machine Learning", "Data Science", "DevOps", "CI/CD"
];

const getSkillColor = (skill: string) => {
  let hash = 0;
  for (let i = 0; i < skill.length; i++) {
    hash = skill.charCodeAt(i) + ((hash << 5) - hash);
  }
  const hue = hash % 360;
  return `hsl(${hue}, 70%, 80%)`;
};

const SkillsSection: React.FC<SkillsSectionProps> = ({ freelancer, onUpdate }) => {
  const [skills, setSkills] = React.useState<string[]>(freelancer.skills || []);
  const [newSkill, setNewSkill] = React.useState<string>("");
  const [availableSkills, setAvailableSkills] = React.useState<string[]>(
    predefinedSkills.filter(skill => !freelancer.skills?.includes(skill))
  );

  const handleAddSkill = (event: React.KeyboardEvent<HTMLDivElement>, value: string | null) => {
    if (event.key === 'Enter' && value && !skills.includes(value)) {
      const updatedSkills = [...skills, value];
      setSkills(updatedSkills);
      onUpdate({ ...freelancer, skills: updatedSkills });
      setAvailableSkills((prevSkills) => prevSkills.filter((skill) => skill !== value));
      setNewSkill("");
    }
  };

  const handleDeleteSkill = (skillToDelete: string) => {
    const updatedSkills = skills.filter((skill) => skill !== skillToDelete);
    setSkills(updatedSkills);
    onUpdate({ ...freelancer, skills: updatedSkills });
    setAvailableSkills((prevSkills) => [...prevSkills, skillToDelete]);
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>Skills</Typography>
      <Autocomplete
        freeSolo
        options={availableSkills}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="standard"
            placeholder="Add a skill"
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            sx={{
              '& .MuiInputBase-root': {
                padding: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: '200px',
                border: '1px solid #ccc',
                borderRadius: '4px',
              },
            }}
          />
        )}
        value={newSkill}
        onChange={(event, value) => setNewSkill(value || "")}
        onKeyDown={(event) => handleAddSkill(event, newSkill)}
        sx={{ mb: 2 }}
      />
      <Box display="flex" flexWrap="wrap" gap={1}>
        {skills.map((skill, index) => (
          <Chip
            key={index}
            label={skill}
            onDelete={() => handleDeleteSkill(skill)}
            sx={{
              backgroundColor: getSkillColor(skill),
              '&:hover': {
                backgroundColor: getSkillColor(skill),
                filter: 'brightness(0.9)',
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default SkillsSection;