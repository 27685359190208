import { DateTime } from "luxon";
import { ProjectAssignment } from "types/projects";
import { Freelancer } from "types/user";

export async function getPreviousMonthAssignments(
  freelancers: Freelancer[]
): Promise<ProjectAssignment[]> {
  const lastMonth = DateTime.now().minus({ months: 1 });
  const startDate = lastMonth.startOf("month");
  const endDate = lastMonth.endOf("month");

  // Group assignments by engineer with proper typing
  const groupedAssignments = freelancers.reduce<ProjectAssignment[]>(
    (acc, freelancer) => {
      if (freelancer.projectAssignments?.length) {
        acc.push(
          ...freelancer.projectAssignments.filter((pa: ProjectAssignment) => {
            const paDate = DateTime.fromISO(pa.startDate || "");
            return paDate >= startDate && paDate <= endDate;
          })
        );
      }
      return acc;
    },
    []
  );

  return groupedAssignments;
}
