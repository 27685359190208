import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  Outlet,
} from "react-router-dom";
import { AccountDeactivated, NotFound, SessionExpired } from "components";
import { AuthRoute, SuperUserRoute, FreelancerRoute } from "./routes";
import { Login, ForgotPassword, ResetPassword } from "views/Auth";
import {
  stakeholderPages,
  superuserPages,
  freelancerPages,
} from "router/pages";
import StakeholderRoute from "./routes/StakeholderRoute";
import { mapToolpadToReactRouter } from "router/layouts/mapToolpadToReactRouter";
import { AuthLayout, OnboardingLayout } from "./layouts";
import { DashboardLayoutProvider } from "./layouts/DashboardLayoutProvider";
import StandardErrorBoundary from "views/Shared/ErrorBoundaries/StandardErrorBoundary";
import { FreelancerOnboarding } from "views/Freelancer/FreelancerOnboarding/FreelancerOnboarding";
import { StakeholderOnboarding } from "views/Stakeholder/StakeholderOnboarding/StakeholderOnboarding";
import { AccountSettings } from "views/Shared/AccountSettings/AccountSettings";
import { LoginMFACode } from "views/Auth/LoginMFACode";
import { useAuth } from "hooks/auth/useAuth";
import { MainLoading } from "./layouts/MainLoading";
import { NeedsResetPassword } from "../views/Shared/NeedsResetPassword/NeedsResetPassword";

export const AppRoutes = () => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return <MainLoading />;
  }

  const AuthRoutes = () => {
    return (
      <>
        <Route
          path="/login"
          element={
            <AuthRoute>
              <Login />
            </AuthRoute>
          }
        />

        <Route
          path="/forgot-password"
          element={
            <AuthRoute>
              <ForgotPassword />
            </AuthRoute>
          }
        />

        <Route
          path="/reset-password"
          element={
            <AuthRoute>
              <ResetPassword />
            </AuthRoute>
          }
        />

        <Route
          path="/login-mfa-code"
          element={
            <AuthRoute>
              <LoginMFACode />
            </AuthRoute>
          }
        />

        <Route path="/session-expired" element={<SessionExpired />} />
      </>
    );
  };

  const RedirectRoutes = () => {
    return (
      <>
        {/* Not Found - other pages redirect here */}
        <Route path="/not-found" element={<NotFound />} />

        {/* Redirect logic to /login or /role occurs in AuthLayout */}
        <Route path="/" />

        {/* Redirect * to NotFound to hide SuperUser Routes  */}
        <Route path="*" element={<Navigate to="/not-found" replace />} />

        <Route path="/account-deactivated" element={<AccountDeactivated />} />
      </>
    );
  };

  const FreelancerRoutes = () => {
    return <>{mapToolpadToReactRouter(freelancerPages, FreelancerRoute)}</>;
  };

  const SuperUserRoutes = () => {
    return <>{mapToolpadToReactRouter(superuserPages, SuperUserRoute)}</>;
  };

  const StakeholderRoutes = () => {
    return <>{mapToolpadToReactRouter(stakeholderPages, StakeholderRoute)}</>;
  };

  return (
    <Router>
      <Routes>
        <Route element={<AuthLayout />}>
          {AuthRoutes()}
          {RedirectRoutes()}
        </Route>

        {/* Onboarding Layout */}
        <Route element={<OnboardingLayout />}>
          <Route
            path="/freelancer-onboarding"
            element={<FreelancerOnboarding />}
          />
          <Route
            path="/stakeholder-onboarding"
            element={<StakeholderOnboarding />}
          />
          <Route
            path="/needs-reset-password"
            element={<NeedsResetPassword />}
          />
        </Route>

        <Route element={<DashboardLayoutProvider />}>
          <Route
            element={
              <StandardErrorBoundary>
                <Outlet />
              </StandardErrorBoundary>
            }
          >
            <Route path="/account-settings" element={<AccountSettings />} />
            {FreelancerRoutes()}
            {SuperUserRoutes()}
            {StakeholderRoutes()}
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};
