interface AdminOnboardingNotificationParams {
  freelancerName: string;
  freelancerEmail: string;
  hasClearance: boolean;
  clearanceLevel?: string;
  isUsCitizen: boolean;
  hasWorkAuthorization?: boolean;
  workAuthorizationType?: string;
  skills: string[];
}

export const adminOnboardingNotification = (params: AdminOnboardingNotificationParams) => {
  const {
    freelancerName,
    freelancerEmail,
    hasClearance,
    clearanceLevel,
    isUsCitizen,
    hasWorkAuthorization,
    workAuthorizationType,
    skills,
  } = params;

  const formatClearanceInfo = () => {
    if (!hasClearance) return 'No security clearance';
    return `Has security clearance (${clearanceLevel})`;
  };

  const formatCitizenshipInfo = () => {
    if (isUsCitizen) return 'U.S. Citizen';
    if (hasWorkAuthorization) return `Non-U.S. Citizen with work authorization (${workAuthorizationType})`;
    return 'Non-U.S. Citizen without work authorization';
  };

  return {
    subject: `New Freelancer Onboarding Complete - ${freelancerName}`,
    html: `
      <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
        <h2>New Freelancer Onboarding Notification</h2>
        
        <h3>Freelancer Details:</h3>
        <ul>
          <li><strong>Name:</strong> ${freelancerName}</li>
          <li><strong>Email:</strong> ${freelancerEmail}</li>
          <li><strong>Citizenship Status:</strong> ${formatCitizenshipInfo()}</li>
          <li><strong>Security Clearance:</strong> ${formatClearanceInfo()}</li>
        </ul>
        
        <h3>Skills:</h3>
        <ul>
          ${skills.map(skill => `<li>${skill}</li>`).join('')}
        </ul>
        
        <p>Please review their profile and take appropriate action.</p>
        
        <div style="margin-top: 30px;">
          <p>Best regards,</p>
          <p>FreeTech Portal</p>
        </div>
      </div>
    `,
  };
}; 