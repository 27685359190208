import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import { useAuth } from "hooks/auth/useAuth";
import SettingsProfileCard from "./components/SettingsProfileCard";
import ResetCurrentUserPassword from "./components/ResetCurrentUserPassword";
import SelfEnrollMFA from "./components/SelfEnrollMFA";

export const AccountSettings = () => {
  return (
    <Box sx={{ p: 3, maxWidth: 800, margin: "0 auto" }}>
      <Typography variant="h4" gutterBottom>
        Account Settings
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <SettingsProfileCard />
        </Grid>
        <Grid item xs={12}>
          <ResetCurrentUserPassword />
        </Grid>
        <Grid item xs={12}>
          <SelfEnrollMFA />
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccountSettings;
