import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { getProjectName } from "core/projects";
import { getCSAName } from "core/csa";
import {
  Box,
  Typography,
  Grid,
  CircularProgress,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEversignSDK } from "hooks/eversign/useEversignSDK";
import { sendMailTemplate } from "core/mail";
import { PASignedByAdminEngineerUpdateProps } from "core/mail/mailTemplates/paSignedByAdminEngineerUpdate";
import { useAdminSignAgreements, AdminAgreementToSign } from "hooks/admin";
import { useAdminClients } from "hooks/admin/useAdminClients";
import { useManageUsers } from "hooks/admin";
import { ProjectAssignment } from "types/projects";
import { IndependentEngineerAgreement } from "types/user";
import { IndEngToSignCard } from "components/IndEngToSignCard";
import { ProjAssToSignCard } from "components/ProjAssToSignCard";
import { usePublicUserList } from "hooks/user/usePublicUserList";

declare global {
  interface Window {
    eversign: any;
  }
}

type EnrichedAgreement = AdminAgreementToSign & {
  projectName?: string;
  csaName?: string;
  freelancerName: string;
  clientName?: string;
};

const AdminSignAgreements: React.FC = () => {
  const { userInfo } = useAuth();
  const [agreements, setAgreements] = useState<EnrichedAgreement[]>([]);
  const [loading, setLoading] = useState(true);
  const [selectedAgreement, setSelectedAgreement] =
    useState<EnrichedAgreement | null>(null);
  const [showWarning, setShowWarning] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const {
    isLoaded: eversignSDKLoaded,
    error: eversignSDKError,
    initSDK,
  } = useEversignSDK();
  const [sdkError, setSdkError] = useState<string | null>(null);
  const [signingError, setSigningError] = useState<string | null>(null);
  const [showEngineerSignatureDialog, setShowEngineerSignatureDialog] =
    useState(false);
  const [reminderSent, setReminderSent] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const {
    data: signingAgreements,
    isLoading,
    isError,
    updateProjectAssignment,
    updateSignedAgreement,
    cancelProjectAssignment,
    cancelIndependentEngineerAgreement,
    isUpdatingProjectAssignment,
    isUpdatingSignedAgreement,
    isCancellingProjectAssignment,
    isCancellingIndependentEngineerAgreement,
  } = useAdminSignAgreements();
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const [agreementToCancel, setAgreementToCancel] =
    useState<EnrichedAgreement | null>(null);
  const [isCancelling, setIsCancelling] = useState(false);
  const { data: users } = usePublicUserList();
  const { clients } = useAdminClients();

  useEffect(() => {
    const fetchAgreements = async () => {
      if (isLoading) {
        setLoading(true);
        return;
      }

      if (isError) {
        setError("Error fetching agreements. Please try again later.");
        setLoading(false);
        return;
      }

      if (signingAgreements) {
        try {
          const enrichedAgreements = await Promise.all(
            signingAgreements.map(async (agreement) => {
              let projectName = "";
              let csaName = "";
              let clientName = "";

              if (agreement.type === "projectAssignment") {
                const projAss = agreement.agreement as ProjectAssignment;
                if (projAss.clientId && projAss.projectId) {
                  projectName = await getProjectName(
                    projAss.clientId,
                    projAss.projectId
                  );
                  if (projAss.csaId) {
                    csaName =
                      (await getCSAName(
                        projAss.clientId,
                        projAss.projectId,
                        projAss.csaId
                      )) || "";
                  }
                  const clientData = clients?.find(
                    (client) => client.id === projAss.clientId
                  );
                  clientName = clientData?.companyName || "Unknown Client";
                }
              }

              const userId =
                agreement.type === "projectAssignment"
                  ? (agreement.agreement as ProjectAssignment).engineerId
                  : (agreement.agreement as IndependentEngineerAgreement)
                      .userId;

              const freelancer = users?.find((user) => user.id === userId);

              return {
                ...agreement,
                projectName,
                csaName,
                freelancerName:
                  freelancer?.firstName + " " + freelancer?.lastName ||
                  "Unknown Freelancer",
                clientName,
              };
            })
          );

          setAgreements(enrichedAgreements);
        } catch (error) {
          console.error("Error enriching agreements:", error);
          setError("Error processing agreements. Please try again later.");
        } finally {
          setLoading(false);
        }
      }
    };

    fetchAgreements();
  }, [signingAgreements, isLoading, isError, users, clients]);

  useEffect(() => {
    if (selectedAgreement && containerRef.current && eversignSDKLoaded) {
      if (window.eversign && window.eversign.open) {
        window.eversign.open({
          url: selectedAgreement.agreement.eversignAdminDocumentEmbedUrl,
          containerID: containerRef.current.id,
          width: containerRef.current.offsetWidth,
          height: containerRef.current.offsetHeight,
          events: {
            loaded: () => {},
            signed: async () => {
              handleAgreementSigned().then(() => {
                setSelectedAgreement(null);
              });
            },
            declined: () => {
              setSelectedAgreement(null);
            },
            error: (error: any) => {
              console.error("Eversign error:", error);
              if (error === "Document not ready to be signed.") {
                setSigningError("Error .");
              } else {
                setSigningError(`An error occurred: ${error}`);
              }
              setSelectedAgreement(null);
            },
          },
        });
      } else {
        console.error("Eversign SDK not properly loaded");
        setSdkError(
          "Eversign SDK failed to load properly. Please try refreshing the page."
        );
      }
    }
  }, [selectedAgreement, eversignSDKLoaded]);

  const handleCardClick = (agreement: EnrichedAgreement) => {
    if (
      agreement.agreement.everSignDocumentStatus ===
      "awaiting_engineer_signature"
    ) {
      setShowEngineerSignatureDialog(true);
    } else {
      setSelectedAgreement(agreement);
    }
  };

  const handleBack = () => {
    setShowWarning(true);
  };

  const handleCloseWarning = () => {
    setShowWarning(false);
  };

  const handleConfirmBack = () => {
    setShowWarning(false);
    setSelectedAgreement(null);
  };

  const handleAgreementSigned = async () => {
    if (!selectedAgreement) return;

    try {
      if (selectedAgreement.type === "projectAssignment") {
        const projAss = selectedAgreement.agreement as ProjectAssignment;
        if (!projAss.clientId || !projAss.id) {
          throw new Error("Missing required fields for project assignment");
        }
        await updateProjectAssignment(projAss.clientId, projAss.id, {
          everSignDocumentStatus: "awaiting_engineer_signature",
          status: "signing",
        });
      } else {
        const indEng =
          selectedAgreement.agreement as IndependentEngineerAgreement;
        if (!indEng.userId || !indEng.id) {
          throw new Error(
            "Missing required fields for independent engineer agreement"
          );
        }

        const userProfile = users?.find((user) => user.id === indEng.userId);
        if (!userProfile || typeof userProfile === "string") {
          throw new Error("No valid user profile found for the engineer");
        }

        await updateSignedAgreement({
          userId: indEng.userId,
          agreementId: indEng.id,
          engineerEmail: userProfile.email,
          engineerName: `${userProfile.firstName} ${userProfile.lastName}`,
          engineerFirstName: userProfile.firstName,
        });
      }

      // Refresh the agreements list
      const updatedAgreements = agreements.filter(
        (a) => a.id !== selectedAgreement.id
      );
      setAgreements(updatedAgreements);
      setSelectedAgreement(null);
    } catch (error) {
      console.error(
        "Error updating agreement status or sending notification:",
        error
      );
      setSigningError("Failed to process signed agreement");
    }
  };

  const handleCloseSigningError = () => {
    setSigningError(null);
  };

  const handleCloseEngineerSignatureDialog = () => {
    setShowEngineerSignatureDialog(false);
  };

  const handleSendReminder = async () => {
    if (!selectedAgreement) return;

    try {
      const userId =
        selectedAgreement.type === "projectAssignment"
          ? (selectedAgreement.agreement as ProjectAssignment).engineerId
          : (selectedAgreement.agreement as IndependentEngineerAgreement)
              .userId;

      if (!userId) {
        throw new Error("No user ID found for the engineer");
      }

      const userProfile = users?.find((user) => user.id === userId);

      if (!userProfile || typeof userProfile === "string") {
        throw new Error("No valid user profile found for the engineer");
      }

      await sendMailTemplate(
        userProfile.email,
        `Reminder: Please sign the agreement for ${selectedAgreement.projectName || "Independent Engineer Agreement"}`,
        "paSignedByAdminEngineerUpdate",
        {
          engineerName: `${userProfile.firstName} ${userProfile.lastName}`,
          projectName:
            selectedAgreement.projectName || "Independent Engineer Agreement",
          url: "https://portal.freetech.co/sign-agreement",
        } as PASignedByAdminEngineerUpdateProps
      );

      setReminderSent(true);
    } catch (error) {
      console.error("Error sending reminder email:", error);
      setError("Failed to send reminder email");
    }
  };

  const handleCancelClick = (agreement: EnrichedAgreement) => {
    setAgreementToCancel(agreement);
    setShowCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setShowCancelDialog(false);
    setAgreementToCancel(null);
  };

  const handleConfirmCancel = async () => {
    if (!agreementToCancel) return;

    setIsCancelling(true);

    try {
      if (agreementToCancel.type === "projectAssignment") {
        const projAss = agreementToCancel.agreement as ProjectAssignment;
        if (!projAss.eversignDocumentHash || !projAss.clientId || !projAss.id) {
          throw new Error(
            `Missing required fields for project assignment: ${JSON.stringify({
              eversignDocumentHash: !!projAss.eversignDocumentHash,
              clientId: !!projAss.clientId,
              id: !!projAss.id,
            })}`
          );
        }
        await cancelProjectAssignment(
          projAss.clientId,
          projAss.id,
          projAss.eversignDocumentHash
        );
      } else {
        const indEng =
          agreementToCancel.agreement as IndependentEngineerAgreement;
        if (!indEng.eversignDocumentHash) {
          throw new Error("Missing eversignDocumentHash");
        }
        if (!indEng.id) {
          throw new Error("Missing agreement id");
        }
        if (!indEng.userId) {
          throw new Error("Missing userId");
        }

        await cancelIndependentEngineerAgreement(
          indEng.userId,
          indEng.id,
          indEng.eversignDocumentHash
        );
      }

      // Update the local state to remove the cancelled agreement
      setAgreements(agreements.filter((a) => a.id !== agreementToCancel.id));
      handleCloseCancelDialog();
    } catch (error) {
      console.error("Error cancelling agreement:", error);
      setError(
        error instanceof Error
          ? error.message
          : "Failed to cancel the agreement. Please try again."
      );
    } finally {
      setIsCancelling(false);
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading agreements...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Typography variant="h6" color="error" gutterBottom>
          {error}
        </Typography>
        <Button variant="contained" onClick={() => window.location.reload()}>
          Retry
        </Button>
      </Box>
    );
  }

  if (sdkError) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "80vh",
        }}
      >
        <Typography variant="h6" color="error" gutterBottom>
          {sdkError}
        </Typography>
        <Button variant="contained" onClick={() => window.location.reload()}>
          Refresh Page
        </Button>
      </Box>
    );
  }

  if (selectedAgreement) {
    const title =
      selectedAgreement.type === "projectAssignment"
        ? `Sign Agreement: ${selectedAgreement.projectName}`
        : "Sign Independent Engineer Agreement";

    return (
      <Box sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <Box
          sx={{
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Button startIcon={<ArrowBackIcon />} onClick={handleBack}>
            Back
          </Button>
          <Typography variant="h6">{title}</Typography>
        </Box>
        <Box ref={containerRef} id="eversign-container" sx={{ flexGrow: 1 }} />
        <Dialog
          open={showWarning}
          onClose={handleCloseWarning}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Incomplete Agreement"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You haven't completed signing the agreement. Are you sure you want
              to go back?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseWarning}>Cancel</Button>
            <Button onClick={handleConfirmBack} autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }

  return (
    <>
      <Box sx={{ p: 3 }}>
        <Typography variant="h4" gutterBottom>
          Agreements to Sign
        </Typography>
        {agreements.length === 0 ? (
          <Typography>No agreements to sign at the moment.</Typography>
        ) : (
          <Grid container spacing={3}>
            {agreements.map((agreement) => (
              <Grid item xs={12} sm={6} md={4} key={agreement.id}>
                {agreement.type === "projectAssignment" ? (
                  <ProjAssToSignCard
                    assignment={agreement.agreement as ProjectAssignment}
                    onClick={() => handleCardClick(agreement)}
                    onCancel={() => handleCancelClick(agreement)}
                    isAdminView={true}
                  />
                ) : (
                  <IndEngToSignCard
                    agreement={
                      agreement.agreement as IndependentEngineerAgreement
                    }
                    onClick={() => handleCardClick(agreement)}
                    onCancel={() => handleCancelClick(agreement)}
                    isAdminView={true}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <Dialog
        open={!!signingError}
        onClose={handleCloseSigningError}
        aria-labelledby="signing-error-dialog-title"
        aria-describedby="signing-error-dialog-description"
      >
        <DialogTitle id="signing-error-dialog-title">
          {"Signing Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="signing-error-dialog-description">
            {signingError}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseSigningError} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showEngineerSignatureDialog}
        onClose={handleCloseEngineerSignatureDialog}
        aria-labelledby="engineer-signature-dialog-title"
        aria-describedby="engineer-signature-dialog-description"
      >
        <DialogTitle id="engineer-signature-dialog-title">
          {"Awaiting Engineer Signature"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="engineer-signature-dialog-description">
            This agreement is currently awaiting the engineer's signature. You
            cannot sign it at this time.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSendReminder}
            color="primary"
            disabled={reminderSent}
          >
            {reminderSent ? "Reminder Sent" : "Send Reminder"}
          </Button>
          <Button onClick={handleCloseEngineerSignatureDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showCancelDialog}
        onClose={handleCloseCancelDialog}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description"
      >
        <DialogTitle id="cancel-dialog-title">
          {"Cancel Agreement Signing"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="cancel-dialog-description">
            Are you sure you want to cancel this agreement signing process? This
            action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCancelDialog} disabled={isCancelling}>
            No, Keep It
          </Button>
          <Button
            onClick={handleConfirmCancel}
            color="error"
            disabled={isCancelling}
            startIcon={isCancelling ? <CircularProgress size={20} /> : null}
          >
            {isCancelling ? "Cancelling..." : "Yes, Cancel It"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminSignAgreements;
