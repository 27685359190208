import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, doc, updateDoc, deleteDoc, Timestamp } from "firebase/firestore";
import { db } from "config/firebase";
import { UploadDocumentParams, UpdateDocumentParams, DeleteDocumentParams } from "../../../types/admin";

export const uploadDocument = async ({ 
  freelancerId, 
  file, 
  documentData 
}: UploadDocumentParams): Promise<string> => {
  const storage = getStorage();
  const fileRef = ref(storage, `users/${freelancerId}/documents/${file.name}`);
  
  await uploadBytes(fileRef, file);
  const fileUrl = await getDownloadURL(fileRef);

  const docData = {
    ...documentData,
    uploadDate: Timestamp.now().toDate().toISOString(),
    fileUrl,
  };

  const docRef = await addDoc(
    collection(db, "users", freelancerId, "documents"),
    docData
  );

  return docRef.id;
};

export const updateDocument = async ({
  freelancerId,
  documentId,
  updates
}: UpdateDocumentParams): Promise<void> => {
  const docRef = doc(db, "users", freelancerId, "documents", documentId);
  await updateDoc(docRef, updates);
};

export const deleteDocument = async ({
  freelancerId,
  documentId
}: DeleteDocumentParams): Promise<void> => {
  await deleteDoc(doc(db, "users", freelancerId, "documents", documentId));
}; 