import React from 'react';
import { Card, CardContent, Typography, Chip, Box, CardActionArea, Divider, Stack } from '@mui/material';
import { Project, ProjectStatus } from "types/projects";
import { DateTime } from "luxon";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import TimerIcon from "@mui/icons-material/Timer";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

interface StakeholderProjectCardProps {
  project: Project;
  onClick?: () => void;
}

const getStatusColor = (status: ProjectStatus) => {
  switch (status) {
    case 'active':
      return 'success';
    case 'idea':
      return 'warning';
    case 'completed':
      return 'info';
    case 'on_hold':
      return 'warning';
    case 'cancelled':
      return 'error';
    default:
      return 'default';
  }
};

const formatDate = (date: string | null) => {
  if (!date) return 'Not set';
  return DateTime.fromISO(date).toFormat('MMM d, yyyy');
};

const getBudgetAmount = (project: Project) => {
  if (!project.consultingServicesAgreements?.length) return null;
  const latestCSA = [...project.consultingServicesAgreements].sort(
    (a, b) => new Date(b.effectiveDate).getTime() - new Date(a.effectiveDate).getTime()
  )[0];
  return {
    amount: latestCSA.monthlyBudget || latestCSA.fixedPriceBudget || 0,
    type: latestCSA.monthlyBudget ? 'monthly' : 'fixed'
  };
};

const StakeholderProjectCard: React.FC<StakeholderProjectCardProps> = ({ project, onClick }) => {
  const budget = getBudgetAmount(project);

  return (
    <Card 
      sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        cursor: onClick ? 'pointer' : 'default',
        '&:hover': onClick ? {
          transform: 'translateY(-4px)',
          boxShadow: 4,
          transition: 'all 0.2s ease-in-out',
        } : {},
      }}
    >
      <CardActionArea onClick={onClick} disabled={!onClick}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
            <Typography variant="h6" component="div" gutterBottom>
              {project.name}
            </Typography>
            <Chip
              label={project.status}
              color={getStatusColor(project.status)}
              size="small"
              sx={{ textTransform: 'capitalize' }}
            />
          </Box>

          <Typography variant="body2" color="text.secondary" sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 3,
            WebkitBoxOrient: 'vertical',
            mb: 2
          }}>
            {project.description}
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Stack spacing={1.5}>
            {project.projectType && (
              <Box display="flex" alignItems="center" gap={1}>
                <AccountBalanceIcon fontSize="small" color="action" />
                <Typography variant="body2">
                  Type: <Chip label={project.projectType} size="small" />
                </Typography>
              </Box>
            )}

            {project.duration && (
              <Box display="flex" alignItems="center" gap={1}>
                <TimerIcon fontSize="small" color="action" />
                <Typography variant="body2">
                  Duration: {project.duration}
                </Typography>
              </Box>
            )}

            {budget && (
              <Box display="flex" alignItems="center" gap={1}>
                <AttachMoneyIcon fontSize="small" color="action" />
                <Typography variant="body2">
                  Budget: ${budget.amount.toLocaleString()} ({budget.type})
                </Typography>
              </Box>
            )}

            <Box display="flex" alignItems="center" gap={1}>
              <CalendarTodayIcon fontSize="small" color="action" />
              <Box>
                <Typography variant="body2" component="div">
                  Start: {formatDate(project.startDate)}
                </Typography>
                {project.endDate && (
                  <Typography variant="body2" component="div">
                    End: {formatDate(project.endDate)}
                  </Typography>
                )}
              </Box>
            </Box>
          </Stack>

          {project.consultingServicesAgreements?.length ? (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="body2" color="text.secondary">
                {project.consultingServicesAgreements.length} Active Agreement{project.consultingServicesAgreements.length > 1 ? 's' : ''}
              </Typography>
            </>
          ) : null}
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default StakeholderProjectCard;
