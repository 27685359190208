import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Chip,
  Box,
  Button,
  Grid,
  Divider,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { ProjectAssignment, ProjectAssignmentStatus } from "types/projects";
import { useFreelancerRoleClients } from "hooks/freelancer/useFreelancerRoleClients";

interface ProjectAssignmentCardProps {
  assignment: ProjectAssignment;
  onViewFile: (assignment: ProjectAssignment) => void;
  getStatusColor: (
    status: ProjectAssignmentStatus
  ) =>
    | "default"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning";
}

const ProjectAssignmentCard: React.FC<ProjectAssignmentCardProps> = ({
  assignment,
  onViewFile,
  getStatusColor,
}) => {
  const theme = useTheme();
  const { clients } = useFreelancerRoleClients();

  // Find client and project details from the clients data
  const client = clients.find((c) => c.id === assignment.clientId);
  const project = client?.projects?.find((p) => p.id === assignment.projectId);

  const formatDate = (dateString: string | null) => {
    if (!dateString) return "Not set";
    return new Date(dateString).toLocaleDateString();
  };

  return (
    <Card
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        transition: "all 0.3s",
        "&:hover": {
          transform: "translateY(-5px)",
          boxShadow: theme.shadows[10],
        },
      }}
    >
      <CardContent sx={{ flexGrow: 1, position: "relative", p: 3 }}>
        <Chip
          label={assignment.status}
          color={getStatusColor(assignment.status)}
          size="small"
          sx={{
            position: "absolute",
            top: theme.spacing(2),
            right: theme.spacing(2),
            fontWeight: "bold",
          }}
        />
        <Typography variant="h6" gutterBottom sx={{ mb: 2, pr: 8 }}>
          {project?.name || assignment.projectName || "Unnamed Project"}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Id: {assignment.id}
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Client: {client?.companyName || "N/A"}
        </Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <CalendarTodayIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body2">
                Start: {formatDate(assignment.startDate)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <CalendarTodayIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body2">
                End: {formatDate(assignment.endDate)}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <AttachMoneyIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body2">
                Rate: ${assignment.billableRate}/hr
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" alignItems="center">
              <AccessTimeIcon sx={{ mr: 1, color: "text.secondary" }} />
              <Typography variant="body2">
                Hours: {assignment.utilizedHours || 0}/
                {assignment.budgetedHours || 0}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      {assignment.isUploadedToStorage && (
        <Button
          fullWidth
          variant="text"
          color="primary"
          onClick={() => onViewFile(assignment)}
          startIcon={<VisibilityIcon />}
          sx={{
            mt: "auto",
            borderTop: `1px solid ${theme.palette.divider}`,
            borderRadius: 0,
            py: 1.5,
          }}
        >
          View Contract
        </Button>
      )}
    </Card>
  );
};

export default ProjectAssignmentCard;
