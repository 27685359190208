import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "hooks/auth/useAuth";
import {
  ClientInvoiceData,
  IncreaseExternalAccountDetails,
  StakeholderPayment,
} from "@freetech/models/bank/bank";
import { loadMyStakeholderInvoices } from "core/stakeholder/loadMyStakeholderInvoices";
import { collection } from "firebase/firestore";
import { db } from "config/firebase";
import { stakeholderPaymentConverter } from "@freetech/models/converters/stakeholderPaymentConverter";
import { getDocs } from "firebase/firestore";
import { bankFunctions } from "core/functions/bankFunctions";

export const useStakeholderPayments = () => {
  const { currentUser, userInfo } = useAuth();
  const queryClient = useQueryClient();

  const fetchPaymentProfile =
    async (): Promise<IncreaseExternalAccountDetails> => {
      if (!currentUser?.uid) {
        throw new Error("No authenticated user");
      }

      const response =
        await bankFunctions.stakeholder.getMyStakeholderPaymentProfile();

      return response;
    };

  const fetchStakeholderPayments = async (): Promise<StakeholderPayment[]> => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }
    const paymentsRef = collection(
      db,
      "users",
      currentUser.uid,
      "payments"
    ).withConverter(stakeholderPaymentConverter);
    const paymentsDocs = await getDocs(paymentsRef);
    const data = paymentsDocs.docs.map((doc) => {
      const docData = doc.data();
      return docData;
    });

    console.log("stakeholder payments", data);
    return data;
  };

  const {
    data: stakeholderPayments,
    error: stakeholderPaymentsError,
    isLoading: stakeholderPaymentsLoading,
  } = useQuery({
    queryKey: ["stakeholderPayments", currentUser?.uid],
    queryFn: fetchStakeholderPayments,
    enabled: !!currentUser?.uid,
  });

  const { data: paymentProfile, error: paymentProfileError } = useQuery({
    queryKey: ["stakeholderPaymentProfile", currentUser?.uid],
    queryFn: fetchPaymentProfile,
    enabled: !!currentUser?.uid,
  });

  const {
    data: clientInvoices,
    error: clientInvoicesError,
    isLoading: clientInvoicesLoading,
  } = useQuery({
    queryKey: ["clientInvoices", currentUser?.uid],
    queryFn: () => {
      if (!userInfo) {
        throw new Error("No authenticated user");
      }
      return loadMyStakeholderInvoices(userInfo);
    },
    enabled: !!currentUser?.uid,
  });

  const createMyStakeholderPaymentProfile = async (
    data: Partial<IncreaseExternalAccountDetails> & { nickname: string }
  ) => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }

    // const { url, method } = functionUrls.bank.createStakeholderPaymentProfile;

    // const response = await fetch(url, {
    //   method,
    //   headers: getHeaders(),
    //   body: JSON.stringify({
    //     userId: currentUser.uid,
    //     encryptedPayload: await encryptPayload(data),
    //   }),
    // });

    const response =
      await bankFunctions.stakeholder.createStakeholderPaymentProfile(
        currentUser.uid,
        data
      );

    if (!response.ok) {
      throw new Error("Failed to create stakeholder payment profile");
    }

    const responseData = await response.json();

    if (responseData.success) {
      // Fetch the updated profile
      await queryClient.invalidateQueries({
        queryKey: ["stakeholderPaymentProfile", currentUser.uid],
      });
      await queryClient.refetchQueries({
        queryKey: ["stakeholderPaymentProfile", currentUser.uid],
      });
    }

    return responseData;
  };

  const updateMyStakeholderPaymentProfile = async (
    data: Partial<IncreaseExternalAccountDetails> & { nickname?: string }
  ) => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }

    const response =
      await bankFunctions.stakeholder.updateStakeholderPaymentProfile(
        currentUser.uid,
        data
      );

    if (!response.ok) {
      return response.json();
    }

    // Invalidate the payment profile query after successful update
    queryClient.invalidateQueries({
      queryKey: ["stakeholderPaymentProfile", currentUser.uid],
    });

    const responseData = await response.json();
    return responseData;
  };

  const payMyStakeholderInvoice = async (
    clientId: string,
    invoiceId: string
  ) => {
    if (!currentUser?.uid) {
      throw new Error("No authenticated user");
    }

    const response = await bankFunctions.stakeholder.payMyStakeholderInvoice(
      clientId,
      invoiceId
    );

    if (!response.ok) {
      throw new Error("Failed to pay my stakeholder invoice");
    }

    await queryClient.invalidateQueries({
      queryKey: ["clientInvoices", currentUser.uid],
    });

    const responseData = await response.json();
    return responseData;
  };

  return {
    paymentProfile,
    paymentProfileError,
    createMyStakeholderPaymentProfile,
    updateMyStakeholderPaymentProfile,
    clientInvoices,
    clientInvoicesError,
    clientInvoicesLoading,
    payMyStakeholderInvoice,
    stakeholderPayments,
    stakeholderPaymentsError,
    stakeholderPaymentsLoading,
  };
};
