import React from 'react';
import { Editor, Range, Extension } from '@tiptap/core';
import tippy, { Instance as TippyInstance } from 'tippy.js';

interface CommandProps {
  editor: Editor;
  range: Range;
}

interface SuggestionItem {
  title: string;
  command: (props: CommandProps) => void;
}

interface SuggestionGroup {
  title: string;
  items: SuggestionItem[];
}

interface CommandsListProps {
  items: SuggestionGroup[];
  command: (item: SuggestionItem) => void;
}

class ReactRenderer {
  element: HTMLElement;
  ref: any;
  editor: Editor;
  props: any;

  constructor(component: React.FC<CommandsListProps>, props: any) {
    this.element = document.createElement('div');
    this.ref = null;
    this.editor = props.editor;
    this.props = props;
  }

  updateProps(props: any) {
    this.props = props;
  }

  destroy() {
    // Implementation would go here
  }
}

export const SlashCommands = Extension.create({
  name: 'slash-commands',
  addOptions() {
    return {
      suggestion: {
        char: '/',
        command: ({
          editor,
          range,
          props,
        }: {
          editor: Editor;
          range: Range;
          props: any;
        }) => {
          props.command({ editor, range });
        },
      },
    };
  },
});

export const getSuggestions = (): SuggestionGroup[] => [
  {
    title: 'Basic Blocks',
    items: [
      {
        title: 'Text',
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).setParagraph().run();
        },
      },
      {
        title: 'Heading 1',
        command: ({ editor, range }: CommandProps) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode('heading', { level: 1 })
            .run();
        },
      },
      {
        title: 'Heading 2',
        command: ({ editor, range }: CommandProps) => {
          editor
            .chain()
            .focus()
            .deleteRange(range)
            .setNode('heading', { level: 2 })
            .run();
        },
      },
    ],
  },
  {
    title: 'Lists',
    items: [
      {
        title: 'Bullet List',
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).toggleBulletList().run();
        },
      },
      {
        title: 'Numbered List',
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).toggleOrderedList().run();
        },
      },
      {
        title: 'Task List',
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).toggleTaskList().run();
        },
      },
    ],
  },
  {
    title: 'Other',
    items: [
      {
        title: 'Code Block',
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).toggleCodeBlock().run();
        },
      },
      {
        title: 'Blockquote',
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).toggleBlockquote().run();
        },
      },
      {
        title: 'Horizontal Rule',
        command: ({ editor, range }: CommandProps) => {
          editor.chain().focus().deleteRange(range).setHorizontalRule().run();
        },
      },
    ],
  },
];

export const renderItems = () => {
  let component: ReactRenderer;
  let popup: TippyInstance[];

  return {
    onStart: (props: any) => {
      component = new ReactRenderer(CommandsList, {
        props,
        editor: props.editor,
      });

      popup = tippy('body', {
        getReferenceClientRect: props.clientRect,
        appendTo: () => document.body,
        content: component.element,
        showOnCreate: true,
        interactive: true,
        trigger: 'manual',
        placement: 'bottom-start',
      });
    },
    onUpdate: (props: any) => {
      component.updateProps(props);
      popup[0].setProps({
        getReferenceClientRect: props.clientRect,
      });
    },
    onKeyDown: (props: { event: KeyboardEvent }) => {
      if (props.event.key === 'Escape') {
        popup[0].hide();
        return true;
      }
      return component.ref?.onKeyDown(props);
    },
    onExit: () => {
      popup[0].destroy();
      component.destroy();
    },
  };
};

const CommandsList: React.FC<CommandsListProps> = ({ items, command }) => {
  return (
    <div className="slash-commands-menu">
      {items.map((group, index) => (
        <div key={index} className="slash-commands-group">
          <div className="slash-commands-group-title">{group.title}</div>
          {group.items.map((item, itemIndex) => (
            <button
              key={itemIndex}
              onClick={() => command(item)}
              className="slash-commands-item"
            >
              {item.title}
            </button>
          ))}
        </div>
      ))}
    </div>
  );
};

export default CommandsList; 