import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Alert,
  Stack,
  Typography,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";
import { IncreaseExternalAccountDetails } from "@freetech/models/bank/bank";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface UpdatePaymentProfileDialogProps {
  open: boolean;
  onClose: () => void;
  currentProfile: Partial<IncreaseExternalAccountDetails>;
}

interface ValidationError {
  message: string;
  field?: string;
}

export const UpdatePaymentProfileDialog: React.FC<UpdatePaymentProfileDialogProps> = ({
  open,
  onClose,
  currentProfile,
}) => {
  const { updateMyStakeholderPaymentProfile } = useStakeholderPayments();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<ValidationError | null>(null);
  const [showAccountNumber, setShowAccountNumber] = useState(false);
  const [formData, setFormData] = useState({
    accountNumber: currentProfile.accountNumber || '',
    routingNumber: currentProfile.routingNumber || '',
    accountHolderFullName: currentProfile.accountHolderFullName || '',
    accountHolderType: currentProfile.accountHolderType || 'individual',
    funding: currentProfile.funding || 'checking',
  });

  const validateForm = (): ValidationError | null => {
    if (!formData.accountHolderFullName) {
      return { message: "Account holder name is required", field: "accountHolderFullName" };
    }
    if (!formData.accountNumber) {
      return { message: "Account number is required", field: "accountNumber" };
    }
    if (!formData.routingNumber) {
      return { message: "Routing number is required", field: "routingNumber" };
    }
    if (formData.routingNumber.length !== 9) {
      return { message: "Routing number must be 9 digits", field: "routingNumber" };
    }
    return null;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    // Client-side validation
    const validationError = validateForm();
    if (validationError) {
      setError(validationError);
      setIsSubmitting(false);
      return;
    }

    try {
      await updateMyStakeholderPaymentProfile({
        accountNumber: formData.accountNumber,
        routingNumber: formData.routingNumber,
        accountHolderFullName: formData.accountHolderFullName,
        accountHolderType: formData.accountHolderType as "individual" | "business",
        funding: formData.funding as "checking" | "savings",
      });
      onClose();
    } catch (err) {
      // Handle specific 400 errors
      if (err instanceof Error) {
        const errorMessage = err.message.toLowerCase();
        if (errorMessage.includes("invalid routing number")) {
          setError({ message: "Invalid routing number. Please check and try again.", field: "routingNumber" });
        } else if (errorMessage.includes("missing required payment information")) {
          setError({ message: "Please fill in all required payment information." });
        } else if (errorMessage.includes("no existing payment profile")) {
          setError({ message: "No existing payment profile found. Please contact support." });
        } else {
          setError({ message: err.message });
        }
      } else {
        setError({ message: 'Failed to update payment profile' });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const getFieldError = (fieldName: string): string | undefined => {
    if (error?.field === fieldName) {
      return error.message;
    }
    return undefined;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Update Payment Profile</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Stack spacing={3}>
            {error && !error.field && (
              <Alert severity="error">{error.message}</Alert>
            )}
            
            <TextField
              required
              label="Account Holder Full Name"
              value={formData.accountHolderFullName}
              onChange={(e) => setFormData({ ...formData, accountHolderFullName: e.target.value })}
              fullWidth
              helperText={getFieldError("accountHolderFullName") || "Enter your full legal name as it appears on your bank account"}
              error={error?.field === "accountHolderFullName"}
            />

            <FormControl fullWidth required>
              <InputLabel>Account Type</InputLabel>
              <Select
                value={formData.accountHolderType}
                label="Account Type"
                onChange={(e) => setFormData({ ...formData, accountHolderType: e.target.value as "individual" | "business" })}
              >
                <MenuItem value="individual">Individual Account</MenuItem>
                <MenuItem value="business">Business Account</MenuItem>
              </Select>
            </FormControl>

            <TextField
              required
              label="Account Number"
              value={formData.accountNumber}
              onChange={(e) => setFormData({ ...formData, accountNumber: e.target.value })}
              fullWidth
              type={showAccountNumber ? "text" : "password"}
              helperText={getFieldError("accountNumber") || "Enter your bank account number"}
              error={error?.field === "accountNumber"}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle account number visibility"
                      onClick={() => setShowAccountNumber(!showAccountNumber)}
                      edge="end"
                    >
                      {showAccountNumber ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              required
              label="Routing Number"
              value={formData.routingNumber}
              onChange={(e) => setFormData({ ...formData, routingNumber: e.target.value })}
              fullWidth
              helperText={getFieldError("routingNumber") || "Enter your 9-digit bank routing number"}
              error={error?.field === "routingNumber"}
            />

            <FormControl fullWidth required>
              <InputLabel>Account Funding Type</InputLabel>
              <Select
                value={formData.funding}
                label="Account Funding Type"
                onChange={(e) => setFormData({ ...formData, funding: e.target.value as "checking" | "savings" })}
              >
                <MenuItem value="checking">Checking Account</MenuItem>
                <MenuItem value="savings">Savings Account</MenuItem>
              </Select>
            </FormControl>

            <Alert severity="info">
              Please ensure all information is accurate. Incorrect bank details may result in failed payments.
            </Alert>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            type="submit"
            variant="contained"
            disabled={isSubmitting}
            startIcon={isSubmitting ? <CircularProgress size={20} /> : null}
          >
            Update Profile
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
