import { getHeaders } from "core/getHeaders";
import { CreateUserType } from "types/user";
import {
  EversignTemplate,
  EversignDocumentBody,
  EversignDocumentRequestBody,
} from "@freetech/models";
import { GenerateProjectAssignmentTemplateRequestBody } from "@freetech/models/eversign/params";
import { functionsBaseUrl } from "config/functionsBaseUrl";
import { sharedFunctions } from "./sharedFunctions";

interface ProcessSignedTaxFormRequestBody {
  userId: string;
  documentHash: string;
  formType: "w9" | "w8ben";
}

export const portalFunctions = {
  // shared: {

  // }
  admin: {
    getAllUsers: async () => {
      const url = `${functionsBaseUrl}/portal/admin/getAuthUsers`;
      const method = "GET";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
      });
      return response;
    },
    createFirebaseUser: async (userToCreate: CreateUserType) => {
      const url = `${functionsBaseUrl}/portal/admin/createFirebaseUser`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify(userToCreate),
      });
      return response;
    },
    deleteFirebaseUser: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/deleteFirebaseUser`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      });
      return response;
    },
    disableFirebaseUser: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/disableFirebaseUser`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      });
      return response;
    },
    enableFirebaseUser: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/enableFirebaseUser`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      });
      return response;
    },
    resendVerificationEmail: async (userId: string) => {
      const url = `${functionsBaseUrl}/portal/admin/resendVerificationEmail`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ userId }),
      });
      return response;
    },
  },
  eversign: {
    generateProjectAssignmentTemplate: async (
      input: GenerateProjectAssignmentTemplateRequestBody
    ) => {
      return fetch(
        `${functionsBaseUrl}/portal/eversign/generateProjectAssignmentTemplate`,
        {
          method: "POST",
          headers: await getHeaders(),
          body: JSON.stringify(input),
        }
      );
    },
    processSignedTaxForm: async (input: ProcessSignedTaxFormRequestBody) => {
      return fetch(`${functionsBaseUrl}/portal/eversign/processSignedTaxForm`, {
        method: "POST",
        headers: await getHeaders(),
        body: JSON.stringify(input),
      });
    },
    fetchEversignTemplates: async (): Promise<EversignTemplate[]> => {
      const url = `${functionsBaseUrl}/portal/eversign/fetchEversignTemplates`;
      const method = "GET";
      const headers = await getHeaders();
      const response = await fetch(url, {
        method: method,
        headers,
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error("Failed to fetch Eversign templates");
      }

      return data;
    },
    viewTemplate: async (templateHash: string) => {
      const url = `${functionsBaseUrl}/portal/eversign/viewTemplate`;
      const method = "GET";
      const headers = await getHeaders();
      const response = await fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ template_hash: templateHash }),
      });
      return response.json();
    },
    useEversignTemplate: async ({
      documentData,
      sandbox,
    }: {
      documentData: EversignDocumentRequestBody;
      sandbox: number;
    }) => {
      const url = `${functionsBaseUrl}/portal/eversign/useEversignTemplate`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({
          document_data: documentData,
          sandbox: sandbox,
        }),
      });
      return response;
    },
    cancelDocument: async (documentHash: string) => {
      const url = `${functionsBaseUrl}/portal/eversign/cancelDocument`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ document_hash: documentHash }),
      });
      return response;
    },
    generateFreelancerW9Form: async () => {
      const url = `${functionsBaseUrl}/portal/eversign/generateFreelancerW9Form`;
      const method = "GET";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
      });
      return response;
    },
    generateFreelancerW8BenForm: async () => {
      const url = `${functionsBaseUrl}/portal/eversign/generateFreelancerW8BenForm`;
      const method = "GET";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
      });
      return response;
    },
    processSignedProjectAssignment: async (
      clientId: string,
      projectAssignmentId: string
    ) => {
      const url = `${functionsBaseUrl}/portal/eversign/processSignedProjectAssignment`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({
          clientId,
          projectAssignmentId,
        }),
      });
      return response;
    },
  },
  public: {
    generatePasswordReset: async (email: string): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/public/generatePasswordReset`;
      const method = "POST";
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log(response);

      if (response.status === 429) {
        throw new Error("Too many requests");
      }

      if (!response.ok) {
        throw new Error("Failed to send password reset email");
      }
    },
    resendVerificationEmail: async (email: string): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/public/resendVerificationEmail`;
      const method = "POST";
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    generateSignInLink: async (email: string): Promise<void> => {
      const url = `${functionsBaseUrl}/portal/public/generateSignInLink`;
      const method = "POST";
      const response = await fetch(url, {
        method: method,
        body: JSON.stringify({ email }),
        headers: { "Content-Type": "application/json" },
      });
    },
  },
  shared: {
    setInitialPassword: async (password: string) => {
      const url = `${functionsBaseUrl}/portal/shared/setInitialPassword`;
      const method = "POST";
      const headers = await getHeaders();
      const response = fetch(url, {
        method: method,
        headers,
        body: JSON.stringify({ password }),
      });
      return response;
    },
  },
};
