import { getDocuments } from "core/firestore";
import { FreelancerStatistics } from "../../../types/admin";
import { ProjectAssignment } from "types/projects";
export const getFreelancerStatisticsForClient = async (
  clientId: string, 
  freelancerId: string
): Promise<FreelancerStatistics> => {
  try {
    // Get all project assignments for the freelancer with this client
    const projectAssignments = await getDocuments<ProjectAssignment>(
      `clients/${clientId}/projectAssignments`,
      ['engineerId', '==', freelancerId]
    );

    // Calculate statistics
    const totalAssignments = projectAssignments.length;
    const activeAssignments = projectAssignments.filter(assignment => 
      assignment.status === 'in_progress'
    ).length;
    const completedAssignments = projectAssignments.filter(assignment => 
      assignment.status === 'complete'
    ).length;

    const totalUtilizedHours = projectAssignments.reduce((total, assignment) => 
      total + (assignment.utilizedHours || 0), 0);

    const totalEarnings = projectAssignments.reduce((total, assignment) => 
      total + (assignment.amountPaid || 0), 0);

    const averageHourlyRate = totalUtilizedHours > 0 
      ? totalEarnings / totalUtilizedHours 
      : 0;

    // Calculate average performance rating
    const performanceReviews = projectAssignments.flatMap(assignment => 
      assignment.performanceReviews || []
    );
    const totalRating = performanceReviews.reduce((total, review) => 
      total + review.rating, 0);
    const averagePerformanceRating = performanceReviews.length > 0 
      ? totalRating / performanceReviews.length 
      : 0;

    return {
      totalAssignments: {
        label: "Total Assignments",
        value: totalAssignments
      },
      activeAssignments: {
        label: "Active Assignments",
        value: activeAssignments
      },
      completedAssignments: {
        label: "Completed Assignments",
        value: completedAssignments
      },
      totalUtilizedHours: {
        label: "Total Utilized Hours",
        value: totalUtilizedHours
      },
      totalEarnings: {
        label: "Total Earnings",
        value: totalEarnings
      },
      averageHourlyRate: {
        label: "Average Hourly Rate",
        value: averageHourlyRate
      },
      averagePerformanceRating: {
        label: "Average Performance Rating",
        value: averagePerformanceRating
      }
    };
  } catch (error) {
    console.error("Error fetching freelancer statistics for client:", error);
    throw error;
  }
}; 