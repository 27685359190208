import {
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { ArchiveListParams } from "types/freelo/crud";

export const archiveList = async (params: ArchiveListParams): Promise<void> => {
  const { boardId, listId } = params;

  // Get all cards in the list
  const cardsRef = collection(db, "boards", boardId, "cards");
  const q = query(cardsRef, where("listId", "==", listId));
  const cardsSnapshot = await getDocs(q);

  // Update all cards to archived status
  const updatePromises = cardsSnapshot.docs.map((cardDoc) => {
    return updateDoc(doc(db, "boards", boardId, "cards", cardDoc.id), {
      archived: true,
      updatedAt: DateTime.now().toISO(),
    });
  });

  await Promise.all(updatePromises);
};
