import { doc, updateDoc, addDoc, collection, getDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { QueryClient } from "@tanstack/react-query";
import { Board } from "types/freelo";
interface AddColumnParams {
  boardId: string;
  name: string;
}

export const createList = async (
  params: AddColumnParams,
  queryClient: QueryClient
): Promise<void> => {
  const { boardId, name } = params;

  // Cancel any outgoing refetches
  await queryClient.cancelQueries({
    queryKey: ["board", boardId],
    exact: true
  });

  // Get current board state from cache
  const previousBoard = queryClient.getQueryData<Board>(["board", boardId]);
  if (!previousBoard) return;

  // Create a temporary ID for optimistic update
  const tempListId = `temp_${Date.now()}`;
  const newListOrder = [...(previousBoard.listOrder || []), tempListId];

  // Optimistically update the cache first
  const optimisticBoard = {
    ...previousBoard,
    listOrder: newListOrder,
    updatedAt: DateTime.now().toISO(),
  };
  queryClient.setQueryData(["board", boardId], optimisticBoard);

  try {
    // Get the current board state from Firestore
    const boardRef = doc(db, "boards", boardId);
    const boardDoc = await getDoc(boardRef);
    
    if (!boardDoc.exists()) {
      throw new Error("Board not found");
    }

    const currentBoard = boardDoc.data();

    // Create new list in the lists collection
    const listsRef = collection(db, "boards", boardId, "lists");
    const newListRef = await addDoc(listsRef, {
      name: name.trim(),
      cardOrder: [],
      createdAt: DateTime.now().toISO(),
      updatedAt: DateTime.now().toISO(),
    });

    // Update the board's listOrder with the real list ID
    const currentListOrder = currentBoard.listOrder || [];
    const finalListOrder = [...currentListOrder, newListRef.id];

    // Update the board document
    const boardUpdate = {
      listOrder: finalListOrder,
      updatedAt: DateTime.now().toISO(),
    };
// Debug log

    await updateDoc(boardRef, boardUpdate);

    // Update the cache with the real ID
    const finalBoard = {
      ...currentBoard,
      listOrder: finalListOrder,
      updatedAt: DateTime.now().toISO(),
    };
    queryClient.setQueryData(["board", boardId], finalBoard);

  } catch (error) {
    console.error('Error in addColumn:', error); // Debug log
    // If there was an error, rollback to the previous state
    queryClient.setQueryData(["board", boardId], previousBoard);
    throw error;
  }
}; 