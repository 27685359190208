enum NotificationType {
    MENTION = "mention",
    ASSIGNMENT = "assignment",
    INVOICE_STATUS = "invoice_status",
    PROJECT_ASSIGNMENT = "project_assignment",
    COMMENT = "comment",
    FREELANCER_PAYMENT = "freelancer_payment",
    INVOICE_SENT = "invoice_sent",
  
    // Add more notification types as needed
  }
  

  export { NotificationType };