import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import AddIcon from "@mui/icons-material/Add";
import EngineerCard from "./EngineerCard/EngineerCard";
import { useAdminClient } from "hooks/admin/useAdminClient";
import { AddEngineerDialog } from "./AddEngineerDialog/AddEngineerDialog";
import { ClientFreelancer } from "types/projects";
import { Client } from "types/projects";

interface AssociatedEngineersProps {
  client: Client;
}

const AssociatedEngineers: React.FC<AssociatedEngineersProps> = ({ client }) => {
  const [isAddDialogOpen, setIsAddDialogOpen] = React.useState(false);
  const { updateClient } = useAdminClient(client.id);


  

  const handleAddEngineer = async (engineerData: ClientFreelancer) => {
    // Get current associatedFreelancerIds or initialize empty array
    const currentIds = client.associatedFreelancerIds || [];
    
    // Only add if not already associated
    if (!currentIds.includes(engineerData.id)) {
      await updateClient.mutateAsync({
        associatedFreelancerIds: [...currentIds, engineerData.id]
      });
    }
    
    setIsAddDialogOpen(false);
  };

  const handleRemoveEngineer = async (engineerId: string) => {
    if (!client.associatedFreelancerIds) return;

    const updatedIds = client.associatedFreelancerIds.filter(id => id !== engineerId);
    await updateClient.mutateAsync({
      associatedFreelancerIds: updatedIds
    });
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Associated Engineers</Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setIsAddDialogOpen(true)}
        >
          Add Engineer
        </Button>
      </Box>

      <Grid container spacing={3}>
        {client.freelancers?.map((freelancer) => (
          <EngineerCard
            key={freelancer.id}
            engineer={freelancer}
            client={client}
            onRemove={() => handleRemoveEngineer(freelancer.id)}
          />
        ))}
        {(!client.freelancers || client.freelancers.length === 0) && (
          <Grid item xs={12}>
            <Typography color="textSecondary" align="center">
              No engineers associated with this client
            </Typography>
          </Grid>
        )}
      </Grid>

      <AddEngineerDialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        onAdd={handleAddEngineer}
        client={client}
        currentEngineers={client.freelancers}
      />
    </Box>
  );
};

export default AssociatedEngineers;
