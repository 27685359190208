import { collection, doc, setDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { ClientInvoiceData } from "types/bank";
import { invoiceConverter } from "@freetech/models/converters/invoiceConverter";

export const createInvoice = async (clientId: string, invoiceData: ClientInvoiceData) => {

  const totalDollarAmountDue = invoiceData.items.reduce((sum, item) => sum + item.quantity * item.rate, 0);

  const invoiceRef = doc(collection(db, `clients/${clientId}/invoices`)).withConverter(invoiceConverter);
  await setDoc(invoiceRef, {
    ...invoiceData,
    id: invoiceRef.id,
    totalDollarAmountDue,
    status: "unpaid",
  });
  return invoiceRef.id;
};
