import { Timesheet } from "types/timesheet";

export const submitWeekForApproval = async (
  weekEntries: Timesheet[],
  updateFunction: (newRow: Timesheet, oldRow: Timesheet) => Promise<Timesheet>
) => {
  const results = [];
  for (const entry of weekEntries) {
    if (!entry.submitted && !entry.approved) {
      try {
        const result = await updateFunction(
          { ...entry, submitted: true },
          entry
        );
        results.push(result);
      } catch (error) {
        console.error(`Failed to submit entry ${entry.id}:`, error);
        throw error;
      }
    }
  }
  return results;
};

export const withdrawWeekFromApproval = async (
  weekEntries: Timesheet[],
  updateFunction: (newRow: Timesheet, oldRow: Timesheet) => Promise<Timesheet>
) => {
  const results = [];
  for (const entry of weekEntries) {
    if (entry.submitted && !entry.approved) {
      try {
        const result = await updateFunction(
          { ...entry, submitted: false },
          entry
        );
        results.push(result);
      } catch (error) {
        console.error(`Failed to withdraw entry ${entry.id}:`, error);
        throw error;
      }
    }
  }
  return results;
}; 