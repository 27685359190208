import React from "react";
import { Box, Typography, Paper, Grid, Avatar } from "@mui/material";
import { Email as EmailIcon } from "@mui/icons-material";
import { User } from "firebase/auth";
import { useAuth } from "hooks/auth/useAuth";

export const SettingsProfileCard: React.FC = () => {
  const { userInfo, currentUser } = useAuth();
  const fullName = userInfo
    ? `${userInfo.firstName} ${userInfo.lastName}`
    : "User";

  return (
    <Paper sx={{ p: 3 }}>
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        alignItems={{ xs: "center", sm: "flex-start" }}
        gap={3}
      >
        <Avatar
          src={userInfo?.profilePicture}
          sx={{
            width: 120,
            height: 120,
            boxShadow: 1,
            border: "4px solid #fff",
          }}
        >
          {userInfo?.firstName?.[0]?.toUpperCase()}
        </Avatar>
        <Box flex={1}>
          <Box mb={3}>
            <Typography variant="h5" gutterBottom>
              {fullName}
            </Typography>
            <Typography
              variant="body1"
              color="textSecondary"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                mb: 1,
              }}
            >
              <EmailIcon fontSize="small" />
              {currentUser?.email}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                display: "inline-block",
                bgcolor: "primary.main",
                color: "primary.contrastText",
                px: 1.5,
                py: 0.5,
                borderRadius: 1,
                textTransform: "capitalize",
              }}
            >
              {userInfo?.role || "N/A"}
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Phone
              </Typography>
              <Typography variant="body1">
                {userInfo?.phone || "Not provided"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle2"
                color="textSecondary"
                gutterBottom
              >
                Member Since
              </Typography>
              <Typography variant="body1">
                {currentUser?.metadata.creationTime
                  ? new Date(
                      currentUser.metadata.creationTime
                    ).toLocaleDateString(undefined, {
                      year: "numeric",
                      month: "long",
                      day: "numeric",
                    })
                  : "N/A"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
};

export default SettingsProfileCard;
