import {
  collection,
  query,
  orderBy,
  where,
  getDocs,
  QuerySnapshot,
  DocumentData,
  updateDoc,
} from "firebase/firestore";
import { db, storage } from "config/firebase";
import { IndependentEngineerAgreement } from "types/user";
import { ref, getDownloadURL, listAll } from "firebase/storage";

// Convert gs:// URL to a storage reference path
const getStoragePathFromGsUrl = (gsUrl: string): string => {
  // Remove the gs://bucket-name/ prefix and any query parameters
  const path = gsUrl.replace(/^gs:\/\/[^/]+\//, '');
  return path.split('&')[0]; // Remove any query parameters
};

export const getIndependentEngineerAgreement = async (
  freelancerId: string
): Promise<IndependentEngineerAgreement | null> => {
  const agreementsRef = collection(
    db,
    "users",
    freelancerId,
    "independentEngineerAgreements"
  );

  // Query to get all agreements ordered by date
  const q = query(agreementsRef, orderBy("sentDate", "desc"));

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return null;
  }

  // Log all agreements for debugging
  console.log(
    "All agreements:",
    querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
  );

  // Find first agreement that matches status criteria
  const matchingDoc = querySnapshot.docs.find((doc) => {
    const status = doc.data().everSignDocumentStatus;
    return [
      "signed",
      "awaiting_engineer_signature",
      "awaiting_admin_signature",
    ].includes(status);
  });

  

  if (!matchingDoc) {
    return null;
  }

  const agreement = matchingDoc.data() as IndependentEngineerAgreement;
  
  // If we have a gs:// URL, convert it to an HTTP URL
  if (agreement.firebaseStorageUrl?.startsWith('gs://')) {
    try {
      const storagePath = getStoragePathFromGsUrl(agreement.firebaseStorageUrl);
      
      const documentUrl = await getDownloadURL(ref(storage, storagePath));
      agreement.firebaseStorageUrl = documentUrl;
      
      // Update the document with the HTTP URL
      await updateDoc(matchingDoc.ref, {
        firebaseStorageUrl: documentUrl
      });
    } catch (error) {
      console.error("Error converting gs:// URL:", error);
      // If conversion fails, try getting the URL directly from the document ID
      try {
        const directPath = `users/${freelancerId}/independentEngineerAgreements/${matchingDoc.id}.pdf`;
        
        const documentUrl = await getDownloadURL(ref(storage, directPath));
        agreement.firebaseStorageUrl = documentUrl;
        await updateDoc(matchingDoc.ref, {
          firebaseStorageUrl: documentUrl
        });
      } catch (directError) {
        console.error("Error with direct path:", directError);
      }
    }
  }

  
  return {
    ...agreement,
    id: matchingDoc.id,
  };
};
