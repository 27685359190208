import { useQuery } from "@tanstack/react-query";
import { getFreelancerStatisticsForClient } from "core/admin/statistics";
import { FreelancerStatistics } from "types/admin";

export const useFreelancerStatisticsForClient = (clientId: string, freelancerId: string) => {
  return useQuery<FreelancerStatistics, Error>({
    queryKey: ['freelancerStatistics', clientId, freelancerId],
    queryFn: () => getFreelancerStatisticsForClient(clientId, freelancerId),
    staleTime: 5 * 60 * 1000, // 5 minutes
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    networkMode: 'offlineFirst',
    retry: false,
    retryOnMount: false,
    refetchInterval: 5 * 60 * 1000, // 5 minutes
    refetchIntervalInBackground: false,
  });
};
