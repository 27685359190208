import React from 'react';
import {
  Popover,
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { PublicUserInfoType } from "types/user";

interface FreeloBoardFiltersProps {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  users?: PublicUserInfoType[];
  currentUser?: PublicUserInfoType;
  labels: { id: string; name: string; color: string }[];
  onFiltersChange: (filters: BoardFilters) => void;
  filters: BoardFilters;
}

export interface BoardFilters {
  members: string[];
  labels: string[];
}

export const FreeloBoardFilters: React.FC<FreeloBoardFiltersProps> = ({
  open,
  anchorEl,
  onClose,
  users,
  currentUser,
  labels,
  onFiltersChange,
  filters,
}) => {
  const handleFilterChange = <K extends keyof BoardFilters>(
    key: K,
    value: BoardFilters[K]
  ) => {
    onFiltersChange({
      ...filters,
      [key]: value,
    });
  };

  return (
    <Popover 
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: {
          width: '384px',
          maxWidth: '90vw',
          bgcolor: '#282E33',
          color: 'white',
          mt: 1,
        }
      }}
    >
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        p: 2,
        borderBottom: 1,
        borderColor: 'rgba(255,255,255,0.12)',
      }}>
        <Typography variant="subtitle1" sx={{ color: 'white', fontWeight: 500 }}>
          Filter
        </Typography>
        <IconButton onClick={onClose} size="small" sx={{ color: 'white' }}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ p: 2 }}>
        {/* Members */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="body2" sx={{ mb: 1, color: 'rgba(255,255,255,0.9)' }}>
            Members
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={filters.members.length === 0}
                  onChange={() => handleFilterChange('members', [])}
                  sx={{ color: 'rgba(255,255,255,0.7)' }}
                />
              }
              label="No members"
              sx={{ color: 'rgba(255,255,255,0.9)' }}
            />
            {currentUser && (
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={filters.members.includes(currentUser.id)}
                    onChange={() => {
                      const newMembers = filters.members.includes(currentUser.id)
                        ? filters.members.filter(id => id !== currentUser.id)
                        : [...filters.members, currentUser.id];
                      handleFilterChange('members', newMembers);
                    }}
                    sx={{ color: 'rgba(255,255,255,0.7)' }}
                  />
                }
                label="Cards assigned to me"
                sx={{ color: 'rgba(255,255,255,0.9)' }}
              />
            )}
            {users?.map(user => (
              user.id !== currentUser?.id && (
                <FormControlLabel
                  key={user.id}
                  control={
                    <Checkbox 
                      checked={filters.members.includes(user.id)}
                      onChange={() => {
                        const newMembers = filters.members.includes(user.id)
                          ? filters.members.filter(id => id !== user.id)
                          : [...filters.members, user.id];
                        handleFilterChange('members', newMembers);
                      }}
                      sx={{ color: 'rgba(255,255,255,0.7)' }}
                    />
                  }
                  label={`${user.firstName} ${user.lastName}`}
                  sx={{ color: 'rgba(255,255,255,0.9)' }}
                />
              )
            ))}
          </FormGroup>
        </Box>

        {/* Labels */}
        <Box sx={{ mb: 3 }}>
          <Typography variant="body2" sx={{ mb: 1, color: 'rgba(255,255,255,0.9)' }}>
            Labels
          </Typography>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox 
                  checked={filters.labels.length === 0}
                  onChange={() => handleFilterChange('labels', [])}
                  sx={{ color: 'rgba(255,255,255,0.7)' }}
                />
              }
              label="No labels"
              sx={{ color: 'rgba(255,255,255,0.9)' }}
            />
            {labels.map(label => (
              <FormControlLabel
                key={label.id}
                control={
                  <Checkbox 
                    checked={filters.labels.includes(label.id)}
                    onChange={() => {
                      const newLabels = filters.labels.includes(label.id)
                        ? filters.labels.filter(id => id !== label.id)
                        : [...filters.labels, label.id];
                      handleFilterChange('labels', newLabels);
                    }}
                    sx={{ color: label.color }}
                  />
                }
                label={label.name}
                sx={{ 
                  color: 'rgba(255,255,255,0.9)',
                  bgcolor: `${label.color}22`,
                  borderRadius: 1,
                  px: 1,
                  my: 0.5,
                }}
              />
            ))}
          </FormGroup>
        </Box>
      </Box>
    </Popover>
  );
}; 