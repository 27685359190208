import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  collection,
  query,
  where,
  orderBy,
  limit,
  updateDoc,
  doc,
  getDocs,
  writeBatch,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { useEffect } from "react";
import { INotification } from "@freetech/models";

export const useNotifications = (userId: string) => {
  const queryClient = useQueryClient();

  const { data: notifications = [] } = useQuery({
    queryKey: ["notifications", userId],
    queryFn: async () => {
      const q = query(
        collection(db, "notifications"),
        where("recipientId", "==", userId),
        orderBy("createdAt", "desc"),
        limit(10)
      );

      const snapshot = await getDocs(q);
      return snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toLocaleString(),
      })) as INotification[];
    },
    staleTime: Infinity,
    enabled: !!userId,
  });

  useEffect(() => {
    if (!userId) return;

    const q = query(
      collection(db, "notifications"),
      where("recipientId", "==", userId),
      orderBy("createdAt", "desc"),
      limit(10)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added" || change.type === "modified") {
          queryClient.setQueryData(
            ["notifications", userId],
            (oldData: INotification[] = []) => {
              const newNotification = {
                id: change.doc.id,
                ...change.doc.data(),
                createdAt: change.doc.data().createdAt,
              } as INotification;

              const filteredOldData = oldData.filter(
                (n) => n.id !== newNotification.id
              );
              return [newNotification, ...filteredOldData].slice(0, 10);
            }
          );
        }

        if (change.type === "removed") {
          queryClient.setQueryData(
            ["notifications", userId],
            (oldData: INotification[] = []) =>
              oldData.filter((n) => n.id !== change.doc.id)
          );
        }
      });
    });

    return () => unsubscribe();
  }, [userId, queryClient]);

  const markAsRead = useMutation({
    mutationFn: async (notificationId: string) => {
      const notificationRef = doc(db, "notifications", notificationId);
      await updateDoc(notificationRef, { read: true });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
  });

  const markAllAsRead = useMutation({
    mutationFn: async () => {
      const batch = writeBatch(db);
      notifications
        .filter((n) => !n.read)
        .forEach((n) => {
          const ref = doc(db, "notifications", n.id!);
          batch.update(ref, { read: true });
        });
      await batch.commit();
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["notifications"] });
    },
  });

  return { notifications, markAsRead, markAllAsRead };
};
