import React, { useState } from "react";
import {
  Box,
  ToggleButton,
  ToggleButtonGroup,
  Paper,
  Typography,
} from "@mui/material";
import PaymentIcon from "@mui/icons-material/Payment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import { ClientInvoices } from "./ClientInvoices";
import { RecentPayments } from "./RecentPayments";

type HistoryView = "payments" | "invoices";

export const PaymentHistory: React.FC = () => {
  const [view, setView] = useState<HistoryView>("invoices");

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: HistoryView | null
  ) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  return (
    <Paper
      elevation={0}
      sx={{
        border: 1,
        borderColor: "divider",
        borderRadius: 2,
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          p: 2,
          borderBottom: 1,
          borderColor: "divider",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6">Payment History</Typography>
        <ToggleButtonGroup
          value={view}
          exclusive
          onChange={handleViewChange}
          size="small"
        >
          <ToggleButton value="invoices">
            <ReceiptIcon sx={{ mr: 1 }} />
            Invoices
          </ToggleButton>
          <ToggleButton value="payments">
            <PaymentIcon sx={{ mr: 1 }} />
            Payments
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {view === "invoices" ? <ClientInvoices /> : <RecentPayments />}
    </Paper>
  );
}; 