import { collection, query, getDocs, where } from "firebase/firestore";
import { db } from "config/firebase";
import {
  AdminStatistics,
  RevenueData,
  ExpenseData,
} from "../../../types/admin";
import { Client } from "types/projects";
import { ProjectAssignment } from "types/projects";
// import { Payment } from "types/projects";
import { freelancerConverter } from "@freetech/models/converters/freelancerConverter";
import { clientConverter } from "@freetech/models/converters/clientConverter";
import { projectAssignmentConverter } from "@freetech/models/converters/projectAssignmentConverter";
import { getDateTimeNowISO } from "@freetech/models/utils";

export const getAdminStatistics = async (): Promise<AdminStatistics> => {
  const clientsRef = collection(db, "clients").withConverter(clientConverter);
  const clientsSnapshot = await getDocs(query(clientsRef));

  const revenueData: RevenueData = { totalRevenue: {}, revenueByClient: {} };
  const expenseData: ExpenseData = { totalExpenses: {}, expensesByClient: {} };
  let totalProjectAssignments = 0;
  let totalBudgetedHours = 0;
  const activeFreelancers = new Set<string>();
  const projectSet = new Set<string>();

  for (const clientDoc of clientsSnapshot.docs) {
    const clientData = clientDoc.data();

    const paymentsRef = collection(clientDoc.ref, "payments")
    const paymentsSnapshot = await getDocs(query(paymentsRef));
    const assignmentsRef = collection(
      clientDoc.ref,
      "projectAssignments"
    ).withConverter(projectAssignmentConverter);
    const assignmentsSnapshot = await getDocs(query(assignmentsRef));

    revenueData.revenueByClient[clientDoc.id] = {
      companyName: clientData.companyName,
      revenue: {},
    };
    expenseData.expensesByClient[clientDoc.id] = {
      companyName: clientData.companyName,
      expenses: {},
    };

    // paymentsSnapshot.forEach((paymentDoc) => {
    //   const payment = paymentDoc.data() as Payment;
    //   const year = new Date(payment.processedDate || "")
    //     .getFullYear()
    //     .toString();
    //   const month = new Date(payment.processedDate || "").getMonth().toString();

    //   if (!revenueData.totalRevenue[year]) revenueData.totalRevenue[year] = {};
    //   revenueData.totalRevenue[year][month] =
    //     (revenueData.totalRevenue[year][month] || 0) +
    //     parseFloat(payment.amount.toString());

    //   if (!revenueData.revenueByClient[clientDoc.id].revenue[year]) {
    //     revenueData.revenueByClient[clientDoc.id].revenue[year] = {};
    //   }
    //   if (!revenueData.revenueByClient[clientDoc.id].revenue[year][month]) {
    //     revenueData.revenueByClient[clientDoc.id].revenue[year][month] = 0;
    //   }
    //   revenueData.revenueByClient[clientDoc.id].revenue[year][month] +=
    //     parseFloat(payment.amount.toString());
    // });

    assignmentsSnapshot.forEach((assignmentDoc) => {
      const assignment = assignmentDoc.data() as ProjectAssignment;
      totalProjectAssignments++;
      totalBudgetedHours += assignment.budgetedHours || 0;
      projectSet.add(assignment.projectId);

      const now = getDateTimeNowISO();
      if (assignment.startDate) {
        const startDate = assignment.startDate;
        const endDate = assignment.endDate || null;
        if (startDate <= now && (!endDate || endDate >= now)) {
          activeFreelancers.add(assignment.engineerId);
        }
      }

      if (assignment.amountPaid) {
        const year = assignment.paymentDate
          ? new Date(assignment.paymentDate).getFullYear().toString()
          : "";
        const month = assignment.paymentDate
          ? new Date(assignment.paymentDate).getMonth().toString()
          : "";

        if (year && month) {
          if (!expenseData.totalExpenses[year])
            expenseData.totalExpenses[year] = {};
          expenseData.totalExpenses[year][month] =
            (expenseData.totalExpenses[year][month] || 0) +
            assignment.amountPaid;

          if (!expenseData.expensesByClient[clientDoc.id].expenses[year]) {
            expenseData.expensesByClient[clientDoc.id].expenses[year] = {};
          }
          if (
            !expenseData.expensesByClient[clientDoc.id].expenses[year][month]
          ) {
            expenseData.expensesByClient[clientDoc.id].expenses[year][month] =
              0;
          }
          expenseData.expensesByClient[clientDoc.id].expenses[year][month] +=
            assignment.amountPaid;
        }
      }
    });
  }

  const freelancersRef = collection(db, "users").withConverter(
    freelancerConverter
  );
  const freelancersQuery = query(
    freelancersRef,
    where("role", "==", "freelancer")
  );
  const freelancersSnapshot = await getDocs(freelancersQuery);

  const totalFreelancers = freelancersSnapshot.size;

  const currentYear = new Date().getFullYear().toString();
  const totalRevenue = Object.values(
    revenueData.totalRevenue[currentYear] || {}
  ).reduce((sum: number, value: number) => sum + value, 0);
  const totalExpenses = Object.values(
    expenseData.totalExpenses[currentYear] || {}
  ).reduce((sum: number, value: number) => sum + value, 0);

  return {
    revenueData,
    expenseData,
    stats: {
      totalRevenue,
      totalExpenses,
      totalClients: clientsSnapshot.size,
      totalProjects: projectSet.size,
      averageProjectValue:
        projectSet.size > 0 ? totalRevenue / projectSet.size : 0,
      totalProjectAssignments,
      totalBudgetedHours,
      activeFreelancers: activeFreelancers.size,
      totalFreelancers,
    },
  };
};
