import { collection, doc, addDoc, deleteDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { DateTime } from "luxon";
import { MoveCardToTrashParams } from "types/freelo/crud";

export const moveCardToTrash = async (params: MoveCardToTrashParams): Promise<void> => {
  const { boardId, cardId, card, listId } = params;
  const cardTrashRef = collection(db, "boards", boardId, "cardTrash");
  
  await addDoc(cardTrashRef, {
    ...card,
    originalListId: listId,
    deletedAt: DateTime.now().toISO(),
    comments: card.comments || [],
  });

  const cardRef = doc(db, "boards", boardId, "cards", cardId);
  await deleteDoc(cardRef);
}; 