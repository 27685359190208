import { doc, updateDoc, Timestamp } from "firebase/firestore";
import { db } from "config/firebase";
import { UpdateProjectAssignmentParams } from "../../../types/admin";

export const updateProjectAssignment = async ({
  clientId,
  projectId,
  assignmentId,
  updates
}: UpdateProjectAssignmentParams) => {
  const assignmentRef = doc(
    db, 
    'clients', 
    clientId,
    'projectAssignments',
    assignmentId
  );

  await updateDoc(assignmentRef, {
    ...updates,
    lastUpdated: Timestamp.now()
  });

  return { assignmentId, updates };
}; 