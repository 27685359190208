import { doc, getDoc, getFirestore } from "firebase/firestore";
import { Freelancer } from "types/user";
import { freelancerConverter } from "@freetech/models/converters/freelancerConverter";
import { getEngineerAssignments } from "core/projectAssignments/getEngineerAssignments";

const db = getFirestore();

export const fetchMyFreelancerProfile = async (
  userId: string
): Promise<Freelancer | null> => {
  const docRef = doc(db, "users", userId).withConverter(freelancerConverter);
  const docSnap = await getDoc(docRef);

  const projectAssignments = await getEngineerAssignments(userId);
  if (docSnap.exists()) {
    const freelancer = docSnap.data();
    
    return {
      ...freelancer,
      projectAssignments,
    };
  }
  return null;
};
