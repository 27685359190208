import React from "react";
import { Box, IconButton, Divider, Link, CircularProgress } from "@mui/material";
import {
  FormatBold,
  FormatItalic,
  FormatListBulleted,
  FormatListNumbered,
  FormatQuote,
  Code,
  FormatClear,
  TableChart,
  HorizontalRule,
  Save as SaveIcon,
  Close as CloseIcon,
  Image as ImageIcon,
} from "@mui/icons-material";
import { Editor } from "@tiptap/react";

interface MenuBarProps {
  editor: Editor | null;
  onSave: () => void;
  onCancel: () => void;
  hasUnsavedChanges: boolean;
  isSaving: boolean;
}

export const DocumentMenuBar: React.FC<MenuBarProps> = ({ 
  editor,
  onSave,
  onCancel,
  hasUnsavedChanges,
  isSaving,
}) => {
  if (!editor) {
    return null;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: 0.5,
        p: 1,
        borderBottom: "1px solid",
        borderColor: "divider",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleBold().run()}
          color={editor.isActive("bold") ? "primary" : "default"}
        >
          <FormatBold fontSize="small" />
        </IconButton>

        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleItalic().run()}
          color={editor.isActive("italic") ? "primary" : "default"}
        >
          <FormatItalic fontSize="small" />
        </IconButton>

        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleCode().run()}
          color={editor.isActive("code") ? "primary" : "default"}
        >
          <Code fontSize="small" />
        </IconButton>

        <Divider orientation="vertical" flexItem />

        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          color={editor.isActive("bulletList") ? "primary" : "default"}
        >
          <FormatListBulleted fontSize="small" />
        </IconButton>

        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          color={editor.isActive("orderedList") ? "primary" : "default"}
        >
          <FormatListNumbered fontSize="small" />
        </IconButton>

        <IconButton
          size="small"
          onClick={() => editor.chain().focus().toggleBlockquote().run()}
          color={editor.isActive("blockquote") ? "primary" : "default"}
        >
          <FormatQuote fontSize="small" />
        </IconButton>

        <Divider orientation="vertical" flexItem />

        <IconButton
          size="small"
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
        >
          <HorizontalRule fontSize="small" />
        </IconButton>

        <IconButton
          size="small"
          onClick={() =>
            editor
              .chain()
              .focus()
              .insertTable({
                rows: 3,
                cols: 3,
                withHeaderRow: true,
              })
              .run()
          }
          color={editor.isActive("table") ? "primary" : "default"}
        >
          <TableChart fontSize="small" />
        </IconButton>

        <IconButton
          size="small"
          onClick={() => {
            const url = window.prompt("Enter URL");
            if (url) {
              editor.chain().focus().setLink({ href: url }).run();
            }
          }}
          color={editor.isActive("link") ? "primary" : "default"}
        >
          <Link fontSize="small" />
        </IconButton>

        <IconButton
          size="small"
          onClick={() => {
            const url = window.prompt("Enter image URL");
            if (url) {
              editor.commands.setImage({ src: url });
            }
          }}
        >
          <ImageIcon fontSize="small" />
        </IconButton>

        <IconButton
          size="small"
          onClick={() => editor.chain().focus().unsetAllMarks().clearNodes().run()}
        >
          <FormatClear fontSize="small" />
        </IconButton>
      </Box>

      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <IconButton
          color="secondary"
          onClick={onCancel}
          size="small"
          sx={{
            backgroundColor: "background.paper",
            boxShadow: 1,
            "&:hover": {
              backgroundColor: "background.paper",
            },
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
        
        <IconButton
          color="primary"
          onClick={onSave}
          disabled={!hasUnsavedChanges || isSaving}
          size="small"
          sx={{
            backgroundColor: "background.paper",
            boxShadow: 1,
            "&:hover": {
              backgroundColor: "background.paper",
            },
          }}
        >
          {isSaving ? (
            <CircularProgress size={20} />
          ) : (
            <SaveIcon fontSize="small" />
          )}
        </IconButton>
      </Box>
    </Box>
  );
};
