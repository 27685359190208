import React, { useState, useMemo } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Box,
  CircularProgress,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Freelancer } from "types/user";
import { useAdminPayroll } from "hooks/admin/useAdminPayroll";
import { useAdminClients } from "hooks/admin/useAdminClients";
import { Client, ConsultingServicesAgreement } from "types/projects";
import { Project, ProjectAssignment } from "types/projects";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateTime } from "luxon";
import { useAdminFreelancers } from "hooks/admin/useAdminFreelancers";

interface ExternalPaymentDialogProps {
  open: boolean;
  onClose: () => void;
  contractor: Freelancer;
}

type ExternalPaymentSource = "venmo" | "paypal" | "cashapp" | "zelle" | "ach" | "cash" | "check" | "wire";

const PAYMENT_METHODS: ExternalPaymentSource[] = [
  "check",
  "wire",
  "paypal",
  "venmo",
  "cash",
  "zelle",
  "cashapp",
  "ach",
];

export const ExternalPaymentDialog: React.FC<ExternalPaymentDialogProps> = ({
  open,
  onClose,
  contractor,
}) => {
  const { addExternalPayment } = useAdminPayroll();
  const { clients } = useAdminClients();

  const [amount, setAmount] = useState<string>("");
  const [description, setDescription] = useState("");
  const [paymentMethod, setPaymentMethod] = useState<ExternalPaymentSource | "">("");
  const [paymentDate, setPaymentDate] = useState<DateTime | null>(DateTime.now());
  const [selectedClientId, setSelectedClientId] = useState<string>("");
  const [selectedProjectId, setSelectedProjectId] = useState<string>("");
  const [selectedCsaId, setSelectedCsaId] = useState<string>("");

  const { data: freelancers } = useAdminFreelancers();

  const freelancer = freelancers?.find((f: Freelancer) => f.id === contractor.id);

  // Get available projects for selected client
  const availableProjects = useMemo(() => {
    if (!selectedClientId || !clients) return [];
    const client = clients.find((c: Client) => c.id === selectedClientId);
    return client?.projects || [];
  }, [selectedClientId, clients]);

  // Get available CSAs for selected client and project
  const availableCsas = useMemo(() => {
    if (!selectedClientId || !selectedProjectId || !clients) return [];
    const client = clients.find((c: Client) => c.id === selectedClientId);
    return client?.consultingServicesAgreements?.filter(csa => csa.projectId === selectedProjectId) || [];
  }, [selectedClientId, selectedProjectId, clients]);

  // Get available project assignments for selected project
  const availableProjectAssignments = useMemo(() => {
    if (!selectedProjectId || !freelancer?.projectAssignments) return [];
    return freelancer.projectAssignments.filter(pa => pa.projectId === selectedProjectId);
  }, [selectedProjectId, freelancer?.projectAssignments]);

  const [selectedProjectAssignmentId, setSelectedProjectAssignmentId] = useState<string>("");

  const handleSubmit = async () => {
    if (!paymentMethod) return;
    
    try {
      await addExternalPayment.mutateAsync({
        userId: contractor.id,
        amount: parseFloat(amount),
        memo: description,
        paymentMethod,
        paymentDate: paymentDate?.toISO() || new Date().toISOString(),
        clientId: selectedClientId || "",
        projectId: selectedProjectId || "",
        projectAssignmentId: selectedProjectAssignmentId || "",
        csaId: selectedCsaId || "",
      });
      onClose();
    } catch (error) {
      console.error("Error creating external payment:", error);
    }
  };

  const isFormValid = Boolean(
    amount &&
    description &&
    paymentMethod &&
    paymentDate
  );

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        Add External Payment for {contractor.firstName} {contractor.lastName}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
          <Typography variant="body2" color="text.secondary">
            Record an external payment that was made outside of the Increase payment system.
          </Typography>

          <FormControl fullWidth required>
            <InputLabel>Payment Method</InputLabel>
            <Select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value as ExternalPaymentSource)}
              label="Payment Method"
            >
              {PAYMENT_METHODS.map((method) => (
                <MenuItem key={method} value={method}>
                  {method.toUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <DatePicker
            label="Payment Date"
            value={paymentDate}
            onChange={(newValue) => setPaymentDate(newValue)}
            slotProps={{
              textField: {
                required: true,
                fullWidth: true,
              },
            }}
          />

          <FormControl fullWidth>
            <InputLabel>Client</InputLabel>
            <Select
              value={selectedClientId}
              onChange={(e) => {
                setSelectedClientId(e.target.value);
                setSelectedProjectId("");
                setSelectedProjectAssignmentId("");
                setSelectedCsaId("");
              }}
              label="Client"
            >
              {clients?.map((client: Client) => (
                <MenuItem key={client.id} value={client.id}>
                  {client.companyName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth disabled={!selectedClientId}>
            <InputLabel>Project</InputLabel>
            <Select
              value={selectedProjectId}
              onChange={(e) => {
                setSelectedProjectId(e.target.value);
                setSelectedProjectAssignmentId("");
              }}
              label="Project"
            >
              {availableProjects.map((project: Project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
            </Select>
            {selectedClientId && availableProjects.length === 0 && (
              <FormHelperText>
                No projects available for this client
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth disabled={!selectedProjectId}>
            <InputLabel>Project Assignment</InputLabel>
            <Select
              value={selectedProjectAssignmentId}
              onChange={(e) => setSelectedProjectAssignmentId(e.target.value)}
              label="Project Assignment"
            >
              {availableProjectAssignments.map((assignment) => (
                <MenuItem key={assignment.id} value={assignment.id}>
                  {assignment.startDate && assignment.endDate 
                    ? `${new Date(assignment.startDate).toLocaleDateString()} - ${new Date(assignment.endDate).toLocaleDateString()} # ${assignment.id}`
                    : assignment.id
                  }
                </MenuItem>
              ))}
            </Select>
            {selectedProjectId && availableProjectAssignments.length === 0 && (
              <FormHelperText>
                No project assignments available for this project
              </FormHelperText>
            )}
          </FormControl>

          <FormControl fullWidth disabled={!selectedProjectId}>
            <InputLabel>CSA</InputLabel>
            <Select
              value={selectedCsaId}
              onChange={(e) => setSelectedCsaId(e.target.value)}
              label="CSA"
            >
              {availableCsas.map((csa: ConsultingServicesAgreement) => (
                <MenuItem key={csa.id} value={csa.id}>
                  {csa.name}
                </MenuItem>
              ))}
            </Select>
            {selectedProjectId && availableCsas.length === 0 && (
              <FormHelperText>
                No CSAs available for this project
              </FormHelperText>
            )}
          </FormControl>

          <TextField
            label="Amount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            fullWidth
            required
            inputProps={{ min: 0, step: 0.01 }}
            placeholder="0.00"
          />

          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            required
            multiline
            rows={3}
            placeholder="Payment description..."
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          disabled={!isFormValid || addExternalPayment.isPending}
        >
          {addExternalPayment.isPending ? (
            <>
              <CircularProgress size={20} sx={{ mr: 1 }} />
              Adding Payment...
            </>
          ) : (
            "Add Payment"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
}; 