import React, { useState } from "react";
import {
  Button,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import { useAuth } from "hooks/auth/useAuth";
import { authTextFieldStyle } from "core/theme";
import { portalFunctions } from "core/functions/portalFunctions";

const ForgotPassword = (): JSX.Element => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    body: "",
    button: "",
  });

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email) {
      setEmailError("Email is required");
      return false;
    }
    if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      return false;
    }
    setEmailError("");
    return true;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      return;
    }

    setIsLoading(true);
    try {
      await portalFunctions.public.generatePasswordReset(email);
      setDialogContent({
        title: "Password Reset Email Sent",
        body: `If an account exists for ${email}, you should see password reset instructions in your inbox.`,
        button: "Ok",
      });
    } catch (error: any) {
      console.log(error);
      if (error.message === "Too many requests") {
        setDialogContent({
          title: "Rate Limit Exceeded",
          body: "You have already requested a password reset within the last minute. Please try again later.",
          button: "Ok",
        });
      } else {
        setDialogContent({
          title: "Error",
          body: "An unexpected error occurred. Please try again.",
          button: "Try Again",
        });
      }
    } finally {
      setIsLoading(false);
      setOpenDialog(true);
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    validateEmail(value);
  };

  return (
    <>
      <Dialog open={isLoading}>
        <DialogTitle>Sending reset email...</DialogTitle>
        <Box sx={{ margin: 2, textAlign: "center" }}>
          <CircularProgress />
        </Box>
      </Dialog>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>{dialogContent.title}</DialogTitle>
        <DialogContent>
          <Typography>{dialogContent.body}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            {dialogContent.button}
          </Button>
        </DialogActions>
      </Dialog>

      <Box sx={{ marginTop: "20px", width: "100%" }}>
        <Typography sx={{ color: "black", marginBottom: 2 }}>
          Enter your email address for a password reset link.
        </Typography>
      </Box>

      <Box
        component="form"
        onSubmit={handleSubmit}
        noValidate
        sx={{ mt: 1, width: "100%" }}
      >
        <TextField
          required
          fullWidth
          autoFocus
          margin="normal"
          id="email"
          label="Email Address"
          name="email"
          value={email}
          onChange={handleEmailChange}
          error={!!emailError}
          helperText={emailError}
          autoComplete="email"
          sx={{
            ...authTextFieldStyle,
          }}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={isLoading}
          sx={{
            mt: 3,
            mb: 2,
            bgcolor: "black",
            color: "white",
            "&:hover": {
              bgcolor: "rgba(0, 0, 0, 0.8)",
            },
          }}
        >
          Send Password Reset Email
        </Button>
      </Box>

      <Grid container>
        <Grid item xs>
          <Link href="/login" variant="body2" sx={{ color: "black" }}>
            Remember your password?
          </Link>
        </Grid>
      </Grid>
    </>
  );
};

export default ForgotPassword;
