import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { portalFunctions } from "core/functions/portalFunctions";
import { getAuth } from "firebase/auth";

export const disableUser = async (
  userId: string
): Promise<{ success: boolean; response: any }> => {
  try {
    const auth = getAuth();
    const token = await auth.currentUser?.getIdToken();

    if (!token) {
      throw new Error("No authentication token available");
    }

    // Call the Firebase Function to disable the user
    const response = await portalFunctions.admin.disableFirebaseUser(userId);

    if (!response.ok) {
      throw new Error("Failed to disable user in Firebase Auth");
    }

    // Update the user document in Firestore
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      disabled: true,
      disabledAt: new Date().toISOString(),
    });

    return { success: true, response: await response.json() };
  } catch (error) {
    console.error("Error disabling user:", error);
    return { success: false, response: error };
  }
};
