import { collection, doc, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "config/firebase";
import { Board, List, Card } from "types/freelo";

export const getBoards = async (): Promise<Board[]> => {
  try {
    const boardsRef = collection(db, "boards");
    const q = query(boardsRef, orderBy("order"));
    const snapshot = await getDocs(q);

    const boards = await Promise.all(
      snapshot.docs.map(async (boardDoc) => {
        const listsRef = collection(db, "boards", boardDoc.id, "lists");
        const listsSnapshot = await getDocs(listsRef);
        const lists = listsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as List[];

        const cardsRef = collection(db, "boards", boardDoc.id, "cards");
        const cardsSnapshot = await getDocs(cardsRef);
        const allCards = cardsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })) as Card[];

        const listsWithCards = lists.map((list) => ({
          ...list,
          cards: allCards.filter((card) => card.listId === list.id),
        }));

        return {
          id: boardDoc.id,
          ...boardDoc.data(),
          lists: listsWithCards,
        } as Board;
      })
    );

    return boards;
  } catch (error) {
    console.error("Error fetching boards:", error);
    throw error;
  }
};
