import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { useState, useCallback, useEffect } from "react";
import { DateTime } from "luxon";
import { formatDuration } from "../../core/timesheet/utils/utils";
import {
  getTimesheets,
  createTimesheet,
  updateTimesheet,
  deleteTimesheet,
  getTimesheet,
  getClientsProjectsAndAssignmentsForFreelancer,
  updateUserActiveTimer,
} from "core/timesheet";
import { useAuth } from "hooks/auth/useAuth";
import { Timesheet } from "types/timesheet";


export const useTimesheet = (userId: string) => {
  const queryClient = useQueryClient();
  const [timesheet, setTimesheet] = useState<Timesheet | undefined>(undefined);
  const [timerStartTime, setTimerStartTime] = useState<string | null>(null);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [timerValue, setTimerValue] = useState("00:00:00");
  const [isSaving, setIsSaving] = useState(false);

  const { userInfo } = useAuth();
  const clientsProjectsAndAssignmentsQuery = useQuery({
    queryKey: ["clientsProjectsAndAssignments", userId],
    queryFn: () => getClientsProjectsAndAssignmentsForFreelancer(userInfo),
    staleTime: 5 * 60 * 1000,
  });

  const timesheetsQuery = useQuery({
    queryKey: ["timesheets", { userId }],
    queryFn: () =>
      getTimesheets({
        userId,
        clientsProjectsAndAssignments: clientsProjectsAndAssignmentsQuery.data,
      }),
    staleTime: 5 * 60 * 1000,
    refetchOnWindowFocus: false,
  });

  const createTimesheetMutation = useMutation({
    mutationFn: (timesheetData: Omit<Timesheet, "id">) =>
      createTimesheet(timesheetData, userId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["timesheets"] });
    },
  });

  const updateTimesheetMutation = useMutation({
    mutationFn: updateTimesheet,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["timesheets"] });
    },
  });

  const deleteTimesheetMutation = useMutation({
    mutationFn: deleteTimesheet,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["timesheets"] });
    },
  });

  const fetchTimerState = async (timesheetId: string) => {
    if (!timesheetId) return null;
    return getTimesheet(timesheetId);
  };

  const { data: firestoreTimerState, refetch: refetchTimerState } = useQuery({
    queryKey: ["timerState", timesheet?.id],
    queryFn: () => fetchTimerState(timesheet?.id || ""),
    enabled: !!timesheet?.id,
    staleTime: 5 * 60 * 1000,
  });

  const startTimer = useCallback(
    async (newTimesheet: Omit<Timesheet, "id">) => {
      try {
        const now = DateTime.now();

        const isNonAssignedEntry =
          !newTimesheet.projectAssignmentId &&
          newTimesheet.clientId &&
          newTimesheet.projectId;

        const createdTimesheetId = await createTimesheetMutation.mutateAsync({
          ...newTimesheet,
          startTime: now.toISO(),
          endTime: now.toISO(),
          duration: 0,
          userId,
          timerActive: true,
          billable: isNonAssignedEntry ? false : newTimesheet.billable || true,
        });

        const createdTimesheet: Timesheet = {
          ...newTimesheet,
          id: createdTimesheetId,
          startTime: now.toISO(),
          endTime: now.toISO(),
          duration: 0,
          timerActive: true,
          billable: isNonAssignedEntry ? false : newTimesheet.billable || true,
        };

        setTimesheet(createdTimesheet);
        setIsTimerActive(true);
        setTimerStartTime(now.toISO());

        await updateUserActiveTimer(userId, createdTimesheetId);
      } catch (error) {
        console.error("Error starting timer:", error);
        throw error;
      }
    },
    [createTimesheetMutation, userId]
  );

  const stopTimer = useCallback(async () => {
    setIsSaving(true);
    try {
      const endTime = DateTime.now();
      if (!timerStartTime) {
        throw new Error("Start time is null");
      }
      const start = DateTime.fromISO(timerStartTime);
      const duration = Math.round(endTime.diff(start).as("seconds"));

      if (timesheet) {
        await updateTimesheetMutation.mutateAsync({
          id: timesheet.id,
          endTime: endTime.toISO(),
          duration,
          timerActive: false,
        });
      }

      setIsTimerActive(false);
      setTimerStartTime(null);
      setTimesheet(undefined);
      setTimerValue("00:00:00");

      await updateUserActiveTimer(userId, null);
      await refetchTimerState();
      return true;
    } catch (error) {
      console.error("Error stopping timer:", error);
      return false;
    } finally {
      setIsSaving(false);
    }
  }, [
    timesheet,
    updateTimesheetMutation,
    timerStartTime,
    refetchTimerState,
    userId,
  ]);

  const fetchActiveTimer = useCallback(async () => {
    const timesheet = await getTimesheet(userId);
    if (timesheet) {
      setTimesheet(timesheet);
      setIsTimerActive(true);
      setTimerStartTime(timesheet.startTime);
    }
  }, [userId]);

  const updateTimerValue = useCallback(() => {
    if (timerStartTime) {
      const diff = DateTime.now().diff(
        DateTime.fromISO(timerStartTime),
        "seconds"
      );
      const newTimerValue = formatDuration(Math.floor(diff.seconds));
      setTimerValue(newTimerValue);
    }
  }, [timerStartTime]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (isTimerActive) {
      interval = setInterval(updateTimerValue, 1000);
    }
    return () => clearInterval(interval);
  }, [isTimerActive, updateTimerValue]);

  const getMyTimesheets = () => {
    return timesheetsQuery;
  };

  return {
    timesheetsQuery,
    timesheetsError: timesheetsQuery.error,
    clientsProjectsAndAssignmentsQuery,
    clientsProjectsAndAssignmentsError: clientsProjectsAndAssignmentsQuery.error,
    createTimesheet: createTimesheetMutation,
    updateTimesheet: updateTimesheetMutation,
    deleteTimesheet: deleteTimesheetMutation,
    timesheet,
    startTimer,
    stopTimer,
    refetchTimerState,
    updateTimerValue,
    isTimerActive,
    timerValue,
    isSaving,
    setTimerValue,
    fetchActiveTimer,
    getMyTimesheets,
  };
};
