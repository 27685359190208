import React, { useState } from "react";
import {
  Box,
  Typography,
  Grid,
  TextField,
  Button,
  Card,
  CardContent,
  MenuItem,
  Alert,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Tooltip,
} from "@mui/material";
import { Info as InfoIcon, Edit as EditIcon } from "@mui/icons-material";
import { useAuth } from "hooks/auth/useAuth";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { usePublicUserList } from "hooks/user/usePublicUserList";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { useQueryClient } from "@tanstack/react-query";
import { Client } from "types/projects";

interface CompanySetupProps {
  onNext: (data: ICreateClient) => Promise<void>;
  onBack: () => void;
  initialData?: ICreateClient;
}

type CompanyFormFields = "companyName" | "pointOfContactName" | "email" | "phone" | "location" | "size";
type ICreateClient = Required<Pick<Client, CompanyFormFields>>;

type FormErrors = Partial<Record<CompanyFormFields, string>>;

const companySizes = [
  "1-10 employees",
  "11-50 employees",
  "51-200 employees",
  "201-500 employees",
  "501+ employees",
];

const initialCompanyForm: ICreateClient = {
  companyName: "",
  pointOfContactName: "",
  email: "",
  phone: "",
  location: "",
  size: "",
};

const CompanySetup: React.FC<CompanySetupProps> = ({
  onNext,
  onBack,
  initialData,
}) => {
  const { currentUser } = useAuth();
  const queryClient = useQueryClient();
  const {
    clients = [],
    createCompany,
    isCreatingCompany,
    companyError,
    clientsIsLoading,
  } = useStakeholder(currentUser?.uid);
  const { data: users = [] } = usePublicUserList();
  console.log(users);
  const [newCompany, setNewCompany] = useState<ICreateClient>(
    initialData ?? initialCompanyForm
  );
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isEditing, setIsEditing] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState<string | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const hasExistingClients = clients.length > 0;
  const selectedClient =
    clients.find((client) => client.id === selectedClientId) || clients[0];
  const canEdit = selectedClient?.createdByUserId === currentUser?.uid;
  const creatorUser = users.find(
    (user) => user.id === selectedClient?.createdByUserId
  );

  const validateForm = (company: ICreateClient): FormErrors => {
    const errors: FormErrors = {};

    if (!company.companyName.trim()) {
      errors.companyName = "Company name is required";
    }

    if (!company.pointOfContactName.trim()) {
      errors.pointOfContactName = "Point of contact name is required";
    }

    if (!company.email.trim()) {
      errors.email = "Email is required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(company.email)) {
      errors.email = "Invalid email address";
    }

    if (!company.phone.trim()) {
      errors.phone = "Phone number is required";
    } else if (!/^\+?[\d\s-()]{10,}$/.test(company.phone)) {
      errors.phone = "Invalid phone number";
    }

    if (!company.location.trim()) {
      errors.location = "Location is required";
    }

    if (!company.size) {
      errors.size = "Company size is required";
    }

    return errors;
  };

  const formatPhoneNumber = (phone: string): string => {
    // Remove all non-numeric characters
    const cleaned = phone.replace(/\D/g, "");

    // Format as (XXX) XXX-XXXX
    if (cleaned.length >= 10) {
      return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    }

    return phone;
  };

  const handleInputChange = (field: CompanyFormFields, value: string) => {
    let processedValue = value;

    if (field === "phone") {
      processedValue = formatPhoneNumber(value);
    }

    setNewCompany((prev) => ({
      ...prev,
      [field]: processedValue,
    }));

    // Clear error when user starts typing
    if (formErrors[field]) {
      setFormErrors((prev) => ({
        ...prev,
        [field]: undefined,
      }));
    }
  };

  const handleSelectChange = (e: SelectChangeEvent) => {
    const { name, value } = e.target;
    handleInputChange(name as CompanyFormFields, value);
  };

  const handleAddCompany = async () => {
    const errors = validateForm(newCompany);
    if (Object.keys(errors).length === 0) {
      try {
        await createCompany({
          ...newCompany,
          status: "active",
          introductionDate: new Date().toISOString(),
          createdByUserId: currentUser?.uid || "",
          companyPhysicalAddress: {
            street: "",
            city: "",
            state: "",
            zip: "",
          },
        });
        await onNext(newCompany);
      } catch (err) {
        console.error("Error creating company:", err);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const handleEditCompany = async () => {
    const errors = validateForm(newCompany);
    if (Object.keys(errors).length === 0 && selectedClientId) {
      try {
        setIsUpdating(true);
        const clientRef = doc(db, "clients", selectedClientId);
        await updateDoc(clientRef, {
          ...newCompany,
          updatedAt: new Date().toISOString(),
        });

        // Invalidate and refetch clients
        await queryClient.invalidateQueries({
          queryKey: ["stakeholder-clients"],
        });
        setIsEditing(false);
      } catch (err) {
        console.error("Error updating company:", err);
      } finally {
        setIsUpdating(false);
      }
    } else {
      setFormErrors(errors);
    }
  };

  const startEditing = () => {
    if (selectedClient) {
      setNewCompany({
        companyName: selectedClient.companyName || "",
        pointOfContactName: selectedClient.pointOfContactName || "",
        email: selectedClient.email || "",
        phone: selectedClient.phone || "",
        location: selectedClient.location || "",
        size: selectedClient.size || "",
      });
      setIsEditing(true);
    }
  };

  const handleNext = () => {
    if (selectedClient) {
      const companyData: ICreateClient = {
        companyName: selectedClient.companyName,
        pointOfContactName: selectedClient.pointOfContactName || "",
        email: selectedClient.email || "",
        phone: selectedClient.phone || "",
        location: selectedClient.location,
        size: selectedClient.size,
      };
      onNext(companyData);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Company Setup
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        {hasExistingClients
          ? "Review your associated companies below. You can edit them if you created them."
          : "Please provide your company details to continue with the onboarding process."}
      </Typography>

      {companyError && (
        <Alert severity="error" sx={{ mb: 2 }}>
          Error creating company: {companyError.message}
        </Alert>
      )}

      {clientsIsLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      ) : isEditing ? (
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Edit Company Details
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              Update your company's information below.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Company Name"
                  value={newCompany.companyName}
                  onChange={(e) =>
                    handleInputChange("companyName", e.target.value)
                  }
                  error={!!formErrors.companyName}
                  helperText={formErrors.companyName}
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title="Enter the legal name of your company"
                        arrow
                      >
                        <InfoIcon color="action" sx={{ ml: 1 }} />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Point of Contact Name"
                  value={newCompany.pointOfContactName}
                  onChange={(e) =>
                    handleInputChange("pointOfContactName", e.target.value)
                  }
                  error={!!formErrors.pointOfContactName}
                  helperText={formErrors.pointOfContactName}
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title="The primary person managing FreeTech services"
                        arrow
                      >
                        <InfoIcon color="action" sx={{ ml: 1 }} />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  type="email"
                  label="Contact Email"
                  value={newCompany.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Business email for communications" arrow>
                        <InfoIcon color="action" sx={{ ml: 1 }} />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Contact Phone"
                  value={newCompany.phone}
                  onChange={(e) => handleInputChange("phone", e.target.value)}
                  error={!!formErrors.phone}
                  helperText={formErrors.phone}
                  placeholder="(XXX) XXX-XXXX"
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title="Business phone number for urgent communications"
                        arrow
                      >
                        <InfoIcon color="action" sx={{ ml: 1 }} />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Location"
                  value={newCompany.location}
                  onChange={(e) =>
                    handleInputChange("location", e.target.value)
                  }
                  error={!!formErrors.location}
                  helperText={formErrors.location}
                  placeholder="City, Country"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Primary business location" arrow>
                        <InfoIcon color="action" sx={{ ml: 1 }} />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required error={!!formErrors.size}>
                  <InputLabel>Company Size</InputLabel>
                  <Select
                    name="size"
                    value={newCompany.size}
                    onChange={handleSelectChange}
                    label="Company Size"
                  >
                    {companySizes.map((size) => (
                      <MenuItem key={size} value={size}>
                        {size}
                      </MenuItem>
                    ))}
                  </Select>
                  {formErrors.size && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ mt: 1, ml: 2 }}
                    >
                      {formErrors.size}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ) : hasExistingClients ? (
        <Box sx={{ mb: 4 }}>
          {clients.map((client) => {
            const clientCreator = users.find(
              (user) => user.id === client.createdByUserId
            );
            console.log(clientCreator);
            const canEditThisClient =
              client.createdByUserId === currentUser?.uid;

            return (
              <Card key={client.id} sx={{ mb: 2 }}>
                <CardContent>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      mb: 2,
                    }}
                  >
                    <Box>
                      <Typography variant="h6" component="div">
                        {client.companyName}
                      </Typography>
                      {clientCreator && (
                        <Typography
                          variant="body2"
                          color="text.secondary"
                          sx={{ mt: 0.5 }}
                        >
                          {canEditThisClient
                            ? "You created this company"
                            : `Created by ${clientCreator.firstName} ${clientCreator.lastName}`}
                        </Typography>
                      )}
                    </Box>
                    {canEditThisClient && (
                      <Button
                        startIcon={<EditIcon />}
                        onClick={() => {
                          setSelectedClientId(client.id);
                          setNewCompany({
                            companyName: client.companyName || "",
                            pointOfContactName: client.pointOfContactName || "",
                            email: client.email || "",
                            phone: client.phone || "",
                            location: client.location || "",
                            size: client.size || "",
                          });
                          setIsEditing(true);
                        }}
                        size="small"
                      >
                        Edit
                      </Button>
                    )}
                  </Box>
                  <Box sx={{ mt: 2 }}>
                    <Typography component="div" variant="body2">
                      {client.pointOfContactName} • {client.email}
                    </Typography>
                    <Typography
                      component="div"
                      variant="body2"
                      color="text.secondary"
                    >
                      {client.location} • {client.size}
                    </Typography>
                    {clientCreator && (
                      <Typography
                        component="div"
                        variant="body2"
                        color="text.secondary"
                        sx={{ mt: 1 }}
                      >
                        Created by {clientCreator.firstName}{" "}
                        {clientCreator.lastName}
                      </Typography>
                    )}
                  </Box>
                </CardContent>
              </Card>
            );
          })}
          {hasExistingClients && (
            <Alert severity="info" sx={{ mt: 2 }}>
              You cannot create additional companies at this time. Contact
              admin@freetech.co to learn about our referral program.
            </Alert>
          )}
        </Box>
      ) : (
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Company Details
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
              By adding your company, you confirm your authority to manage
              FreeTech services on its behalf.
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  label="Company Name"
                  value={newCompany.companyName}
                  onChange={(e) =>
                    handleInputChange("companyName", e.target.value)
                  }
                  error={!!formErrors.companyName}
                  helperText={formErrors.companyName}
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title="Enter the legal name of your company"
                        arrow
                      >
                        <InfoIcon color="action" sx={{ ml: 1 }} />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Point of Contact Name"
                  value={newCompany.pointOfContactName}
                  onChange={(e) =>
                    handleInputChange("pointOfContactName", e.target.value)
                  }
                  error={!!formErrors.pointOfContactName}
                  helperText={formErrors.pointOfContactName}
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title="The primary person managing FreeTech services"
                        arrow
                      >
                        <InfoIcon color="action" sx={{ ml: 1 }} />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  type="email"
                  label="Contact Email"
                  value={newCompany.email}
                  onChange={(e) => handleInputChange("email", e.target.value)}
                  error={!!formErrors.email}
                  helperText={formErrors.email}
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Business email for communications" arrow>
                        <InfoIcon color="action" sx={{ ml: 1 }} />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Contact Phone"
                  value={newCompany.phone}
                  onChange={(e) => handleInputChange("phone", e.target.value)}
                  error={!!formErrors.phone}
                  helperText={formErrors.phone}
                  placeholder="(XXX) XXX-XXXX"
                  InputProps={{
                    endAdornment: (
                      <Tooltip
                        title="Business phone number for urgent communications"
                        arrow
                      >
                        <InfoIcon color="action" sx={{ ml: 1 }} />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Location"
                  value={newCompany.location}
                  onChange={(e) =>
                    handleInputChange("location", e.target.value)
                  }
                  error={!!formErrors.location}
                  helperText={formErrors.location}
                  placeholder="City, Country"
                  InputProps={{
                    endAdornment: (
                      <Tooltip title="Primary business location" arrow>
                        <InfoIcon color="action" sx={{ ml: 1 }} />
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth required error={!!formErrors.size}>
                  <InputLabel>Company Size</InputLabel>
                  <Select
                    name="size"
                    value={newCompany.size}
                    onChange={handleSelectChange}
                    label="Company Size"
                  >
                    {companySizes.map((size) => (
                      <MenuItem key={size} value={size}>
                        {size}
                      </MenuItem>
                    ))}
                  </Select>
                  {formErrors.size && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ mt: 1, ml: 2 }}
                    >
                      {formErrors.size}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      )}

      {/* Navigation Buttons */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
        <Button onClick={onBack} disabled={isCreatingCompany || isUpdating}>
          Back
        </Button>
        {hasExistingClients ? (
          isEditing ? (
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button onClick={() => setIsEditing(false)} disabled={isUpdating}>
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleEditCompany}
                disabled={isUpdating}
                startIcon={isUpdating ? <CircularProgress size={20} /> : null}
              >
                {isUpdating ? "Saving..." : "Save Changes"}
              </Button>
            </Box>
          ) : (
            <Button
              variant="contained"
              onClick={handleNext}
              disabled={isCreatingCompany || isUpdating}
            >
              Next
            </Button>
          )
        ) : (
          <Button
            variant="contained"
            onClick={handleAddCompany}
            disabled={isCreatingCompany || isUpdating}
            startIcon={
              isCreatingCompany ? <CircularProgress size={20} /> : null
            }
          >
            {isCreatingCompany ? "Creating..." : "Create Company"}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default CompanySetup;
