import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { adminController } from "controllers/adminController";
import { UploadProfilePictureParams } from "types/admin";
import {
  CombinedUserData,
  CreateUserType,
  PrivateUserInfoType,
} from "types/user";
import { portalFunctions } from "core/functions/portalFunctions";

interface CreateUserResponse {
  userId: string;
  success: boolean;
}

export const useManageUsers = () => {
  const queryClient = useQueryClient();

  // Fetch all users
  const {
    data: authUsers,
    isLoading: authUsersLoading,
    isError: authUsersError,
  } = useQuery<CombinedUserData[], Error>({
    queryKey: ["users"],
    queryFn: async () => {
      const users = await adminController.users.loadAuthUserProfiles();
      return users;
    },
    staleTime: 5 * 60 * 1000,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const {
    data: privateUsers,
    isLoading: privateUsersLoading,
    isError: privateUsersError,
  } = useQuery<PrivateUserInfoType[], Error>({
    queryKey: ["privateUsers"],
    queryFn: async () => {
      const users = await adminController.users.loadPrivateUsers();
      return users;
    },
  });

  const createUserMutation = useMutation({
    mutationFn: async (user: CreateUserType) => {
      const response = await portalFunctions.admin.createFirebaseUser(user);
      if (!response.ok) {
        const text = await response.text();
        try {
          const data = JSON.parse(text);
          throw new Error(data.message || 'Failed to create user');
        } catch (e) {
          // If JSON parsing fails, use the raw text
          throw new Error(text || 'Failed to create user');
        }
      }
      
      const data = await response.json() as CreateUserResponse;
      if (!data.userId) {
        throw new Error("Failed to create user");
      }
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  const updateUserMutation = useMutation({
    mutationFn: (user: CombinedUserData) => {
      return adminController.users.updateUser(user);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  const deleteUserMutation = useMutation({
    mutationFn: (userId: string) => {
      return portalFunctions.admin.deleteFirebaseUser(userId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  const disableUserMutation = useMutation({
    mutationFn: (userId: string) => {
      return adminController.users.disableUser(userId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  const enableUserMutation = useMutation({
    mutationFn: (userId: string) => {
      return adminController.users.enableUser(userId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  const uploadProfilePictureMutation = useMutation({
    mutationFn: (params: UploadProfilePictureParams) => {
      return adminController.users.uploadProfilePicture(params);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
  });

  return {
    authUsers,
    privateUsers,
    authUsersLoading,
    privateUsersLoading,
    authUsersError,
    privateUsersError,
    createUserMutation,
    updateUserMutation,
    deleteUserMutation,
    disableUserMutation,
    enableUserMutation,
    uploadProfilePictureMutation,
  };
};
