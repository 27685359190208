import React from 'react';
import { Box, Typography, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Client } from "types/projects";

interface ProjectAssignmentsHeaderProps {
  clients: Client[];
  onClientChange: (client: string) => void;
  onStatusChange: (status: string) => void;
  onStartDateChange: (date: string) => void;
  onEndDateChange: (date: string) => void;
}

const ProjectAssignmentsHeader: React.FC<ProjectAssignmentsHeaderProps> = ({
  clients,
  onClientChange,
  onStatusChange,
  onStartDateChange,
  onEndDateChange
}) => {
  return (
    <Box sx={{ mb: 4 }}>
      <Typography variant="h5" gutterBottom>Project Assignment Contracts</Typography>
      <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Client</InputLabel>
          <Select label="Client" onChange={(e) => onClientChange(e.target.value as string)}>
            <MenuItem value="all">All Clients</MenuItem>
            {clients.map((client) => (
              <MenuItem key={client.id} value={client.id}>{client.companyName}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel>Status</InputLabel>
          <Select label="Status" onChange={(e) => onStatusChange(e.target.value as string)}>
            <MenuItem value="all">All Statuses</MenuItem>
            <MenuItem value="in_progress">In Progress</MenuItem>
            <MenuItem value="complete">Complete</MenuItem>
            <MenuItem value="ready">Ready</MenuItem>
            <MenuItem value="interviewing">Interviewing</MenuItem>
          </Select>
        </FormControl>
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => onStartDateChange(e.target.value)}
        />
        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => onEndDateChange(e.target.value)}
        />
      </Box>
    </Box>
  );
};

export default ProjectAssignmentsHeader;