import { useEffect } from "react";
import { useAuth } from "hooks/auth/useAuth";
import { useLocation } from "react-router-dom";
import { pingUserStatus } from "core/websocket/pingUserStatus";

export const useUserPing = () => {
  const { userInfo } = useAuth();
  const location = useLocation();

  useEffect(() => {
    if (!userInfo?.id) return;

    // Initial ping
    pingUserStatus({
      userId: userInfo.id,
      timestamp: Date.now(),
      userRole: userInfo.role,
      currentPage: location.pathname,
    });

    // Set up periodic ping
    const pingInterval = setInterval(() => {
      pingUserStatus({
        userId: userInfo.id,
        timestamp: Date.now(),
        userRole: userInfo.role,
        currentPage: location.pathname,
      });
    }, 30000); // Ping every 30 seconds

    return () => {
      clearInterval(pingInterval);
    };
  }, [userInfo?.id, userInfo?.role, location.pathname]);
};
