import {
  FirestoreDataConverter,
  DocumentSnapshot,
  SnapshotOptions,
  WithFieldValue,
} from "firebase/firestore";
import { Timesheet } from "../timesheet";

export const timesheetConverter: FirestoreDataConverter<Timesheet> = {
  toFirestore(timesheet: WithFieldValue<Timesheet>) {
    const { id, ...data } = timesheet;
    return data;
  },
  fromFirestore(
    snapshot: DocumentSnapshot,
    options: SnapshotOptions
  ): Timesheet {
    const data = snapshot.data(options);
    return {
      id: snapshot.id,
      userId: data?.userId,
      clientId: data?.clientId,
      projectId: data?.projectId,
      projectAssignmentId: data?.projectAssignmentId,
      startTime: data?.startTime,
      endTime: data?.endTime,
      date: data?.date,
      duration: data?.duration || 0,
      description: data?.description || "",
      billable: data?.billable || false,
      submitted: data?.submitted || false,
      submittedAt: data?.submittedAt || "",
      approved: data?.approved || false,
      approvedAt: data?.approvedAt || "",
      approvedBy: data?.approvedBy || "",
      rejected: data?.rejected || false,
      rejectedAt: data?.rejectedAt || "",
      rejectedBy: data?.rejectedBy || "",
      timerActive: data?.timerActive || false,
      longDescription: data?.longDescription || "",
    };
  },
};
