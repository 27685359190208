import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { db } from "config/firebase";
import { clientConverter } from "@freetech/models/converters/clientConverter";
import { consultingServicesAgreementConverter } from "@freetech/models/converters/consultingServicesAgreementConverter";
import { projectConverter } from "@freetech/models/converters/projectConverter";
import { invoiceConverter } from "@freetech/models/converters/invoiceConverter";

import { stakeholderConverter } from "@freetech/models/converters/stakeholderConverter";
import { Client } from "types/projects";
import { ConsultingServicesAgreement } from "types/projects";
import { ProjectAssignment } from "types/projects";
import { ClientFreelancer } from "types/projects";
import { ClientStakeholder } from "types/projects";

export const loadSingleNestedClient = async (
  clientId: string
): Promise<Client | null> => {
  try {
    const clientRef = doc(db, "clients", clientId).withConverter(
      clientConverter
    );
    const clientDoc = await getDoc(clientRef);

    if (!clientDoc.exists()) return null;

    const clientData = clientDoc.data();

    // Get consulting services agreements (new structure)
    const csaSnapshot = await getDocs(
      collection(clientRef, "consultingServicesAgreements").withConverter(
        consultingServicesAgreementConverter
      )
    );

    clientData.consultingServicesAgreements = csaSnapshot.docs.map((doc) =>
      doc.data()
    ) as ConsultingServicesAgreement[];

    // Fetch projects
    const projectsSnapshot = await getDocs(
      collection(clientRef, "projects").withConverter(projectConverter)
    );
    clientData.projects = await Promise.all(
      projectsSnapshot.docs.map(async (projectDoc) => projectDoc.data())
    );

    // Fetch invoices
    const invoicesSnapshot = await getDocs(
      collection(clientRef, "invoices").withConverter(invoiceConverter)
    );
    clientData.invoices = invoicesSnapshot.docs.map((invoiceDoc) =>
      invoiceDoc.data()
    );

    // Fetch payments
    // const paymentsSnapshot = await getDocs(
    //   collection(clientRef, "payments").withConverter(legacyPaymentConverter)
    // );
    // clientData.payments = paymentsSnapshot.docs.map((paymentDoc) =>
    //   paymentDoc.data()
    // );

    // Fetch project assignments
    const projectAssignmentsSnapshot = await getDocs(
      collection(clientRef, "projectAssignments")
    );
    clientData.projectAssignments = projectAssignmentsSnapshot.docs.map(
      (paDoc) => ({ id: paDoc.id, ...paDoc.data() }) as ProjectAssignment
    );

    // Fetch freelancers using associatedFreelancerIds
    if (clientData.associatedFreelancerIds?.length) {
      clientData.freelancers = await Promise.all(
        clientData.associatedFreelancerIds.map(async (freelancerId) => {
          const freelancerDoc = await getDoc(doc(db, "users", freelancerId));
          const freelancerData = freelancerDoc.data();

          const projectAssignments = clientData.projectAssignments?.filter(
            (pa) => pa.engineerId === freelancerId
          );

          if (!freelancerData) {
            console.warn(`No data found for freelancer ID: ${freelancerId}`);
            return null;
          }

          // Make sure we include the ID in the freelancer data
          const freelancer: ClientFreelancer = {
            id: freelancerId,
            ...freelancerData,
            // Required fields from Freelancer interface
            role: freelancerData.role || "freelancer",
            permissions: freelancerData.permissions || [],
            documents: freelancerData.documents || [],
            email: freelancerData.email || "",
            firstName: freelancerData.firstName || "",
            lastName: freelancerData.lastName || "",
            displayName: freelancerData.displayName || "",
            // Additional fields from ClientFreelancer interface
            associationDate:
              freelancerData.associationDate || new Date().toISOString(),
            status: freelancerData.status || "ready",
            projectAssignments: projectAssignments || [],
            disabled: freelancerData.disabled,
          };

          return freelancer;
        })
      ).then((results) =>
        results.filter((f): f is ClientFreelancer => f !== null)
      );
    } else {
      clientData.freelancers = [];
    }

    if (clientData.associatedStakeholderIds?.length) {
      clientData.stakeholders = await Promise.all(
        clientData.associatedStakeholderIds.map(async (stakeholderId) => {
          const stakeholderDoc = await getDoc(doc(db, "users", stakeholderId));
          const stakeholderData = stakeholderDoc.data();
          return stakeholderData;
        })
      ).then((results) =>
        results.filter((f): f is ClientStakeholder => f !== null)
      );
    } else {
      clientData.stakeholders = [];
    }
    return clientData;
  } catch (error) {
    console.error("Error getting client by ID:", error);
    throw error;
  }
};
