import { useState, useEffect, useCallback, useRef } from "react";
import * as Y from "yjs";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { useAuth } from "hooks/auth/useAuth";

export type CollaborationStatus = "connecting" | "connected" | "disconnected";

const wsUrl = "wss://tiptap.freetech.co";

interface UseCollaborationProps {
  documentId?: string;
  userName?: string;
}

interface CursorState {
  name: string;
  color: string;
  position: { from: number; to: number } | null;
}

interface AwarenessState {
  user: {
    name: string;
    color: string;
  };
  cursor: {
    from: number;
    to: number;
  } | null;
}

const RECONNECT_DELAY = 3000; // 3 seconds

export const useCollaboration = ({
  documentId,
  userName,
}: UseCollaborationProps) => {
  const providerRef = useRef<HocuspocusProvider>();
  const [status, setStatus] = useState<CollaborationStatus>("disconnected");
  const ydocRef = useRef(new Y.Doc());
  const [cursors, setCursors] = useState<Map<number, CursorState>>(new Map());
  const reconnectAttempts = useRef(0);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout>();
  const [isReconnecting, setIsReconnecting] = useState(false);
  const { currentUser } = useAuth();

  const clearAwareness = useCallback(() => {
    if (providerRef.current?.awareness) {
      providerRef.current.awareness.setLocalState(null);
      setCursors(new Map());
    }
  }, []);

  const initializeProvider = useCallback(async () => {
    if (!documentId || !userName) return;
    
    try {
      if (providerRef.current) {
        providerRef.current.destroy();
      }

      const token = await currentUser?.getIdToken();

      const provider = new HocuspocusProvider({
        url: wsUrl,
        name: documentId,
        document: ydocRef.current,
        connect: true,
        token,
        onConnect: () => {
setStatus("connected");

          if (provider.awareness) {
            const userColor = `#${Math.floor(Math.random() * 16777215)
              .toString(16)
              .padStart(6, "0")}`;
            const awarenessState: AwarenessState = {
              user: {
                name: userName,
                color: userColor,
              },
              cursor: null,
            };
            provider.awareness.setLocalState(awarenessState);
          }
        },
        onDisconnect: () => {
setStatus("disconnected");
          clearAwareness();
        },
        onClose: () => {
setStatus("disconnected");
          clearAwareness();
        },
        onStatus: ({ status: newStatus }) => {
setStatus(newStatus as CollaborationStatus);
        },
        onAuthenticated: () => {
setStatus("connected");
        },
        onAuthenticationFailed: (error) => {
          console.error("Authentication failed:", error);
          setStatus("disconnected");
          clearAwareness();
        },
      });

      // Set up awareness handling
      provider.awareness?.on("change", () => {
        const states = provider.awareness?.getStates();
        if (!states) return;

        const newCursors = new Map<number, CursorState>();
        states.forEach((value: unknown, clientId: number) => {
          const state = value as Partial<AwarenessState>;
          if (state?.user && "cursor" in state) {
            newCursors.set(clientId, {
              name: state.user.name,
              color: state.user.color,
              position: state.cursor || null,
            });
          }
        });

        setCursors(newCursors);
      });
providerRef.current = provider;

      provider.on("error", (error: any) => {
        console.error("Provider error:", error);
      });

      reconnectAttempts.current = 0;
      setIsReconnecting(false);
    } catch (error) {
      console.error('Connection attempt failed:', error);
      throw error;
    }
  }, [documentId, userName, clearAwareness]);

  const attemptReconnect = useCallback(() => {
    if (reconnectAttempts.current >= 5) {
      console.error('Max reconnection attempts reached');
      setStatus('disconnected');
      return;
    }

    setIsReconnecting(true);
    reconnectAttempts.current += 1;

    initializeProvider().catch(() => {
      reconnectTimeoutRef.current = setTimeout(() => {
        attemptReconnect();
      }, RECONNECT_DELAY);
    });
  }, [initializeProvider]);

  useEffect(() => {
    if (!documentId || !userName) {
return;
    }

    initializeProvider().catch((error) => {
      console.error("Failed to initialize provider:", error);
      setStatus("disconnected");
    });

    return () => {
      clearTimeout(reconnectTimeoutRef.current);
      clearAwareness();
      providerRef.current?.destroy();
    };
  }, [documentId, userName, clearAwareness, initializeProvider]);

  const updateCursorPosition = useCallback(
    (position: { from: number; to: number } | null) => {
      if (providerRef.current?.awareness && status === "connected") {
        try {
          const currentState =
            providerRef.current.awareness.getLocalState() as AwarenessState | null;
          if (currentState?.user) {
            providerRef.current.awareness.setLocalState({
              ...currentState,
              cursor: position,
            });
          }
        } catch (error) {
          console.error("Error updating cursor position:", error);
        }
      }
    },
    [status]
  );

  return {
    provider: status === "connected" ? providerRef.current : undefined,
    status,
    ydoc: ydocRef.current,
    updateCursorPosition,
    cursors,
    isReconnecting,
  };
};
