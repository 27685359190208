import React, { useState } from "react";
import {
  Typography,
  Box,
  CircularProgress,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Stack,
  Grid,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuth } from "hooks/auth/useAuth";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import ViewFileDialog from "components/ViewFileDialog";
import PayInvoiceDialog from "./PayInvoiceDialog";
import { getDownloadableURL } from "core/storage/getDownloadableURL";
import { ClientInvoiceData } from "@freetech/models/bank";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PaymentIcon from '@mui/icons-material/Payment';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

const getStatusColor = (status: string) => {
  switch (status) {
    case "paid":
      return "success";
    case "unpaid":
      return "error";
    case "partial":
      return "warning";
    case "pending":
      return "info";
    default:
      return "default";
  }
};

export const ClientInvoices: React.FC = () => {
  const [selectedInvoice, setSelectedInvoice] = useState<ClientInvoiceData | null>(null);
  const [isViewDialogOpen, setIsViewDialogOpen] = useState(false);
  const [isPayDialogOpen, setIsPayDialogOpen] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  const { currentUser } = useAuth();
  const { clients } = useStakeholder(currentUser?.uid);
  const { clientInvoices: invoices, clientInvoicesError, clientInvoicesLoading } = useStakeholderPayments();

  const handleAccordionChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleViewInvoice = async (invoice: ClientInvoiceData) => {
    try {
      const fullStoragePath = `clients/${invoice.clientId}/invoices/${invoice.id}.pdf`;
      const url = await getDownloadableURL(fullStoragePath);
      invoice.pdfUrl = url;
      setSelectedInvoice(invoice);
      setIsViewDialogOpen(true);
    } catch (error) {
      console.error("Error fetching invoice PDF:", error);
    }
  };

  const handlePayInvoice = (invoice: ClientInvoiceData) => {
    setSelectedInvoice(invoice);
    setIsPayDialogOpen(true);
  };

  const handleCloseViewDialog = () => {
    setIsViewDialogOpen(false);
    setSelectedInvoice(null);
  };

  const handleClosePayDialog = () => {
    setIsPayDialogOpen(false);
    setSelectedInvoice(null);
  };

  return (
    <Box p={3}>
      {clientInvoicesLoading ? (
        <CircularProgress />
      ) : (
        <Stack spacing={2}>
          {invoices?.map((invoice) => {
            const client = clients?.find(c => c.id === invoice.clientId);
            
            return (
              <Accordion
                key={invoice.id}
                expanded={expanded === invoice.id}
                onChange={handleAccordionChange(invoice.id)}
                sx={{ border: 1, borderColor: 'divider', boxShadow: 'none' }}
              >
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs={3}>
                      <Typography variant="subtitle1">
                        #{invoice.invoiceNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        ${invoice.totalDollarAmountDue?.toLocaleString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>
                        {new Date(invoice.invoiceDate).toLocaleDateString()}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Chip
                        label={invoice.status?.toUpperCase()}
                        color={getStatusColor(invoice.status || "")}
                        size="small"
                      />
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <Stack spacing={3}>
                    <Grid container spacing={3}>
                      {/* Client Information */}
                      <Grid item xs={12}>
                        <Box sx={{ 
                          p: 2, 
                          bgcolor: 'background.default', 
                          borderRadius: 1,
                          border: 1,
                          borderColor: 'divider'
                        }}>
                          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            <BusinessIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
                            Client Information
                          </Typography>
                          <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={6} md={3}>
                              <Typography variant="body2" color="text.secondary">
                                Company
                              </Typography>
                              <Typography variant="body1">
                                {client?.companyName || invoice.billTo?.company || 'N/A'}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={3}>
                              <Typography variant="body2" color="text.secondary">
                                Contact
                              </Typography>
                              <Typography variant="body1">
                                {client?.pointOfContactName || invoice.billTo?.name || 'N/A'}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={3}>
                              <Typography variant="body2" color="text.secondary">
                                Email
                              </Typography>
                              <Typography variant="body1">
                                {client?.email || 'N/A'}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={3}>
                              <Typography variant="body2" color="text.secondary">
                                Phone
                              </Typography>
                              <Typography variant="body1">
                                {client?.phone || 'N/A'}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>

                      {/* Invoice Details */}
                      <Grid item xs={12}>
                        <Box sx={{ 
                          p: 2, 
                          bgcolor: 'background.default', 
                          borderRadius: 1,
                          border: 1,
                          borderColor: 'divider'
                        }}>
                          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                            Invoice Details
                          </Typography>
                          <Grid container spacing={2} sx={{ mt: 1 }}>
                            <Grid item xs={6} md={4}>
                              <Typography variant="body2" color="text.secondary">
                                Project
                              </Typography>
                              <Typography variant="body1">
                                {invoice.projectName || 'N/A'}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <Typography variant="body2" color="text.secondary">
                                Invoice Date
                              </Typography>
                              <Typography variant="body1">
                                {new Date(invoice.invoiceDate).toLocaleDateString()}
                              </Typography>
                            </Grid>
                            <Grid item xs={6} md={4}>
                              <Typography variant="body2" color="text.secondary">
                                Due Date
                              </Typography>
                              <Typography variant="body1">
                                {new Date(invoice.dueDate).toLocaleDateString()}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                      <Button
                        startIcon={<PictureAsPdfIcon />}
                        variant="outlined"
                        onClick={() => handleViewInvoice(invoice)}
                      >
                        View Invoice
                      </Button>
                      {invoice.status === "unpaid" && (
                        <Button
                          startIcon={<PaymentIcon />}
                          variant="contained"
                          onClick={() => handlePayInvoice(invoice)}
                          color="primary"
                        >
                          Pay Invoice
                        </Button>
                      )}
                    </Box>
                  </Stack>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </Stack>
      )}
      {selectedInvoice && (
        <>
          <ViewFileDialog
            open={isViewDialogOpen}
            onClose={handleCloseViewDialog}
            fileUrl={selectedInvoice.pdfUrl || ""}
            fileName={`Invoice_${selectedInvoice.invoiceNumber}.pdf`}
          />
          <PayInvoiceDialog
            open={isPayDialogOpen}
            onClose={handleClosePayDialog}
            invoice={selectedInvoice}
            accountId={"sandbox_account_wsja3ql2aqobwsegmehy"}
          />
        </>
      )}
    </Box>
  );
};