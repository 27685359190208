import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createBugReport,
  getBugReports,
  getMyBugReports,
} from "core/bugReport";
import { useAuth } from "hooks/auth/useAuth";
import { CreateBugReportInput } from "types/bugReport";

export const useBugReport = () => {
  const { userInfo } = useAuth();
  const queryClient = useQueryClient();

  const createReport = useMutation<string, Error, CreateBugReportInput>({
    mutationFn: createBugReport,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["bugReports"] });
      queryClient.invalidateQueries({ queryKey: ["myBugReports"] });
    },
  });

  const { data: bugReports, isLoading: isLoadingAllReports } = useQuery({
    queryKey: ["bugReports"],
    queryFn: () => getBugReports(),
    refetchInterval: 1000 * 60 * 60 * 1, // 1 hour
    refetchOnMount: false,
    enabled: !!userInfo,
  });

  const { data: myBugReports, isLoading: isLoadingMyReports } = useQuery({
    queryKey: ["myBugReports", userInfo?.id],
    queryFn: () => getMyBugReports(userInfo!.id),
    refetchInterval: 1000 * 60 * 60 * 1, // 1 hour
    refetchOnMount: false,
    enabled: !!userInfo?.id,
  });

  return {
    createReport,
    bugReports,
    myBugReports,
    isLoadingAllReports,
    isLoadingMyReports,
    isLoading: isLoadingAllReports || isLoadingMyReports,
  };
};
