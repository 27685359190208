import {
  collection,
  collectionGroup,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "config/firebase";
import { loadClientsNonAdmin } from "core/clients/loadClientsNonAdmin";
import { PrivateUserInfoType } from "types/user";

export const loadClientsForStakeholder = async (
  user: PrivateUserInfoType
) => {
  try {
    const clients = await loadClientsNonAdmin(user);
    return clients;
  } catch (error) {
    console.error("Error fetching clients:", error);
    throw error;
  }
};
