import React from "react";
import {
  Box,
  Typography,
  Paper,
  Grid,
  Alert,
  Stack,
  AlertTitle,
  Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "hooks/auth/useAuth";
import { useStakeholderPayments } from "hooks/stakeholder/useStakeholderPayments";
import { useStakeholder } from "hooks/stakeholder/useStakeholder";
import { PaymentProfileCard } from "./components/PaymentProfileCard";
import { ActiveProjectsCard } from "./components/ActiveProjectsCard";
import { PaymentHistory } from "./components/PaymentHistory";
import PaymentIcon from "@mui/icons-material/Payment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

export const StakeholderPayments: React.FC = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { paymentProfile, paymentProfileError, clientInvoices, clientInvoicesLoading } = useStakeholderPayments();
  const {
    stakeholderProfile,
    clients,
    isLoadingStakeholderProfile: isLoadingStakeholder,
  } = useStakeholder(currentUser?.uid);

  const handleSetupPayment = () => {
    navigate("/stakeholder/onboarding", { state: { startAtStep: 3 } });
  };

  if (isLoadingStakeholder || clientInvoicesLoading) {
    return <div>Loading...</div>;
  }

  // Calculate payment overview metrics using clientInvoices
  const pendingInvoices = clientInvoices?.filter(invoice => invoice.status === "unpaid") || [];
  const totalExpectedAmount = pendingInvoices.reduce(
    (total, invoice) => total + (invoice.totalDollarAmountDue || 0),
    0
  );

  return (
    <Box sx={{ p: 3 }}>
      {paymentProfileError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          Error loading payment profile: {paymentProfileError.message}
        </Alert>
      )}

      <Grid container spacing={3}>
        {/* Payment Overview Section - Left Side (70%) */}
        <Grid item xs={12} lg={8}>
          <Stack spacing={3}>
            {/* Payment Overview Card */}
            <Paper
              elevation={0}
              sx={{
                border: 1,
                borderColor: "divider",
                borderRadius: 2,
              }}
            >
              <Box
                sx={{
                  p: 2,
                  borderBottom: 1,
                  borderColor: "divider",
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <AccountBalanceWalletIcon color="primary" />
                <Typography variant="h6">Payment Overview</Typography>
              </Box>

              <Box sx={{ p: 3 }}>
                <Stack spacing={2}>
                  {pendingInvoices.length > 0 ? (
                    <>
                      <Box sx={{ textAlign: 'center', mb: 1 }}>
                        <Typography variant="h4" color="text.primary" gutterBottom>
                          ${totalExpectedAmount.toLocaleString()}
                        </Typography>
                        <Typography variant="subtitle1" color="text.secondary">
                          Total Outstanding Amount
                        </Typography>
                      </Box>

                      <Alert
                        severity="warning"
                        icon={<ReceiptIcon />}
                      >
                        <AlertTitle>Pending Invoices</AlertTitle>
                        You have {pendingInvoices.length} invoice
                        {pendingInvoices.length !== 1 ? "s" : ""} pending payment
                      </Alert>

                      <Alert
                        severity="info"
                        icon={<PaymentIcon />}
                      >
                        <AlertTitle>Payments Due </AlertTitle>
                        {pendingInvoices.map((invoice) => (
                          <Typography key={invoice.id} variant="body1">
                           Invoice #{invoice.invoiceNumber}: {clients?.find(client => client.id === invoice.clientId)?.companyName} - ${invoice.totalDollarAmountDue?.toLocaleString()}
                          </Typography>
                        ))}
                      </Alert>
                    </>
                  ) : (
                    <Alert severity="success">
                      <AlertTitle>All Set!</AlertTitle>
                      You have no pending invoices or expected payments at this time.
                    </Alert>
                  )}
                </Stack>
              </Box>
            </Paper>

            {/* Payment History */}
            <PaymentHistory />
          </Stack>
        </Grid>

        {/* Payment Profile Section - Right Side (30%) */}
        <Grid item xs={12} lg={4}>
          {paymentProfile ? (
            <PaymentProfileCard
              stakeholder={stakeholderProfile}
              privateExternalAccountDetails={paymentProfile}
            />
          ) : (
            <Paper
              elevation={0}
              sx={{
                border: 1,
                borderColor: "divider",
                borderRadius: 2,
                p: 3,
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                variant="body1"
                color="text.secondary"
                gutterBottom
                align="center"
              >
                No payment profile set up yet. Set up your payment profile to
                receive payments.
              </Typography>
              <Button
                variant="contained"
                onClick={handleSetupPayment}
                sx={{ mt: 2 }}
                startIcon={<PaymentIcon />}
              >
                Set Up Payment Profile
              </Button>
            </Paper>
          )}
        </Grid>

        {/* Active Projects Section */}
        <Grid item xs={12}>
          <ActiveProjectsCard clients={clients} />
        </Grid>
      </Grid>
    </Box>
  );
};
