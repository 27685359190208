import { doc, updateDoc } from "firebase/firestore";
import { db } from "config/firebase";
import { UpdateUserParams } from "../../../types/admin";
import { PrivateUserInfoType } from "types/user";

export const updateUser = async (
  userData: UpdateUserParams
): Promise<PrivateUserInfoType> => {
  const userRef = doc(db, "users", userData.id);

  // Remove any undefined or null values
  const cleanedData = Object.entries(userData).reduce(
    (acc, [key, value]) => {
      if (value !== undefined && value !== null) {
        acc[key] = value;
      }
      return acc;
    },
    {} as Record<string, any>
  );

  // Convert string boolean values to actual booleans
  if (typeof cleanedData.disabled === "string") {
    cleanedData.disabled = cleanedData.disabled === "true";
  }
  if (typeof cleanedData.emailVerified === "string") {
    cleanedData.emailVerified = cleanedData.emailVerified === "true";
  }

  await updateDoc(userRef, cleanedData);
  return userData;
};
