import React, { memo, useState, useCallback, useMemo } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { alpha } from "@mui/material/styles";
import {
  Draggable,
  Droppable,
  DroppableProvided,
  DraggableProvided,
  DraggableStateSnapshot,
} from "@hello-pangea/dnd";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";
import FreeloTicket from "./FreeloTicket";
import { useFreelo } from "hooks/freelo/useFreelo";
import DraggableCard from "./DraggableCard";
import { List, Card } from "types/freelo";
interface FreeloListProps {
  list: List;
  index: number;
  cards: Card[];
  cardOrder: string[];
  boardId: string;
  onListMenuOpen: (
    event: React.MouseEvent<HTMLElement>,
    listId: string
  ) => void;
}

// Memoize the card list to prevent unnecessary rerenders
const CardList = memo(
  ({
    cards,
    boardId,
    listId,
    onUpdate,
    onArchive,
    onCopy,
    onTrash,
  }: {
    cards: Card[];
    boardId: string;
    listId: string;
    onUpdate: (cardId: string, updates: Partial<Card>) => Promise<void>;
    onArchive: (cardId: string) => Promise<void>;
    onCopy: (card: Card) => Promise<void>;
    onTrash: (cardId: string, card: Card) => Promise<void>;
  }) => {
    return (
      <>
        {cards.map((card: Card, index: number) => (
          <DraggableCard
            key={card.id}
            card={card}
            index={index}
            boardId={boardId}
            listId={listId}
            onUpdate={onUpdate}
            onArchive={onArchive}
            onCopy={onCopy}
            onTrash={onTrash}
          />
        ))}
      </>
    );
  }
);

CardList.displayName = "CardList";

// Memoize the inner content to prevent re-renders when dragging over
const InnerList = memo(
  ({
    cards,
    boardId,
    listId,
    onUpdate,
    onArchive,
    onCopy,
    onTrash,
    provided,
    isAddingCard,
    isCreatingCard,
    newCardName,
    onCreateCard,
    onAddCardClick,
    onCancelAdd,
    onCardNameChange,
  }: {
    cards: Card[];
    boardId: string;
    listId: string;
    onUpdate: (cardId: string, updates: Partial<Card>) => Promise<void>;
    onArchive: (cardId: string) => Promise<void>;
    onCopy: (card: Card) => Promise<void>;
    onTrash: (cardId: string, card: Card) => Promise<void>;
    provided: DroppableProvided;
    isAddingCard: boolean;
    isCreatingCard: boolean;
    newCardName: string;
    onCreateCard: () => void;
    onAddCardClick: () => void;
    onCancelAdd: () => void;
    onCardNameChange: (value: string) => void;
  }) => (
    <Box
      ref={provided.innerRef}
      {...provided.droppableProps}
      sx={{
        p: 1,
        flex: 1,
        overflowY: "auto",
        minHeight: 100,
      }}
    >
      <CardList
        cards={cards}
        boardId={boardId}
        listId={listId}
        onUpdate={onUpdate}
        onArchive={onArchive}
        onCopy={onCopy}
        onTrash={onTrash}
      />
      {provided.placeholder}

      {/* Add Card Button/Form */}
      {isAddingCard ? (
        <Box sx={{ p: 1 }}>
          <TextField
            autoFocus
            fullWidth
            placeholder="Enter card title..."
            size="small"
            value={newCardName}
            onChange={(e) => onCardNameChange(e.target.value)}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                onCreateCard();
              }
            }}
            disabled={isCreatingCard}
            sx={{
              mb: 1,
              "& .MuiOutlinedInput-root": {
                bgcolor: "white",
              },
            }}
          />
          <Box sx={{ display: "flex", gap: 1 }}>
            <Button
              variant="contained"
              size="small"
              onClick={onCreateCard}
              disabled={isCreatingCard}
            >
              {isCreatingCard ? "Adding..." : "Add card"}
            </Button>
            <IconButton size="small" onClick={onCancelAdd}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Button
          fullWidth
          startIcon={<AddIcon />}
          onClick={onAddCardClick}
          sx={{
            mt: 1,
            justifyContent: "flex-start",
            color: "text.secondary",
            "&:hover": {
              bgcolor: "rgba(0, 0, 0, 0.04)",
            },
          }}
        >
          Add a card
        </Button>
      )}
    </Box>
  )
);

InnerList.displayName = "InnerList";

export const FreeloList = memo(
  ({ list, index, boardId, onListMenuOpen }: FreeloListProps) => {
    const { createCard, updateCard, moveCardToTrash } = useFreelo();

    const [isAddingCard, setIsAddingCard] = useState<boolean>(false);
    const [newCardName, setNewCardName] = useState("");
    const [isCreatingCard, setIsCreatingCard] = useState(false);

    const visibleCards = useMemo(() => {
      if (!list) return [];

      const nonArchivedCards = list.cards.filter(
        (card: Card) => !card.archived
      );

      if (
        list.cardOrder &&
        Array.isArray(list.cardOrder) &&
        list.cardOrder.length > 0
      ) {
        const orderedCards = list.cardOrder
          .map((cardId: string) =>
            nonArchivedCards.find((card: Card) => card.id === cardId)
          )
          .filter((card: Card | undefined): card is Card => card !== undefined);

        const unorderedCards = nonArchivedCards.filter(
          (card: Card) => !list.cardOrder?.includes(card.id)
        );

        return [...orderedCards, ...unorderedCards];
      }

      return nonArchivedCards;
    }, [list]);

    const handleUpdateCard = useCallback(
      async (cardId: string, updates: Partial<Card>) => {
        await updateCard.mutateAsync({
          boardId,
          cardId,
          updates,
        });
      },
      [boardId, updateCard]
    );

    const handleArchiveCard = useCallback(
      async (cardId: string) => {
        await updateCard.mutateAsync({
          boardId,
          cardId,
          updates: { archived: true },
        });
      },
      [boardId, updateCard]
    );

    const handleCopyCard = useCallback(
      async (card: Card) => {
        await createCard.mutateAsync({
          boardId,
          listId: list.id,
          title: card.title,
          description: card.description,
          assignee: card.assignee,
          reporter: card.reporter,
          additionalAssignees: card.additionalAssignees,
          labels: card.labels,
          dueDate: card.dueDate,
          startDate: card.startDate,
        });
      },
      [boardId, list.id, createCard]
    );

    const handleTrashCard = useCallback(
      async (cardId: string, card: Card) => {
        await moveCardToTrash.mutateAsync({
          boardId,
          listId: list.id,
          cardId,
          card,
        });
      },
      [boardId, list.id, moveCardToTrash]
    );

    const handleCreateCard = useCallback(async () => {
      if (!newCardName.trim() || isCreatingCard) return;

      setIsCreatingCard(true);
      try {
        await createCard.mutateAsync({
          boardId,
          listId: list.id,
          title: newCardName,
        });
        setNewCardName("");
        setIsAddingCard(false);
      } finally {
        setIsCreatingCard(false);
      }
    }, [boardId, list.id, newCardName, isCreatingCard, createCard]);

    // Memoize the draggable render function
    const renderDraggable = useCallback(
      (provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <Paper
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            width: 280,
            bgcolor: list.color
              ? alpha(list.color, 0.1)
              : snapshot.isDragging
                ? "action.hover"
                : "grey.50",
            borderTop: list.color ? `2px solid ${list.color}` : undefined,
            height: "fit-content",
            maxHeight: "100%",
            display: "flex",
            flexDirection: "column",
            flexShrink: 0,
          }}
        >
          {/* Header */}
          <Box
            {...provided.dragHandleProps}
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: 1,
              borderColor: "divider",
              bgcolor: "background.paper",
              cursor: "grab",
              "&:active": {
                cursor: "grabbing",
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{
                cursor: "pointer",
                "&:hover": {
                  color: "primary.main",
                },
              }}
            >
              {list.name}
            </Typography>
            <IconButton
              size="small"
              onClick={(e) => onListMenuOpen(e, list.id)}
            >
              <MoreVertIcon />
            </IconButton>
          </Box>

          {/* Cards */}
          <Droppable droppableId={list.id} type="card">
            {(droppableProvided) => (
              <InnerList
                cards={visibleCards}
                boardId={boardId}
                listId={list.id}
                onUpdate={handleUpdateCard}
                onArchive={handleArchiveCard}
                onCopy={handleCopyCard}
                onTrash={handleTrashCard}
                provided={droppableProvided}
                isAddingCard={isAddingCard}
                isCreatingCard={isCreatingCard}
                newCardName={newCardName}
                onCreateCard={handleCreateCard}
                onAddCardClick={() => setIsAddingCard(true)}
                onCancelAdd={() => {
                  setIsAddingCard(false);
                  setNewCardName("");
                }}
                onCardNameChange={setNewCardName}
              />
            )}
          </Droppable>
        </Paper>
      ),
      [list, boardId, isAddingCard, isCreatingCard, newCardName]
    );

    return (
      <Draggable draggableId={list.id} index={index}>
        {renderDraggable}
      </Draggable>
    );
  },
  (prevProps, nextProps) => {
    // Custom comparison function for memo
    return (
      prevProps.list === nextProps.list &&
      prevProps.index === nextProps.index &&
      prevProps.boardId === nextProps.boardId &&
      prevProps.cards === nextProps.cards
    );
  }
);

FreeloList.displayName = "FreeloList";

export default FreeloList;
